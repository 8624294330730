import { Form } from 'antd'

import Loading from 'components/Loading'

import { yesNoOptions } from 'utils/commonOptions'
import CustomFooter from 'pages/QuickSearch/CustomFooter/CustomFooter'

import { StyledContainer, StyledTitle, StyledTitleContainer, StyledRequiredMsg, StyledBreakLine } from './HeartAndVascularDetails.styles'
import CustomFormItem from 'components/CustomFormItem/CustomFormItem'
import useHeartAndVascularDetails from 'hooks/clients/QuickSearch/MedicalDetails/useHeartAndVascularDetails'

const HeartAndVascularDetails = () => {
  const {
    loading,
    handleNext,
    handleAntdFormItemChange,
    flattenedFormData,
    customerFirstName,
    customerSecondName,
    enterMedicalDetailsOfCustomerFirst,
    enterMedicalDetailsOfCustomerSecond,
  } = useHeartAndVascularDetails()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <Form
        layout="vertical"
        size="large"
        name="basic"
        onFinish={handleNext}
        initialValues={flattenedFormData}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '12px',
          marginTop: '8px',
          width: '100%',
        }}
      >
        {enterMedicalDetailsOfCustomerFirst && (
          <>
            <StyledTitleContainer>
              <StyledTitle>Heart And Vascular Details of {customerFirstName}</StyledTitle>
              <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
            </StyledTitleContainer>
            <CustomFormItem
              label="Have you had a heart attack? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.hadHeartAttack"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerFirst.hadHeartAttack'] && (
              <>
                <CustomFormItem
                  label="Did it require hospitalisation? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerFirst.heartAttackRequiredHospitalisation"
                  type="radio"
                  rules={['isRequired']}
                />
                <CustomFormItem
                  label="Date of diagnosis (YYYY-MM) *"
                  onChange={handleAntdFormItemChange}
                  name="customerFirst.heartAttackDateOfDiagnosis"
                  rules={['isRequired']}
                />
              </>
            )}
            <CustomFormItem
              label="Have you had surgery for a heart condition? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.hadSurgeryForHeartCondition"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerFirst.hadSurgeryForHeartCondition'] && (
              <>
                <CustomFormItem
                  label="Have you had a heart bypass or coronary artery bypass graft or angioplasty? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerFirst.hadHeartOrArteryBypass"
                  type="radio"
                  rules={['isRequired']}
                />
                {flattenedFormData['customerFirst.hadHeartOrArteryBypass'] && (
                  <CustomFormItem
                    label="Date of (initial) surgery (YYYY-MM) *"
                    onChange={handleAntdFormItemChange}
                    name="customerFirst.bypassSurgeryDate"
                    rules={['isRequired']}
                  />
                )}
                <CustomFormItem
                  label="Have you had a stent inserted? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerFirst.hadStentInserted"
                  type="radio"
                  rules={['isRequired']}
                />
                {flattenedFormData['customerFirst.hadStentInserted'] && (
                  <CustomFormItem
                    label="Date of (initial) surgery (YYYY-MM) *"
                    onChange={handleAntdFormItemChange}
                    name="customerFirst.stentSurgeryDate"
                    rules={['isRequired']}
                  />
                )}
                <CustomFormItem
                  label="Have you had a heart valve replacement? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerFirst.hadValveReplacement"
                  type="radio"
                  rules={['isRequired']}
                />
                {flattenedFormData['customerFirst.hadValveReplacement'] && (
                  <CustomFormItem
                    label="Date of (initial) surgery (YYYY-MM) *"
                    onChange={handleAntdFormItemChange}
                    name="customerFirst.valveReplacementSurgeryDate"
                    rules={['isRequired']}
                  />
                )}
                <CustomFormItem
                  label="Have you had a pacemaker or ICD (Implantable cardioverter defibrillator) fitted? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerFirst.hadPaceMakerOrICDFitted"
                  type="radio"
                  rules={['isRequired']}
                />
                {flattenedFormData['customerFirst.hadPaceMakerOrICDFitted'] && (
                  <CustomFormItem
                    label="Date of (initial) surgery (YYYY-MM) *"
                    onChange={handleAntdFormItemChange}
                    name="customerFirst.pacemakerOrICDSurgeryDate"
                    rules={['isRequired']}
                  />
                )}
              </>
            )}
            <CustomFormItem
              label="Have you been diagnosed with ischaemic heart disease or coronary artery disease, requiring prescribed daily medication? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.isDiagnosedWithIschaemicHeartDisease"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerFirst.isDiagnosedWithIschaemicHeartDisease'] && (
              <CustomFormItem
                label="Date of diagnosis (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerFirst.ischaemicHeartDiseaseDateOfDiagnosis"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="Have you been diagnosed with angina, requiring prescribed daily medication? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.isDiagnosedWithAngina"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerFirst.isDiagnosedWithAngina'] && (
              <CustomFormItem
                label="Date of diagnosis (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerFirst.anginaDateOfDiagnosis"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="Have you been diagnosed with peripheral vascular disease (including intermittent claudication)? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.isDiagnosedWithPeripheralVascularDisease"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerFirst.isDiagnosedWithPeripheralVascularDisease'] && (
              <CustomFormItem
                label="Date of diagnosis (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerFirst.peripheralVascularDiseaseDateOfDiagnosis"
                rules={['isRequired']}
              />
            )}
          </>
        )}
        {enterMedicalDetailsOfCustomerSecond && (
          <>
            {enterMedicalDetailsOfCustomerFirst && <StyledBreakLine />}
            <StyledTitleContainer>
              <StyledTitle>Heart And Vascular Details of {customerSecondName}</StyledTitle>
              <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
            </StyledTitleContainer>
            <CustomFormItem
              label="Have you had a heart attack? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.hadHeartAttack"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerSecond.hadHeartAttack'] && (
              <>
                <CustomFormItem
                  label="Did it require hospitalisation? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerSecond.heartAttackRequiredHospitalisation"
                  type="radio"
                  rules={['isRequired']}
                />
                <CustomFormItem
                  label="Date of diagnosis (YYYY-MM) *"
                  onChange={handleAntdFormItemChange}
                  name="customerSecond.heartAttackDateOfDiagnosis"
                  rules={['isRequired']}
                />
              </>
            )}
            <CustomFormItem
              label="Have you had surgery for a heart condition? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.hadSurgeryForHeartCondition"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerSecond.hadSurgeryForHeartCondition'] && (
              <>
                <CustomFormItem
                  label="Have you had a heart bypass or coronary artery bypass graft or angioplasty? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerSecond.hadHeartOrArteryBypass"
                  type="radio"
                  rules={['isRequired']}
                />
                {flattenedFormData['customerSecond.hadHeartOrArteryBypass'] && (
                  <CustomFormItem
                    label="Date of (initial) surgery (YYYY-MM) *"
                    onChange={handleAntdFormItemChange}
                    name="customerSecond.bypassSurgeryDate"
                    rules={['isRequired']}
                  />
                )}
                <CustomFormItem
                  label="Have you had a stent inserted? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerSecond.hadStentInserted"
                  type="radio"
                  rules={['isRequired']}
                />
                {flattenedFormData['customerSecond.hadStentInserted'] && (
                  <CustomFormItem
                    label="Date of (initial) surgery (YYYY-MM) *"
                    onChange={handleAntdFormItemChange}
                    name="customerSecond.stentSurgeryDate"
                    rules={['isRequired']}
                  />
                )}
                <CustomFormItem
                  label="Have you had a heart valve replacement? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerSecond.hadValveReplacement"
                  type="radio"
                  rules={['isRequired']}
                />
                {flattenedFormData['customerSecond.hadValveReplacement'] && (
                  <CustomFormItem
                    label="Date of (initial) surgery (YYYY-MM) *"
                    onChange={handleAntdFormItemChange}
                    name="customerSecond.valveReplacementSurgeryDate"
                    rules={['isRequired']}
                  />
                )}
                <CustomFormItem
                  label="Have you had a pacemaker or ICD (Implantable cardioverter defibrillator) fitted? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerSecond.hadPaceMakerOrICDFitted"
                  type="radio"
                  rules={['isRequired']}
                />
                {flattenedFormData['customerSecond.hadPaceMakerOrICDFitted'] && (
                  <CustomFormItem
                    label="Date of (initial) surgery (YYYY-MM) *"
                    onChange={handleAntdFormItemChange}
                    name="customerSecond.pacemakerOrICDSurgeryDate"
                    rules={['isRequired']}
                  />
                )}
              </>
            )}
            <CustomFormItem
              label="Have you been diagnosed with ischaemic heart disease or coronary artery disease, requiring prescribed daily medication? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.isDiagnosedWithIschaemicHeartDisease"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerSecond.isDiagnosedWithIschaemicHeartDisease'] && (
              <CustomFormItem
                label="Date of diagnosis (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerSecond.ischaemicHeartDiseaseDateOfDiagnosis"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="Have you been diagnosed with angina, requiring prescribed daily medication? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.isDiagnosedWithAngina"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerSecond.isDiagnosedWithAngina'] && (
              <CustomFormItem
                label="Date of diagnosis (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerSecond.anginaDateOfDiagnosis"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="Have you been diagnosed with peripheral vascular disease (including intermittent claudication)? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.isDiagnosedWithPeripheralVascularDisease"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerSecond.isDiagnosedWithPeripheralVascularDisease'] && (
              <CustomFormItem
                label="Date of diagnosis (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerSecond.peripheralVascularDiseaseDateOfDiagnosis"
                rules={['isRequired']}
              />
            )}
          </>
        )}
        <CustomFooter loading={loading} buttonText="Next" buttonTheme="light" />
      </Form>
    </StyledContainer>
  )
}

export default HeartAndVascularDetails
