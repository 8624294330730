import { ICustomersDetails } from 'interfaces/common/customersDetails'

export const getCustomersOptions = (customersData: ICustomersDetails, jointOption: boolean) => {
  const customerFirst = customersData?.customerFirst
  const customerSecond = customersData?.customerSecond

  const customerFirstLabel = customerFirst?.firstName ? `${customerFirst?.firstName || ''} ${customerFirst?.lastName || ''}` : 'Customer 1'
  const customerSecondLabel = customerSecond?.firstName ? `${customerSecond?.firstName || ''} ${customerSecond?.lastName || ''}` : 'Customer 2'

  return [
    { label: customerFirstLabel, value: 'customerFirst' },
    { label: customerSecondLabel, value: 'customerSecond' },
    ...(jointOption ? [{ label: 'Joint', value: 'both' }] : []),
  ]
}
