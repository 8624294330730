import { useContext, useEffect, useState } from 'react'
import message from 'antd/lib/message'

import Forms from 'contexts/Forms'
import { useGetQuickSearchDataForPropertyAndLanding } from 'services/clients/clients.service'
import { commonUtils } from 'utils'
import { IPropertyAndLanding, IQuickSearch } from 'interfaces/quickSearch.interface'

const usePropertyAndLandingTab = () => {
  const {
    quickSearch: { tabs, activeTab, setActiveTab },
  } = useContext(Forms)
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { data, loading: initialDataLoading } = useGetQuickSearchDataForPropertyAndLanding(idNumber)
  const [loading, setLoading] = useState(initialDataLoading)

  const [formData, setFormData] = useState({
    propertyValue: data?.propertyValue,
    propertyType: data?.propertyType,
    inheritanceProtection: data?.inheritanceProtection,
    postcode: data?.address?.postcode,
    houseNameOrNumber: data?.houseNameOrNumber,
    location: data?.location,
    tenureType: data?.tenureType,
    leasePeriod: data?.leasePeriod,
    isExLocalAuthorityOrMODProperty: data?.isExLocalAuthorityOrMODProperty,
    lendingOptions: ['initialAdvance'],
    isMaximumPaymentOnInitialAdvance: data?.isMaximumPaymentOnInitialAdvance,
    paymentAmountOnInitialAdvance: data?.paymentAmountOnInitialAdvance,
    includeCashbackInLoan: data?.includeCashbackInLoan,
    isMaximumPaymentOnReserveFacility: data?.isMaximumPaymentOnReserveFacility,
    paymentAmountOnReserveFacility: data?.paymentAmountOnReserveFacility,
    isMaximumPaymentOnRegularPayments: data?.isMaximumPaymentOnRegularPayments,
    paymentAmountOnRegularPayments: data?.paymentAmountOnRegularPayments,
  })

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [loading])

  useEffect(() => {
    const localData: IPropertyAndLanding = JSON.parse(localStorage.getItem('quickSearch') || '{}').propertyAndLanding
    const initialData = {
      propertyValue: localData?.propertyValue || data?.propertyValue,
      propertyType: localData?.propertyType || data?.propertyType,
      inheritanceProtection: localData?.inheritanceProtection || data?.inheritanceProtection || false,
      postcode: localData?.postcode || data?.address?.postcode,
      houseNameOrNumber: localData?.houseNameOrNumber || data?.houseNameOrNumber,
      location: localData?.location || data?.location,
      tenureType: localData?.tenureType || data?.tenureType,
      leasePeriod: localData?.leasePeriod || data?.leasePeriod,
      isExLocalAuthorityOrMODProperty: localData?.isExLocalAuthorityOrMODProperty || data?.isExLocalAuthorityOrMODProperty,
      lendingOptions: localData?.lendingOptions || ['initialAdvance'],
      isMaximumPaymentOnInitialAdvance: localData?.isMaximumPaymentOnInitialAdvance || data?.isMaximumPaymentOnInitialAdvance,
      paymentAmountOnInitialAdvance: localData?.paymentAmountOnInitialAdvance || data?.paymentAmountOnInitialAdvance,
      includeCashbackInLoan: localData?.includeCashbackInLoan || data?.includeCashbackInLoan,
      isMaximumPaymentOnReserveFacility: localData?.isMaximumPaymentOnReserveFacility || data?.isMaximumPaymentOnReserveFacility,
      paymentAmountOnReserveFacility: localData?.paymentAmountOnReserveFacility || data?.paymentAmountOnReserveFacility,
      isMaximumPaymentOnRegularPayments: localData?.isMaximumPaymentOnRegularPayments || data?.isMaximumPaymentOnRegularPayments,
      paymentAmountOnRegularPayments: localData?.paymentAmountOnRegularPayments || data?.paymentAmountOnRegularPayments,
    }

    setFormData(initialData)
    setLoading(initialDataLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDataLoading])

  const handleCustomCheckboxChange = ({ name, value }: { name: string; value: string[] }) => {
    setFormData((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: value,
      }

      // Reset specific fields to default values if 'initialAdvance' is unchecked
      if (!value.includes('initialAdvance')) {
        updatedState.isMaximumPaymentOnInitialAdvance = false
        updatedState.paymentAmountOnInitialAdvance = 0 // or any default value
      }

      // Reset specific fields to default values if 'reserveFacility' is unchecked
      if (!value.includes('reserveFacility')) {
        updatedState.isMaximumPaymentOnReserveFacility = false
        updatedState.paymentAmountOnReserveFacility = 0 // or any default value
      }

      return updatedState
    })
  }

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value, e.target?.name)
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: parsedValue,
    }))
  }

  const handleCustomSelectChange = (name: string, value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const navigateNext = () => {
    const { clientDetails, medicalQuestions, propertyAndLanding, furtherInformation } = tabs
    const tabValues = [clientDetails, propertyAndLanding, furtherInformation]
    if (medicalQuestions) tabValues.splice(1, 0, medicalQuestions)
    const activeTabIndex = tabValues.indexOf(activeTab)
    setActiveTab(tabValues[activeTabIndex + 1])
  }

  const handleSubmit = async () => {
    const maxFields = [formData.isMaximumPaymentOnInitialAdvance, formData.isMaximumPaymentOnReserveFacility, formData.isMaximumPaymentOnRegularPayments]
    // Count how many max fields are set to true
    const maxCount = maxFields.filter(Boolean).length

    if (maxCount > 1) {
      message.error('It is not possible to have more than one Maximum amount specified for the Initial Advance, Regular Payment and Reserve Facility.', 5)
      return
    }

    if (!formData.lendingOptions.includes('initialAdvance') && !formData.lendingOptions.includes('regularPayments')) {
      message.error('You must select Initial advance or Regular payments', 5)
      return
    }

    const newState: IQuickSearch = {
      ...JSON.parse(localStorage.getItem('quickSearch') || '{}'),
      propertyAndLanding: formData,
    }
    localStorage.setItem('quickSearch', JSON.stringify(newState))
    navigateNext()
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill out every required field')
  }

  return {
    loading,
    formData,
    handleCustomCheckboxChange,
    handleAntdFormItemChange,
    handleCustomSelectChange,
    handleSubmit,
    onFinishFailed,
  }
}

export default usePropertyAndLandingTab
