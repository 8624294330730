import { useContext, useEffect, useState } from 'react'
import Forms from 'contexts/Forms'
import { useNavigate } from 'react-router-dom'
import config from 'config'
import generatePDF from 'utils/generatePdf'
import { IQuickSearch } from 'interfaces/quickSearch.interface'
import { IQuoteResponse } from 'interfaces/quoteResult.interface'

const useQuoteResult = () => {
  const navigate = useNavigate()
  const { quoteResultLoading, quoteResultActiveTab, setQuoteResultActiveTab } = useContext(Forms)
  const quickSearchData: IQuickSearch = JSON.parse(localStorage.getItem('quickSearch') || '{}')
  const [loading, setLoading] = useState(quoteResultLoading)
  const [pdfReportLoading, setPdfReportLoading] = useState(false)
  const [error, setError] = useState(quickSearchData.error)
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const [serviceProviderReference, setServiceProviderReference] = useState('')

  useEffect(() => {
    setLoading(quoteResultLoading)

    if (!idNumber && !quoteResultLoading) {
      const newIdNumber = Number(localStorage.getItem('idNumber'))
      navigate(`/quote-result?idNumber=${newIdNumber}`)
    }

    if (!quoteResultLoading) {
      const quoteResult: IQuoteResponse = JSON.parse(localStorage.getItem('quoteResult') || '{}')
      if (quoteResult?.successFullQuotes?.length > 0) {
        setServiceProviderReference(quoteResult.successFullQuotes[0].$['perqresp:service_provider_reference'])
      }
    }

    setError(quickSearchData.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteResultLoading])

  const clientDetails: any = quickSearchData.clientDetails.firstLife
  const furtherInformation: any = quickSearchData.furtherInformation
  const currentDate = new Date()
  const formattedDate = currentDate.toLocaleDateString('en-GB')

  const handleTabChange = (key: string) => {
    setQuoteResultActiveTab(key)
  }

  const handleGoBackBtnClick = () => {
    navigate(`/quick-search?idNumber=${idNumber}`)
  }

  const handlePdfReport = async () => {
    setPdfReportLoading(true)

    const response = await fetch(`${config.API_URL}/iress/comparison-print`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify({
        documentReferenceId: serviceProviderReference,
      }),
    })

    if (!response.ok) {
      throw new Error('Request failed')
    }

    const responseData = await response.json()

    const base64String = responseData[0]
    const filename: string = 'report.pdf'

    generatePDF(base64String, filename)
    setPdfReportLoading(false)
  }

  const handleRequote = () => {
    navigate(`/quick-search?idNumber=${idNumber}`)
  }

  return {
    loading,
    clientDetails,
    furtherInformation,
    formattedDate,
    quoteResultActiveTab,
    handleTabChange,
    handleGoBackBtnClick,
    error,
    handlePdfReport,
    pdfReportLoading,
    handleRequote,
    serviceProviderReference,
  }
}

export default useQuoteResult
