import { Slider } from 'antd'

import Layout from 'components/Layout'
import MainContentContainer from 'components/MainContentContainer/MainContentContainer'
import { FormTable } from 'components/FormTable'
import HouseValueChart from 'components/PropertyValueChart/PropertyValueChart'
import BackButton from 'components/BackButton/BackButton'

import useQuoteDetails from 'hooks/clients/QuoteDetails/useQuoteDetails'

import {
  CommissionDesc,
  CommissionTitle,
  CommissionWrapper,
  StyledContainer,
  StyledDescContainer,
  StyledHeaderContainer,
  StyledLinkBtn,
  StyledLogo,
  StyledRedirectBtn,
  StyledDesc,
  SliderWrapper,
} from './QuoteDetails.styles'
import CustomFooter from './CustomFooter/CustomFooter'

const QuoteDetails = () => {
  const {
    policySummaryColumns,
    policySummaryDataSource,
    freeSummaryColumns,
    freeSummaryDataSource,
    annualInterestColumns,
    annualInterestDataSource,
    groups,
    quoteData,
    clientDetails,
    handleLinkRedirect,
    formattedDate,
    propertyValue,
    avatar,
    handleQuoteInputRedirect,
    handleSliderChange,
    propertyGrowth,
    handleGoBackBtnClick,
    loading,
    handleConvertModal,
  } = useQuoteDetails()

  return (
    <Layout headerCustomComponent={<BackButton handleClick={handleGoBackBtnClick} />}>
      <MainContentContainer pageName="Equity release - Quote Details">
        <StyledContainer>
          <StyledHeaderContainer>
            <StyledLogo src={avatar} />
            <StyledDescContainer>
              <h1>
                Quote details for {quoteData.provider_success[0].provider_product_data[0].provider_name[0]} -{' '}
                {quoteData.provider_success[0].provider_product_data[0].provider_variant_name[0]}
              </h1>
              <p>
                This quote was produced for {clientDetails.title} {clientDetails.firstName} {clientDetails.lastName} and obtained on {formattedDate} for a
                property value of £{propertyValue} with an initial advance only. Your quote reference was 230121075111490. (
                <StyledRedirectBtn onClick={handleQuoteInputRedirect}>see quote input details</StyledRedirectBtn>)
              </p>
            </StyledDescContainer>
            <StyledLinkBtn onClick={handleLinkRedirect}>Link</StyledLinkBtn>
          </StyledHeaderContainer>
          <FormTable
            title="Policy summary"
            columns={policySummaryColumns}
            dataSource={policySummaryDataSource}
            checkboxColumn={false}
            spacesAroundGrid={false}
            headerGridGap="16px"
          />
          <FormTable
            title="Free summary"
            columns={freeSummaryColumns}
            dataSource={freeSummaryDataSource}
            checkboxColumn={false}
            spacesAroundGrid={false}
            headerGridGap="16px"
          />
          <CommissionWrapper>
            <CommissionTitle>Commission summary</CommissionTitle>
            <CommissionDesc>
              The total commission payable for this product is £{quoteData.provider_success[0].quote_results[0].commission_paid[0]._}
            </CommissionDesc>
          </CommissionWrapper>
          <FormTable
            title="Annual interest table"
            columns={annualInterestColumns}
            dataSource={annualInterestDataSource}
            checkboxColumn={false}
            spacesAroundGrid={false}
            headerGridGap="16px"
            groups={groups}
            headerHeight={80}
          />
          <HouseValueChart annualInterestDataSource={annualInterestDataSource} propertyGrowth={propertyGrowth} />
          <StyledDesc>
            Please refer to the lender KFI for full projections, noting that the assumptions and the term used by this system may differ. The projections shown
            in this table/graph are based on roll up, excluding fees and repayments, and may also differ from the Total Cost of Loan for these reasons.
          </StyledDesc>
          <SliderWrapper>
            <Slider onChange={handleSliderChange} defaultValue={2} min={0} max={20} tooltip={{ formatter: (value) => `${value}%` }} />
          </SliderWrapper>
        </StyledContainer>
      </MainContentContainer>
      <CustomFooter loading={loading} handleConvert={handleConvertModal} />
    </Layout>
  )
}

export default QuoteDetails
