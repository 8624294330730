import CustomBreadcrumb from 'components/CustomBreadcrumb'
import {
  StyledContainer,
  StyledImg,
  StyledItem,
  StyledItemsContainer,
  StyledLabel,
  StyledLeftContainer,
  // StyledRightContainer,
} from './MedicalQuestionsTab.styles'
import { MedicalQuestionsTabProps } from './MedicalQuestionsTab.types'
import DetailedForm from 'pages/QuickSearch/DetailedForm/DetailedForm'

const MedicalQuestionsTab = ({
  displayDetailForm,
  handleDetailedFormClose,
  items,
  breadcrumbItems,
  handleBreadcrumbTabChange,
  handleItemClick,
  detailedFormName,
}: MedicalQuestionsTabProps) => {
  return (
    <StyledContainer>
      <CustomBreadcrumb breadcrumbItems={breadcrumbItems} handleBreadcrumbTabChange={handleBreadcrumbTabChange} />
      {displayDetailForm ? (
        <DetailedForm handleDetailedFormClose={handleDetailedFormClose} detailedFormName={detailedFormName} />
      ) : (
        <StyledItemsContainer>
          {items.map((item: any) => (
            <StyledItem key={item.label} onClick={handleItemClick.bind(this, item)}>
              <StyledLeftContainer>
                <StyledImg src={item.icon} />
                <StyledLabel>{item.label}</StyledLabel>
              </StyledLeftContainer>
            </StyledItem>
          ))}
        </StyledItemsContainer>
      )}
    </StyledContainer>
  )
}

export default MedicalQuestionsTab
