import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import message from 'antd/lib/message'

import config from 'config'
import Forms from 'contexts/Forms'
import { AuthContext } from 'contexts'
import { useGetQuickSearchDataForFurtherInformation } from 'services/clients/clients.service'
import { commonUtils } from 'utils'
import { useCustomMutation } from 'services/shared/mutation'
import { AddQuoteDetailsPayload } from 'services/shared/mutation.payload'
import { addQuoteDetailsMutation } from 'gql/client'
import { IFurtherInformation, IQuickSearch } from 'interfaces/quickSearch.interface'

const fetchData = async () => {
  const localStorageData: IQuickSearch = JSON.parse(localStorage.getItem('quickSearch') || '{}')

  try {
    const { clientDetails, furtherInformation, propertyAndLanding, medicalDetails } = localStorageData

    localStorage.setItem(
      'quickSearch',
      JSON.stringify({
        ...localStorageData,
        error: false,
      }),
    )

    const firstLifeAge = commonUtils.calculateAge(clientDetails.firstLife.dateOfBirth)
    const secondLifeAge = commonUtils.calculateAge(clientDetails.secondLife.dateOfBirth)

    const body = JSON.stringify({
      ...furtherInformation,
      ...propertyAndLanding,
      postcode: (propertyAndLanding.postcode || '').toUpperCase(),
      equityProtection: propertyAndLanding.inheritanceProtection,
      firstLife: {
        ...clientDetails.firstLife,
        age: firstLifeAge,
        ...medicalDetails?.cancerDetails?.customerFirst,
        ...medicalDetails?.heartAndVascularDetails?.customerFirst,
        ...medicalDetails?.lifeStyleDetails?.customerFirst,
        ...medicalDetails?.otherConditionsDetails?.customerFirst,
        ...medicalDetails?.strokeDetails?.customerFirst,
      },
      isSecondLife: clientDetails.isSecondLife || false,
      secondLife: {
        ...clientDetails.secondLife,
        age: secondLifeAge,
        ...medicalDetails?.cancerDetails?.customerSecond,
        ...medicalDetails?.heartAndVascularDetails?.customerSecond,
        ...medicalDetails?.lifeStyleDetails?.customerSecond,
        ...medicalDetails?.otherConditionsDetails?.customerSecond,
        ...medicalDetails?.strokeDetails?.customerSecond,
      },
    })

    const response = await fetch(`${config.API_URL}/iress/quote-result`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body,
    })

    if (!response.ok) {
      throw new Error('Request failed')
    }

    const responseData = await response.text()

    let data
    if (responseData) {
      data = JSON.parse(responseData)
      return data
    } else {
      throw new Error('Empty response')
    }
  } catch (error) {
    // Handle any errors here
    console.error(error)
    localStorage.setItem(
      'quickSearch',
      JSON.stringify({
        ...localStorageData,
        error: true,
      }),
    )
  }
}

const useFurtherInformationTab = () => {
  const { setQuoteResultLoading } = useContext(Forms)
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const addQuoteDetails = useCustomMutation<AddQuoteDetailsPayload>({ mutation: addQuoteDetailsMutation })
  const { data, loading: initialDataLoading } = useGetQuickSearchDataForFurtherInformation(idNumber)
  const [loading, setLoading] = useState(initialDataLoading)

  const [formData, setFormData] = useState({
    productResearchEnvironment: data?.caseOverview?.productResearchEnvironment,
    // requiredFunding: data?.requiredFunding,
    // interestServicingLevel: data?.interestServicingLevel,
    residenceType: 'Main Residence',
    // propertyType: data?.propertyType,
    earlyRepaymentCharge: ['none', 'fixed', 'variable'],
    adviceFee: data?.suitabilityAndNextSteps?.adviceFee,
  })

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [loading])

  useEffect(() => {
    const localData: IFurtherInformation = JSON.parse(localStorage.getItem('quickSearch') || '{}').furtherInformation

    let initialData = {
      productResearchEnvironment: localData?.productResearchEnvironment || data?.productResearchEnvironment,
      // requiredFunding: data?.requiredFunding,
      // interestServicingLevel: data?.interestServicingLevel,
      residenceType: localData?.residenceType || 'Main Residence',
      // propertyType: data?.propertyType,
      earlyRepaymentCharge: localData?.earlyRepaymentCharge || ['none', 'fixed', 'variable'],
      adviceFee: localData?.adviceFee || data?.suitabilityAndNextSteps?.adviceFee,
    }

    setLoading(initialDataLoading)
    setFormData(initialData)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDataLoading])

  const handleCustomCheckboxChange = ({ name, value }: { name: string; value: string[] }) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value, e.target?.name)
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: parsedValue,
    }))
  }

  const handleSubmit = async () => {
    // Check if user IRESS details are verified
    if (!user?.isIressCredentialsVerified) {
      message.error('IRESS details are not verified, please verify them first from header menu')
      return
    }

    // Note: this is the every tab form's state to be used for IRESS, except the medical questions(they be added when forms be created for them.)
    const newState: IQuickSearch = {
      ...JSON.parse(localStorage.getItem('quickSearch') || '{}'),
      furtherInformation: formData,
    }
    localStorage.setItem('quickSearch', JSON.stringify(newState))
    setQuoteResultLoading(true)
    navigate(`/quote-result?idNumber=${idNumber}`)
    fetchData()
      .then((res) => {
        localStorage.setItem('quoteResult', JSON.stringify(res))
        setQuoteResultLoading(false)

        const transactionNumber = Number(res?.successFullQuotes[0].$['perqresp:service_provider_reference'])

        let clientIdNumber

        if (idNumber) {
          clientIdNumber = idNumber
        } else {
          clientIdNumber = commonUtils.generateIdNumber()
        }

        // Note: this is to update the url within memory with the idNumber
        urlParams.set('idNumber', String(clientIdNumber))
        // Note: this is to update the url with the idNumber in the browser
        window.history.pushState(null, '', '?' + urlParams.toString())

        const dataToSave = {
          idNumber: Number(clientIdNumber),
          transactionNumber,
          userInput: {
            ...newState,
            propertyAndLanding: {
              ...newState.propertyAndLanding,
              propertyValue: Number(newState.propertyAndLanding.propertyValue),
              paymentAmountOnInitialAdvance: Number(newState.propertyAndLanding.paymentAmountOnInitialAdvance),
              leasePeriod: Number(newState.propertyAndLanding.leasePeriod),
              houseNameOrNumber: String(newState.propertyAndLanding.houseNameOrNumber),
            },
            furtherInformation: {
              ...newState.furtherInformation,
              adviceFee: Number(newState.furtherInformation.adviceFee),
            },
          },
        }

        addQuoteDetails(dataToSave)
      })
      .catch((err) => {
        setQuoteResultLoading(false)
      })
  }

  const onFinishFailed = () => {
    message.error('please fill out every required field')
  }

  return {
    loading,
    formData,
    handleCustomCheckboxChange,
    handleAntdFormItemChange,
    handleSubmit,
    onFinishFailed,
  }
}

export default useFurtherInformationTab
