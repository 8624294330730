import { useNavigate } from 'react-router-dom'

const useBreadcrumb = () => {
  const navigate = useNavigate()
  const breadcrumbItems = JSON.parse(localStorage.getItem('resourcesBreadcrumbItems') || '[]')

  const handleBreadcrumbTabChange = (e: any) => {
    const itemText = e.target.innerHTML
    if (itemText === 'Resources') {
      navigate(`/resources`)
    }
    if (itemText === 'News &amp; Training') {
      navigate(`/resources/news-and-training`)
      localStorage.setItem('resourcesBreadcrumbItems', JSON.stringify(breadcrumbItems.slice(0, 2)))
    }
  }

  return {
    breadcrumbItems,
    handleBreadcrumbTabChange,
  }
}

export default useBreadcrumb
