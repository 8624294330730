import FormTable from 'components/FormTable/FormTable'
import { unsuccessfulQuotesTabColumns } from './staticData'
import { IUnsuccessfulQuote } from 'interfaces/quoteResult.interface'

const UnsuccessfulQuotesTab = () => {
  const unsuccessfulQuotes: IUnsuccessfulQuote[] = JSON.parse(localStorage.getItem('quoteResult') || '{}').unsuccessfulQuotes

  const dataSource = unsuccessfulQuotes?.map((item: IUnsuccessfulQuote) => {
    return {
      id: item.$['perqresp:quote_response_identifier'],
      provider: item.provider_error[0].provider_product[0].provider_name[0],
      product: item.provider_error[0].provider_product[0].provider_variant_name[0],
      reason: item.provider_error[0].business_error[0].error_note[0],
    }
  })

  return <FormTable columns={unsuccessfulQuotesTabColumns} dataSource={dataSource} checkboxColumn={false} spacesAroundGrid={false} headerGridGap="8px" />
}

export default UnsuccessfulQuotesTab
