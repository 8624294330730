import TextArea from 'antd/lib/input/TextArea'
import { StyledContainer, StyledLabel } from './CustomTextArea.styles'
import { CustomTextAreaProps } from './CustomTextArea.types'

const CustomTextArea = ({ label, readOnly, placeholder, value, name, onChange, rows = 2, wrapperStyles, disabled = false }: CustomTextAreaProps) => {
  return (
    <StyledContainer style={wrapperStyles}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <TextArea
        style={{ marginTop: '8px', width: '100%' }}
        readOnly={readOnly || false}
        placeholder={placeholder || 'Placeholder text'}
        value={value}
        onChange={onChange}
        rows={rows}
        name={name}
        disabled={disabled}
      />
    </StyledContainer>
  )
}

export default CustomTextArea
