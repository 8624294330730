export const pensionTypeOptions = [
  {
    label: 'State Pension',
    value: 'statePension',
  },
  {
    label: 'Private Pension',
    value: 'privatePension',
  },
  {
    label: 'Occupational Pension',
    value: 'occupationalPension',
  },
  {
    label: 'Defined Benefit Pension',
    value: 'definedBenefitPension',
  },
  {
    label: 'Defined Contribution Pension',
    value: 'definedContributionPension',
  },
  {
    label: 'Unsure',
    value: 'unsure',
  },
]

export const whenWillPensionMatureOptions = [
  {
    label: 'Within 12 months',
    value: 'within12months',
  },
  {
    label: '1-2 years',
    value: 'oneToTwoYears',
  },
  {
    label: '2-3 years',
    value: 'twoToThreeYears',
  },
  {
    label: '3-5 years',
    value: 'threeToFiveYears',
  },
  {
    label: '5-10 years',
    value: 'fiveToTenYears',
  },
  {
    label: '10-15 years',
    value: 'tenToFifteenYears',
  },
  {
    label: 'After 15 years',
    value: 'afterFifteenYears',
  },
]
