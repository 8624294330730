export const maximumPaymentOptions = [
  {
    label: 'Maximum amount',
    value: true,
  },
  {
    label: 'Specified amount',
    value: false,
  },
]

export const lendingOptions = [
  {
    label: 'Initial Advance',
    value: 'initialAdvance',
  },
  {
    label: 'Reserve Facility',
    value: 'reserveFacility',
  },
  // {
  //   label: 'Regular Payments',
  //   value: 'regularPayments',
  // },
]
