export const options = [
  {
    value: 'faceToFace',
    label: 'Face to Face',
  },
  {
    value: 'telephone',
    label: 'Telephone',
  },
  {
    value: 'videoCall',
    label: 'Video Call',
  },
]

export const customerCountOptions = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
]

export const mainPurposeOfDiscussionOptions = [
  {
    label: 'Raising Capital',
    value: 'raisingCapital',
  },
  {
    label: 'Debt Consolidation',
    value: 'debtConsolidation',
  },
]

export const caseOverviewTableColumns = [
  {
    name: 'name',
    header: 'Name',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'relationshipWithCustomer',
    header: 'Relationship',
    defaultFlex: 1,
    type: 'text',
  },
]
