import { FormsContext } from 'contexts'
import { useContext, useEffect, useState } from 'react'
import { useGetClientCount, useGetCustomerInfo } from 'services/clients/clients.service'

interface IItem {
  label: string
  icon?: string
  percentage?: number
  hasChildren: boolean
  itemName?: string
  children?: IItem[]
}

interface IHandleItemClick {
  handleItemClick: (item: IItem) => void
}

const useCustomers = ({ handleItemClick }: IHandleItemClick) => {
  const { setCustomerToEdit } = useContext(FormsContext)
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))

  const {
    data: { customerQuantity },
    loading: formInitialDataLoading,
  } = useGetClientCount(idNumber)
  const [loading, setLoading] = useState(formInitialDataLoading)

  useEffect(() => {
    setLoading(formInitialDataLoading)
  }, [formInitialDataLoading])

  const { customer: customerFirst } = useGetCustomerInfo(idNumber, 1)
  const { customer: customerSecond } = useGetCustomerInfo(idNumber, 2)

  const onEditClick = (customer: number) => {
    setCustomerToEdit(customer)

    handleItemClick({
      label: `Customer ${customer === 1 ? 'First' : 'Second'} Details`,
      hasChildren: false,
    })
  }

  return {
    loading,
    onEditClick,
    customerFirst,
    customerSecond,
    customerQuantity,
  }
}

export default useCustomers
