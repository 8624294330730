import { useState, useEffect, useContext } from 'react'
import message from 'antd/lib/message'
import Form from 'antd/lib/form'
import * as _ from 'lodash'

import { commonHelper } from 'helpers'
import { useGetCustomers, useGetPreviousAddress } from 'services/clients/clients.service'

import { commonUtils, eventManager } from 'utils'
import { FormsContext } from 'contexts'
import { addEditPreviousAddressHistoryMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { AddEditPreviousAddressHistoryPayload } from 'services/shared/mutation.payload'
import { getCustomersOptions } from 'utils/getCustomersOptions'

const initialState = {
  isOpen: false,
  data: { _id: '', isEdit: false },
  cb: { confirm: () => null, cancel: () => null },
  loading: false,
}

const usePreviousAddressHistoryModal = () => {
  const [form] = Form.useForm()
  const { propertyToEdit } = useContext(FormsContext)
  const addEditPreviousAddressHistory = useCustomMutation<AddEditPreviousAddressHistoryPayload>({
    mutation: addEditPreviousAddressHistoryMutation,
    refetchQueries: ['getPreviousAddresses'],
  })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const [showAddressDetails, setShowAddressDetails] = useState(false)

  const [modalState, setModalState] = useState(initialState)
  const previousAddressId = modalState?.data?._id
  const { previousAddress, loading: initialDataLoading } = useGetPreviousAddress({
    idNumber,
    previousAddressId,
    skip: !modalState.isOpen || !modalState.data.isEdit,
  })
  const [loading, setLoading] = useState(initialDataLoading)
  const [formData, setFormData] = useState({
    residents: previousAddress.residents,
    address: {
      formattedAddress: previousAddress?.address?.formattedAddress,
      address1: previousAddress?.address?.address1,
      address2: previousAddress?.address?.address2,
      town: previousAddress?.address?.town,
      county: previousAddress?.address?.county,
      country: previousAddress?.address?.country,
      postcode: previousAddress?.address?.postcode,
    },
    dateMovedIn: previousAddress.dateMovedIn,
    dateMovedOut: previousAddress.dateMovedOut,
  })
  const flattenedFormData = commonUtils.flattenObject(formData)
  const { data: customersData } = useGetCustomers(idNumber)

  const customersOptions = getCustomersOptions(customersData, true)

  useEffect(() => {
    setLoading(initialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(previousAddress)
    delete newData._id
    setFormData({ ...newData })
    form.setFieldsValue({ ...newData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDataLoading])

  const clearFormData = () => {
    setFormData((prevState) => commonUtils.resetFormData(prevState))
    form.setFieldsValue(commonUtils.resetFormData(formData))
  }

  const handleAntdFormInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => _.set(_.cloneDeep(prevState), e.target.name, e.target.value))
  }

  const handleCustomSelectChange = (name: string, value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const onPlaceSelect = ({ formattedAddress, address1, address2, town, county, country, postcode }: any) => {
    setFormData((prevState) => ({
      ...prevState,
      address: {
        formattedAddress,
        address1,
        address2,
        town,
        county,
        country,
        postcode,
      },
    }))

    form.setFieldValue('address.formattedAddress', formattedAddress)
    form.setFieldValue('address.address1', address1)
    form.setFieldValue('address.address2', address2)
    form.setFieldValue('address.town', town)
    form.setFieldValue('address.county', county)
    form.setFieldValue('address.country', country)
    form.setFieldValue('address.postcode', postcode)
  }

  useEffect(() => {
    eventManager.addListener('previous-address-history-modal', (data: any, cb: any) => {
      setModalState((state) => ({
        ...state,
        isOpen: data !== undefined,
        data,
        cb,
      }))
    })
  }, [setModalState])

  const handleClose = () => {
    setModalState((state) => ({ ...state, loading: false }))
    eventManager.closePreviousAddressHistoryModal()
  }

  const handleCancelClick = async () => {
    try {
      setModalState((state) => ({ ...state, loading: true }))
      if (modalState.cb.cancel) {
        await modalState.cb.cancel()
      }
    } catch (err) {
      // console.error(err)
    }
    handleClose()
    clearFormData()
  }

  const handleSubmit = async () => {
    const { errors } = await addEditPreviousAddressHistory({
      idNumber,
      addressToEdit: previousAddressId,
      propertyToEdit,
      values: formData,
    })
    if (errors?.length) {
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const handleConfirmClick = async () => {
    try {
      setModalState((state) => ({ ...state, loading: true }))
      await handleSubmit()
      clearFormData()
      if (modalState.cb.confirm) {
        await modalState.cb.confirm()
      }
    } catch (err) {
      // console.error(err)
    }
    setModalState((state) => ({ ...state, loading: false }))
    handleClose()
  }

  const showAddressDetailsChange = (checked: boolean) => {
    setShowAddressDetails(checked)
  }

  return {
    modalState,
    handleCancelClick,
    handleConfirmClick,
    formData,
    flattenedFormData,
    handleAntdFormInputChange,
    onPlaceSelect,
    showAddressDetails,
    showAddressDetailsChange,
    loading,
    form,
    handleCustomSelectChange,
    customersOptions,
  }
}

export default usePreviousAddressHistoryModal
