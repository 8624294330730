import { DocumentNode, OperationVariables, useMutation, FetchResult } from '@apollo/client'

export type MutationResultType = FetchResult<any, Record<string, any>, Record<string, any>>

// eslint-disable-next-line
interface ICustomMutationProps<TVariables extends OperationVariables> {
  mutation: DocumentNode
  refetchQueries?: string[]
}

type EnforceNonEmptyRecord<R> = keyof R extends never ? never : R

/**
 * @param mutation the mutation query to be used
 * @param refetchQueries the queries to be re-fetched after mutation
 * @returns {function} The mutation function.
 * ```typescript
 * import { myMutation } from 'services/clients/clients.service'
 * type MyPayloadType = { field1: string, field2: number };
 *
 * const customFunc = useCustomMutation<MyPayloadType>({
 *   mutation: myMutation,
 *   refetchQueries: ['QueryToRefetch'],
 * });
 * await customFunc({ field1: 'value', field2: 42 });
 * ```
 */
export const useCustomMutation = <TVariables extends EnforceNonEmptyRecord<OperationVariables>>({
  mutation,
  refetchQueries,
}: ICustomMutationProps<TVariables>) => {
  const [mutate] = useMutation(mutation, { refetchQueries })

  const customFunc = async (mutationPayload: TVariables): Promise<MutationResultType> => {
    return await mutate({ variables: mutationPayload })
  }

  return customFunc
}
