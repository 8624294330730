import { useState } from 'react'
import message from 'antd/lib/message'

import { useGetClientFilesQuery } from 'services/clients/clients.service'
import { eventManager } from 'utils'
import { commonHelper } from 'helpers'
import { useCustomMutation } from 'services/shared/mutation'
import { deleteFilesMutation } from 'gql/client'
import { DeleteWithIdNumberAnd_IdsPayload } from 'services/shared/mutation.payload'

interface IFile {
  _id: string
  description: string
  createdAt: string
  link: string
}

interface IFilesData {
  data: {
    id: string
    description: string
    createdAt: string
    link: string
  }
}

const useFilesTabContents = () => {
  const deleteFiles = useCustomMutation<DeleteWithIdNumberAnd_IdsPayload>({
    mutation: deleteFilesMutation,
    refetchQueries: ['getClientFiles'],
  })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { data, refetch } = useGetClientFilesQuery(idNumber)
  const [selected, setSelected] = useState({})

  const filesColumns = [
    {
      name: 'description',
      header: 'Description',
      defaultFlex: 1,
      type: 'text',
      render: ({ data }: IFilesData) => (
        <p
          style={{
            color: '#120224',
            fontSize: '16px',
            lineHeight: '24px',
          }}
        >
          {data.description}
        </p>
      ),
    },
    {
      name: 'createdAt',
      header: 'Created At',
      defaultFlex: 1,
      type: 'text',
      render: ({ data }: IFilesData) => (
        <p
          style={{
            color: '#120224',
            fontSize: '16px',
            lineHeight: '24px',
          }}
        >
          {data.createdAt}
        </p>
      ),
    },
    {
      name: 'link',
      header: 'Option',
      defaultFlex: 1,
      type: 'text',
      render: ({ data }: IFilesData) => (
        <p
          style={{
            textAlign: 'center',
            fontSize: '16px',
            lineHeight: '24px',
            cursor: 'pointer',
            fontWeight: 500,
          }}
        >
          <a href={data.link} target="_blank" rel="noreferrer" style={{ color: '#219653' }}>
            DOWNLOAD
          </a>
        </p>
      ),
    },
  ]

  const filesDataSource = data?.map((item: IFile) => {
    const formattedDate = new Date(Number(item.createdAt)).toLocaleDateString('en-GB')

    return {
      id: item._id,
      description: item.description,
      createdAt: formattedDate,
      link: item.link,
    }
  })

  const handleAddFile = () => {
    eventManager.openFileUploadModal(
      {},
      {
        confirm: refetch,
      },
    )
  }
  const displayDeleteButton = !!Object.keys(selected).length

  const setSelectedRows = (selectedRowObj: any) => {
    setSelected(selectedRowObj)
  }

  const handleDelete = async () => {
    // setLoading(true)
    const files_IdToDelete = Object.keys(selected)
    const { errors } = await deleteFiles({ _ids: files_IdToDelete, idNumber })
    if (errors?.length) {
      // setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    // setLoading(false)
    refetch()
    setSelected({})
  }

  const handleDeleteModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleDelete(),
      },
    )
  }

  return {
    filesColumns,
    filesDataSource,
    handleAddFile,
    displayDeleteButton,
    setSelectedRows,
    handleDeleteModal,
  }
}

export default useFilesTabContents
