import { StyledSwitch, StyledSwitchContainer, StyledSwitchLabel } from './CustomSwitch.styles'
import { CustomSwitchProps } from './CustomSwitch.types'

// Note: This is used with google address input and styles is custom for that
const CustomSwitch = ({ checked, onChange, label, alignSelf }: CustomSwitchProps) => {
  return (
    <StyledSwitchContainer alignSelf={alignSelf}>
      <StyledSwitch checked={checked} onChange={onChange} />
      <StyledSwitchLabel>{label}</StyledSwitchLabel>
    </StyledSwitchContainer>
  )
}

export default CustomSwitch
