import { useState } from 'react'

const useDashboard = () => {
  // Note: Dashboards filter drawer state
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  // Note: Dashboard filter's save button loading state
  // const [loading, setLoading] = useState(false)

  const openFilterDrawer = () => setIsFilterOpen(true)
  const closeFilterDrawer = () => setIsFilterOpen(false)

  const handleFilterSubmit = () => {
    // setLoading(false)
    closeFilterDrawer()
  }

  return {
    handleFilterSubmit,
    // loading,
    closeFilterDrawer,
    isFilterOpen,
    openFilterDrawer,
  }
}

export default useDashboard
