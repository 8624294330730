import { Button, Checkbox } from 'antd'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  background-color: #fefefe;
  box-shadow: 0px 0px 24px rgba(18, 2, 36, 0.08);

  display: flex;
  flex-direction: column;
`

export const StyledMinContainer = styled.div`
  margin: 32px 0;

  max-width: 100%;
  width: 872px;

  align-self: center;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const StyledEmptyNotesWrapper = styled.div`
  justify-self: center;
  align-self: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  width: 571px;
  height: 236px;
  flex-shrink: 0;
  border: 1px dashed #d7ccd8;
`

export const StyledEmptyNotesText = styled.p`
  color: #603363;
  text-align: center;

  /* Headline/H5 - 24px */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 150% */
`

export const StyledHeaderActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`

export const StyledAddButton = styled.button`
  display: flex;
  width: 140px;
  padding: 12px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  background: #90258c;

  /* Headline/H5 - 24px */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`

export const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const StyledActions = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledNoteWrapper = styled.div<{ isAddNewNote?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 16px;
  border-radius: 8px;
  border: 3px solid ${({ isAddNewNote }) => (isAddNewNote ? '#90258c' : '#e6e6e7')};
`

export const StyledFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
`

export const StylesFieldLabel = styled.p`
  color: #120224;

  /* Label/Label - 16px */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
`

export const StyledRows = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`

export const StyledCancelButton = styled(Button)`
  min-width: fit-content;
  width: 140px;
  height: 48px;

  background-color: #fefefe !important;
  color: #90258c !important;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`

export const StyledSaveButton = styled(Button)`
  width: 140px;
  height: 48px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`

export const StyledItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
`

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-wrapper .ant-checkbox-inner,
  .ant-checkbox .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    width: 18px !important;
    height: 18px !important;
    border-color: #323232;
  }
`

export const StyledCheckboxMsg = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #120224;
`
