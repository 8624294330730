import { useEffect, useState } from 'react'

import { useGetContentfulDataByContentModel } from 'hooks/useContentful'

const useVideoResources = () => {
  const [data, setData] = useState<any>([])

  const { data: initialData = [], isLoading } = useGetContentfulDataByContentModel('videoResources')

  useEffect(() => {
    setData(
      initialData?.map((item: any, index: number) => {
        return {
          id: `${index}`,
          title: item.fields.title,
          video: item.fields.video, // Changed from content to video
        }
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const displayLoading = isLoading || !data

  const [videos, setVideos] = useState<any>([])

  useEffect(() => {
    if (data) {
      const videoList = data.map((item: any) => ({
        url: item?.video?.fields?.file?.url.replace('//', 'https://'), // Updated to match new structure
        title: item?.video?.fields?.title, // Updated to match new structure
      }))
      setVideos(videoList)
    }
  }, [data])

  return { videos, displayLoading }
}

export default useVideoResources
