import TextArea from 'antd/lib/input/TextArea'
import Tooltip from 'antd/lib/tooltip'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { validationUtils } from 'utils'

import { InputProps, InputRules, RenderInputP } from './CustomFormItem.types'
import {
  Wrapper,
  StyledFieldWrapper,
  StyledInput,
  StyledInputNumber,
  StyledInputPassword,
  StyledFormItem,
  StyledPrefixImg,
  StyledSelect,
  StyledRadioGroup,
  StyledRadioButton,
} from './CustomFormItem.styles'
import { ILabelAndValue } from 'interfaces/common/labelAndValue'

const grayColor = { color: '#707070' }

const renderInput = ({
  type,
  min,
  max,
  placeholder,
  label,
  height,
  readOnly,
  inputPrefixImg,
  prefixImgHeight,
  name,
  $inputStyle,
  value,

  defaultValue,
  options,
  allowClear,
  disabled,
  onChange,
  rows,
  maxTagCount,
  mode,
}: RenderInputP) => {
  const props = {
    type,
    height,
    readOnly,
    placeholder: placeholder || label,
    prefix: inputPrefixImg ? <StyledPrefixImg src={inputPrefixImg} height={prefixImgHeight} alt="search" /> : null,
    name,
    value,
    $inputStyle,
    disabled,
  }

  if (type === 'password') {
    return (
      <StyledInputPassword
        iconRender={(visible: boolean) => (visible ? <VisibilityIcon style={grayColor} /> : <VisibilityOffIcon style={grayColor} />)}
        {...props}
        style={$inputStyle}
      />
    )
  }
  if (type === 'number') {
    return <StyledInputNumber {...props} style={$inputStyle} min={min} max={max} />
  }

  if (type === 'select') {
    return (
      <StyledSelect
        showSearch={true}
        disabled={disabled}
        height={height}
        size="large"
        defaultValue={defaultValue}
        placeholder={placeholder}
        allowClear={allowClear}
        maxTagCount={maxTagCount}
        mode={mode}
        onChange={onChange.bind(this, name)}
      >
        {options?.map((option: ILabelAndValue) => (
          <StyledSelect.Option key={option.label} value={option.value}>
            {option.label}
          </StyledSelect.Option>
        ))}
      </StyledSelect>
    )
  }

  if (type === 'radio') {
    return (
      <StyledRadioGroup size="large" onChange={onChange} name={name}>
        {options?.map((option: ILabelAndValue) => (
          <StyledRadioButton key={String(option.value)} value={option.value}>
            {option.label}
          </StyledRadioButton>
        ))}
      </StyledRadioGroup>
    )
  }

  if (type === 'textarea') {
    return (
      <TextArea
        style={{ marginTop: '8px', width: '100%' }}
        readOnly={readOnly || false}
        placeholder={placeholder || 'Placeholder text'}
        value={value}
        onChange={onChange}
        rows={rows}
        name={name}
      />
    )
  }

  return <StyledInput {...props} style={$inputStyle} />
}

const CustomFormItem = ({
  label,
  name,
  value,
  defaultValue,
  rules = [],
  type = 'text',
  min,
  max,
  placeholder,
  width,
  height,
  layout = {},
  readOnly,
  required,
  asterisk,
  onChange,
  toolTipText,
  inputPrefixImg,
  prefixImgHeight,
  $inputStyle,
  options,
  allowClear = true,
  disabled = false,
  rows = 2,
  maxTagCount = 1,
  mode,
}: InputProps) => (
  <Wrapper width={width}>
    <StyledFieldWrapper>
      {!!toolTipText && (
        <Tooltip placement="top" title={toolTipText}>
          <InfoOutlinedIcon color="action" style={{ zIndex: 1 }} />
        </Tooltip>
      )}
      <StyledFormItem
        label={label}
        required={required}
        asterisk={asterisk}
        name={name}
        rules={rules.map((rule: InputRules) => validationUtils[rule])}
        {...layout}
        onChange={!!onChange ? onChange : undefined}
      >
        {renderInput({
          type,
          min,
          max,
          placeholder,
          label,
          height,
          readOnly,
          inputPrefixImg,
          prefixImgHeight,
          name,
          value,
          defaultValue,
          $inputStyle,
          options,
          onChange,
          allowClear,
          disabled,
          rows,
          maxTagCount,
          mode,
        })}
      </StyledFormItem>
    </StyledFieldWrapper>
  </Wrapper>
)

export default CustomFormItem
