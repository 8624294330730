/* eslint-disable no-useless-computed-key */
import { Form } from 'antd'

import Loading from 'components/Loading'
import CustomFormItem from 'components/CustomFormItem'
import useCreditHistory from 'hooks/clients/DetailedForms/useCreditHistory'
import CustomFooter from 'pages/ClientDetails/CustomFooter/CustomFooter'

import { StyledContainer, StyledDescription, StyledTitle, StyledTitleContainer, StyledRequiredMsg } from './CreditHistory.styles'
import { yesNoOptions } from 'utils/commonOptions'

const CreditHistory = () => {
  const { onFinishFailed, onSubmit, setSaveType, loading, formData, flattenedFormData, handleAntdFormItemChange, customers } = useCreditHistory()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <StyledTitle style={{ fontSize: '32px' }}>Credit History</StyledTitle>
        <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
      </StyledTitleContainer>
      <StyledDescription>
        Please use the buttons below to answer questions relating to CCJs, Bankruptcy, IVAs and payments on your customer’s credit history.
      </StyledDescription>
      <StyledTitle style={{ marginTop: '20px' }}>Credit History of {customers?.customerFirst?.firstName}</StyledTitle>
      <Form
        layout="vertical"
        size="large"
        name="basic"
        initialValues={flattenedFormData}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '12px',
          marginTop: '8px',
          width: '100%',
        }}
        onFinishFailed={onFinishFailed}
        onFinish={onSubmit}
      >
        <CustomFormItem
          label="Have you ever had a judgement for debt or a loan default registered against you? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          value={formData.customerFirst?.hasEverJudgementRegistered}
          name="customerFirst.hasEverJudgementRegistered"
          type="radio"
          rules={['isRequired']}
        />
        {formData.customerFirst?.hasEverJudgementRegistered && (
          <CustomFormItem
            label="Details (i.e amount, by whom and the applicant this applies to and whether settled or not)"
            placeholder="E.g will be shown here"
            value={formData.customerFirst?.applicantAndAmountDetails}
            name="customerFirst.applicantAndAmountDetails"
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        <CustomFormItem
          label="Have you ever been declared bankrupt or made an arrangement with creditors (IVA)? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          value={formData.customerFirst?.hasEverBeenBankrupt}
          name="customerFirst.hasEverBeenBankrupt"
          type="radio"
          rules={['isRequired']}
        />
        {formData.customerFirst?.hasEverBeenBankrupt && (
          <>
            <CustomFormItem
              label="Has it now been fully discharged?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              value={formData.customerFirst?.isBankruptcyFullyDischarged}
              name="customerFirst.isBankruptcyFullyDischarged"
              type="radio"
            />
            {formData.customerFirst?.isBankruptcyFullyDischarged && (
              <CustomFormItem
                label="When was it discharged?"
                onChange={handleAntdFormItemChange}
                name="customerFirst.bankruptcyDischargeDate"
                placeholder="DD/MM/YYYY"
                type="date"
              />
            )}
            <CustomFormItem
              label="Any other details regarding Bankruptcy / IVA"
              placeholder="E.g will be shown here"
              value={formData.customerFirst?.otherDetailsRegardingBankruptcy}
              name="customerFirst.otherDetailsRegardingBankruptcy"
              onChange={handleAntdFormItemChange}
              rows={4}
              type="textarea"
            />
          </>
        )}
        <CustomFormItem
          label="Have you ever failed to keep up payments under any previous or current mortgage, rental or loan arrangement?"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          value={formData.customerFirst?.hasEverBeenLateForPayments}
          name="customerFirst.hasEverBeenLateForPayments"
          type="radio"
          rules={['isRequired']}
        />
        {formData.customerFirst?.hasEverBeenLateForPayments && (
          <CustomFormItem
            label="Please provide details : (i.e amount, when, by whom and the applicant this applies to and whether settled or not)"
            placeholder="E.g will be shown here"
            value={formData.customerFirst?.detailsAboutLatePayments}
            name="customerFirst.detailsAboutLatePayments"
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        <CustomFormItem
          label="Are you currently struggling to keep up with credit repayments *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          value={formData.customerFirst?.isStrugglingWithPayments}
          name="customerFirst.isStrugglingWithPayments"
          type="radio"
          rules={['isRequired']}
        />
        {customers?.customerSecond?.firstName && (
          <>
            <StyledTitle style={{ marginTop: '20px' }}>Credit History of {customers?.customerSecond?.firstName}</StyledTitle>
            <CustomFormItem
              label="Have you ever had a judgement for debt or a loan default registered against you? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              value={formData.customerSecond?.hasEverJudgementRegistered}
              name="customerSecond.hasEverJudgementRegistered"
              type="radio"
              rules={['isRequired']}
            />
            {formData.customerSecond?.hasEverJudgementRegistered && (
              <CustomFormItem
                label="Details (i.e amount, by whom and the applicant this applies to and whether settled or not)"
                placeholder="E.g will be shown here"
                value={formData.customerSecond?.applicantAndAmountDetails}
                name="customerSecond.applicantAndAmountDetails"
                onChange={handleAntdFormItemChange}
                rows={4}
                type="textarea"
              />
            )}
            <CustomFormItem
              label="Have you ever been declared bankrupt or made an arrangement with creditors (IVA)? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              value={formData.customerSecond?.hasEverBeenBankrupt}
              name="customerSecond.hasEverBeenBankrupt"
              type="radio"
              rules={['isRequired']}
            />
            {formData.customerSecond?.hasEverBeenBankrupt && (
              <>
                <CustomFormItem
                  label="Has it now been fully discharged?"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  value={formData.customerSecond?.isBankruptcyFullyDischarged}
                  name="customerSecond.isBankruptcyFullyDischarged"
                  type="radio"
                />
                {formData.customerSecond?.isBankruptcyFullyDischarged && (
                  <CustomFormItem
                    label="When was it discharged?"
                    onChange={handleAntdFormItemChange}
                    name="customerSecond.bankruptcyDischargeDate"
                    placeholder="DD/MM/YYYY"
                    type="date"
                  />
                )}
                <CustomFormItem
                  label="Any other details regarding Bankruptcy / IVA"
                  placeholder="E.g will be shown here"
                  value={formData.customerSecond?.otherDetailsRegardingBankruptcy}
                  name="customerSecond.otherDetailsRegardingBankruptcy"
                  onChange={handleAntdFormItemChange}
                  rows={4}
                  type="textarea"
                />
              </>
            )}
            <CustomFormItem
              label="Have you ever failed to keep up payments under any previous or current mortgage, rental or loan arrangement?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              value={formData.customerSecond?.hasEverBeenLateForPayments}
              name="customerSecond.hasEverBeenLateForPayments"
              type="radio"
              rules={['isRequired']}
            />
            {formData.customerSecond?.hasEverBeenLateForPayments && (
              <CustomFormItem
                label="Please provide details : (i.e amount, when, by whom and the applicant this applies to and whether settled or not)"
                placeholder="E.g will be shown here"
                value={formData.customerSecond?.detailsAboutLatePayments}
                name="customerSecond.detailsAboutLatePayments"
                onChange={handleAntdFormItemChange}
                rows={4}
                type="textarea"
              />
            )}
            <CustomFormItem
              label="Are you currently struggling to keep up with credit repayments *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              value={formData.customerSecond?.isStrugglingWithPayments}
              name="customerSecond.isStrugglingWithPayments"
              type="radio"
              rules={['isRequired']}
            />
          </>
        )}
        <CustomFooter loading={loading} setSaveType={setSaveType} />
      </Form>
    </StyledContainer>
  )
}

export default CreditHistory
