import styled from 'styled-components'

export const StyledContainer = styled.div`
  margin-bottom: 56px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 32px;
`

export const StyledHeaderContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 32px;

  background-color: #fff;
  padding: 24px;
`

export const StyledLogo = styled.img`
  width: 168px;
`

export const StyledRedirectBtn = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

export const StyledDescContainer = styled.div`
  display: flex;
  align-self: self-start;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 16px;
`

export const StyledLinkBtn = styled.button`
  border: none;
  padding: 12px 51px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  /* White/100 */

  color: #fefefe;
`

export const CommissionWrapper = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 16px;
`

export const CommissionTitle = styled.h1`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #38003c;
`

export const CommissionDesc = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #120224;
`

export const StyledDesc = styled.div`
  width: 80%;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 16px;

  background-color: #eaeaea;

  font-weight: 400;
  font-size: 18px;
  line-height: 26px;

  color: #120224;
`

export const SliderWrapper = styled.div`
  width: 80%;
`
