import styled from 'styled-components'

export const StyledContainer = styled.div`
  margin-top: 28px;
  width: max-content;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  max-width: 100%;
`

export const StyledLabel = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #120224;

  max-width: 100%;
`

export const ValuesContainer = styled.div<{ optionsCount: number }>`
  width: 100%;
  height: 160px;
  display: grid;
  justify-content: center;
  align-items: center;

  grid-template-columns: repeat(${({ optionsCount }) => optionsCount}, 1fr);

  column-gap: 16px;

  margin-top: 12px;
`

export const StyledOption = styled.p<{ bgColor: string; color: string }>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px 16px;
  background: ${({ bgColor }) => bgColor};

  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: ${({ color }) => color};
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 #ef9a9a, 0 1px 5px 0 rgba(0, 0, 0, 0.12);
`

export const StyledDescription = styled.div<{ lineColor: string }>`
  margin-top: 16px;
  padding: 16px 24px;
  background-image: linear-gradient(to right, ${({ lineColor }) => lineColor} 8px, #eceff1 8px);

  font-size: 16px;

  border-radius: 4px;
`
