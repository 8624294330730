import styled from 'styled-components';

export const StyledContainer = styled.div`
  background-color: #fefefe;
  box-shadow: 0px 0px 24px rgba(18, 2, 36, 0.08);

  display: flex;
  flex-direction: column;
`;

export const StyledMinContainer = styled.div`
  margin: 32px 0;

  max-width: 100%;
  width: 872px;

  align-self: center;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const StyledTitle = styled.h3`
  margin-bottom: 16px;

  font-weight: 600;
  font-size: 18px;
  line-height: 26px;

  color: #120224;
`;

export const StyledFormContainer = styled.div`
  width: 100%;

  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StylesRequirementsContainer = styled.div`
  margin-top: 32px;

  width: 100%;
  height: fit-content;
  box-sizing: border-box;

  border: 1px solid #120224;
  border-radius: 4px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledReqTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  column-gap: 16px;
`;

export const StyledReqImg = styled.img``;

export const StyledReqTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.5px;
  text-transform: uppercase;

  color: #120224;
`;

export const StyledReqUl = styled.ul`
  margin-left: 18px;
`;

export const StyledReqLi = styled.li`
  margin-top: 16px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #120224;
`;
