import styled from 'styled-components'

export const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  border: none;
  background-color: #ffffff;
  color: #90258c;

  cursor: pointer;
`

export const StyledImg = styled.img``

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 32px;
`

export const StyledNoteWrapper = styled.div``

export const StyledHeader = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;

  color: #120224;
`
export const StyledDesc = styled.div``
