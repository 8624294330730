import { FormsContext } from 'contexts'
import { addPropertyMutation, removePropertyMutation } from 'gql/client'
import { useContext } from 'react'
import { useGetPropertiesInfo } from 'services/clients/clients.service'
import { useCustomMutation } from 'services/shared/mutation'
import { AddPropertyPayload, RemovePropertyPayload } from 'services/shared/mutation.payload'

const useProperties = ({ handleItemClick }: { handleItemClick: any }) => {
  const { setPropertyToEdit } = useContext(FormsContext)

  const onEditClick = (_id: string) => {
    setPropertyToEdit(_id)

    handleItemClick({
      label: 'Property Details',
      hasChildren: false,
    })
  }

  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { data } = useGetPropertiesInfo(idNumber)

  const addProperty = useCustomMutation<AddPropertyPayload>({ mutation: addPropertyMutation, refetchQueries: ['getPropertiesInfo'] })
  const removeProperty = useCustomMutation<RemovePropertyPayload>({ mutation: removePropertyMutation, refetchQueries: ['getPropertiesInfo'] })

  const handleAddProperty = async () => {
    const { errors } = await addProperty({
      idNumber,
    })
    if (errors?.length) {
      return
    }
  }

  const handleRemoveProperty = async (property_id: string) => {
    const { errors } = await removeProperty({
      idNumber,
      property_id,
    })
    if (errors?.length) {
      return
    }
  }

  return { data, onEditClick, handleAddProperty, handleRemoveProperty }
}

export default useProperties
