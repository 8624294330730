import styled from 'styled-components'

export const StyledComingSoon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`

export const StyledComingSoonHeader = styled.h1`
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #020210;
  text-align: center;
  text-transform: capitalize;
`

export const StyledComingSoonText = styled.h2`
  color: #020210;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
`
