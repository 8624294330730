import Form from 'antd/lib/form'

import Loading from 'components/Loading'
import FormTable from 'components/FormTable/FormTable'
import CustomFormItem from 'components/CustomFormItem/CustomFormItem'

import useObjectivesAndAlternatives from 'hooks/clients/DetailedForms/useObjectivesAndAlternatives'
import CustomFooter from 'pages/ClientDetails/CustomFooter/CustomFooter'

import {
  cuttingBackOnCurrentSpendingOptions,
  localAuthorityOptions,
  lodgerOptions,
  returnToWorkOptions,
  askFamilyForAssistanceOptions,
  savingsOptions,
  lessExpensivePropertyOptions,
  TakingCashFromPensionsWhenTheyMatureOptions,
  rentOrMoveWithFriendsOptions,
  yesNoPossiblyOptions,
  objectivesTableColumns,
} from './ObjectivesAndAlternatives.helper'
import { StyledContainer, StyledDescription, StyledTitle, StyledTitleContainer, StyledRequiredMsg } from './ObjectivesAndAlternatives.styles'

const ObjectivesAndAlternatives = () => {
  const {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    handleAntdFormItemChange,
    handleCustomSelectChange,
    handleAddButton,
    objectivesDataSource,
    liabilitiesDataSource,
    initialFundsTotalValue,
    futureFundsTotalValue,
    setSelectedRows,
    displayDeleteButton,
    handleDeleteModal,
    displayEditButton,
    handleEditModal,
    tableError,
  } = useObjectivesAndAlternatives()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <StyledTitle style={{ fontSize: '32px' }}>Objectives and Alternatives</StyledTitle>
        <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
      </StyledTitleContainer>
      <StyledDescription>
        This page is where you will list your customer’s reasons behind looking to raise capital, for example gifting, home improvements or care fees. To add a
        new objective, press the purple 'Add New Objective' button.
      </StyledDescription>
      <FormTable
        columns={objectivesTableColumns}
        dataSource={liabilitiesDataSource}
        title="Liabilities"
        description="Here are Liabilities and Objectives tables, from which are calculated Initial and Future Funds, Please note you cannot edit Liabilities here, navigate in the Liabilities section to edit them."
        checkboxColumn={false}
      />
      <FormTable
        columns={objectivesTableColumns}
        dataSource={objectivesDataSource}
        title="Objectives"
        addButtonText="Add New Objective"
        handleAddButton={handleAddButton}
        total={initialFundsTotalValue}
        totalText="Initial Funds Total"
        secondTotal={futureFundsTotalValue}
        secondTotalText="Future Funds Total"
        setSelectedRows={setSelectedRows}
        displayDeleteButton={displayDeleteButton}
        handleDeleteModal={handleDeleteModal}
        displayEditButton={displayEditButton}
        handleEditModal={handleEditModal}
        errorMessage={tableError}
      />
      <StyledTitle style={{ marginTop: '20px' }}>Alternative Options</StyledTitle>
      <StyledDescription>
        Has your customer considered all alternatives before looking at Equity Release as an option to reach their objectives?
      </StyledDescription>
      <Form
        layout="vertical"
        size="large"
        name="basic"
        initialValues={formData}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '12px',
          marginTop: '8px',
          width: '100%',
        }}
        onFinishFailed={onFinishFailed}
        onFinish={onSubmit}
      >
        <CustomFormItem
          label="Cutting back on current spending? *"
          width={'100%'}
          placeholder="Cutting back on current spending"
          onChange={handleCustomSelectChange}
          options={cuttingBackOnCurrentSpendingOptions}
          name="cuttingBackCurrentSpending"
          type="select"
          rules={['isRequired']}
        />
        {formData.cuttingBackCurrentSpending === 'other' && (
          <CustomFormItem
            label="Other; Please detail"
            placeholder="Other; Please detail"
            value={formData.detailsOfCuttingBackCurrentSpending}
            name="detailsOfCuttingBackCurrentSpending"
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        <CustomFormItem
          label="Claim State means tested benefits / Local Authority / Home Improvement Grants? *"
          width={'100%'}
          placeholder="Claim State means tested benefits / Local Authority / Home Improvement Grants? *"
          onChange={handleCustomSelectChange}
          options={localAuthorityOptions}
          name="claimStateBenefits"
          type="select"
          rules={['isRequired']}
        />
        {formData.claimStateBenefits === 'other' && (
          <CustomFormItem
            label="Other; Please detail"
            placeholder="Other; Please detail"
            value={formData.detailsOfClaimStateBenefits}
            name="detailsOfClaimStateBenefits"
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        <CustomFormItem
          label="Take in a lodger? *"
          width={'100%'}
          placeholder="Take in a lodger? *"
          onChange={handleCustomSelectChange}
          options={lodgerOptions}
          name="takeLodger"
          type="select"
          rules={['isRequired']}
        />
        {formData.takeLodger === 'other' && (
          <CustomFormItem
            label="Other; Please detail"
            placeholder="Other; Please detail"
            value={formData.detailsOfTakeLodger}
            name="detailsOfTakeLodger"
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        <CustomFormItem
          label="Return to work? *"
          width={'100%'}
          placeholder="Return to work? *"
          onChange={handleCustomSelectChange}
          options={returnToWorkOptions}
          name="returnToWork"
          type="select"
          rules={['isRequired']}
        />
        {formData.returnToWork === 'other' && (
          <CustomFormItem
            label="Other; Please detail"
            placeholder="Other; Please detail"
            value={formData.detailsOfReturnToWork}
            name="detailsOfReturnToWork"
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        <CustomFormItem
          label="Ask family for assistance? *"
          width={'100%'}
          placeholder="Ask family for assistance? *"
          onChange={handleCustomSelectChange}
          options={askFamilyForAssistanceOptions}
          name="askFamilyForAssistance"
          type="select"
          rules={['isRequired']}
        />
        {formData.askFamilyForAssistance === 'other' && (
          <CustomFormItem
            label="Other; Please detail"
            placeholder="Other; Please detail"
            value={formData.detailsOfAskFamilyForAssistance}
            name="detailsOfAskFamilyForAssistance"
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        <CustomFormItem
          label="Use other funds or assets, including second homes or buy-to-lets?"
          width={'100%'}
          placeholder="Use other funds or assets, including second homes or buy-to-lets?"
          onChange={handleCustomSelectChange}
          options={savingsOptions}
          name="useOtherFundsOrAssets"
          type="select"
          rules={['isRequired']}
        />
        {formData.useOtherFundsOrAssets === 'other' && (
          <CustomFormItem
            label="Other; Please detail"
            placeholder="Other; Please detail"
            value={formData.detailsOfUseOtherFundsOrAssets}
            name="detailsOfUseOtherFundsOrAssets"
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        <CustomFormItem
          label="Have you considered a standard mortgage? *"
          width={'100%'}
          placeholder="Have you considered a standard mortgage? *"
          onChange={handleAntdFormItemChange}
          name="standardMortgageDetails"
          rules={['isRequired']}
        />
        <CustomFormItem
          label="have you considered a retirement interest-only mortgage? *"
          width={'100%'}
          placeholder="have you considered a retirement interest-only mortgage? *"
          onChange={handleAntdFormItemChange}
          name="retirementInterestOnlyMortgageDetails"
          rules={['isRequired']}
        />
        <CustomFormItem
          label="have you considered a personal loan? *"
          width={'100%'}
          placeholder="have you considered a personal loan? *"
          onChange={handleAntdFormItemChange}
          name="personalLoanDetails"
          rules={['isRequired']}
        />
        <CustomFormItem
          label="Move to a less expensive property & use sale proceeds? *"
          width={'100%'}
          placeholder="Move to a less expensive property & use sale proceeds? *"
          onChange={handleCustomSelectChange}
          options={lessExpensivePropertyOptions}
          name="moveToCheaperProperty"
          type="select"
          rules={['isRequired']}
        />
        {formData.moveToCheaperProperty === 'other' && (
          <CustomFormItem
            label="Other; Please detail"
            placeholder="Other; Please detail"
            value={formData.detailsOfMoveToCheaperProperty}
            name="detailsOfMoveToCheaperProperty"
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        <CustomFormItem
          label="Taking cash from pensions when they mature? *"
          width={'100%'}
          placeholder="Taking cash from pensions when they mature? *"
          onChange={handleCustomSelectChange}
          options={TakingCashFromPensionsWhenTheyMatureOptions}
          name="takeCashFromPensions"
          type="select"
          rules={['isRequired']}
        />
        {formData.takeCashFromPensions === 'other' && (
          <CustomFormItem
            label="Other; Please detail"
            placeholder="Other; Please detail"
            value={formData.detailsOfTakeCashFromPensions}
            name="detailsOfTakeCashFromPensions"
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        <CustomFormItem
          label="Sell your property and rent or move in with family/friends? *"
          width={'100%'}
          placeholder="Sell your property and rent or move in with family/friends? *"
          onChange={handleCustomSelectChange}
          options={rentOrMoveWithFriendsOptions}
          name="sellPropertyAndMove"
          type="select"
          rules={['isRequired']}
        />
        {formData.sellPropertyAndMove === 'other' && (
          <CustomFormItem
            label="Other; Please detail"
            placeholder="Other; Please detail"
            value={formData.detailsOfSellPropertyAndMove}
            name="detailsOfSellPropertyAndMove"
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        <CustomFormItem
          label="Would a Further Advance or 2nd Charge be suitable?"
          onChange={handleAntdFormItemChange}
          options={yesNoPossiblyOptions}
          value={formData.furtherAdvanceOrSecondCharge}
          name="furtherAdvanceOrSecondCharge"
          type="radio"
        />
        <CustomFooter loading={loading} setSaveType={setSaveType} />
      </Form>
    </StyledContainer>
  )
}

export default ObjectivesAndAlternatives
