import { Form, Modal } from 'antd'

import { StyledWrapper, Styled2x2Grid } from './EditLiabilityModal.styles'
import CustomFormItem from 'components/CustomFormItem/CustomFormItem'
import { liabilityTypeOptions, monthlyCostChangePeriodOptions } from './EditLiabilityModal.helper'
import useEditLiabilityModal from 'hooks/clients/Modals/useEditLiabilityModal'
import Loading from 'components/Loading/Loading'
import { PoundIcon } from 'assets/images'
import { yesNoOptions } from 'utils/commonOptions'

const EditLiabilityModal = () => {
  const {
    loading,
    modalState,
    handleCancelClick,
    handleConfirmClick,
    formData,
    handleCustomCheckboxChange,
    handleCustomSelectChange,
    handleAntdFormInputChange,
    handleCustomTextAreaChange,
    form,
    customersOptions,
  } = useEditLiabilityModal()

  if (loading) {
    return <Loading />
  }

  return (
    <Modal
      title="Edit Liability"
      open={modalState.isOpen}
      onOk={handleConfirmClick}
      onCancel={handleCancelClick}
      width={700}
      okText="Save"
      cancelText="Cancel"
      closable={false}
      className="edit-asset-modal"
      confirmLoading={modalState.loading}
    >
      {modalState.loading ? (
        <Loading />
      ) : (
        <StyledWrapper>
          <Form
            form={form}
            layout="vertical"
            size="large"
            name="basic"
            initialValues={formData}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
              marginTop: '8px',
              width: '100%',
            }}
          >
            <Styled2x2Grid>
              <CustomFormItem
                label="Select Customer *"
                width={'100%'}
                placeholder="Select Customer *"
                onChange={handleCustomSelectChange}
                options={customersOptions}
                name="liabilityHolder"
                rules={['isRequired']}
                type="select"
              />
              <CustomFormItem
                label="Type of Liability *"
                width={'100%'}
                placeholder="Type of Liability *"
                defaultValue={formData.liabilityType}
                onChange={handleCustomSelectChange}
                options={liabilityTypeOptions}
                name="liabilityType"
                type="select"
                rules={['isRequired']}
              />
              <CustomFormItem
                label="Balance *"
                name="balance"
                placeholder="Balance"
                inputPrefixImg={PoundIcon}
                prefixImgHeight={12}
                onChange={handleAntdFormInputChange}
                type="number"
                rules={['isRequired']}
              />
              <CustomFormItem
                label="Monthly Cost *"
                name="monthlyCost"
                placeholder="Monthly Cost"
                inputPrefixImg={PoundIcon}
                prefixImgHeight={12}
                onChange={handleAntdFormInputChange}
                type="number"
                rules={['isRequired']}
              />
            </Styled2x2Grid>
            <CustomFormItem
              label="Remaining Term (Months)"
              name="remainingTerm"
              placeholder="Remaining Term (Months)"
              onChange={handleAntdFormInputChange}
              type="number"
            />
            <CustomFormItem
              label="Will the monthly cost change in the future? *"
              onChange={handleCustomCheckboxChange}
              options={yesNoOptions}
              name="willMonthlyCostChange"
              value={formData.willMonthlyCostChange}
              rules={['isRequired']}
              type="radio"
            />
            <Styled2x2Grid>
              {formData.willMonthlyCostChange && (
                <>
                  <CustomFormItem
                    label="When will it change? *"
                    width={'100%'}
                    placeholder="When will it change? *"
                    defaultValue={formData.monthlyCostChangePeriod}
                    onChange={handleCustomSelectChange}
                    options={monthlyCostChangePeriodOptions}
                    name="monthlyCostChangePeriod"
                    type="select"
                    rules={['isRequired']}
                  />
                  <CustomFormItem
                    label="To what amount? *"
                    name="monthlyCostWillChangeTo"
                    placeholder="To what amount?"
                    inputPrefixImg={PoundIcon}
                    prefixImgHeight={12}
                    onChange={handleAntdFormInputChange}
                    type="number"
                    rules={['isRequired']}
                  />
                </>
              )}
              <CustomFormItem
                label="Early Repayment Charges & Associated Fees if Applicable *"
                name="earlyRepaymentCharges"
                placeholder="Early Repayment Charges & Associated Fees if Applicable"
                inputPrefixImg={PoundIcon}
                prefixImgHeight={12}
                onChange={handleAntdFormInputChange}
                type="number"
                rules={['isRequired']}
              />
              <CustomFormItem
                label="Interest rate (if applicable)"
                name="interestRate"
                placeholder="Interest rate (if applicable)"
                onChange={handleAntdFormInputChange}
                type="number"
              />
            </Styled2x2Grid>
            <CustomFormItem
              label="Plan to Repay? *"
              onChange={handleCustomCheckboxChange}
              options={yesNoOptions}
              name="planToRepay"
              value={formData.planToRepay}
              type="radio"
              rules={['isRequired']}
            />
            <CustomFormItem
              label="Description"
              placeholder="Description"
              value={formData.description}
              name="description"
              onChange={handleCustomTextAreaChange}
              rows={4}
              type="textarea"
            />
          </Form>
        </StyledWrapper>
      )}
    </Modal>
  )
}

export default EditLiabilityModal
