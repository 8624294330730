import {
  LenderDirectory,
  ProcFees,
  ServicesAndWills,
  NewsAndTraining,
  RateChanges,
  Guidelines,
  ComplianceSupport,
  Solicitors,
  VideoResources,
} from 'assets/images'

const subPages = [
  {
    id: 1,
    name: 'Lender Directory',
    path: '/resources/lender-directory',
    description: 'Our Lenders offer you exclusive deals as a Member of PERC, click below to view our full lender listing.',
    img: LenderDirectory,
  },
  {
    id: 2,
    name: 'Proc Fees',
    path: '/resources/proc-fees',
    description: 'View the full list of procuration fees from our lenders and how to avail of these.',
    img: ProcFees,
  },
  {
    id: 3,
    name: 'Solicitors',
    path: '/resources/solicitors',
    description: 'PERC elevates equity release advising with expert solicitors. Navigate legal intricacies for financial success.',
    img: Solicitors,
  },
  {
    id: 4,
    name: 'LPA Services & Wills',
    path: '/resources/lpa-services-wills',
    description: 'Elevate equity release advising with PERC. Expert LPA services & wills tailored for financial security.',
    img: ServicesAndWills,
  },
  {
    id: 5,
    name: 'News & Training',
    path: '/resources/news-and-training',
    description: 'Stay ahead with PERC: News and training for equity release advisers. Empower your expertise, shape success.',
    img: NewsAndTraining,
  },
  {
    id: 6,
    name: 'Rate Changes',
    path: '/resources/rate-changes',
    description: 'Stay informed with ongoing Rate changes, tailored for equity release advisers navigating markets confidently.',
    img: RateChanges,
  },
  {
    id: 7,
    name: 'FCA Guidelines & Equity Release Council',
    path: '/resources/fca-guidelines-equity-release-council',
    description: 'Empower your practice with guidelines and support for savvy advisors.',
    img: Guidelines,
  },
  {
    id: 8,
    name: 'Compliance Support',
    path: '/resources/compliance-support',
    description: 'Enhance your success with PERCS Compliance Support. Tailored for equity release advisors.',
    img: ComplianceSupport,
  },
  {
    id: 8,
    name: 'Video Resources',
    path: '/resources/video-resources',
    description: 'See Details how advisers use the system',
    img: VideoResources,
  },
]

const useResources = () => {
  return {
    subPages,
  }
}

export default useResources
