import * as uuid from 'uuid';

export const journeyDatesFields = [
  {
    key: uuid.v4(),
    label: 'First contact',
    name: 'firstContact',
  },
  {
    key: uuid.v4(),
    label: '1st Meeting',
    name: 'firstMeeting',
  },
  {
    key: uuid.v4(),
    label: 'Business Card Given',
    name: 'businessCardGiven',
  },
  {
    key: uuid.v4(),
    label: 'IDD Given to Client',
    name: 'iddGivenToClient',
  },
  {
    key: uuid.v4(),
    label: 'GDPR Completed',
    name: 'gdprCompleted',
  },
  {
    key: uuid.v4(),
    label: 'Factfind Completed',
    name: 'factfindCompleted',
  },
  {
    key: uuid.v4(),
    label: 'Client Signed Factfind',
    name: 'clientSignedFactfind',
  },
  {
    key: uuid.v4(),
    label: '2nd Meeting',
    name: 'secondMeeting',
  },
  {
    key: uuid.v4(),
    label: 'Identification Verified',
    name: 'identificationVerified',
  },
  {
    key: uuid.v4(),
    label: 'KFI Given / Sent to Client',
    name: 'kfiGivenSentToClient',
  },
  {
    key: uuid.v4(),
    label: 'Application Signed',
    name: 'applicationSigned',
  },
  {
    key: uuid.v4(),
    label: 'Suitability Letter Issued',
    name: 'suitabilityLetterIssued',
  },
  {
    key: uuid.v4(),
    label: 'Fee Payment Authority Signed',
    name: 'feePaymentAuthoritySigned',
  },
  {
    key: uuid.v4(),
    label: 'Solicitor Authority Signed',
    name: 'solicitorAuthoritySigned',
  },
  {
    key: uuid.v4(),
    label: 'Application Submitted',
    name: 'applicationSubmitted',
  },
  {
    key: uuid.v4(),
    label: 'Solicitor Instructions Sent',
    name: 'solicitorInstructionsSent',
  },
  {
    key: uuid.v4(),
    label: 'Valuation',
    name: 'valuation',
  },
  {
    key: uuid.v4(),
    label: 'Addendum Letter',
    name: 'addendumLetter',
  },
  {
    key: uuid.v4(),
    label: 'Offer Issued',
    name: 'offerIssued',
  },
  {
    key: uuid.v4(),
    label: 'Fee Payment sent to Solicitor',
    name: 'feePaymentSentToSolicitor',
  },
  {
    key: uuid.v4(),
    label: 'Solicitor Appointment',
    name: 'solicitorAppointment',
  },
  {
    key: uuid.v4(),
    label: 'Offer Signed',
    name: 'offerSigned',
  },
  {
    key: uuid.v4(),
    label: 'Completion',
    name: 'completion',
  },
  {
    key: uuid.v4(),
    label: 'Fee Payment Received',
    name: 'feePaymentReceived',
  },
];

export const recommendFieldsData = [
  {
    key: uuid.v4(),
    label: 'Recommended Lender (with contact number)',
    name: 'recommendedLender',
  },
  {
    key: uuid.v4(),
    label: 'Recommended Plan and How Much Borrowing',
    name: 'recommendedPlan',
  },
  {
    key: uuid.v4(),
    label: 'Recommended Solicitor (with contact number)',
    name: 'recommendedSolicitor',
  },
  {
    key: uuid.v4(),
    label: 'Referred by',
    name: 'referredBy',
  },
];
