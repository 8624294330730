import { Button } from 'antd'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  padding: 16px;
  width: 100%;
  background-color: #fefefe;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`

export const StyledLightButton = styled(Button)`
  min-width: fit-content;
  width: 200px;
  height: 48px;

  background-color: #fefefe !important;
  color: #90258c !important;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`
