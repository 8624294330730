import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { Layout } from 'antd'

import Header from 'components/Header'
import SideMenu from 'components/SideMenu'
import AuthContext from 'contexts/AuthContext'
import LayoutContext from 'contexts/LayoutContext'
import { TCustomLayoutProps } from './CustomLayout.types'

const { Content } = Layout

const CustomLayout = ({ children, headerCustomComponent }: TCustomLayoutProps) => {
  const { user } = useContext(AuthContext)
  const { sideBarWidth } = useContext(LayoutContext)
  const navigate = useNavigate()
  const client = useApolloClient()

  const handleLogout = async () => {
    await client.cache.reset()
    localStorage.removeItem('accessToken')
    // localStorage.clear()
    navigate('/')
  }

  return (
    <Layout hasSider>
      <SideMenu />
      <Layout className="site-layout" style={{ marginLeft: sideBarWidth, backgroundColor: '#f8f8f8' }}>
        <Header user={user} handleLogout={handleLogout} headerCustomComponent={headerCustomComponent} />
        {/* Note: top margin is calculated based on the height of the header + 24px */}
        <Content
          style={{
            margin: '104px 16px 0',
            overflow: 'initial',
            backgroundColor: '#f8f8f8',
          }}
        >
          <div
            style={{
              padding: 24,
            }}
          >
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default CustomLayout
