import { Button } from 'antd'
import styled from 'styled-components'

export const StyledButtonsContainer = styled.div<{ sideBarWidth: number }>`
  width: ${({ sideBarWidth }) => `calc(100% - ${sideBarWidth}px)`};
  min-height: 80px;
  height: fit-content;

  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  justify-content: space-between;
  align-items: center;
`

export const StyledLeftContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledRightContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  column-gap: 22px;
`

export const StyledButton = styled(Button)`
  width: 200px;
  height: 48px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`

export const StyledLightButton = styled(Button)`
  min-width: fit-content;
  width: 200px;
  height: 48px;

  background-color: #fefefe !important;
  color: #90258c !important;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`
