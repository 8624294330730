import styled from 'styled-components';

export const StyledContainer = styled.div``;

export const StyledFilterButton = styled.button`
  cursor: pointer;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
  gap: 8px;

  width: 140px;
  height: 48px;

  border: 2px solid #90258c;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background-color: #f8f8f8;
  color: #90258c;
`;
