import styled from 'styled-components'

export const StyledGridContainer = styled.div`
  margin-top: 24px;
`

export const SubPagesWrapper = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 250px));
  /* grid-template-columns: repeat(4, minmax(50px, 250px)); */
  grid-gap: 24px;
`

export const SubPageWrapper = styled.div`
  /* position: relative; */
  width: 100%;
  height: 350px;
  padding: 16px 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between !important;
  gap: 12px !important;

  border: 3px solid #90258c;
`

export const SubPageInternalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 12px !important;
`

export const StyledType = styled.div<{ color: string }>`
  max-width: 150px;
  align-self: flex-start;

  background-color: ${({ color }) => color || '#d3d3d3'}; // Replace with your desired background color
  border: none;
  color: #fff; // Replace with your desired text color
  cursor: pointer;
  padding: 8px 40px 8px 16px; // Adjust the padding as needed
  margin-top: 24px;
  margin-bottom: 32px;
  position: relative; // Required for the absolute positioning of the pseudo-elements
  font-size: 20px; // Adjust the font size as needed
  font-weight: 800;
  text-align: center;

  // Creating the arrow shape with pseudo-elements
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -40px; // Adjust this to change where the arrow point starts
    width: 0;
    height: 0;
    border-top: 20px solid transparent; // Adjust the height of the arrow
    border-bottom: 20px solid transparent; // Adjust the height of the arrow
    /* border-left: 40px solid #d3d3d3; // The color should match the button's background */
    border-left: ${({ color }) => `40px solid ${color || '#d3d3d3'}`};
  }
`

export const StyledTitle = styled.h3`
  align-self: flex-start;
  padding: 8px 0;

  font-size: 18px;
  font-weight: 800;
`

export const StyledDescription = styled.p`
  align-self: flex-start;
`

export const SubPageWrapperFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  align-self: flex-start;
  gap: 12px;
`

export const StyledDate = styled.p`
  align-self: flex-start;
  padding: 8px 0;

  font-weight: bold;
`

export const StyledButton = styled.a`
  color: #fff;
  background-color: #90258c;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 0;
`
