import Tabs from 'antd/lib/tabs'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from 'components/Layout'
import MainContentContainer from 'components/MainContentContainer'
import { contentfulDefaultOptions } from 'services/contentful'
import Loading from 'components/Loading'
import CustomBreadcrumb from 'components/CustomBreadcrumb'
import { useBreadcrumb, useLenderDirectory } from 'hooks/resources'

const { TabPane } = Tabs

const LenderDirectory = () => {
  const { data, displayLoading, activeTabKey, handleTabChange } = useLenderDirectory()
  const { breadcrumbItems, handleBreadcrumbTabChange } = useBreadcrumb()

  return (
    <Layout>
      <MainContentContainer pageName="Lender Directory">
        <CustomBreadcrumb breadcrumbItems={breadcrumbItems} handleBreadcrumbTabChange={handleBreadcrumbTabChange} />
        <Tabs style={{ width: '100%', marginTop: '24px' }} activeKey={activeTabKey} type="card" onChange={handleTabChange}>
          {displayLoading ? (
            <Loading />
          ) : (
            <>
              {' '}
              {data?.map((item: any) => (
                <TabPane tab={item.title} key={item.id}>
                  <div
                    style={{
                      padding: '0 20px',
                    }}
                  >
                    {documentToReactComponents(item.content, contentfulDefaultOptions)}
                  </div>
                </TabPane>
              ))}
            </>
          )}
        </Tabs>
      </MainContentContainer>
    </Layout>
  )
}

export default LenderDirectory
