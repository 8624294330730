export const incomeTypeOptions = [
  {
    label: 'Earned Income',
    value: 'earnedIncome',
  },
  {
    label: 'State Pension',
    value: 'statePension',
  },
  {
    label: 'Private Pension',
    value: 'privatePension',
  },
  {
    label: 'Occupational Pension',
    value: 'occupationalPension',
  },
  {
    label: 'Defined Benefit Pension',
    value: 'definedBenefitPension',
  },
  {
    label: 'Defined Contribution Pension',
    value: 'definedContributionPension',
  },
  {
    label: 'Investment Income',
    value: 'investmentIncome',
  },
  {
    label: 'Investment Withdrawals',
    value: 'investmentWithdrawals',
  },
  {
    label: 'Rental Income',
    value: 'rentalIncome',
  },
  {
    label: 'Dependants Income',
    value: 'dependantsIncome',
  },
  {
    label: 'Other Taxable Income(use notes)',
    value: 'otherTaxableIncome',
  },
  {
    label: 'Other NON Taxable Income(use notes)',
    value: 'otherNonTaxableIncome',
  },
]

export const whenWillIncomeChangeOptions = [
  {
    label: 'Within 12 months',
    value: 'within12months',
  },
  {
    label: '1-2 years',
    value: 'oneToTwoYears',
  },
  {
    label: '2-3 years',
    value: 'twoToThreeYears',
  },
  {
    label: '3-5 years',
    value: 'threeToFiveYears',
  },
  {
    label: '5-10 years',
    value: 'fiveToTenYears',
  },
  {
    label: '10-15 years',
    value: 'tenToFifteenYears',
  },
  {
    label: 'After 15 years',
    value: 'afterFifteenYears',
  },
]
