import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from 'components/Layout'
import MainContentContainer from 'components/MainContentContainer'
import Loading from 'components/Loading'
import CustomBreadcrumb from 'components/CustomBreadcrumb'
import { useBreadcrumb, useRateChanges } from 'hooks/resources'

import { options } from './RateChanges.helper'

const RateChanges = () => {
  const { displayLoading, data } = useRateChanges()
  const { breadcrumbItems, handleBreadcrumbTabChange } = useBreadcrumb()

  return (
    <Layout>
      <MainContentContainer pageName="Rate Changes">
      <CustomBreadcrumb breadcrumbItems={breadcrumbItems} handleBreadcrumbTabChange={handleBreadcrumbTabChange} />
        {displayLoading ? (
          <Loading />
        ) : (
          <div
            style={{
              padding: '24px 20px',
            }}
          >
            {documentToReactComponents(data[0].content, options)}
          </div>
        )}
      </MainContentContainer>
    </Layout>
  )
}

export default RateChanges
