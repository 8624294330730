import { ArrowBack } from 'assets/images'
import { StyledWrapper, StyledImg, StyledText } from './BackButton.styles'
import { BackButtonProps } from './BackButton.types'

const BackButton = ({ handleClick }: BackButtonProps) => {
  return (
    <StyledWrapper onClick={handleClick}>
      <StyledImg src={ArrowBack} />
      <StyledText>Back</StyledText>
    </StyledWrapper>
  )
}

export default BackButton
