import { AccountIcon, EditDarkIcon } from 'assets/images'
import { StyledContainer, StyledEditImg, StyledHeaderContainer, StyledImg, StyledLastName, StyledName, StyledTitle } from './CustomerCard.styles'
import { CustomerCardProps } from './CustomerCard.interface'

const CustomerCard = ({ editButtonClick, firstName, lastName }: CustomerCardProps) => {
  return (
    <StyledContainer>
      <StyledHeaderContainer>
        <StyledTitle>Customer</StyledTitle>
        <StyledImg src={AccountIcon} />
      </StyledHeaderContainer>
      <StyledLastName>{lastName || 'Last name'}</StyledLastName>
      <StyledName>{firstName || 'Names'}</StyledName>
      <StyledEditImg src={EditDarkIcon} onClick={editButtonClick} />
    </StyledContainer>
  )
}

export default CustomerCard
