import styled from 'styled-components'

export const StyledContainer = styled.div`
  margin-top: 28px;
  width: max-content;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  max-width: 100%;
`

export const StyledLabel = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #120224;

  max-width: 100%;
`

export const ValuesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1px;

  margin-top: 12px;
`

export const StyledRequiredError = styled.p`
  margin-top: 8px;
  line-height: 12px;
  color: #e73f3f;
`

export const StyledOption = styled.p<{ bgColor: string; color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px 16px;
  background: ${({ bgColor }) => bgColor};

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${({ color }) => color};
  cursor: pointer;

  &:first-child {
    border-radius: 4px 0px 0px 4px;
  }

  &:last-child {
    border-radius: 0px 4px 4px 0px;
  }
`
