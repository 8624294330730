import { whenWillIncomeChangeOptions } from 'modals/IncomeSourceModal/IncomeSourceModal.helper'

export const calculateTotalMonthlyIncome = (incomeSources: any, futurePensions: any) => {
  const income = incomeSources.map((incomeSource: any) => ({
    monthlyIncome: incomeSource.monthlyNetIncome,
    whenWillIncomeChange: incomeSource.whenWillIncomeChange,
    futureMonthlyIncome: incomeSource.incomeWillChangeTo,
  }))

  const pensionIncome = futurePensions.map((pensionSource: any) => ({
    monthlyIncome: 0,
    whenWillIncomeChange: pensionSource.whenWillPensionMature,
    futureMonthlyIncome: pensionSource.monthlyIncomeFromPension,
  }))

  const concatIncome = income?.concat(pensionIncome)

  const dateIndexObj: { [key: string]: number } = {}

  // eslint-disable-next-line array-callback-return
  whenWillIncomeChangeOptions?.map((item: any, index: number) => {
    dateIndexObj[item.value] = index
  })

  const initialIncomeTableDataSource = [{ when: 'Now', total: 0 }]

  const uniqueDatesWithEmptyTotal =
    concatIncome?.reduce((acc: any, current: any) => {
      initialIncomeTableDataSource[0].total += current.monthlyIncome
      if (current.whenWillIncomeChange) {
        const found = acc.find((item: any) => item.when === current.whenWillIncomeChange)
        if (!found) {
          acc.push({ when: current.whenWillIncomeChange, total: 0 })
        }
      }
      return acc
    }, []) || []

  const arrayWithCalculatedTotal = uniqueDatesWithEmptyTotal.map((item: any) => {
    let total = 0

    // eslint-disable-next-line array-callback-return
    concatIncome.map((source: any) => {
      if (!source.whenWillIncomeChange) {
        total += source.monthlyIncome
      }

      if (dateIndexObj[source.whenWillIncomeChange] <= dateIndexObj[item.when]) {
        total += source.futureMonthlyIncome
      }

      if (dateIndexObj[source.whenWillIncomeChange] > dateIndexObj[item.when]) {
        total += source.monthlyIncome
      }
    })

    return {
      ...item,
      total,
    }
  })

  // eslint-disable-next-line array-callback-return
  arrayWithCalculatedTotal?.map((item: any) => {
    const { when, total } = item
    let indexToAdd
    // eslint-disable-next-line array-callback-return
    initialIncomeTableDataSource.map((initialData: any, index) => {
      if (dateIndexObj[initialData.when] > dateIndexObj[when]) {
        indexToAdd = index
      }
    })
    if (typeof indexToAdd !== 'number') {
      initialIncomeTableDataSource.splice(initialIncomeTableDataSource.length, 0, { when, total })
    } else {
      initialIncomeTableDataSource.splice(indexToAdd, 0, { when, total })
    }
  })

  const incomeTableDataSource = initialIncomeTableDataSource?.map((item: any) => {
    const when = item.when === 'Now' ? 'Now' : whenWillIncomeChangeOptions?.find((date: any) => date.value === item.when)?.label

    return {
      when,
      total: item.total,
    }
  })

  return incomeTableDataSource
}
