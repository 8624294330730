import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import message from 'antd/lib/message'

import { commonHelper } from 'helpers'
import AuthContext from 'contexts/AuthContext'
import { useCustomMutation } from 'services/shared/mutation'
import { SignInPayload } from 'services/shared/mutation.payload'
import { signInMutation } from 'gql/user'
import { eventManager } from 'utils'

const useLogin = () => {
  const { refetch } = useContext(AuthContext)
  const signIn = useCustomMutation<SignInPayload>({ mutation: signInMutation })
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const isFirstSignIn = localStorage.getItem('isFirstSignIn') === null

  const handleSubmit = async ({ email, password }: { email: string; password: string }): Promise<void> => {
    try {
      setLoading(true)

      const { errors, data } = await signIn({ email, password })
      if (errors?.length) {
        setLoading(false)
        const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
        message.error(errMessage)
        return
      }

      const accessToken = data.signIn.accessToken
      localStorage.setItem('accessToken', accessToken)

      await refetch()
      setLoading(false)

      if (isFirstSignIn) {
        eventManager.openIressDetailsModal(
          {},
          {
            confirm: refetch,
          },
        )
        localStorage.setItem('isFirstSignIn', 'false')
      }

      navigate('/clients')
    } catch (err) {
      // pass.
    }
  }

  return {
    handleSubmit,
    loading,
  }
}

export default useLogin
