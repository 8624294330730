import { Modal, Form } from 'antd'
import useUserFileUploadModal from 'hooks/user/modals/useUserFileUploadModal'
import { FileInputContainer, FileInputLabel, FileName, StyledFileInput } from './UserFileUploadModal.styles'
import CustomFormItem from 'components/CustomFormItem'

const FileUploadModal = () => {
  const { modalState, handleCancelClick, handleConfirmClick, description, handleCustomTextAreaChange, fileName, handleFileInput, isSaveEnabled, form } =
    useUserFileUploadModal()

  return (
    <Modal
      title="Upload File"
      open={modalState.isOpen}
      onOk={handleConfirmClick}
      onCancel={handleCancelClick}
      width={700}
      okText="Save"
      cancelText="Cancel"
      closable={false}
      className="edit-asset-modal"
      confirmLoading={modalState.loading}
      okButtonProps={{ disabled: !isSaveEnabled }}
    >
      <FileInputContainer>
        <StyledFileInput id="fileInput" type="file" onChange={handleFileInput} required />
        <FileInputLabel htmlFor="fileInput">Choose a file</FileInputLabel>
        {fileName && <FileName>Selected File: {fileName}</FileName>}
      </FileInputContainer>
      <Form form={form}>
        <CustomFormItem
          placeholder="Description"
          value={description}
          name="description"
          onChange={handleCustomTextAreaChange}
          rows={4}
          type="textarea"
          rules={['isRequired']}
        />
      </Form>
    </Modal>
  )
}

export default FileUploadModal
