export const smokingOptions = [
  {
    label: 'More than 10 cigarettes per day on a regular basis for the last 10 years or more',
    value: 'moreThan10CigarettesPerDay',
  },
  {
    label: 'More than or 3oz (85g) of rolling tobacco per week on a regular basis for the last 10 years or more',
    value: 'moreThan3ozTobaccoPerWeek',
  },
]

export const alcoholConsumptionOptions = [
  {
    label: '0 - 49 units',
    value: '0-49',
  },
  {
    label: '50 - 69 units',
    value: '50-69',
  },
  {
    label: '70+ units',
    value: '70+',
  },
]

export const illnessOptions = [
  {
    label: 'High blood pressure (hypertension) which requires prescribed daily medication?',
    value: 'highBloodPressure',
  },
  {
    label: 'Diabetes which is controlled with tablets or insulin?',
    value: 'diabetes',
  },
  {
    label: 'A heart attack that required hospital admission?',
    value: 'heartAttack',
  },
  {
    label: 'Coronary Artery Disease, requiring prescribed medication',
    value: 'coronaryArteryDisease',
  },
  {
    label: 'ischemic Heart Disease, requiring prescribed medication?',
    value: 'ischemicHeartDisease',
  },
  {
    label: 'Angina, requiring prescribed medication?',
    value: 'angina',
  },
  {
    label: 'Stroke (CVA)?',
    value: 'stroke',
  },
  {
    label: 'A mini stroke (TIA)?',
    value: 'miniStroke',
  },
  {
    label: 'Multiple sclerosis?',
    value: 'multipleSclerosis',
  },
  {
    label: "Any of the following that required chemotherapy or radiotherapy; cancer, leukemia, Hodgkin's disease, lymphoma, any malignant growth or tumour?",
    value: 'requiredChemotherapyRadiotherapy',
  },
  {
    label: "Parkinson's disease?",
    value: 'parkinsonDisease',
  },
  {
    label: 'A chronic respiratory disease requiring daily medication or inhalers?',
    value: 'chronicRespiratoryDisease',
  },
  {
    label: 'Take early retirement due to ill health',
    value: 'earlyRetirement',
  },
  {
    label: 'Surgery for a heart condition?',
    value: 'heartSurgery',
  },
  {
    label: "Dementia (including Alzheimer's Disease)?",
    value: 'dementia',
  },
  {
    label: 'Chronic Kidney Failure?',
    value: 'kidneyFailure',
  },
  {
    label: 'Heart, Kidney, Liver or Lung transplant?',
    value: 'organTransplant',
  },
  {
    label: 'Cirrhosis of the Liver?',
    value: 'liverCirrhosis',
  },
  {
    label: 'Motor Neuron Diseases?',
    value: 'motorNeuronDiseases',
  },
  {
    label: 'Peripheral Vascular Disease (including Intermittent Claudication)?',
    value: 'peripheralVascularDisease',
  },
  {
    label: 'Hepatitis C?',
    value: 'hepatitisC',
  },
  {
    label: 'HIV',
    value: 'hiv',
  },
]

export const healthOptions = [
  {
    label: 'Physical disability',
    value: 'physicalDisability',
  },
  {
    label: 'Severe or long-term illness',
    value: 'severeOrLongTermIllness',
  },
  {
    label: 'Hearing or visual impairment',
    value: 'hearingOrVisualImpairment',
  },
  {
    label: 'Mental health condition or disability, Addiction',
    value: 'mentalHealthConditionOrDisabilityAddiction',
  },
  {
    label: 'Low mental capacity or cognitive disability',
    value: 'lowMentalCapacityOrCognitiveDisability',
  },
]

export const lifeEventsOptions = [
  {
    label: 'Retirement',
    value: 'retirement',
  },
  {
    label: 'Bereavement',
    value: 'bereavement',
  },
  {
    label: 'Income Shock',
    value: 'incomeShock',
  },
  {
    label: 'Relationship Breakdown',
    value: 'relationshipBreakdown',
  },
  {
    label: 'Domestic abuse (including economic control)',
    value: 'domesticAbuse',
  },
  {
    label: 'Caring responsibilities',
    value: 'caringResponsibilities',
  },
  {
    label:
      "Other circumstances that affect people's experience of financial services eg, leaving care, migration or seeking asylum human trafficking or modern slavery, convictions",
    value: 'otherConditions',
  },
]

export const resilienceOptions = [
  {
    label: 'Inadequate (outgoings exceed income) or erratic income',
    value: 'inadequateOrErraticIncome',
  },
  {
    label: 'Over-indebtedness',
    value: 'overIndebtedness',
  },
  {
    label: 'Low savings',
    value: 'lowSavings',
  },
  {
    label: 'Low emotional resilience',
    value: 'lowEmotionalResilience',
  },
]

export const capabilityOptions = [
  {
    label: 'Low knowledge or confidence in managing finances',
    value: 'lowKnowledgeOrConfidenceInManagingFinance',
  },
  {
    label: 'Poor literacy or numeracy skills',
    value: 'poorLiteracyOrNumeracySkills',
  },
  {
    label: 'Poor English language skills',
    value: 'poorEnglishLanguageSkills',
  },
  {
    label: 'Poor or non-existent digital skills',
    value: 'poorOrNonExistentDigitalSkills',
  },
  {
    label: 'Learning difficulties',
    value: 'learningDifficulties',
  },
  {
    label: 'No or low access to help or support',
    value: 'noOrLowAccessToHelpOrSupport',
  },
]

export const yesNoOtherOptions = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
  {
    label: 'Other, See Notes',
    value: 'other',
  },
]

export const attitudeTowardsClientVulnerabilityOptions = [
  {
    label: 'Potentially Vulnerable',
    value: 'potentiallyVulnerable',
    description:
      'If your client is currently able to manage their finances (including being able to make their own decisions) and is not behaving in a way that might harm themselves or others; they are neither vulnerable nor particularly vulnerable to detriment. Instead, they remain potentially vulnerable.',
    initialBgColor: '#ffe082',
    bgColor: '#ffc008',
  },
  {
    label: 'Vulnerable',
    value: 'vulnerable',
    description:
      'If your client is currently exposed to a risk of harm, loss or disadvantage: your client should be identified as being in a vulnerable situation and should receive help and assistance to avoid detriment to them. The aim of you as the adviser is to assist (within reason) returning the customer back to the potentially vulnerable category, although this could mean time will need to be spent on the advisory process.',
    initialBgColor: '#ffcc80',
    bgColor: '#ff9800',
  },
  {
    label: 'Particularly Vulnerable',
    value: 'particularlyVulnerable',
    description:
      "Your client is currently at a greatly heightened risk of experiencing detriment compared to the majority of vulnerable consumers. The detriment could also be far more serious in terms of its negative impact on the client's situation and could be far more imminent. These clients need to be quickly identified and action needs to be swift and effective to avoid significant harm.",
    initialBgColor: '#ef9a9a',
    bgColor: '#f44336',
  },
]
