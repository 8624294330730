import { ApolloError, ApolloQueryResult, OperationVariables } from '@apollo/client'
import { createContext } from 'react'

export interface IContextUser {
  _id: string
  avatar: string
  companyId: string
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
  role: string
  isIressCredentialsVerified: boolean
  fcaNumber?: string
  __typename: string
}

export interface UserContext {
  user: IContextUser
  refetch: (variables?: Partial<OperationVariables>) => Promise<ApolloQueryResult<any>>
  loading: boolean
  error: ApolloError | undefined
}

const context: UserContext = {
  user: {
    _id: '',
    avatar: '',
    companyId: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    role: '',
    isIressCredentialsVerified: false,
    fcaNumber: '',
    __typename: '',
  },
  refetch: () => Promise.resolve({} as ApolloQueryResult<any>),
  loading: false,
  error: undefined,
}

export default createContext(context)
