import { ReactElement } from 'react'

import { StyledComingSoon, StyledComingSoonHeader, StyledComingSoonText } from './ComingSoon.styles'
import { SuccessRocketIcon } from 'assets/svg'

const ComingSoon = (): ReactElement => {
  return (
    <StyledComingSoon>
      <SuccessRocketIcon />
      <StyledComingSoonHeader>Coming Soon 🛠️</StyledComingSoonHeader>
      <StyledComingSoonText>This feature is currently under development. We're working hard to bring it to you soon!</StyledComingSoonText>
    </StyledComingSoon>
  )
}

export default ComingSoon
