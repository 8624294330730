import styled from 'styled-components'
import Select from 'antd/lib/select'

export const StyledWrapper = styled.div`
  /* margin-top: 12px; */
`

export const StyledLabel = styled.p`
  margin: 0 0 8px;
  white-space: initial;
  text-align: start;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #120224;
  height: 40px;
  display: flex;
  align-items: center;
`

export const StyledSelect = styled(Select)<{ width?: string; height?: string }>`
  width: ${({ width }) => width || '200px'};
  /* box-shadow: none; */

  /* .ant-select-selector {
    background: #fefefe !important;

    box-sizing: border-box !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    padding: 12px 16px !important;
    width: 200px !important;
    height: 48px !important;
    background: #fefefe !important;
    border: 1px solid #d8d8d8 !important;
    border-radius: 4px !important;

    .ant-select-selection-item {
      font-weight: 400 !important;
    }
  } */

  .ant-select-selector {
    /* background: #eaeaea !important; */
    border-radius: 4px !important;
    height: ${({ height }) => height || '48px'} !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
`
