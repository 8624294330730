import { Switch } from 'antd'
import styled from 'styled-components'

export const StyledSwitchContainer = styled.div<{ alignSelf?: string }>`
  align-self: ${({ alignSelf }) => (alignSelf ? alignSelf : 'flex-end')};
  height: 45px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  column-gap: 12px;
`

export const StyledSwitch = styled(Switch)``

export const StyledSwitchLabel = styled.div`
  font-size: 16px;
`
