/* eslint-disable no-useless-computed-key */
import { Form } from 'antd'

import Loading from 'components/Loading'
import CustomFormItem from 'components/CustomFormItem'
import CustomWarnings from 'components/CustomWarnings/CustomWarnings'
import GoogleAddressInput from 'components/GoogleAddressInput/GoogleAddressInput'
import useHealth from 'hooks/clients/DetailedForms/useHealth'
import CustomFooter from 'pages/ClientDetails/CustomFooter/CustomFooter'
import { yesNoOptions } from 'utils/commonOptions'

import {
  smokingOptions,
  alcoholConsumptionOptions,
  illnessOptions,
  healthOptions,
  lifeEventsOptions,
  resilienceOptions,
  capabilityOptions,
  yesNoOtherOptions,
  attitudeTowardsClientVulnerabilityOptions,
} from './Health.helper'
import {
  StyledContainer,
  StyledDescription,
  StyledTitle,
  StyledTitleContainer,
  StyledRequiredMsg,
  StyledRow,
  StyledCheckbox,
  StyledCheckboxContainer,
  StyledCheckboxDescription,
  StyledBreakLine,
  Styled2x2Grid,
} from './Health.styles'
import CustomSwitch from 'components/CustomSwitch/CustomSwitch'

const Health = () => {
  const {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    flattenedFormData,
    handleAntdFormItemChange,
    handleCustomSelectChange,
    handleCustomCheckbox,
    onPlaceSelectForCustomerFirst,
    onPlaceSelectForCustomerSecond,
    showAddressDetails,
    showAddressDetailsChange,
    displayVulnerabilityDetailsForCustomerFirst,
    displayVulnerabilityDetailsForCustomerSecond,
    customers,
    form,
  } = useHealth()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <StyledTitle style={{ fontSize: '32px' }}>Health</StyledTitle>
        <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
      </StyledTitleContainer>
      <StyledDescription>
        Some providers will offer enhanced rates if your customer’s 'qualify' with specific conditions. This section can be skipped if they are in good health.
      </StyledDescription>
      <StyledTitle>Health information of {customers?.customerFirst?.firstName}</StyledTitle>
      <Form
        form={form}
        layout="vertical"
        size="large"
        name="basic"
        initialValues={flattenedFormData}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '12px',
          marginTop: '8px',
          width: '100%',
        }}
        onFinishFailed={onFinishFailed}
        onFinish={onSubmit}
      >
        <StyledRow>
          <CustomFormItem
            label="Height in feet *"
            name="customerFirst.height.feet"
            placeholder="Feet"
            value={formData?.customerFirst?.height?.feet}
            type="number"
            onChange={handleAntdFormItemChange}
            rules={['isRequired']}
          />
          <CustomFormItem
            label="Height in inches *"
            name="customerFirst.height.inches"
            placeholder="Inches"
            value={formData?.customerFirst?.height?.inches}
            type="number"
            onChange={handleAntdFormItemChange}
            rules={['isRequired']}
          />
        </StyledRow>
        <StyledRow>
          <CustomFormItem
            label="Weight in stone *"
            name="customerFirst.weight.stone"
            placeholder="Stone"
            value={formData?.customerFirst?.weight?.stone}
            type="number"
            onChange={handleAntdFormItemChange}
            rules={['isRequired']}
          />
          <CustomFormItem
            label="Weight in pound *"
            name="customerFirst.weight.pound"
            placeholder="Pound"
            value={formData?.customerFirst?.weight?.pound}
            type="number"
            onChange={handleAntdFormItemChange}
            rules={['isRequired']}
          />
        </StyledRow>
        <CustomFormItem
          label="Have you smoked? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          value={formData?.customerFirst?.isSmoking}
          name="customerFirst.isSmoking"
          type="radio"
          rules={['isRequired']}
        />
        {formData?.customerFirst?.isSmoking && (
          <CustomFormItem
            label="How much do you smoke? *"
            width={'100%'}
            placeholder="Have you smoked?"
            onChange={handleCustomSelectChange}
            options={smokingOptions}
            maxTagCount={1}
            mode="multiple"
            name="customerFirst.smokingInfo"
            type="select"
            rules={['isRequired']}
          />
        )}
        <CustomFormItem
          label="What is your average alcohol consumption per week? *"
          width={'100%'}
          placeholder="What is your average alcohol consumption per week?"
          onChange={handleCustomSelectChange}
          options={alcoholConsumptionOptions}
          name="customerFirst.averageAlcoholConsumptionPerWeek"
          type="select"
          rules={['isRequired']}
        />
        <CustomFormItem
          label="Have you been diagnosed with any illness? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          value={formData?.customerFirst?.hasDiagnosedWithAnyIllness}
          name="customerFirst.hasDiagnosedWithAnyIllness"
          type="radio"
          rules={['isRequired']}
        />
        {formData?.customerFirst?.hasDiagnosedWithAnyIllness && (
          <CustomFormItem
            label="Have you been diagnosed with any of the following?"
            width={'100%'}
            placeholder="Have you been diagnosed with any of the following?"
            onChange={handleCustomSelectChange}
            options={illnessOptions}
            maxTagCount={1}
            mode="multiple"
            name="customerFirst.diagnosedWith"
            type="select"
          />
        )}
        <StyledTitle style={{ marginTop: '32px' }}>Life Expectancy</StyledTitle>
        <StyledDescription>Typical Life Expectancy:</StyledDescription>
        <CustomFormItem
          label="Do you have any alteration to the typical life expectancy based on current health? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          value={formData?.customerFirst?.hasAlterationToTypicalLifeExpectancy}
          name="customerFirst.hasAlterationToTypicalLifeExpectancy"
          type="radio"
          rules={['isRequired']}
        />
        {formData?.customerFirst?.hasAlterationToTypicalLifeExpectancy && (
          <CustomFormItem
            label="Any alteration to the typical life expectancy based on current health? *"
            name="customerFirst.alterationToTypicalLifeExpectancy"
            placeholder="E.g will be shown here"
            value={formData?.customerFirst?.alterationToTypicalLifeExpectancy}
            onChange={handleAntdFormItemChange}
            rules={['isRequired']}
          />
        )}
        <StyledTitle style={{ marginTop: '32px' }}>Surgery Details</StyledTitle>

        <CustomFormItem
          label="Doctor's Name"
          name="customerFirst.doctorName"
          placeholder="E.g will be shown here"
          value={formData?.customerFirst?.doctorName}
          onChange={handleAntdFormItemChange}
        />
        <CustomFormItem
          label="Surgery Name"
          name="customerFirst.surgeryName"
          placeholder="E.g will be shown here"
          value={formData?.customerFirst?.surgeryName}
          onChange={handleAntdFormItemChange}
        />
        <CustomFormItem
          label="Surgery Contact Number"
          name="customerFirst.surgeryContactNumber"
          placeholder="E.g will be shown here"
          value={formData?.customerFirst?.surgeryContactNumber}
          onChange={handleAntdFormItemChange}
          rules={['validateTelephoneNumber']}
        />
        <Styled2x2Grid>
          <GoogleAddressInput
            label="Search for an Address"
            onPlaceSelect={onPlaceSelectForCustomerFirst}
            value={formData?.customerFirst?.surgeryAddress?.formattedAddress}
            marginTop={12}
          />
          <CustomSwitch checked={showAddressDetails} onChange={showAddressDetailsChange} label="Show Address Details" />
        </Styled2x2Grid>
        {showAddressDetails && (
          <Styled2x2Grid>
            <CustomFormItem
              label="Address 1"
              name="customerFirst.surgeryAddress.address1"
              placeholder="Address 1"
              onChange={handleAntdFormItemChange}
              value={formData?.customerFirst.surgeryAddress?.address1}
            />
            <CustomFormItem
              label="Address 2"
              name="customerFirst.surgeryAddress.address2"
              placeholder="Address 2"
              onChange={handleAntdFormItemChange}
              value={formData?.customerFirst.surgeryAddress?.address2}
            />
            <CustomFormItem
              label="Town"
              name="customerFirst.surgeryAddress.town"
              placeholder="Town*"
              onChange={handleAntdFormItemChange}
              value={formData?.customerFirst.surgeryAddress?.town}
            />
            <CustomFormItem
              label="County"
              name="customerFirst.surgeryAddress.county"
              placeholder="County"
              onChange={handleAntdFormItemChange}
              value={formData?.customerFirst.surgeryAddress?.county}
            />
            <CustomFormItem
              label="Country"
              name="customerFirst.surgeryAddress.country"
              placeholder="Country"
              onChange={handleAntdFormItemChange}
              value={formData?.customerFirst.surgeryAddress?.country}
            />
            <CustomFormItem
              label="Postcode"
              name="customerFirst.surgeryAddress.postcode"
              placeholder="Postcode"
              onChange={handleAntdFormItemChange}
              value={formData?.customerFirst.surgeryAddress?.postcode}
            />
          </Styled2x2Grid>
        )}
        <StyledTitle style={{ marginTop: '32px' }}>FCA Vulnerability Guidance (Characteristics associated with the main drivers of vulnerability)</StyledTitle>
        <StyledDescription>
          Do you or have you been affected by any of the following. Please tick any that apply and use the additional information box underneath to record
          further details.
        </StyledDescription>
        <CustomFormItem
          label="Health"
          width={'100%'}
          placeholder="E.g will be shown here"
          onChange={handleCustomSelectChange}
          options={healthOptions}
          maxTagCount={1}
          mode="multiple"
          name="customerFirst.healthIssues"
          type="select"
        />
        <CustomFormItem
          label="Life events"
          width={'100%'}
          placeholder="E.g will be shown here"
          onChange={handleCustomSelectChange}
          options={lifeEventsOptions}
          maxTagCount={1}
          mode="multiple"
          name="customerFirst.lifeEvents"
          type="select"
        />
        <CustomFormItem
          label="Resilience"
          width={'100%'}
          placeholder="E.g will be shown here"
          onChange={handleCustomSelectChange}
          options={resilienceOptions}
          maxTagCount={1}
          mode="multiple"
          name="customerFirst.resilience"
          type="select"
        />
        <CustomFormItem
          label="Capability"
          width={'100%'}
          placeholder="E.g will be shown here"
          onChange={handleCustomSelectChange}
          options={capabilityOptions}
          maxTagCount={1}
          mode="multiple"
          name="customerFirst.capability"
          type="select"
        />
        {displayVulnerabilityDetailsForCustomerFirst && (
          <CustomFormItem
            label="Further details on the above issues"
            placeholder="E.g will be shown here"
            name="customerFirst.vulnerabilityDetails"
            value={formData?.customerFirst?.vulnerabilityDetails}
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        <StyledCheckboxContainer>
          <StyledCheckbox
            checked={formData?.customerFirst?.vulnerabilityDoNotApply}
            onChange={(e: any) => handleCustomCheckbox({ e, field: 'customerFirst.vulnerabilityDoNotApply' })}
            name="customerFirst.vulnerabilityDoNotApply"
          />
          <StyledCheckboxDescription>
            You confirm that none of the characteristics associated with the main drivers of vulnerability apply
          </StyledCheckboxDescription>
        </StyledCheckboxContainer>
        <CustomFormItem
          label="Do you understand that any loan will reduce the value of your estate? If No, please document in notes any measures you will take to ensure customer understands the nature and effect of a loan"
          onChange={handleAntdFormItemChange}
          options={yesNoOtherOptions}
          value={formData?.customerFirst?.understandsLoanReducesAssetValue}
          name="customerFirst.understandsLoanReducesAssetValue"
          type="radio"
        />
        <CustomFormItem
          label="Is there any indication of coercion or undue influence from someone else?"
          onChange={handleAntdFormItemChange}
          options={yesNoOtherOptions}
          value={formData?.customerFirst?.hasCoercionOrUndueInfluence}
          name="customerFirst.hasCoercionOrUndueInfluence"
          type="radio"
        />
        <StyledBreakLine />
        <StyledTitle>Attitude Towards Client Vulnerability</StyledTitle>
        <CustomWarnings
          label="Select an option to reveal further information that may prove useful in determining your assessment."
          onChange={handleAntdFormItemChange}
          options={attitudeTowardsClientVulnerabilityOptions}
          value={formData?.customerFirst?.attitudeTowardsClientVulnerability}
          name="customerFirst.attitudeTowardsClientVulnerability"
        />
        {customers?.customerSecond?.firstName && (
          <>
            <StyledBreakLine />
            <StyledTitle>Health information of {customers?.customerSecond?.firstName}</StyledTitle>
            <StyledRow>
              <CustomFormItem
                label="Height in feet *"
                name="customerSecond.height.feet"
                placeholder="Feet"
                value={formData?.customerSecond?.height?.feet}
                type="number"
                onChange={handleAntdFormItemChange}
                rules={['isRequired']}
              />
              <CustomFormItem
                label="Height in inches *"
                name="customerSecond.height.inches"
                placeholder="Inches"
                value={formData?.customerSecond?.height?.inches}
                type="number"
                onChange={handleAntdFormItemChange}
                rules={['isRequired']}
              />
            </StyledRow>
            <StyledRow>
              <CustomFormItem
                label="Weight in stone *"
                name="customerSecond.weight.stone"
                placeholder="Stone"
                value={formData?.customerSecond?.weight?.stone}
                type="number"
                onChange={handleAntdFormItemChange}
                rules={['isRequired']}
              />
              <CustomFormItem
                label="Weight in pound *"
                name="customerSecond.weight.pound"
                placeholder="Pound"
                value={formData?.customerSecond?.weight?.pound}
                type="number"
                onChange={handleAntdFormItemChange}
                rules={['isRequired']}
              />
            </StyledRow>
            <CustomFormItem
              label="Have you smoked? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              value={formData?.customerSecond?.isSmoking}
              name="customerSecond.isSmoking"
              type="radio"
              rules={['isRequired']}
            />
            {formData?.customerSecond?.isSmoking && (
              <CustomFormItem
                label="How mush do you smoke? *"
                width={'100%'}
                placeholder="Have you smoked?"
                onChange={handleCustomSelectChange}
                options={smokingOptions}
                maxTagCount={1}
                mode="multiple"
                name="customerSecond.smokingInfo"
                type="select"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="What is your average alcohol consumption per week? *"
              width={'100%'}
              placeholder="What is your average alcohol consumption per week?"
              onChange={handleCustomSelectChange}
              options={alcoholConsumptionOptions}
              name="customerSecond.averageAlcoholConsumptionPerWeek"
              type="select"
              rules={['isRequired']}
            />
            <CustomFormItem
              label="Have you been diagnosed with any illness? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              value={formData?.customerSecond?.hasDiagnosedWithAnyIllness}
              name="customerSecond.hasDiagnosedWithAnyIllness"
              type="radio"
              rules={['isRequired']}
            />
            {formData?.customerSecond?.hasDiagnosedWithAnyIllness && (
              <CustomFormItem
                label="Have you been diagnosed with any of the following?"
                width={'100%'}
                placeholder="Have you been diagnosed with any of the following?"
                onChange={handleCustomSelectChange}
                options={illnessOptions}
                maxTagCount={1}
                mode="multiple"
                name="customerSecond.diagnosedWith"
                type="select"
              />
            )}
            <StyledTitle style={{ marginTop: '32px' }}>Life Expectancy</StyledTitle>
            <StyledDescription>Typical Life Expectancy:</StyledDescription>
            <CustomFormItem
              label="Do you have any alteration to the typical life expectancy based on current health? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              value={formData?.customerSecond?.hasAlterationToTypicalLifeExpectancy}
              name="customerSecond.hasAlterationToTypicalLifeExpectancy"
              type="radio"
              rules={['isRequired']}
            />
            {formData?.customerSecond?.hasAlterationToTypicalLifeExpectancy && (
              <CustomFormItem
                label="Any alteration to the typical life expectancy based on current health? *"
                name="customerSecond.alterationToTypicalLifeExpectancy"
                placeholder="E.g will be shown here"
                value={formData?.customerSecond?.alterationToTypicalLifeExpectancy}
                onChange={handleAntdFormItemChange}
                rules={['isRequired']}
              />
            )}
            <StyledTitle style={{ marginTop: '32px' }}>Surgery Details</StyledTitle>

            <CustomFormItem
              label="Doctor's Name"
              name="customerSecond.doctorName"
              placeholder="E.g will be shown here"
              value={formData?.customerSecond?.doctorName}
              onChange={handleAntdFormItemChange}
            />
            <CustomFormItem
              label="Surgery Name"
              name="customerSecond.surgeryName"
              placeholder="E.g will be shown here"
              value={formData?.customerSecond?.surgeryName}
              onChange={handleAntdFormItemChange}
            />
            <CustomFormItem
              label="Surgery Contact Number"
              name="customerSecond.surgeryContactNumber"
              placeholder="E.g will be shown here"
              value={formData?.customerSecond?.surgeryContactNumber}
              onChange={handleAntdFormItemChange}
              rules={['validateTelephoneNumber']}
            />
            <Styled2x2Grid>
              <GoogleAddressInput
                label="Search for an Address"
                onPlaceSelect={onPlaceSelectForCustomerSecond}
                value={formData?.customerSecond?.surgeryAddress?.formattedAddress}
                marginTop={12}
              />
              <CustomSwitch checked={showAddressDetails} onChange={showAddressDetailsChange} label="Show Address Details" />
            </Styled2x2Grid>
            {showAddressDetails && (
              <Styled2x2Grid>
                <CustomFormItem
                  label="Address 1"
                  name="customerSecond.surgeryAddress.address1"
                  placeholder="Address 1"
                  onChange={handleAntdFormItemChange}
                  value={formData?.customerSecond.surgeryAddress?.address1}
                />
                <CustomFormItem
                  label="Address 2"
                  name="customerSecond.surgeryAddress.address2"
                  placeholder="Address 2"
                  onChange={handleAntdFormItemChange}
                  value={formData?.customerSecond.surgeryAddress?.address2}
                />
                <CustomFormItem
                  label="Town"
                  name="customerSecond.surgeryAddress.town"
                  placeholder="Town*"
                  onChange={handleAntdFormItemChange}
                  value={formData?.customerSecond.surgeryAddress?.town}
                />
                <CustomFormItem
                  label="County"
                  name="customerSecond.surgeryAddress.county"
                  placeholder="County"
                  onChange={handleAntdFormItemChange}
                  value={formData?.customerSecond.surgeryAddress?.county}
                />
                <CustomFormItem
                  label="Country"
                  name="customerSecond.surgeryAddress.country"
                  placeholder="Country"
                  onChange={handleAntdFormItemChange}
                  value={formData?.customerSecond.surgeryAddress?.country}
                />
                <CustomFormItem
                  label="Postcode"
                  name="customerSecond.surgeryAddress.postcode"
                  placeholder="Postcode"
                  onChange={handleAntdFormItemChange}
                  value={formData?.customerSecond.surgeryAddress?.postcode}
                />
              </Styled2x2Grid>
            )}
            <StyledTitle style={{ marginTop: '32px' }}>
              FCA Vulnerability Guidance (Characteristics associated with the main drivers of vulnerability)
            </StyledTitle>
            <StyledDescription>
              Do you or have you been affected by any of the following. Please tick any that apply and use the additional information box underneath to record
              further details.
            </StyledDescription>
            <CustomFormItem
              label="Health"
              width={'100%'}
              placeholder="E.g will be shown here"
              onChange={handleCustomSelectChange}
              options={healthOptions}
              maxTagCount={1}
              mode="multiple"
              name="customerSecond.healthIssues"
              type="select"
            />
            <CustomFormItem
              label="Life events"
              width={'100%'}
              placeholder="E.g will be shown here"
              onChange={handleCustomSelectChange}
              options={lifeEventsOptions}
              maxTagCount={1}
              mode="multiple"
              name="customerSecond.lifeEvents"
              type="select"
            />
            <CustomFormItem
              label="Resilience"
              width={'100%'}
              placeholder="E.g will be shown here"
              onChange={handleCustomSelectChange}
              options={resilienceOptions}
              maxTagCount={1}
              mode="multiple"
              name="customerSecond.resilience"
              type="select"
            />
            <CustomFormItem
              label="Capability"
              width={'100%'}
              placeholder="E.g will be shown here"
              onChange={handleCustomSelectChange}
              options={capabilityOptions}
              maxTagCount={1}
              mode="multiple"
              name="customerSecond.capability"
              type="select"
            />
            {displayVulnerabilityDetailsForCustomerSecond && (
              <CustomFormItem
                label="Further details on the above issues"
                placeholder="E.g will be shown here"
                name="customerSecond.vulnerabilityDetails"
                value={formData?.customerSecond?.vulnerabilityDetails}
                onChange={handleAntdFormItemChange}
                rows={4}
                type="textarea"
              />
            )}
            <StyledCheckboxContainer>
              <StyledCheckbox
                checked={formData?.customerSecond?.vulnerabilityDoNotApply}
                onChange={(e: any) => handleCustomCheckbox({ e, field: 'customerSecond.vulnerabilityDoNotApply' })}
                name="customerSecond.vulnerabilityDoNotApply"
              />
              <StyledCheckboxDescription>
                You confirm that none of the characteristics associated with the main drivers of vulnerability apply
              </StyledCheckboxDescription>
            </StyledCheckboxContainer>
            <CustomFormItem
              label="Do you understand that any loan will reduce the value of your estate? If No, please document in notes any measures you will take to ensure customer understands the nature and effect of a loan"
              onChange={handleAntdFormItemChange}
              options={yesNoOtherOptions}
              value={formData?.customerSecond?.understandsLoanReducesAssetValue}
              name="customerSecond.understandsLoanReducesAssetValue"
              type="radio"
            />
            <CustomFormItem
              label="Is there any indication of coercion or undue influence from someone else?"
              onChange={handleAntdFormItemChange}
              options={yesNoOtherOptions}
              value={formData?.customerSecond?.hasCoercionOrUndueInfluence}
              name="customerSecond.hasCoercionOrUndueInfluence"
              type="radio"
            />
            <StyledBreakLine />
            <StyledTitle>Attitude Towards Client Vulnerability</StyledTitle>
            <CustomWarnings
              label="Select an option to reveal further information that may prove useful in determining your assessment."
              onChange={handleAntdFormItemChange}
              options={attitudeTowardsClientVulnerabilityOptions}
              value={formData?.customerSecond?.attitudeTowardsClientVulnerability}
              name="customerSecond.attitudeTowardsClientVulnerability"
            />
          </>
        )}
        <CustomFooter loading={loading} setSaveType={setSaveType} />
      </Form>
    </StyledContainer>
  )
}

export default Health
