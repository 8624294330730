import styled from 'styled-components'
import Button from 'antd/lib/button'
import { Link } from 'react-router-dom'

export const StyledSignUpButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;

  width: 100%;
  max-width: 343px;
  height: 48px;
  background: #90258c;
  border-radius: 4px !important;

  &:hover {
    color: white;
  }

  &:focus {
    color: white;
  }
`

export const StyledSignUpContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledLeftContainer = styled.div`
  width: 50%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const StyledRightContainer = styled.div`
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const StyledBackground = styled.div<{ img: any }>`
  height: 100%;
  max-width: 90%;
  width: 748px;
  background-image: url(${({ img }) => img});

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledLogo = styled.img`
  margin-top: 20px;
  width: 200px;
  height: 80px;
`

export const StyledContainerPosition = styled.div`
  margin-top: 276px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const StyledDescContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
  width: 424px;
`

export const StyledBreakLine = styled.div`
  width: 70px;
  height: 0;
  border: 1.5px solid #fefefe;
  background-color: #fefefe;
  align-self: flex-start;
`

export const StyledDesc = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;

  color: #fefefe;

  align-self: flex-end;
`

export const StyledFormContainer = styled.div`
  /* width: 424px; */
  display: flex;
  flex-direction: column;

  /* @media (max-width: 768px) {
    width: 100%;
  } */
`

export const StyledRegisterMsg = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;

  display: flex;
  align-items: center;

  color: #90258c;
`

export const StyledMinMsg = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #120224;
  margin: 8px 0 12px 0;
`

export const StyledFormsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 12px;
`

export const StyledMainFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
`

export const StyledLinksContainer = styled.div`
  display: flex;
  margin-top: 10px;
`

export const StyledCreateAccount = styled.p`
  margin-right: 5px;
  font-size: 1rem;
  white-space: no-wrap;
`

export const StyledLink = styled(Link)`
  color: blue;
  white-space: no-wrap;
  font-size: 1rem;
  color: #0088ff;
  text-decoration: underline;
`

export const StyledChooseLogoText = styled.label`
  align-self: flex-start;
  margin: 20px 0 0 0;

  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #90258c;

  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
`

export const StyledAvatar = styled.img`
  width: 48px;
  height: 48px;
`

export const StyledPlaceholderAvatar = styled.img``
