import { IIncomeData, IPensionsData } from './Income.interface'

export const employmentStatusOptions = [
  {
    label: 'Fully Retired',
    value: 'fullyRetired',
  },
  {
    label: 'Semi-Retired',
    value: 'semiRetired',
  },
  {
    label: 'Employed',
    value: 'employed',
  },
  {
    label: 'Self Employed',
    value: 'selfEmployed',
  },
  {
    label: 'Unemployed',
    value: 'unemployed',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

export const incomeTableColumns = [
  {
    name: 'incomeOwner',
    header: 'Customer',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IIncomeData) => data.incomeOwner || 'N/A',
  },
  {
    name: 'incomeType',
    header: 'Type of Income',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'monthlyNetIncome',
    header: 'Monthly Net Income',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IIncomeData) => (data.monthlyNetIncome ? `£${data.monthlyNetIncome}` : 'N/A'),
  },
  {
    name: 'annualGross',
    header: 'Annual Gross',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IIncomeData) => (data.annualGross ? `£${data.annualGross}` : 'N/A'),
  },
  {
    name: 'willIncomeChange',
    header: 'Will change in the future?',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IIncomeData) => (data.willIncomeChange ? 'Yes' : 'No'),
  },
  {
    name: 'whenWillIncomeChange',
    header: 'When will it change?',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IIncomeData) => (data.whenWillIncomeChange ? data.whenWillIncomeChange : 'N/A'),
  },
  {
    name: 'incomeWillChangeTo',
    header: 'To What Amount?',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IIncomeData) => (data.incomeWillChangeTo ? `£${data.incomeWillChangeTo}` : 'N/A'),
  },
]

export const pensionsTableColumns = [
  {
    name: 'pensionOwner',
    header: 'Customer',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IPensionsData) => data.pensionOwner || 'N/A',
  },
  {
    name: 'pensionType',
    header: 'Type of Pension',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'whenWillPensionMature',
    header: 'will be mature in',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IPensionsData) => (data.whenWillPensionMature ? data.whenWillPensionMature : 'N/A'),
  },
  {
    name: 'pensionFundAmount',
    header: 'Fund Amount',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IPensionsData) => `£${data.pensionFundAmount}`,
  },
  {
    name: 'cashFromFund',
    header: 'Cash Amount',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IPensionsData) => `£${data.cashFromFund}`,
  },
  {
    name: 'monthlyIncomeFromPension',
    header: 'Estimated Monthly Income',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IPensionsData) => `£${data.monthlyIncomeFromPension}`,
  },
]
