import styled from 'styled-components';

export const StyledContainer = styled.div`
  background-color: #fefefe;

  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledFormContainer = styled.div`
  padding: 36px 32px;

  width: 936px;
`;

export const StyledTabTitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;

  color: #120224;
`;

export const StyledReferContainer = styled.div`
  max-width: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 24px;
`;
