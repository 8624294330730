import { useEffect, useState } from 'react'
import { message } from 'antd'

import { commonHelper } from 'helpers'
import useGeneral from './useGeneral'
import { useGetCustomers, useGetLiabilitiesInfo, useGetOtherLiabilities } from 'services/clients/clients.service'
import { commonUtils, eventManager } from 'utils'
import { liabilityTypeOptions } from 'modals/EditLiabilityModal/EditLiabilityModal.helper'
import { addEditLiabilitiesMutation, deleteOtherLiabilitiesMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { AddEditLiabilitiesPayload, DeleteWithIdNumberAnd_IdsPayload } from 'services/shared/mutation.payload'
import { getCustomersOptions } from 'utils/getCustomersOptions'
import { ILabelAndValue } from 'interfaces/common/labelAndValue'

const useLiabilities = () => {
  const deleteOtherLiabilities = useCustomMutation<DeleteWithIdNumberAnd_IdsPayload>({ mutation: deleteOtherLiabilitiesMutation })
  const addEditLiabilities = useCustomMutation<AddEditLiabilitiesPayload>({ mutation: addEditLiabilitiesMutation, refetchQueries: ['getClientFormsProgress'] })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { liabilities, loading: formInitialDataLoading, refetch } = useGetLiabilitiesInfo(idNumber)
  const { otherLiabilities } = useGetOtherLiabilities(idNumber)
  const [loading, setLoading] = useState(formInitialDataLoading)
  const { data: customersData } = useGetCustomers(idNumber)
  const [formData, setFormData] = useState({
    hasLiabilities: liabilities.hasLiabilities,
    isSecuredDebtsRepaidWithAdvance: liabilities.isSecuredDebtsRepaidWithAdvance,
    lessThanTwoYearsBeforeDebtsConsolidation: liabilities.lessThanTwoYearsBeforeDebtsConsolidation,
  })
  const [tableError, setTableError] = useState('')

  const customersOptions = getCustomersOptions(customersData, true)

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [loading])

  useEffect(() => {
    setLoading(formInitialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(liabilities)
    delete newData._id
    setFormData({ ...newData })

    return () => {
      setSelected({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInitialDataLoading])

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value, e.target?.name)
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: parsedValue,
    }))
  }

  const handleSubmit = async () => {
    const { errors } = await addEditLiabilities({
      idNumber,
      values: formData,
    })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const { handleSaveAndGoBack, handleSave, handleSaveAndContinue } = useGeneral({ handleSubmit, setLoading })

  const [saveType, setSaveType] = useState<'save' | 'saveAndGoBack' | 'saveAndContinue'>('save')

  const onSubmit = () => {
    if (saveType === 'saveAndGoBack') {
      handleSaveAndGoBack()
    } else if (saveType === 'saveAndContinue') {
      handleSaveAndContinue()
    } else if (saveType === 'save') {
      handleSave()
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill out every required field')
  }

  const handleAddButton = () => {
    eventManager.openEditLiabilityModal(
      {},
      {
        confirm: () => setTableError(''),
      },
    )
  }

  let liabilitiesTotalValue = 0

  const liabilitiesDataSource =
    otherLiabilities.map((liability: any) => {
      liabilitiesTotalValue += Number(liability.balance)
      return {
        id: liability._id,
        liabilityHolder: customersOptions.find((customer: any) => customer.value === liability.liabilityHolder)?.label,
        liabilityType: liabilityTypeOptions?.find((item: ILabelAndValue) => item.value === liability.liabilityType)?.label,
        monthlyCost: liability.monthlyCost,
        remainingTerm: liability.remainingTerm,
        balance: liability.balance,
        earlyRepaymentCharges: liability.earlyRepaymentCharges,
        planToRepay: liability.planToRepay ? 'Yes' : 'No',
      }
    }) || []

  const [selected, setSelected] = useState({})
  const displayDeleteButton = !!Object.keys(selected).length
  const displayEditButton = Object.keys(selected).length === 1

  const setSelectedRows = (selectedRowObj: any) => {
    setSelected(selectedRowObj)
  }

  const handleDelete = async () => {
    setLoading(true)
    const otherLiabilities_IdToDelete = Object.keys(selected)
    const { errors } = await deleteOtherLiabilities({ _ids: otherLiabilities_IdToDelete, idNumber })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    setLoading(false)
    refetch()
    setSelected({})
  }

  const handleDeleteModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleDelete(),
      },
    )
  }

  const handleEditModal = () => {
    eventManager.openEditLiabilityModal(
      { _id: Object.keys(selected)[0], isEdit: true },
      {
        confirm: () => {},
      },
    )
  }

  return {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    handleAntdFormItemChange,
    handleAddButton,
    liabilitiesDataSource,
    liabilitiesTotalValue,
    setSelectedRows,
    displayDeleteButton,
    handleDeleteModal,
    displayEditButton,
    handleEditModal,
    tableError,
  }
}

export default useLiabilities
