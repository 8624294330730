import { whenWillExpenditureChangeOptions } from 'modals/ExpenditureModal/ExpenditureDetailsModal.helper'

interface ITableDataSource {
  when: string | undefined
  total: number
}

export const calculateDisposableIncome = (incomeTableDataSource: ITableDataSource[], expenditureTableDataSource: ITableDataSource[]) => {
  const concatExpenditure = incomeTableDataSource.concat(expenditureTableDataSource)

  const dateIndexObj: { [key: string]: number } = {}

  // eslint-disable-next-line array-callback-return
  whenWillExpenditureChangeOptions?.map((item: any, index: number) => {
    dateIndexObj[item.label] = index
  })

  const initialExpenditureTableDataSource: { when: string; total: number }[] = []

  const uniqueDatesWithEmptyTotal =
    concatExpenditure?.reduce((acc: any, current: any) => {
      if (current.when) {
        const found = acc.find((item: any) => item.when === current.when)
        if (!found) {
          acc.push({ when: current.when, total: 0 })
        }
      }
      return acc
    }, []) || []

  // eslint-disable-next-line array-callback-return
  uniqueDatesWithEmptyTotal?.map((item: any) => {
    const { when, total } = item
    let indexToAdd
    // eslint-disable-next-line array-callback-return
    initialExpenditureTableDataSource.map((initialData: any, index) => {
      if (dateIndexObj[initialData.when] > dateIndexObj[when]) {
        indexToAdd = index - 1
      }
    })
    if (typeof indexToAdd !== 'number') {
      initialExpenditureTableDataSource.splice(initialExpenditureTableDataSource.length, 0, { when, total })
    } else {
      initialExpenditureTableDataSource.splice(indexToAdd, 0, { when, total })
    }
  })

  const rotateArray1 = (arr: any) => {
    return arr.map((e: any, i: number) => arr[arr.length - i - 1])
  }

  const finalExpenditureTableDataSource = initialExpenditureTableDataSource.map((item: { when: string; total: number }) => {
    const { when } = item
    const rotatedIncomeTableDataSource = rotateArray1(incomeTableDataSource)
    const rotatedExpenditureTableDataSource = rotateArray1(expenditureTableDataSource)

    let incomeRow = rotatedIncomeTableDataSource.find((element: any) => dateIndexObj[element.when] <= dateIndexObj[when])

    if (!incomeRow) {
      incomeRow = incomeTableDataSource[0]
    }

    let expenditureRow = rotatedExpenditureTableDataSource.find((element: any) => dateIndexObj[element.when] <= dateIndexObj[when])

    if (!expenditureRow) {
      expenditureRow = expenditureTableDataSource[0]
    }

    return {
      when,
      total: incomeRow.total - expenditureRow.total,
    }
  })

  return finalExpenditureTableDataSource
}
