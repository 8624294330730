import CustomPagination from 'components/CustomPagination'
import EditDeleteButton from 'components/EditDeleteButton'
import Grid from 'components/Grid'
import Layout from 'components/Layout'
import MainContentContainer from 'components/MainContentContainer'
import CompanyAddEditDrawer from 'drawers/CompanyAddEditDrawer/CompanyAddEditDrawer'
import { useCompanyPage } from 'hooks'

import {
  StyledContainer,
  ButtonsContainer,
  StyledAddCompanyButton,
  StyledGridContainer,
  StyledPaginationContainer,
  StyledSuspendButton,
} from './Companies.styles'

const Companies = () => {
  const {
    companyGridColumns,
    companyGridDataSource,
    refetch,
    count,
    limit,
    open,
    closeDrawer,
    openDrawer,
    handleLogoUpload,
    companyLogo,
    avatarUrl,
    loading,
    handleSubmit,
    handleFormFieldChange,
    formData,
    drawerType,
    handleEdit,
    displayEditButton,
    displaySuspendButton,
    handleSuspend,
    handleDeleteModal,
    setSelectedRows,
  } = useCompanyPage()

  return (
    <Layout>
      <MainContentContainer pageName="Companies">
        <StyledContainer>
          <ButtonsContainer>
            {displayEditButton && <EditDeleteButton onClick={handleEdit} type="edit" value="Edit" />}
            {displaySuspendButton && (
              <>
                <EditDeleteButton onClick={handleDeleteModal} type="delete" value="Delete" />
                <StyledSuspendButton onClick={handleSuspend}>Suspend</StyledSuspendButton>
              </>
            )}
            <StyledAddCompanyButton onClick={openDrawer} disabled={displaySuspendButton}>
              Add Company
            </StyledAddCompanyButton>
          </ButtonsContainer>

          <StyledGridContainer>
            <Grid setSelectedRows={setSelectedRows} columns={companyGridColumns} dataSource={companyGridDataSource} checkboxColumn={true} />
          </StyledGridContainer>
          <StyledPaginationContainer>
            <CustomPagination pageSize={limit} count={count} refetch={refetch} />
          </StyledPaginationContainer>
        </StyledContainer>
        <CompanyAddEditDrawer
          formData={formData}
          companyLogo={companyLogo}
          avatarUrl={avatarUrl}
          open={open}
          closeDrawer={closeDrawer}
          handleLogoUpload={handleLogoUpload}
          loading={loading}
          handleSubmit={handleSubmit}
          handleFormFieldChange={handleFormFieldChange}
          drawerType={drawerType}
        />
      </MainContentContainer>
    </Layout>
  )
}

export default Companies
