import Tabs from 'antd/lib/tabs'

import Layout from 'components/Layout'
import MainContentContainer from 'components/MainContentContainer'
// import ComingSoon from 'components/ComingSoon'

import { StyledContainer, StyledFilterButton } from './Dashboard.styles'
import DashboardFilterDrawer from 'drawers/DashboardFilterDrawer'
import { useDashboard } from 'hooks'
import WrittenBusiness from './Tabs/WrittenBusiness'
import OnRisk from './Tabs/OnRisk'
import CommissionPaid from './Tabs/CommissionPaid'
import Ntu from './Tabs/Ntu'
import useLinkClient from 'hooks/dashboard/useLinkClient'

const Dashboard = () => {
  const {
    // loading,
    isFilterOpen,
    closeFilterDrawer,
    openFilterDrawer,
    handleFilterSubmit,
  } = useDashboard()

  const {
    open,
    handleDrawerClose,
    loading,
    clientsOptions,
    handleCustomSelectChange,
    formData,
    handleFormFieldChange,
    handleSubmit,
    form,
    detailsDataSource,
    count,
    limit,
    refetch,
    statusOptions,
    Status,
    handleTabChange,
    setSelectedRows,
    displayEditButton,
    handleEdit,
    displayDeleteButton,
    handleDeleteModal,
    formState,
    handleOpenDrawer,
  } = useLinkClient()

  const tabItems = [
    {
      label: 'WRITTEN BUSINESS',
      key: Status.writtenBusiness,
      children: (
        <WrittenBusiness
          open={open}
          handleDrawerClose={handleDrawerClose}
          loading={loading}
          clientsOptions={clientsOptions}
          handleCustomSelectChange={handleCustomSelectChange}
          formData={formData}
          handleFormFieldChange={handleFormFieldChange}
          handleSubmit={handleSubmit}
          form={form}
          detailsDataSource={detailsDataSource}
          count={count}
          limit={limit}
          refetch={refetch}
          statusOptions={statusOptions}
          setSelectedRows={setSelectedRows}
          displayEditButton={displayEditButton}
          handleEdit={handleEdit}
          displayDeleteButton={displayDeleteButton}
          handleDeleteModal={handleDeleteModal}
          formState={formState}
          handleOpenDrawer={handleOpenDrawer}
        />
      ),
    },
    {
      label: 'ON RISK',
      key: Status.onRisk,
      children: (
        <OnRisk
          open={open}
          handleDrawerClose={handleDrawerClose}
          loading={loading}
          clientsOptions={clientsOptions}
          handleCustomSelectChange={handleCustomSelectChange}
          formData={formData}
          handleFormFieldChange={handleFormFieldChange}
          handleSubmit={handleSubmit}
          form={form}
          detailsDataSource={detailsDataSource}
          count={count}
          limit={limit}
          refetch={refetch}
          statusOptions={statusOptions}
          setSelectedRows={setSelectedRows}
          displayEditButton={displayEditButton}
          handleEdit={handleEdit}
          formState={formState}
          handleOpenDrawer={handleOpenDrawer}
        />
      ),
    },
    {
      label: 'COMMISSION PAID',
      key: Status.commissionPaid,
      children: (
        <CommissionPaid
          open={open}
          handleDrawerClose={handleDrawerClose}
          loading={loading}
          clientsOptions={clientsOptions}
          handleCustomSelectChange={handleCustomSelectChange}
          formData={formData}
          handleFormFieldChange={handleFormFieldChange}
          handleSubmit={handleSubmit}
          form={form}
          detailsDataSource={detailsDataSource}
          count={count}
          limit={limit}
          refetch={refetch}
          statusOptions={statusOptions}
          setSelectedRows={setSelectedRows}
          displayEditButton={displayEditButton}
          handleEdit={handleEdit}
          formState={formState}
          handleOpenDrawer={handleOpenDrawer}
        />
      ),
    },
    {
      label: 'NTUS',
      key: Status.ntu,
      children: (
        <Ntu
          open={open}
          handleDrawerClose={handleDrawerClose}
          loading={loading}
          clientsOptions={clientsOptions}
          handleCustomSelectChange={handleCustomSelectChange}
          formData={formData}
          handleFormFieldChange={handleFormFieldChange}
          handleSubmit={handleSubmit}
          form={form}
          detailsDataSource={detailsDataSource}
          count={count}
          limit={limit}
          refetch={refetch}
          statusOptions={statusOptions}
          setSelectedRows={setSelectedRows}
          displayEditButton={displayEditButton}
          handleEdit={handleEdit}
          formState={formState}
          handleOpenDrawer={handleOpenDrawer}
        />
      ),
    },
  ]

  return (
    <Layout>
      <MainContentContainer pageName="Dashboard">
        <StyledContainer>
          <Tabs
            defaultActiveKey={Status.writtenBusiness}
            type="card"
            onChange={handleTabChange}
            tabBarExtraContent={<StyledFilterButton onClick={openFilterDrawer}>Filters</StyledFilterButton>}
            items={tabItems}
          />
        </StyledContainer>
        <DashboardFilterDrawer open={isFilterOpen} closeDrawer={closeFilterDrawer} loading={loading} handleSubmit={handleFilterSubmit} />
      </MainContentContainer>
    </Layout>
  )
}

export default Dashboard
