import { useState, useEffect } from 'react'
import { client } from 'services/contentful'

export const useGetContentfulDataByContentModel = (
  contentType:
    | 'lender'
    | 'procFees'
    | 'solicitors'
    | 'servicesAndWills'
    | 'rateChanges'
    | 'guidelines'
    | 'complianceSupport'
    | 'newsAndTraining'
    | 'videoResources',
  orderField: string | null = null,
) => {
  const [data, setData] = useState<any>(null)
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const entriesOptions: any = { content_type: contentType }
    if (orderField) {
      entriesOptions.order = [orderField]
    }

    client
      .getEntries(entriesOptions)
      .then((response) => {
        setData(response.items)
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setError(err)
        setLoading(false)
      })
  }, [contentType, orderField])

  return { data, isLoading, error }
}

export const useGetContentfulDataByTitle = (
  contentType: 'lender' | 'procFees' | 'solicitors' | 'servicesAndWills' | 'rateChanges' | 'guidelines' | 'complianceSupport' | 'newsAndTraining',
  title: string,
) => {
  const [data, setData] = useState<any>(null)
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    setLoading(true) // Ensure loading state is reset on title or contentType change
    client
      .getEntries({
        content_type: contentType,
        'fields.title': title, // Use the slug to fetch a specific entry
        limit: 1,
      })
      .then((response) => {
        // Set the first item if available, otherwise null
        setData(response.items.length > 0 ? response.items[0] : null)
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setError(err)
        setLoading(false)
      })
    // Dependency array includes slug to refetch when it changes
  }, [contentType, title])

  return { data, isLoading, error }
}
