import styled from 'styled-components';

export const StyledContainer = styled.div``;

export const StyledCompanyLogoTitle = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #120224;
`;

export const StyledLogoUploaderContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 536px;
  max-width: 100%;
  height: 153px;

  background: #f8f8f8;
  border-radius: 4px;
`;

export const StyledLogoAndDescContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 28px;
`;

export const StyledLogoPlaceholderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 88px;
  border: 2px solid #c3b3c5;
  border-radius: 50%;
`;

export const StyledPlaceholderLogo = styled.img`
  width: 32px;
  height: 32px;
`;

export const StyledDesc = styled.p`
  width: 378px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8a8a8a;
`;

export const StyledChooseLogoText = styled.label`
  align-self: flex-start;
  margin: 20px 0 0 16px;

  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #90258c;
`;

export const StyledCompanyLogo = styled.img`
  width: 88px;
  height: 88px;
  border-radius: 50%;
`;
