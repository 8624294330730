import styled from 'styled-components';

export const StyledContainer = styled.div``;

export const StyledItemsContainer = styled.div`
  margin-top: 24px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  justify-content: flex-start;
  align-items: center;

  gap: 24px;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 930px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StyledItem = styled.div`
  cursor: pointer;
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 16px;

  /* width: 424px; */
  height: 64px;

  /* White/80 */

  background: #eaeaea;
  /* White/70 */

  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;

export const StyledLeftContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
`;

export const StyledImg = styled.img``;

export const StyledLabel = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #120224;
`;

export const StyledRightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
