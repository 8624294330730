import { ApolloClient, DefaultOptions, ApolloLink } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import config from 'config';

const accessToken = () => localStorage.getItem('accessToken');

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const errorLink = onError(({ graphQLErrors, networkError }: any) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }: any) => {
      if (
        JSON.stringify(message).indexOf('Unauthorized') !== -1 &&
        accessToken()
      ) {
        localStorage.clear();
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      }
      // tslint:disable-next-line: no-console
      return console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });
  }

  // tslint:disable-next-line: no-console
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_: any, { headers }: any) => ({
  headers: {
    ...headers,
    Authorization: `Bearer ${accessToken()}`,
  },
}));

const uploadLink = createUploadLink({ uri: `${config.API_URL}/graphql` });

const apolloLink = ApolloLink.from([errorLink, authLink, uploadLink]);

const inMemoryCache = new InMemoryCache();

// eslint-disable-next-line
export default new ApolloClient({
  link: apolloLink,
  cache: inMemoryCache,
  defaultOptions,
});
