import { HomeIcon, DeleteIcon, EditDarkIcon } from 'assets/images'
import {
  StyledContainer,
  StyledActionButtons,
  StyledActionButton,
  StyledHeaderContainer,
  StyledImg,
  StyledLastName,
  StyledName,
  StyledTitle,
} from './PropertyCard.styles'
import { PropertyCardProps } from './PropertyCard.types'

const PropertyCard = ({ editButtonClick, address, handleRemoveProperty, displayDeleteButton }: PropertyCardProps) => {
  return (
    <StyledContainer>
      <StyledHeaderContainer>
        <StyledTitle>Property</StyledTitle>
        <StyledImg src={HomeIcon} />
      </StyledHeaderContainer>
      <StyledLastName>{address || 'Address'}</StyledLastName>
      <StyledName>Town</StyledName>
      <StyledActionButtons>
        {displayDeleteButton && <StyledActionButton src={DeleteIcon} onClick={handleRemoveProperty} style={{ width: '32px', height: '32px' }} />}

        <StyledActionButton src={EditDarkIcon} onClick={editButtonClick} />
      </StyledActionButtons>
    </StyledContainer>
  )
}

export default PropertyCard
