import { Switch } from 'antd';
import styled from 'styled-components';

export const StyledContainer = styled.div``;

export const StyledCompanyOptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledSwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  column-gap: 24px;
`;

export const StyledSwitchLabel = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #120224;
`;

export const StyledSwitch = styled(Switch)``;

export const StyledSelectContainer = styled.div`
  width: 100%;
  margin-top: 24px;
`;
