import { IAssetsData } from './Assets.interface'

export const assetsTableColumns = [
  {
    name: 'assetOwner',
    header: 'Customer',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IAssetsData) => data.assetOwner || 'N/A',
  },
  {
    name: 'assetType',
    header: 'Type of Asset',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'assetValue',
    header: 'Value of Asset',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IAssetsData) => `£${data.assetValue}`,
  },
]
