import styled from 'styled-components'

export const StyledWrapper = styled.div`
  width: 100%;
  /* padding: 32px 16px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const Styled2x2Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`
