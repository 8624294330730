import CustomerCard from 'components/CustomerCard'
import { StyledContainer, StyledDescription, StyledCustomerCardsContainer, StyledTitle } from './Customers.styles'
import useCustomers from 'hooks/clients/useCustomers'
import Loading from 'components/Loading/Loading'

const Customers = ({ handleItemClick }: any) => {
  const { loading, onEditClick, customerFirst, customerSecond, customerQuantity } = useCustomers({ handleItemClick })

  if (loading) {
    return <Loading fontSize={48} />
  }

  return (
    <StyledContainer>
      <StyledTitle>Customers</StyledTitle>
      <StyledDescription>
        Here is where you can enter and manage your customers’ information. To edit customer details, please click the black pencil.
        <br />
        To change customer number please visit <strong>Case Overview</strong> form
      </StyledDescription>
      <StyledCustomerCardsContainer>
        {customerQuantity === 1 ? (
          <CustomerCard editButtonClick={onEditClick.bind(this, 1)} firstName={customerFirst?.firstName} lastName={customerFirst?.lastName} />
        ) : (
          <>
            <CustomerCard editButtonClick={onEditClick.bind(this, 1)} firstName={customerFirst?.firstName} lastName={customerFirst?.lastName} />
            <CustomerCard editButtonClick={onEditClick.bind(this, 2)} firstName={customerSecond?.firstName} lastName={customerSecond?.lastName} />
          </>
        )}
      </StyledCustomerCardsContainer>
    </StyledContainer>
  )
}

export default Customers
