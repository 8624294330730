import {
  StyledContainer,
  StyledPageName,
  StyledChildren,
} from './MainContentContainer.styles';
import { MainContentContainerTypes } from './MainContentContainer.types';

const MainContentContainer = ({
  pageName,
  children,
}: MainContentContainerTypes) => {
  return (
    <StyledContainer>
      <StyledPageName>{pageName}</StyledPageName>
      <StyledChildren>{children}</StyledChildren>
    </StyledContainer>
  );
};

export default MainContentContainer;
