import { Form } from 'antd'

import Loading from 'components/Loading'

import { yesNoOptions } from 'utils/commonOptions'
import CustomFooter from 'pages/QuickSearch/CustomFooter/CustomFooter'

import { StyledContainer, StyledTitle, StyledTitleContainer, StyledRequiredMsg, StyledBreakLine } from './OtherConditionDetails.styles'
import CustomFormItem from 'components/CustomFormItem/CustomFormItem'
import useOtherConditionDetails from 'hooks/clients/QuickSearch/MedicalDetails/useOtherConditionDetails'

const OtherConditionDetails = () => {
  const {
    loading,
    handleNext,
    handleAntdFormItemChange,
    flattenedFormData,
    customerFirstName,
    customerSecondName,
    enterMedicalDetailsOfCustomerFirst,
    enterMedicalDetailsOfCustomerSecond,
  } = useOtherConditionDetails()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <Form
        layout="vertical"
        size="large"
        name="basic"
        onFinish={handleNext}
        initialValues={flattenedFormData}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '12px',
          marginTop: '8px',
          width: '100%',
        }}
      >
        {enterMedicalDetailsOfCustomerFirst && (
          <>
            <StyledTitleContainer>
              <StyledTitle>Other Conditions Details of {customerFirstName}</StyledTitle>
              <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
            </StyledTitleContainer>
            <CustomFormItem
              label="Have you been diagnosed with diabetes, which is controlled by insulin or tablet treatment? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.hasControlledDiabetes"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerFirst.hasControlledDiabetes'] && (
              <>
                <CustomFormItem
                  label="Date of diagnosis (YYYY-MM) *"
                  onChange={handleAntdFormItemChange}
                  name="customerFirst.diabetesDiagnosisDate"
                  rules={['isRequired']}
                />
              </>
            )}
            <CustomFormItem
              label="Have you been diagnosed with Parkinson's disease? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.hasParkinsonDisease"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerFirst.hasParkinsonDisease'] && (
              <>
                <CustomFormItem
                  label="Does this require prescribed daily medication? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerFirst.parkinsonRequiresDailyMedication"
                  type="radio"
                  rules={['isRequired']}
                />
                <CustomFormItem
                  label="Does this require the use of walking sticks or similar aids? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerFirst.parkinsonRequiresMobilityAIDS"
                  type="radio"
                  rules={['isRequired']}
                />
                <CustomFormItem
                  label="Date of diagnosis (YYYY-MM) *"
                  onChange={handleAntdFormItemChange}
                  name="customerFirst.parkinsonDiagnosisDate"
                  rules={['isRequired']}
                />
              </>
            )}
            <CustomFormItem
              label="Have you been diagnosed with multiple sclerosis? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.hasMultipleSclerosis"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerFirst.hasMultipleSclerosis'] && (
              <>
                <CustomFormItem
                  label="Does this require the use of mobility aids? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerFirst.sclerosisRequiresMobilityAIDS"
                  type="radio"
                  rules={['isRequired']}
                />
                <CustomFormItem
                  label="Date of diagnosis (YYYY-MM) *"
                  onChange={handleAntdFormItemChange}
                  name="customerFirst.sclerosisDiagnosisDate"
                  rules={['isRequired']}
                />
              </>
            )}
            <CustomFormItem
              label="Have you been diagnosed with chronic respiratory disease requiring daily medication or inhalers? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.isDiagnosedWithChronicRespiratoryDisease"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerFirst.isDiagnosedWithChronicRespiratoryDisease'] && (
              <CustomFormItem
                label="Date of diagnosis (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerFirst.respiratoryDiseaseDiagnosisDate"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="Have you been diagnosed with dementia (including Alzheimer’s Disease)? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.hasDementia"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerFirst.hasDementia'] && (
              <CustomFormItem
                label="Date of diagnosis (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerFirst.dementiaDiagnosisDate"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="Have you been diagnosed with chronic kidney failure? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.hasChronicKidneyFailure"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerFirst.hasChronicKidneyFailure'] && (
              <CustomFormItem
                label="Date of diagnosis (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerFirst.kidneyFailureDiagnosisDate"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="Have you had a heart, kidney, liver or lung transplant? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.hadOrganTransplant"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerFirst.hadOrganTransplant'] && (
              <CustomFormItem
                label="Date of surgery (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerFirst.organTransplantSurgeryDate"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="Have you been diagnosed with cirrhosis of the liver? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.hasCirrhosisOfLiver"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerFirst.hasCirrhosisOfLiver'] && (
              <CustomFormItem
                label="Date of diagnosis (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerFirst.liverCirrhosisDiagnosisDate"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="Have you been diagnosed with motor neuron diseases? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.isDiagnosedWithMotorNeuronDiseases"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerFirst.isDiagnosedWithMotorNeuronDiseases'] && (
              <CustomFormItem
                label="Date of diagnosis (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerFirst.motorNeuronDiseasesDiagnosisDate"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="Have you been diagnosed with hepatitis C? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.hasHepatitisC"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerFirst.hasHepatitisC'] && (
              <CustomFormItem
                label="Date of diagnosis (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerFirst.hepatitisCDiagnosisDate"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="Have you been diagnosed with HIV? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.isDiagnosedWithHIV"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerFirst.isDiagnosedWithHIV'] && (
              <CustomFormItem
                label="Date of diagnosis (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerFirst.hivDiagnosisDate"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="Have you taken early retirement on the grounds of ill health? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.hasTakenEarlyRetirementForIllHealth"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerFirst.hasTakenEarlyRetirementForIllHealth'] && (
              <CustomFormItem
                label="If so, was it for a reason that is different to all the conditions identified above; and was it also: – not due to musculoskeletal disorder (for example osteoarthritis, rheumatoid arthritis, back, neck, shoulder or joint pains) and – not due to mental health disorder (for example anxiety, stress, depression or any mental or nervous illness) *"
                onChange={handleAntdFormItemChange}
                options={yesNoOptions}
                name="customerFirst.hasTakenEarlyRetirementForIllHealthForDifferentReason"
                type="radio"
                rules={['isRequired']}
              />
            )}
          </>
        )}
        {enterMedicalDetailsOfCustomerSecond && (
          <>
            {enterMedicalDetailsOfCustomerFirst && <StyledBreakLine />}
            <StyledTitleContainer>
              <StyledTitle>Other Conditions Details of {customerSecondName}</StyledTitle>
              <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
            </StyledTitleContainer>
            <CustomFormItem
              label="Have you been diagnosed with diabetes, which is controlled by insulin or tablet treatment? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.hasControlledDiabetes"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerSecond.hasControlledDiabetes'] && (
              <>
                <CustomFormItem
                  label="Date of diagnosis (YYYY-MM) *"
                  onChange={handleAntdFormItemChange}
                  name="customerSecond.diabetesDiagnosisDate"
                  rules={['isRequired']}
                />
              </>
            )}
            <CustomFormItem
              label="Have you been diagnosed with Parkinson's disease? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.hasParkinsonDisease"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerSecond.hasParkinsonDisease'] && (
              <>
                <CustomFormItem
                  label="Does this require prescribed daily medication? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerSecond.parkinsonRequiresDailyMedication"
                  type="radio"
                  rules={['isRequired']}
                />
                <CustomFormItem
                  label="Does this require the use of walking sticks or similar aids? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerSecond.parkinsonRequiresMobilityAIDS"
                  type="radio"
                  rules={['isRequired']}
                />
                <CustomFormItem
                  label="Date of diagnosis (YYYY-MM) *"
                  onChange={handleAntdFormItemChange}
                  name="customerSecond.parkinsonDiagnosisDate"
                  rules={['isRequired']}
                />
              </>
            )}
            <CustomFormItem
              label="Have you been diagnosed with multiple sclerosis? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.hasMultipleSclerosis"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerSecond.hasMultipleSclerosis'] && (
              <>
                <CustomFormItem
                  label="Does this require the use of mobility aids? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerSecond.sclerosisRequiresMobilityAIDS"
                  type="radio"
                  rules={['isRequired']}
                />
                <CustomFormItem
                  label="Date of diagnosis (YYYY-MM) *"
                  onChange={handleAntdFormItemChange}
                  name="customerSecond.sclerosisDiagnosisDate"
                  rules={['isRequired']}
                />
              </>
            )}
            <CustomFormItem
              label="Have you been diagnosed with chronic respiratory disease requiring daily medication or inhalers? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.isDiagnosedWithChronicRespiratoryDisease"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerSecond.isDiagnosedWithChronicRespiratoryDisease'] && (
              <CustomFormItem
                label="Date of diagnosis (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerSecond.respiratoryDiseaseDiagnosisDate"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="Have you been diagnosed with dementia (including Alzheimer’s Disease)? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.hasDementia"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerSecond.hasDementia'] && (
              <CustomFormItem
                label="Date of diagnosis (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerSecond.dementiaDiagnosisDate"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="Have you been diagnosed with chronic kidney failure? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.hasChronicKidneyFailure"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerSecond.hasChronicKidneyFailure'] && (
              <CustomFormItem
                label="Date of diagnosis (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerSecond.kidneyFailureDiagnosisDate"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="Have you had a heart, kidney, liver or lung transplant? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.hadOrganTransplant"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerSecond.hadOrganTransplant'] && (
              <CustomFormItem
                label="Date of surgery (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerSecond.organTransplantSurgeryDate"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="Have you been diagnosed with cirrhosis of the liver? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.hasCirrhosisOfLiver"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerSecond.hasCirrhosisOfLiver'] && (
              <CustomFormItem
                label="Date of diagnosis (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerSecond.liverCirrhosisDiagnosisDate"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="Have you been diagnosed with motor neuron diseases? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.isDiagnosedWithMotorNeuronDiseases"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerSecond.isDiagnosedWithMotorNeuronDiseases'] && (
              <CustomFormItem
                label="Date of diagnosis (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerSecond.motorNeuronDiseasesDiagnosisDate"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="Have you been diagnosed with hepatitis C? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.hasHepatitisC"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerSecond.hasHepatitisC'] && (
              <CustomFormItem
                label="Date of diagnosis (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerSecond.hepatitisCDiagnosisDate"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="Have you been diagnosed with HIV? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.isDiagnosedWithHIV"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerSecond.isDiagnosedWithHIV'] && (
              <CustomFormItem
                label="Date of diagnosis (YYYY-MM) *"
                onChange={handleAntdFormItemChange}
                name="customerSecond.hivDiagnosisDate"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="Have you taken early retirement on the grounds of ill health? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.hasTakenEarlyRetirementForIllHealth"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerSecond.hasTakenEarlyRetirementForIllHealth'] && (
              <CustomFormItem
                label="If so, was it for a reason that is different to all the conditions identified above; and was it also: – not due to musculoskeletal disorder (for example osteoarthritis, rheumatoid arthritis, back, neck, shoulder or joint pains) and – not due to mental health disorder (for example anxiety, stress, depression or any mental or nervous illness) *"
                onChange={handleAntdFormItemChange}
                options={yesNoOptions}
                name="customerSecond.hasTakenEarlyRetirementForIllHealthForDifferentReason"
                type="radio"
                rules={['isRequired']}
              />
            )}
          </>
        )}
        <CustomFooter loading={loading} buttonText="Next" buttonTheme="light" />
      </Form>
    </StyledContainer>
  )
}

export default OtherConditionDetails
