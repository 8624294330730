import { IQuickSearch } from 'interfaces/quickSearch.interface'
import { commonUtils } from 'utils'
import { IAnnualInterestData } from './interface'
import { IQuoteResult } from 'interfaces/quoteResult.interface'
import { IAnnualInterestDataItem } from 'components/PropertyValueChart/PropertyValueChart'

export const policySummaryColumns = [
  {
    name: 'item',
    header: 'Item',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'value',
    header: 'Value',
    defaultFlex: 1,
    type: 'text',
  },
]

export const generatePolicySummaryDataSource = (userData: any) => {
  return [
    {
      id: 'leo1',
      item: 'Initial advance',
      value: `£${commonUtils.formatNumberWithCommas(userData.payments[0].initial_only[0].initial_amount[0]._)}`,
    },
    {
      id: 'leo2',
      item: 'Net loan amount',
      value: `£${commonUtils.formatNumberWithCommas(userData.net_loan_amount[0]._)}`,
    },
    {
      id: 'leo3',
      item: 'Total cost of loan',
      value: `£${commonUtils.formatNumberWithCommas(userData.total_cost_of_loan?.[0]._)}`,
    },
    {
      id: 'leo4',
      item: 'Cost per pound',
      value: `£${userData.cost_per_pound?.[0]._}`,
    },
    {
      id: 'leo5',
      item: 'Interest type',
      value: userData.interest_rates[0].type[0],
    },
    {
      id: 'leo6',
      item: 'Interest type - AER',
      value: `${userData.interest_rates[0].aer[0]}%`,
    },
    {
      id: 'leo7',
      item: 'Interest type - MER',
      value: `${userData.interest_rates[0].mer[0]}%`,
    },
    {
      id: 'leo8',
      item: 'Interest type - Typical APR',
      value: `${userData.interest_rates[0].apr[0]}%`,
    },
    {
      id: 'leo10',
      item: 'Early repayment charges',
      value: userData?.early_repayment_charge?.[0].description?.[0],
    },
    {
      id: 'leo11',
      item: 'Early repayment charge type',
      value: userData.early_repayment_charge[0].type[0],
    },
    {
      id: 'leo12',
      item: 'Early repayment charges period',
      value: userData.early_repayment_charge[0].years?.[0] ? `${userData.early_repayment_charge[0].years[0]} years` : '-',
    },
    {
      id: 'leo13',
      item: 'Offers Cashback',
      value: `${userData.cashback_ind[0] === 'true' ? 'Yes' : 'No'}`,
    },
    {
      id: 'leo14',
      item: 'Cashback amount',
      value: `${userData.cashback_ind[0] === 'true' ? userData.cashback_details?.[0].cashback_amount[0]._ : '-'}`,
    },
    {
      id: 'leo15',
      item: 'Free valuations',
      value: `${userData.free_valuations_ind[0] === 'true' ? 'Yes' : 'No'}`,
    },
    {
      id: 'leo16',
      item: 'Downsizing protection',
      value: `${userData.downsizing_protection_ind[0] === 'true' ? 'Yes' : 'No'}`,
    },
    {
      id: 'leo17',
      item: 'Free repayment on death of first life',
      value: `${userData.free_repayment_on_first_death_ind[0] === 'true' ? 'Yes' : 'No'}`,
    },
    {
      id: 'leo18',
      item: 'Guaranteed drawdown',
      value: `${userData.guaranteed_drawdown_ind[0] === 'true' ? 'Yes' : 'No'}`,
    },
    {
      id: 'leo19',
      item: 'Free optional repayments type',
      value: `${userData.optional_repayments_ind[0] === 'true' ? 'Yes' : 'No'}`,
    },
    {
      id: 'leo20',
      item: 'Health/Lifestyle considered',
      value: `${userData.medical_underwriting_ind[0] === 'true' ? 'Yes' : 'No'}`,
    },
  ]
}

export const freeSummaryColumns = [
  {
    name: 'type',
    header: 'Type',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'amount',
    header: 'Amount',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'paid',
    header: 'Paid',
    defaultFlex: 1,
    type: 'text',
  },
]

export const generateFreeSummaryDataSource = (userData: any) => {
  return [
    {
      id: 'cscascasc1',
      type: userData.fees[0].application_fee_type[0],
      amount: userData.fees[0].application_fee[0]._,
      paid: 'no',
    },
  ]
}

export const generateGroups = (propertyGrowth: number) => {
  return [
    { name: 'noPropertyGrowth', header: 'No property growth' },
    { name: 'propertyGrowth', header: `${propertyGrowth}% property growth` },
  ]
}

export const annualInterestColumns = [
  {
    name: 'year',
    header: 'Year',
    defaultFlex: 1,
    type: 'number',
  },
  {
    name: 'startingBalance',
    header: 'Starting balance',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IAnnualInterestData) => (data.startingBalance ? `£${commonUtils.formatNumberWithCommas(data.startingBalance)}` : '-'),
  },
  {
    name: 'additionalPayment',
    header: 'Additional payment',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IAnnualInterestData) => (data.additionalPayment ? `£${commonUtils.formatNumberWithCommas(data.additionalPayment)}` : '-'),
  },
  {
    name: 'annualInterest',
    header: 'Annual interest',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IAnnualInterestData) => (data.annualInterest ? `£${commonUtils.formatNumberWithCommas(data.annualInterest)}` : '-'),
  },
  {
    name: 'endingBalance',
    header: 'Ending balance',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IAnnualInterestData) => (data.endingBalance ? `£${commonUtils.formatNumberWithCommas(data.endingBalance)}` : '-'),
  },
  {
    name: 'noPropertyGrowthEndingValue',
    header: 'Ending value',
    defaultFlex: 1,
    type: 'text',
    group: 'noPropertyGrowth',
    render: ({ data }: IAnnualInterestData) =>
      data.noPropertyGrowthEndingValue ? `£${commonUtils.formatNumberWithCommas(data.noPropertyGrowthEndingValue)}` : '-',
  },
  {
    name: 'noPropertyGrowthEndingEstate',
    header: 'Ending estate',
    defaultFlex: 1,
    type: 'text',
    group: 'noPropertyGrowth',
    render: ({ data }: IAnnualInterestData) =>
      data.noPropertyGrowthEndingEstate ? `£${commonUtils.formatNumberWithCommas(data.noPropertyGrowthEndingEstate)}` : '-',
  },
  {
    name: 'propertyGrowthEndingValue',
    header: 'Ending value',
    defaultFlex: 1,
    type: 'text',
    group: 'propertyGrowth',
    render: ({ data }: IAnnualInterestData) =>
      data.propertyGrowthEndingValue ? `£${commonUtils.formatNumberWithCommas(data.propertyGrowthEndingValue)}` : '-',
  },
  {
    name: 'propertyGrowthEndingEstate',
    header: 'Ending estate',
    defaultFlex: 1,
    type: 'text',
    group: 'propertyGrowth',
    render: ({ data }: IAnnualInterestData) =>
      data.propertyGrowthEndingEstate ? `£${commonUtils.formatNumberWithCommas(data.propertyGrowthEndingEstate)}` : '-',
  },
]

export const generateAnnualInterestDataSource = (data: IQuoteResult, propertyGrowth: number): IAnnualInterestDataItem[] => {
  const quickSearch: IQuickSearch = JSON.parse(localStorage.getItem('quickSearch') || '{}')
  const propertyValue = quickSearch?.propertyAndLanding?.propertyValue || 0

  const ANNUAL_INTEREST_TABLE_PERIOD = 10
  const TO_BE_APPLIED = data.interest_rates[0].to_be_applied[0]
  let RATE_TO_USE = 0

  if (TO_BE_APPLIED === 'Monthly') {
    // Note: this formula is known as "compound interest formula" or "nominal annual interest rate formula"
    RATE_TO_USE = ((1 + Number(data.interest_rates[0].mer[0]) / 1200) ** 12 - 1) * 100
  } else if (TO_BE_APPLIED === 'Annually') {
    RATE_TO_USE = Number(data.interest_rates[0].aer[0])
  }

  const arr: any = []

  for (let index = 0; index < ANNUAL_INTEREST_TABLE_PERIOD; index++) {
    const year = index + 1

    const startingBalance =
      index === 0 ? parseFloat(Number(data.payments?.[0]?.initial_only?.[0]?.initial_amount?.[0]?._ ?? 0).toFixed(2)) : arr[index - 1].endingBalance

    const annualInterest = parseFloat(((startingBalance * RATE_TO_USE) / 100).toFixed(2))
    const endingBalance = parseFloat((startingBalance + annualInterest).toFixed(2))

    const noPropertyGrowthEndingValue = propertyValue
    const noPropertyGrowthEndingEstate = parseFloat((noPropertyGrowthEndingValue - endingBalance).toFixed(2))

    const previousPropertyGrowthEndingValue = index === 0 ? propertyValue : arr[index - 1].propertyGrowthEndingValue

    const incrementalGrowthValue = parseFloat(((previousPropertyGrowthEndingValue * propertyGrowth) / 100).toFixed(2))
    const propertyGrowthEndingValue = parseFloat((previousPropertyGrowthEndingValue + incrementalGrowthValue).toFixed(2))
    const propertyGrowthEndingEstate = parseFloat((propertyGrowthEndingValue - endingBalance).toFixed(2))

    arr.push({
      id: index,
      year,
      startingBalance,
      additionalPayment: null,
      annualInterest,
      endingBalance,
      noPropertyGrowthEndingValue,
      noPropertyGrowthEndingEstate,
      propertyGrowthEndingValue,
      propertyGrowthEndingEstate,
    })
  }

  return arr
}
