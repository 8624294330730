import { CellProps } from '@inovua/reactdatagrid-community/types'
import { IObjectivesData } from './ObjectivesAndAlternatives.interface'

export const cuttingBackOnCurrentSpendingOptions = [
  {
    label: 'You do not want to cut back on your current spending',
    value: 'youDoNotWantToCutBackOnYourCurrentSpending',
  },
  {
    label: 'Not possible due to your low income',
    value: 'notPossibleDueToYourLowIncome',
  },
  {
    label: 'It will not provide the lump sum required',
    value: 'itWllNotProvideTheLumpSumRequired',
  },
  {
    label: 'It would not provide a large enough lump sum',
    value: 'itWouldNotProvideLargeEnoughLumpSum',
  },
  {
    label: 'You will try reducing expenditure as a solution',
    value: 'youWillTryReducingExpenditureAsSolution',
  },
  {
    label: 'Other (detail below)',
    value: 'other',
  },
]

export const localAuthorityOptions = [
  {
    label: 'You are not eligable for State Benefits',
    value: 'youAreNotEligableForStateBenefits',
  },
  {
    label: 'You are already receiving all available State Benefits',
    value: 'youAreAlreadyReceivingAllAvailableStateBenefits',
  },
  {
    label: 'It will not provide the lump sum required',
    value: 'itWillNotProvideTheLumpSumRequired',
  },
  {
    label: 'You will investigate State Benefits as a solution',
    value: 'youWillInvestigateStateBenefitsAsSolution',
  },
  {
    label: 'Other (detail below)',
    value: 'other',
  },
]

export const lodgerOptions = [
  {
    label: 'You do not want to take in a lodger',
    value: 'youDoNotWantToTakeInLodger',
  },
  {
    label: 'It will not provide the initial sum required',
    value: 'itWillNotProvideTheInitialSumRequired',
  },
  {
    label: 'You already have a lodger',
    value: 'youAlreadyHaveLodger',
  },
  {
    label: 'You plan to take in a lodger to provide extra income but still require a lump sum',
    value: 'youPlanToTakeInLodgerToProvideExtraIncomeButStillRequireLumpSum',
  },
  {
    label: 'You will try lodging as a solution',
    value: 'youWillTryLodgingAsSolution',
  },
  {
    label: 'Other (detail below)',
    value: 'other',
  },
]

export const returnToWorkOptions = [
  {
    label: 'Do not wish to return to work',
    value: 'doNotWishToReturnToWork',
  },
  {
    label: 'Unable to work for health reasons',
    value: 'unableToWorkForHealthReasons',
  },
  {
    label: 'Unable to work due to other commitments',
    value: 'unableToWorkDueToOtherCommitments',
  },
  {
    label: 'It would not provide the lump sum required',
    value: 'itWouldNotProvideTheLumpSumRequired',
  },
  {
    label: 'Do not wish to consider this option',
    value: 'doNotWishToConsiderThisOption',
  },
  {
    label: 'Other (detail below)',
    value: 'other',
  },
]

export const askFamilyForAssistanceOptions = [
  {
    label: 'No family to ask',
    value: 'noFamilyToAsk',
  },
  {
    label: "Don't feel comfortable asking family",
    value: 'dontFeelComfortableAskingFamily',
  },
  {
    label: 'Family cannot afford to help with this sum',
    value: 'familyCannotAffordToHelpWithThisSum',
  },
  {
    label: 'This is a gift to help family',
    value: 'thisIsAGiftToHelpFamily',
  },
  {
    label: 'Will discuss with family',
    value: 'willDiscussWithFamily',
  },
  {
    label: 'Other (detail below)',
    value: 'other',
  },
]

export const savingsOptions = [
  {
    label: 'Do not have sufficient savings',
    value: 'doNotHaveSufficientSavings',
  },
  {
    label: 'Already using available savings',
    value: 'alreadyUsingAvailableSavings',
  },
  {
    label: 'Want to keep savings separate',
    value: 'wantToKeepSavingsSeparate',
  },
  {
    label: 'Wants savings to generate future income',
    value: 'wantsSavingsToGenerateFutureIncome',
  },
  {
    label: 'Want to retain savings as emergency fund',
    value: 'wantToRetainSavingsAsEmergencyFund',
  },
  {
    label: 'Will use savings instead',
    value: 'willUseSavingsInstead',
  },
  {
    label: 'Will use savings towards one or more objective',
    value: 'willUseSavingsTowardsOneOrMoreObjective',
  },
  {
    label: 'Prefer not to use existing assets or savings',
    value: 'preferNotToUseExistingAssetsOrSavings',
  },
  {
    label: 'Prefer not to use Pension funds',
    value: 'preferNotToUsePensionFunds',
  },
  {
    label: 'There are penalties or tax implications using these assets',
    value: 'thereArePenaltiesOrTaxImplicationsUsingTheseAssets',
  },
  {
    label: 'Savings are in tax efficient investments',
    value: 'savingsAreInTaxEfficientInvestments',
  },
  {
    label: 'Other (detail below)',
    value: 'other',
  },
]

export const lessExpensivePropertyOptions = [
  {
    label: 'You do not wish to consider this at all',
    value: 'youDoNotWishToConsiderThisAtAll',
  },
  {
    label: 'You are settled in your home and community',
    value: 'youAreSettledInYourHomeAndCommunity',
  },
  {
    label: 'Family and/or friends live nearby',
    value: 'familyAndOrFriendsLiveNearby',
  },
  {
    label: "You don't feel it would be cost effective",
    value: 'youDonNotFeelItWouldBeCostEffective',
  },
  {
    label: "You don't think you could find a suitable new property",
    value: 'youDonNotThinkYouCouldFindASuitableNewProperty',
  },
  {
    label: 'You may sell in the future',
    value: 'youMaySellInTheFuture',
  },
  {
    label: 'You will consider selling now to achieve objectives',
    value: 'youWillConsiderSellingNowToAchieveObjectives',
  },
  {
    label: 'Other (detail below)',
    value: 'other',
  },
]

export const TakingCashFromPensionsWhenTheyMatureOptions = [
  {
    label: 'Already utilised my pensions',
    value: 'alreadyUtilizedMyPensions',
  },
  {
    label: 'Not available soon enough',
    value: 'notAvailableSoonEnough',
  },
  {
    label: 'It would not provide the lump sum required',
    value: 'itWouldNotProvideTheLumpSumRequired',
  },
  {
    label: "You don't wish to for tax reasons",
    value: 'youDoNotWishToForTaxReasons',
  },
  {
    label: 'You want to keep pensions for retirement income or their purposes',
    value: 'youWantToKeepPensionsForRetirementIncomeOrTheirPurposes',
  },
  {
    label: 'You will use your future pensions to provide the capital',
    value: 'youWillUseYourFuturePensionsToProvideTheCapital',
  },
  {
    label: 'Other (detail below)',
    value: 'other',
  },
]

export const rentOrMoveWithFriendsOptions = [
  {
    label: 'You do not wish to consider this at all',
    value: 'youDoNotWishToConsiderThisAtAll',
  },
  {
    label: 'You are settled in your home and community',
    value: 'youAreSettledInYourHomeAndCommunity',
  },
  {
    label: 'Family and/or friends live nearby',
    value: 'familyAndOrFriendsLiveNearby',
  },
  {
    label: "You don't feel it would be cost effective",
    value: 'youDoNotFeelItWouldBeCostEffective',
  },
  {
    label: "You don't think you could find a suitable new property",
    value: 'youDoNotThinkYouCouldFindASuitableNewProperty',
  },
  {
    label: 'You may sell in the future',
    value: 'youMaySellInTheFuture',
  },
  {
    label: 'You will consider selling now to achieve objectives',
    value: 'youWillConsiderSellingNowToAchieveObjectives',
  },
  {
    label: 'Other (detail below)',
    value: 'other',
  },
]

export const yesNoPossiblyOptions = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
  {
    label: 'Possibly',
    value: 'possibly',
  },
]

export const objectivesTableColumns = [
  {
    name: 'purposeOfRaisingCapital',
    header: 'Purpose of Raising Capital',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IObjectivesData) => (data.purposeOfRaisingCapital ? data.purposeOfRaisingCapital : 'N/A'),
    onRender: (cellProps: CellProps) => {
      if (cellProps.style && typeof cellProps.style === 'object') {
        ;(cellProps.style as React.CSSProperties).color = '#00000061'
      }
    },
  },
  {
    name: 'requiredAmount',
    header: 'Amount Required',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IObjectivesData) => (typeof data.requiredAmount === 'number' ? `£${data.requiredAmount}` : 'N/A'),
    onRender: (cellProps: CellProps) => {
      if (cellProps.style && typeof cellProps.style === 'object') {
        ;(cellProps.style as React.CSSProperties).color = '#00000061'
      }
    },
  },
  {
    name: 'fundsTimescale',
    header: 'Funds Timescale',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IObjectivesData) => (data.fundsTimescale ? data.fundsTimescale : 'N/A'),
    onRender: (cellProps: CellProps) => {
      if (cellProps.style && typeof cellProps.style === 'object') {
        ;(cellProps.style as React.CSSProperties).color = '#00000061'
      }
    },
  },
  {
    name: 'objectiveSummery',
    header: 'Objective Summary',
    defaultFlex: 1,
    type: 'text',
    onRender: (cellProps: CellProps) => {
      if (cellProps.style && typeof cellProps.style === 'object') {
        ;(cellProps.style as React.CSSProperties).color = '#00000061'
      }
    },
  },
]
