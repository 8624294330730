import styled from 'styled-components'

export const ButtonsContainer = styled.span`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
`

export const StyledIcon = styled.img``

export const StyledValue = styled.p<{ type: string }>`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${({ type }) => (type === 'edit' ? '#2080F6' : '#e73f3f')};

  flex: none;
  order: 1;
  flex-grow: 0;
`
