import { Breadcrumb } from 'antd'
import { IBreadcrumbItem, TCustomBreadcrumb } from './CustomBreadcrumb.types'

const CustomBreadcrumb = ({ breadcrumbItems, handleBreadcrumbTabChange }: TCustomBreadcrumb) => {
  return (
    <Breadcrumb separator="">
      {breadcrumbItems.map((item: IBreadcrumbItem) => (
        <Breadcrumb.Item key={item.key} onClick={handleBreadcrumbTabChange}>
          {item.text}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )
}

export default CustomBreadcrumb
