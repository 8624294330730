import DetailedForm from 'pages/ClientDetails/DetailedForm'
import { StyledContainer } from './NotesTabContent.styles'
import { tabItems } from 'hooks/clients/staticData'

const NotesTabContent = () => {
  return (
    <StyledContainer>
      <DetailedForm detailedFormName={tabItems.notes} />
    </StyledContainer>
  )
}

export default NotesTabContent
