import CustomDrawer from 'components/CustomDrawer';
import CustomSelect from 'components/CustomSelect';
import {
  StyledFormContainer,
  StyledSelectorContainer,
  StyledSelectorName,
} from './DashboardFilterDrawer.styles';

import { TDashboardFilterDrawer } from './DashboardFilterDrawer.types';

const DashboardFilterDrawer = ({
  open,
  closeDrawer,
  loading,
  handleSubmit,
}: TDashboardFilterDrawer) => {
  return (
    <CustomDrawer
      open={open}
      closeDrawer={closeDrawer}
      title="Filter"
      disableSaveButton={false}
      loading={loading}
      handleSubmit={handleSubmit}
    >
      <StyledFormContainer>
        <StyledSelectorContainer>
          <StyledSelectorName>Select month</StyledSelectorName>
          <CustomSelect
            disabled={false}
            onChange={() => {}}
            defaultValue={null}
            placeholder="Select here"
            options={[]}
            width="100%"
          />
        </StyledSelectorContainer>
        <StyledSelectorContainer>
          <StyledSelectorName>Select year</StyledSelectorName>
          <CustomSelect
            disabled={false}
            onChange={() => {}}
            defaultValue={null}
            placeholder="Select here"
            options={[]}
            width="100%"
          />
        </StyledSelectorContainer>
      </StyledFormContainer>
    </CustomDrawer>
  );
};

export default DashboardFilterDrawer;
