import { useEffect, useState } from 'react'
import { StyledContainer, StyledLabel, ValuesContainer, StyledRequiredError } from './CheckboxOptions.styles'
import { CheckboxOptionsProps, TOption } from './CheckboxOptions.types'
import CheckboxWithLabel from 'components/CheckboxWithLabel/CheckboxWithLabel'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

const CheckboxOptions = ({ label, onChange, options, name, value, required = false }: CheckboxOptionsProps) => {
  // Note: false is passed it needs this check to set it as default value instead of null
  const initialValue = value && value[0] ? value : null
  const [checkedValues, setCheckedValues] = useState(initialValue)
  const [error, setError] = useState(false)

  const handleChange = (optionValue: string, name: string, e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      if (checkedValues === null) {
        setCheckedValues([optionValue])
      } else {
        setCheckedValues((prevState: string[] | null) => (prevState ? [...prevState, optionValue] : [optionValue]))
      }
      // Note: if current e.target becomes null, the error will be displayed
      if (required) {
        setError(true)
      } else {
        setError(false)
      }
    } else {
      if (error) setError(false)
      setCheckedValues((prevState: string[] | null) => prevState?.filter((value: string) => value !== optionValue) || null)
    }
  }

  useEffect(() => {
    onChange({ name, value: checkedValues || [] })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedValues])

  return (
    <StyledContainer>
      <StyledLabel>{label}</StyledLabel>
      <ValuesContainer>
        {options.map((option: TOption) => (
          <CheckboxWithLabel
            key={option.value}
            checked={checkedValues ? checkedValues.indexOf(option.value) > -1 : false}
            onChange={handleChange.bind(this, option.value)}
            label={option.label}
            name={name}
          />
        ))}
      </ValuesContainer>
      {error && <StyledRequiredError>Required</StyledRequiredError>}
    </StyledContainer>
  )
}

export default CheckboxOptions
