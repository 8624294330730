import { useContext, useState } from 'react'
import { FormsContext } from 'contexts'
import { initialMedicalQuestionsTabItemsData } from './staticData'

interface IItem {
  label: string
  icon?: string
  percentage?: number
  hasChildren: boolean
  itemName?: string
  children?: IItem[]
}

const useQuickSearchPage = () => {
  const {
    medicalQuestionsTabItems,
    setMedicalQuestionsTabItems,
    detailedFormName,
    setDetailedFormName,
    antdActiveTabKey,
    quickSearch: { setActiveTab },
    displayDetailForm,
    setDisplayDetailForm,
    breadcrumbItems,
    setBreadcrumbItems,
  } = useContext(FormsContext)

  const [formStepsHistory, setFormStepsHistory] = useState<
    {
      text: string
      displayForm: boolean
    }[]
  >([
    {
      text: 'Home',
      displayForm: false,
    },
  ])

  const handleTabChange = (key: string) => {
    setActiveTab(key)
  }

  const handleDetailedFormClose = () => {
    // Note: removes opened form name from breadcrumb when closing
    setBreadcrumbItems(breadcrumbItems.slice(0, breadcrumbItems.length - 1))
    let currentIndex = 0
    // eslint-disable-next-line array-callback-return
    formStepsHistory.map((item, index) => {
      if (item.text === detailedFormName) {
        currentIndex = index
      }
    })
    // Note: finds the index of the form which will be opened to set its options
    const prevTabOptions = formStepsHistory[currentIndex - 1]

    setDisplayDetailForm(prevTabOptions.displayForm)
    setDetailedFormName(prevTabOptions.text)
    // Note: removes one element from history
    setFormStepsHistory((prevState) => [...prevState.slice(0, formStepsHistory.length - 1)])
  }

  const handleBreadcrumbTabChange = (e: React.MouseEvent<HTMLElement>) => {
    const itemText = (e.target as HTMLElement).innerHTML
    if (itemText === 'Home') {
      setMedicalQuestionsTabItems(initialMedicalQuestionsTabItemsData)
    }
    if (itemText !== detailedFormName) {
      setDisplayDetailForm(false)
    }
    let itemIndex = 1
    // eslint-disable-next-line array-callback-return
    breadcrumbItems.map((item, index) => {
      if (item.text === itemText) {
        itemIndex = index
      }
    })
    const newItems = breadcrumbItems.slice(0, itemIndex + 1)
    setBreadcrumbItems(newItems)

    let currentIndex = 0
    // eslint-disable-next-line array-callback-return
    formStepsHistory.map((item, index) => {
      if (item.text === itemText) {
        currentIndex = index
      }
    })
    const tabOptions = formStepsHistory[currentIndex]

    setDisplayDetailForm(tabOptions.displayForm)
    setDetailedFormName(tabOptions.text)
    // Note: removes every item history from breadcrumb after current tab
    setFormStepsHistory((prevState) => [...prevState.slice(0, currentIndex + 1)])
  }

  const pushNewItemToBreadcrumb = (text: string) => {
    setBreadcrumbItems([
      ...breadcrumbItems,
      {
        key: `${breadcrumbItems.length + 1}`,
        text,
      },
    ])
  }

  const handleItemClick = (item: IItem) => {
    pushNewItemToBreadcrumb(item.label)
    setDetailedFormName(item.label)

    if (item.hasChildren) {
      setMedicalQuestionsTabItems(item.children)

      setFormStepsHistory((prevState) => [
        ...prevState,
        {
          text: item.label,
          displayForm: false,
        },
      ])
    }

    if (!item.hasChildren) {
      setDisplayDetailForm(true)

      setFormStepsHistory((prevState) => [
        ...prevState,
        {
          text: item.label,
          displayForm: true,
        },
      ])
    }
  }

  const handleNextFormNavigation = (callback: () => void) => {
    const indexOfCurrentForm = medicalQuestionsTabItems.findIndex((item: IItem) => item.label === detailedFormName)
    setDetailedFormName(medicalQuestionsTabItems[indexOfCurrentForm + 1].label)
    setBreadcrumbItems([
      ...breadcrumbItems.slice(0, breadcrumbItems.length - 1),
      { key: `${breadcrumbItems.length + 1}`, text: medicalQuestionsTabItems[indexOfCurrentForm + 1].label },
    ])
    callback()
  }

  return {
    antdActiveTabKey,
    handleTabChange,
    handleNextFormNavigation,
    displayDetailForm,
    handleDetailedFormClose,
    medicalQuestionsTabItems,
    breadcrumbItems,
    handleBreadcrumbTabChange,
    handleItemClick,
    detailedFormName,
  }
}

export default useQuickSearchPage
