import Form from 'antd/lib/form'

import { useResetPassword } from 'hooks'
import TextInput from 'components/CustomFormItem'

import {
  StyledSignUpContainer,
  StyledLeftContainer,
  StyledRightContainer,
  StyledBackground,
  StyledLogo,
  StyledDesc,
  StyledContainerPosition,
  StyledDescContainer,
  StyledBreakLine,
  StyledSignUpButton,
  StyledFormContainer,
  StyledRegisterMsg,
  StyledLinksContainer,
  StyledCreateAccount,
  StyledLink,
  StyledFormsWrapper,
  StyledMainFieldsWrapper,
  StyledReqUl,
  StyledReqLi,
} from './ResetPassword.styles'

import {
  // FileUploadIcon,
  LoginPageImg,
  LogoLight,
} from 'assets/images'

const ResetPassword = () => {
  const { handleSubmit, loading } = useResetPassword()

  return (
    <StyledSignUpContainer>
      <StyledLeftContainer>
        <StyledBackground img={LoginPageImg}>
          <StyledLogo src={LogoLight} />
          <StyledContainerPosition>
            <StyledDescContainer>
              <StyledBreakLine />
              <StyledDesc>The platform for later life lending professionals</StyledDesc>
            </StyledDescContainer>
          </StyledContainerPosition>
        </StyledBackground>
      </StyledLeftContainer>
      <StyledRightContainer>
        <StyledFormContainer>
          <StyledRegisterMsg>Choose New Password</StyledRegisterMsg>
          <Form
            layout="vertical"
            size="large"
            name="basic"
            onFinish={handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
            }}
          >
            <StyledFormsWrapper>
              <StyledMainFieldsWrapper style={{ width: '100%' }}>
                <TextInput label="New Password *" name="newPassword" placeholder="Enter your new password" rules={['isRequired']} type="password" />
                <TextInput label="Confirm Password *" name="confirmPassword" placeholder="Confirm password" rules={['isRequired']} type="password" />
              </StyledMainFieldsWrapper>
            </StyledFormsWrapper>
            <StyledReqUl>
              <StyledReqLi>At least 10 characters long.</StyledReqLi>
              <StyledReqLi>A combination of uppercase letters, lowercase letters, numbers, and symbols.</StyledReqLi>
            </StyledReqUl>
            <StyledSignUpButton loading={loading} type="primary" htmlType="submit">
              Reset Password
            </StyledSignUpButton>
            <StyledLinksContainer>
              <StyledCreateAccount>Already have an account?</StyledCreateAccount>
              <StyledLink to="/">Log In</StyledLink>
            </StyledLinksContainer>
          </Form>
        </StyledFormContainer>
      </StyledRightContainer>
    </StyledSignUpContainer>
  )
}

export default ResetPassword
