import Layout from 'components/Layout'
import MainContentContainer from 'components/MainContentContainer'
import ComingSoon from 'components/ComingSoon'

// import { LearningPageIllustration } from 'assets/images'

// import { StyledContainer, StyledDescription } from './Learning.styles'

const Learning = () => {
  return (
    <Layout>
      <MainContentContainer pageName="Learning">
        <ComingSoon />
        {/* <StyledContainer>
          <img src={LearningPageIllustration} alt="learn" />
          <StyledDescription>We are preparing content for this page. You will be able to see it soon.</StyledDescription>
        </StyledContainer> */}
      </MainContentContainer>
    </Layout>
  )
}

export default Learning
