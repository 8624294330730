import { Form, Modal } from 'antd'

import { StyledWrapper, Styled2x2Grid } from './RecipientModal.styles'
import TextInput from 'components/CustomFormItem/CustomFormItem'
import Loading from 'components/Loading/Loading'
import useRecipientModal from 'hooks/clients/Modals/useRecipientModal'
import GoogleAddressInput from 'components/GoogleAddressInput/GoogleAddressInput'
import CustomSwitch from 'components/CustomSwitch/CustomSwitch'

const RecipientModal = () => {
  const {
    modalState,
    handleCancelClick,
    handleConfirmClick,
    formData,
    handleAntdFormInputChange,
    onPlaceSelect,
    showAddressDetails,
    showAddressDetailsChange,
    loading,
    form,
  } = useRecipientModal()

  if (loading) {
    return <Loading />
  }

  return (
    <Modal
      title="Edit Recipient"
      open={modalState.isOpen}
      onOk={handleConfirmClick}
      onCancel={handleCancelClick}
      width={700}
      okText="Save"
      cancelText="Cancel"
      closable={false}
      className="edit-asset-modal"
      confirmLoading={modalState.loading}
    >
      {modalState.loading ? (
        <Loading />
      ) : (
        <StyledWrapper>
          <Form
            form={form}
            layout="vertical"
            size="large"
            name="basic"
            initialValues={formData}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
              marginTop: '8px',
              width: '100%',
            }}
          >
            <TextInput label="Name *" name="name" placeholder="Name *" onChange={handleAntdFormInputChange} />
            <Styled2x2Grid>
              <GoogleAddressInput label="Search for an Address" onPlaceSelect={onPlaceSelect} value={formData?.address?.formattedAddress} />
              <CustomSwitch checked={showAddressDetails} onChange={showAddressDetailsChange} label="Show Address Details" />
              {showAddressDetails && (
                <>
                  <TextInput
                    label="Address 1"
                    name="address.address1"
                    placeholder="Address 1"
                    onChange={handleAntdFormInputChange}
                    value={formData?.address?.address1}
                  />
                  <TextInput
                    label="Address 2"
                    name="address.address2"
                    placeholder="Address 2"
                    onChange={handleAntdFormInputChange}
                    value={formData?.address?.address2}
                  />
                  <TextInput label="Town" name="address.town" placeholder="Town*" onChange={handleAntdFormInputChange} value={formData?.address?.town} />
                  <TextInput label="County" name="address.county" placeholder="County" onChange={handleAntdFormInputChange} value={formData?.address?.county} />
                  <TextInput
                    label="Country"
                    name="address.country"
                    placeholder="Country"
                    onChange={handleAntdFormInputChange}
                    value={formData?.address?.country}
                  />
                  <TextInput
                    label="Postcode"
                    name="address.postcode"
                    placeholder="Postcode"
                    onChange={handleAntdFormInputChange}
                    value={formData?.address?.postcode}
                  />
                </>
              )}
              <TextInput label="Email *" name="email" placeholder="Email *" onChange={handleAntdFormInputChange} rules={['validateEmail']} />
              <TextInput label="Relationship *" name="relationship" placeholder="Relationship *" onChange={handleAntdFormInputChange} />
            </Styled2x2Grid>
          </Form>
        </StyledWrapper>
      )}
    </Modal>
  )
}

export default RecipientModal
