export const liabilityTypeOptions = [
  {
    label: 'Mortgage',
    value: 'mortgage',
  },
  {
    label: 'Mortgage (2nd property/other)',
    value: 'mortgageOther',
  },
  {
    label: 'Buy to Let Mortgage',
    value: 'buyToLetMortgage',
  },
  {
    label: 'Lifetime Mortgage',
    value: 'lifetimeMortgage',
  },
  {
    label: 'Home Reversion',
    value: 'homeReversion',
  },
  {
    label: 'Secured Loan(s)',
    value: 'securedLoan',
  },
  {
    label: 'Unsecured Loan(s)',
    value: 'unsecuredLoan',
  },
  {
    label: 'Credit card(s)',
    value: 'creditCard',
  },
  {
    label: 'Store card(s)',
    value: 'storeCard',
  },
  {
    label: 'Car finance',
    value: 'carFinance',
  },
  {
    label: 'Hire Purchase',
    value: 'hirePurchase',
  },
  {
    label: 'Overdraft(s)',
    value: 'overdraft',
  },
  {
    label: 'Other Liabilities',
    value: 'other',
  },
]

export const monthlyCostChangePeriodOptions = [
  {
    label: 'Within 12 months',
    value: 'within12months',
  },
  {
    label: '1-2 years',
    value: 'OneToTwoYears',
  },
  {
    label: '2-3 years',
    value: 'TwoToThreeYears',
  },
  {
    label: '3-5 years',
    value: 'ThreeToFiveYears',
  },
  {
    label: '5-10 years',
    value: 'FiveToTenYears',
  },
  {
    label: '10-15 years',
    value: 'TenToFifteenYears',
  },
  {
    label: 'After 15 years',
    value: 'AfterFifteenYears',
  },
]
