import { commonUtils } from 'utils'
import { IAddressTableData } from './Properties.interface'

export const yesNoPossiblyOptions = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
  {
    label: 'Possibly',
    value: 'possibly',
  },
]

export const loanRepaymentOnHomeMoveOptions = [
  {
    label: 'Part',
    value: 'part',
  },
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Transfer loan to new property (if possible)',
    value: 'transferLoanToNewProperty',
  },
]

export const ownershipOptions = [
  {
    value: 'soleOwnership',
    label: 'Sole Ownership',
  },
  {
    value: 'jointTenancy',
    label: 'Joint Tenancy',
  },
  {
    value: 'jointTenancyWithRightsOfSurvivorship',
    label: 'Joint Tenancy With Rights Of Survivorship',
  },
  {
    value: 'tenancyInCommon',
    label: 'tenants in common',
  },
  {
    value: 'communityProperty',
    label: 'Community Property',
  },
  {
    value: 'renting',
    label: 'Renting',
  },
]

export const houseOptions = [
  {
    value: 'no',
    label: 'No',
  },
  {
    value: 'ageRestricted',
    label: 'Age Restricted',
  },
  {
    value: 'shelteredHousing',
    label: 'Sheltered Housing',
  },
  {
    value: 'wardenAssistedShelteredHousing',
    label: 'Warden Assisted Sheltered Housing',
  },
  {
    value: 'other',
    label: 'Other',
  },
]

export const solarPanelOptions = [
  {
    value: 'leased',
    label: 'Yes, leased',
  },
  {
    value: 'owned',
    label: 'Yes, owned',
  },
  {
    value: 'no',
    label: 'No',
  },
]

export const listedGradeOptions = [
  {
    value: 'gradeOne',
    label: 'Grade 1',
  },
  {
    value: 'gradeTwo',
    label: 'Grade 2',
  },
  {
    value: 'gradeThree',
    label: 'Grade2*',
  },
]

export const moveToHomePeriodOptions = [
  {
    value: 'duringNextFiveYears',
    label: 'During next 5 years',
  },
  {
    value: 'inNextFiveToEightYears',
    label: 'In 5 - 8 years',
  },
  {
    value: 'inNextEightToTenYears',
    label: 'In 8 - 10 years',
  },
  {
    value: 'inTenOrMoreYears',
    label: 'In 10 years or more',
  },
]

export const addressTableColumns = [
  {
    name: 'residents',
    header: 'Customer',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IAddressTableData) => data.residents || 'N/A',
  },
  {
    name: 'address1',
    header: 'Address 1',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'town',
    header: 'Town',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'country',
    header: 'Country',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'postcode',
    header: 'Postcode',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'dateMovedIn',
    header: 'Date moved In',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IAddressTableData) => (data.dateMovedIn ? commonUtils.formatDate(data.dateMovedIn) : 'N/A'),
  },
  {
    name: 'dateMovedOut',
    header: 'Date moved Out',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IAddressTableData) => (data.dateMovedOut ? commonUtils.formatDate(data.dateMovedOut) : 'N/A'),
  },
]

export const occupantsTableColumns = [
  {
    name: 'name',
    header: 'Name',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'age',
    header: 'Age',
    defaultFlex: 1,
    type: 'number',
  },
  {
    name: 'relationshipWithCustomer',
    header: 'Relationship',
    defaultFlex: 1,
    type: 'text',
  },
]
