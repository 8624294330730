export const incomeTableColumns = [
  {
    name: 'when',
    header: 'When',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'total',
    header: 'Total',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: any) => `£${data.total}`,
  },
]
