import styled from 'styled-components'
import GoogleAutocomplete from 'react-google-autocomplete'

export const StyledContainer = styled.div<{ marginTop?: number }>`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  margin-top: ${({ marginTop }) => (typeof marginTop === 'number' ? `${marginTop}px` : 'unset')};
`

export const StyledLabel = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #120224;

  max-width: 100%;

  margin-bottom: 12px;
`

export const StyledGoogleAutocomplete = styled<any>(GoogleAutocomplete)`
  box-sizing: border-box;
  width: 100%;
  padding: 12px 16px;
  background: #fefefe;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  font-size: 1rem;
`
