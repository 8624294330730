import Grid from 'components/Grid/Grid'
import { FormTableContainer, TableContainer, StyledTitle } from './IncomeTable.styles'
import { IncomeTableProps } from './IncomeTable.types'
import { incomeTableColumns } from './IncomeTable.helper'

const IncomeTable = ({ dataSource, title }: IncomeTableProps) => {
  let sourceData = []
  if (dataSource.length === 0) {
    sourceData = [
      {
        id: 1,
        when: 'Now',
        total: 0,
      },
    ]
  } else {
    sourceData = dataSource
  }
  return (
    <FormTableContainer>
      <TableContainer>
        <StyledTitle>{title}</StyledTitle>
        <Grid columns={incomeTableColumns} dataSource={sourceData} checkboxColumn={false} />
      </TableContainer>
    </FormTableContainer>
  )
}

export default IncomeTable
