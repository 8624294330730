import { IObjectivesData } from './BorrowingSolutionAndPriorities.interface'

export const repayOptions = [
  {
    label: 'Plan To Repay Early',
    value: 'planToRepayEarly',
  },
  {
    label: 'Very Likely To Repay Early',
    value: 'veryLikelyToRepayEarly',
  },
  {
    label: 'Very Unlikely To Repay Early',
    value: 'veryUnlikelyToRepayEarly',
  },
  {
    label: 'No Plans To Repay Whatsoever',
    value: 'noPlansToRepayWhatsoever',
  },
]

export const importanceOptions = [
  {
    label: 'Very Important',
    value: 'veryImportant',
  },
  {
    label: 'Preferable',
    value: 'preferable',
  },
  {
    label: 'Not Important',
    value: 'notImportant',
  },
]

export const facilitatingLoanOptions = [
  {
    label: 'To add to the loan',
    value: 'toAddToTheLoan',
  },
  {
    label: 'Cover fees from your own funds',
    value: 'coverFeesFromYourOwnFunds',
  },
  {
    label: 'Deduct fees from required initial advance',
    value: 'deductFeesFromRequiredInitialAdvance',
  },
  {
    label: 'Take my advice upon recommendation',
    value: 'takeMyAdviceUponRecommendation',
  },
  {
    label: 'Prefer not to pay a fee at all',
    value: 'preferNotToPayAFeeAtAll',
  },
  {
    label: 'Other (use notes)',
    value: 'other',
  },
]

export const changesOptions = [
  {
    label: 'Do not anticipate any changes in circumstances',
    value: 'doNotAnticipateAnyChangesInCircumstances',
  },
  {
    label: 'A need for additional funds',
    value: 'aNeedForAdditionalFunds',
  },
  {
    label: 'Sell property and move in with family',
    value: 'sellPropertyAndMoveInWithFamily',
  },
  {
    label: 'Have a family member or friend come and live with you',
    value: 'haveAFamilyMemberOrFriendComeAndLiveWithYou',
  },
  {
    label: 'Transfer any loan to another property',
    value: 'transferAnyLoanToAnotherProperty',
  },
  {
    label: 'A change in income',
    value: 'changeInIncome',
  },
  {
    label: 'An expected need for care at home or in a residential care home',
    value: 'expectedNeedForCareAtHomeOrInAResidentialCareHome',
  },
  {
    label: 'Other - Additional Information underneath',
    value: 'other',
  },
]

export const objectivesTableColumns = [
  {
    name: 'objectiveSummery',
    header: 'Objective Summery',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'purposeOfRaisingCapital',
    header: 'Purpose of Raising Capital',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'requiredAmount',
    header: 'Amount Required',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IObjectivesData) => `£${data.requiredAmount}`,
  },
  {
    name: 'fundsTimescale',
    header: 'Funds Timescale',
    defaultFlex: 1,
    type: 'text',
  },
]

export const minimumWithdrawalAmountOptions = [
  {
    label: '500',
    value: 500,
  },
  {
    label: '1000',
    value: 1000,
  },
  {
    label: '2000',
    value: 2000,
  },
]

export const paymentTypeOptions = [
  {
    label: '£25pm (minimum)',
    value: 'twentyFivePoundPmMinimum',
  },
  {
    label: '25% monthly interest',
    value: 'twentyFivePercentMonthlyInterest',
  },
  {
    label: '50% monthly interest',
    value: 'fiftyPercentMonthlyInterest',
  },
  {
    label: '75% monthly interest',
    value: 'seventyFivePercentMonthlyInterest',
  },
  {
    label: '100% monthly interest',
    value: 'hundredPercentMonthlyInterest',
  },
  {
    label: 'Other (see notes)',
    value: 'other',
  },
]

export const timeFrameForLoanRepaymentOptions = [
  {
    label: 'In Under 5 Years',
    value: 'underFiveYears',
  },
  {
    label: '5 - 8 Years',
    value: 'fiveToEightYears',
  },
  {
    label: '8 - 10 Years',
    value: 'eightToTenYears',
  },
  {
    label: '10 Years or After',
    value: 'tenYearsOrAfter',
  },
]

export const howToRepayLoanEarlyOptions = [
  {
    label: 'Moving home sale proceeds',
    value: 'movingHomeSaleProceeds',
  },
  {
    label: 'Future savings/investment maturity',
    value: 'futureSavingsInvestmentMaturity',
  },
  {
    label: 'From future inheritance',
    value: 'fromFutureInheritance',
  },
  {
    label: 'From property sale proceeds of non residence',
    value: 'fromPropertySaleProceedsOfNonResidence',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

export const fixedPeriodForEarlyRepaymentChargeOptions = [
  {
    label: 'Up to 5 Years',
    value: 'upToFiveYears',
  },
  {
    label: 'Up to 8 Years',
    value: 'upToEightYears',
  },
  {
    label: 'Up to 10 Years',
    value: 'upToTenYears',
  },
  {
    label: '10 Years +',
    value: 'moreThanTenYears',
  },
]

export const lendersToExcludeOptions = [
  {
    label: 'Aviva',
    value: 'aviva',
  },
  {
    label: 'LV=',
    value: 'lv',
  },
  {
    label: 'Hodge',
    value: 'hodge',
  },
  {
    label: 'Canada Life',
    value: 'canadaLife',
  },
  {
    label: 'JUST',
    value: 'just',
  },
  {
    label: 'Legal & General Home Finance',
    value: 'legalAndGeneralHomeFinance',
  },
  {
    label: 'more2life',
    value: 'more2life',
  },
  {
    label: 'Pure Retirement',
    value: 'pureRetirement',
  },
  {
    label: 'AIR TEST PROVIDER',
    value: 'airTestProvider',
  },
  {
    label: 'Onefamily',
    value: 'onefamily',
  },
  {
    label: 'Shawbrook Bank',
    value: 'shawbrookBank',
  },
  {
    label: 'The Mansfield Building Society',
    value: 'theMansfieldBuildingSociety',
  },
  {
    label: 'Buckinghamshire Building Society',
    value: 'buckinghamshireBuildingSociety',
  },
  {
    label: 'Bath Building Society',
    value: 'bathBuildingSociety',
  },
  {
    label: 'Vernon Building Society',
    value: 'vernonBuildingSociety',
  },
  {
    label: 'Dudley Building Society',
    value: 'dudleyBuildingSociety',
  },
  {
    label: 'Leeds Building Society',
    value: 'leedsBuildingSociety',
  },
  {
    label: 'Tipton & Coseley Building Society',
    value: 'tiptonCoseleyBuildingSociety',
  },
  {
    label: 'Hinckley & Rugby',
    value: 'hinckleyRugby',
  },
  {
    label: 'Livemore Capital',
    value: 'livemoreCapital',
  },
  {
    label: 'Chorley Building Society',
    value: 'chorleyBuildingSociety',
  },
  {
    label: 'Saga',
    value: 'saga',
  },
  {
    label: 'Scottish Widows',
    value: 'scottishWidows',
  },
]

export const lenderPreferenceOptions = [
  {
    label: 'Select best lender, regardless of timescales',
    value: 'selectBestLenderRegardlessTimescales',
  },
  {
    label: 'Select a lender offering shortest timescale ',
    value: 'selectShortestTimescale',
  },
  {
    label: 'Take advice based on my requirements for funds',
    value: 'takeAdviceBasedOnMyRequirementsForFunds',
  },
]

export const attitudeToRiskOptions = [
  {
    label: 'Cautious',
    value: 'cautious',
    description:
      'You wish to minimise the risk of eroding the equity within your property and you aim to retain a reasonable amount of this equity for your future use, whilst receiving just enough capital for your immediate needs.',
    initialBgColor: '#a5d6a7',
    bgColor: '#4caf50',
  },
  {
    label: 'Medium',
    value: 'medium',
    description:
      'You are prepared to accept some risk of eroding the equity within your property for the benefit of having sufficient capital to utilise now, whilst aiming to retain some equity.',
    initialBgColor: '#e5ee9c',
    bgColor: '#cddc39',
  },
  {
    label: 'High',
    value: 'high',
    description:
      'You are prepared to accept the risk of faster erosion of the equity within your property for the benefit of having as much capital as possible now.',
    initialBgColor: '#ffcc80',
    bgColor: '#ff9800',
  },
  {
    label: 'Speculative',
    value: 'speculative',
    description:
      'You are prepared to accept the risk of eroding all of the equity within your property for the benefit of having as much capital as possible now.',
    initialBgColor: '#ef9a9a',
    bgColor: '#f44336',
  },
]
