import { useEffect, useState } from 'react'

import { useGetContentfulDataByContentModel } from 'hooks/useContentful'

const useLenderDirectory = () => {
  const [data, setData] = useState([])
  const [activeTabKey, setActiveTabKey] = useState('0')
  const handleTabChange = (key: string) => setActiveTabKey(key)

  const { data: initialData = [], isLoading } = useGetContentfulDataByContentModel('lender', 'fields.title')

  useEffect(() => {
    setData(
      initialData?.map((item: any, index: number) => {
        return {
          //   id: item.sys.id,
          id: `${index}`,
          title: item.fields.title,
          content: item.fields.content,
        }
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const displayLoading = isLoading || !data

  return {
    data,
    displayLoading,
    activeTabKey,
    handleTabChange,
  }
}

export default useLenderDirectory
