import Form from 'antd/lib/form'

import { useForgetPassword } from 'hooks'
import TextInput from 'components/CustomFormItem'

import {
  StyledSignUpContainer,
  StyledLeftContainer,
  StyledRightContainer,
  StyledBackground,
  StyledLogo,
  StyledDesc,
  StyledContainerPosition,
  StyledDescContainer,
  StyledBreakLine,
  StyledSignUpButton,
  StyledFormContainer,
  StyledRegisterMsg,
  StyledMinMsg,
  StyledLinksContainer,
  StyledCreateAccount,
  StyledLink,
  StyledFormsWrapper,
  StyledMainFieldsWrapper,
} from './ForgetPassword.styles'

import {
  // FileUploadIcon,
  LoginPageImg,
  LogoLight,
} from 'assets/images'

const ForgetPassword = () => {
  const { handleSubmit, loading } = useForgetPassword()

  return (
    <StyledSignUpContainer>
      <StyledLeftContainer>
        <StyledBackground img={LoginPageImg}>
          <StyledLogo src={LogoLight} />
          <StyledContainerPosition>
            <StyledDescContainer>
              <StyledBreakLine />
              <StyledDesc>The platform for later life lending professionals</StyledDesc>
            </StyledDescContainer>
          </StyledContainerPosition>
        </StyledBackground>
      </StyledLeftContainer>
      <StyledRightContainer>
        <StyledFormContainer>
          <StyledRegisterMsg>Forget Password?</StyledRegisterMsg>
          <StyledMinMsg>Enter your account’s email address and we’ll send you a link to reset your password.</StyledMinMsg>
          <Form
            layout="vertical"
            size="large"
            name="basic"
            onFinish={handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
            }}
          >
            <StyledFormsWrapper>
              <StyledMainFieldsWrapper
                style={{
                  width: '50%',
                }}
              >
                <TextInput label="Email *" name="email" placeholder="Enter your email" rules={['isRequired']} />
              </StyledMainFieldsWrapper>
            </StyledFormsWrapper>
            <StyledSignUpButton loading={loading} type="primary" htmlType="submit">
              Reset Password
            </StyledSignUpButton>
            <StyledLinksContainer>
              <StyledCreateAccount>Already have an account?</StyledCreateAccount>
              <StyledLink to="/">Log In</StyledLink>
            </StyledLinksContainer>
          </Form>
        </StyledFormContainer>
      </StyledRightContainer>
    </StyledSignUpContainer>
  )
}

export default ForgetPassword
