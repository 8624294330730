import ClientInfoLayout from 'components/ClientInfoLayout'
import { tabItems } from 'hooks/clients/staticData'
import { DetailedFormProps } from './DetailedForm.types'
import Assets from './Forms/Assets'
import Benefits from './Forms/Benefits'
import BorrowingSolutionAndPriorities from './Forms/BorrowingSolutionAndPriorities'
import CaseOverview from './Forms/CaseOverview'
import CreditHistory from './Forms/CreditHistory'
import Customers from './Forms/Customers'
import CustomerDetails from './Forms/Customers/CustomerDetails'
import EstatePlanning from './Forms/EstatePlanning'
import Expenditure from './Forms/Expenditure'
import Family from './Forms/Family'
import Health from './Forms/Health'
import Income from './Forms/Income'
import Liabilities from './Forms/Liabilities'
import ObjectivesAndAlternatives from './Forms/ObjectivesAndAlternatives'
import Properties from './Forms/Properties'
import PropertyDetails from './Forms/Properties/PropertyDetails'
import SuitabilityAndNextSteps from './Forms/SuitabilityAndNextSteps'
import Notes from './Forms/Notes'

const DetailedForm = ({ handleDetailedFormClose, detailedFormName, handleItemClick }: DetailedFormProps) => {
  return (
    <ClientInfoLayout title="Client Information" handleDetailedFormClose={handleDetailedFormClose}>
      {detailedFormName === tabItems.caseOverview && <CaseOverview />}
      {detailedFormName === tabItems.customers && <Customers handleItemClick={handleItemClick} />}
      {detailedFormName === tabItems.customerFirst && <CustomerDetails />}
      {detailedFormName === tabItems.customerSecond && <CustomerDetails />}
      {detailedFormName === tabItems.properties && <Properties handleItemClick={handleItemClick} />}
      {detailedFormName === tabItems.propertyDetails && <PropertyDetails />}
      {detailedFormName === tabItems.assets && <Assets />}
      {detailedFormName === tabItems.liabilities && <Liabilities />}
      {detailedFormName === tabItems.creditHistory && <CreditHistory />}
      {detailedFormName === tabItems.income && <Income />}
      {detailedFormName === tabItems.benefits && <Benefits />}
      {detailedFormName === tabItems.expenditure && <Expenditure />}
      {detailedFormName === tabItems.objectivesAndAlternatives && <ObjectivesAndAlternatives />}
      {detailedFormName === tabItems.family && <Family />}
      {detailedFormName === tabItems.health && <Health />}
      {detailedFormName === tabItems.borrowingSolutionAndPriorities && <BorrowingSolutionAndPriorities />}
      {detailedFormName === tabItems.estatePlanning && <EstatePlanning />}
      {detailedFormName === tabItems.suitabilityAndNextSteps && <SuitabilityAndNextSteps />}
      {detailedFormName === tabItems.notes && <Notes />}
    </ClientInfoLayout>
  )
}

export default DetailedForm
