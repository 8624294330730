import ClientInfoLayout from 'components/ClientInfoLayout'
import { medicalQuestionsTabItems } from 'hooks/clients/staticData'
import { DetailedFormProps } from './DetailedForm.types'
import LifestyleDetails from './Forms/LifestyleDetails'
import HeartAndVascularDetails from './Forms/HeartAndVascularDetails'
import StrokeDetails from './Forms/StrokeDetails'
import CancerDetails from './Forms/CancerDetails'
import OtherConditionDetails from './Forms/OtherConditionDetails'

const DetailedForm = ({ handleDetailedFormClose, detailedFormName }: DetailedFormProps) => {
  return (
    <ClientInfoLayout title="Client Information" handleDetailedFormClose={handleDetailedFormClose}>
      {detailedFormName === medicalQuestionsTabItems.lifestyleDetails && <LifestyleDetails />}
      {detailedFormName === medicalQuestionsTabItems.heartAndVascularDetails && <HeartAndVascularDetails />}
      {detailedFormName === medicalQuestionsTabItems.strokeDetails && <StrokeDetails />}
      {detailedFormName === medicalQuestionsTabItems.cancerDetails && <CancerDetails />}
      {detailedFormName === medicalQuestionsTabItems.otherConditionDetails && <OtherConditionDetails />}
    </ClientInfoLayout>
  )
}

export default DetailedForm
