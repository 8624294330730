import { useState, useEffect } from 'react'
import message from 'antd/lib/message'
import Form from 'antd/lib/form'
import * as _ from 'lodash'

import { commonHelper } from 'helpers'

import { commonUtils, eventManager } from 'utils'
import { useGetRecipient } from 'services/clients/clients.service'
import { addEditRecipientMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { AddEditRecipientPayload } from 'services/shared/mutation.payload'

const initialState = {
  isOpen: false,
  data: { _id: '', isEdit: false },
  cb: { confirm: () => null, cancel: () => null },
  loading: false,
}

const useRecipientModal = () => {
  const [form] = Form.useForm()
  const addEditRecipient = useCustomMutation<AddEditRecipientPayload>({ mutation: addEditRecipientMutation, refetchQueries: ['getRecipients'] })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const [showAddressDetails, setShowAddressDetails] = useState(false)

  const [modalState, setModalState] = useState(initialState)
  const recipientId = modalState?.data?._id
  const { recipient, loading: initialDataLoading } = useGetRecipient({ idNumber, recipientId, skip: !modalState.isOpen || !modalState.data.isEdit })
  const [loading, setLoading] = useState(initialDataLoading)
  const [formData, setFormData] = useState({
    name: recipient.name,
    address: {
      formattedAddress: recipient?.address?.formattedAddress,
      address1: recipient?.address?.address1,
      address2: recipient?.address?.address2,
      town: recipient?.address?.town,
      county: recipient?.address?.county,
      country: recipient?.address?.country,
      postcode: recipient?.address?.postcode,
    },
    email: recipient.email,
    relationship: recipient.relationship,
  })

  useEffect(() => {
    setLoading(initialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(recipient)
    delete newData._id
    setFormData({ ...newData })
    form.setFieldsValue({ ...newData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDataLoading])

  const clearFormData = () => {
    setFormData((prevState) => commonUtils.resetFormData(prevState))
    form.setFieldsValue(commonUtils.resetFormData(formData))
  }

  const handleAntdFormInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => _.set(_.cloneDeep(prevState), e.target.name, e.target.value))
  }

  const onPlaceSelect = ({ formattedAddress, address1, address2, town, county, country, postcode }: any) => {
    setFormData((prevState) => ({
      ...prevState,
      address: {
        formattedAddress,
        address1,
        address2,
        town,
        county,
        country,
        postcode,
      },
    }))

    form.setFieldValue('address.formattedAddress', formattedAddress)
    form.setFieldValue('address.address1', address1)
    form.setFieldValue('address.address2', address2)
    form.setFieldValue('address.town', town)
    form.setFieldValue('address.county', county)
    form.setFieldValue('address.country', country)
    form.setFieldValue('address.postcode', postcode)
  }

  useEffect(() => {
    eventManager.addListener('recipient-modal', (data: any, cb: any) => {
      setModalState((state) => ({
        ...state,
        isOpen: data !== undefined,
        data,
        cb,
      }))
    })
  }, [setModalState])

  const handleClose = () => {
    setModalState((state) => ({ ...state, loading: false }))
    eventManager.closeRecipientModal()
  }

  const handleCancelClick = async () => {
    try {
      setModalState((state) => ({ ...state, loading: true }))
      if (modalState.cb.cancel) {
        await modalState.cb.cancel()
      }
    } catch (err) {
      // console.error(err)
    }
    handleClose()
    clearFormData()
  }

  const handleSubmit = async () => {
    const { errors } = await addEditRecipient({
      idNumber,
      recipientToEdit: recipientId,
      values: {
        ...formData,
      },
    })
    if (errors?.length) {
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const handleConfirmClick = async () => {
    try {
      setModalState((state) => ({ ...state, loading: true }))
      await handleSubmit()
      clearFormData()
      if (modalState.cb.confirm) {
        await modalState.cb.confirm()
      }
    } catch (err) {
      // console.error(err)
    }
    setModalState((state) => ({ ...state, loading: false }))
    handleClose()
  }

  const showAddressDetailsChange = (checked: boolean) => {
    setShowAddressDetails(checked)
  }

  return {
    modalState,
    handleCancelClick,
    handleConfirmClick,
    formData,
    handleAntdFormInputChange,
    onPlaceSelect,
    showAddressDetails,
    showAddressDetailsChange,
    loading,
    form,
  }
}

export default useRecipientModal
