// import { FileDownloadIcon } from 'assets/images'
import CustomPagination from 'components/CustomPagination'
// import CustomSelect from 'components/CustomSelect'
import EditDeleteButton from 'components/EditDeleteButton'
import Grid from 'components/Grid'
import Layout from 'components/Layout'
import MainContentContainer from 'components/MainContentContainer'
import CustomFormItem from 'components/CustomFormItem'
import { useClientsPage } from 'hooks'
import { Search } from 'assets/images'

import {
  StyledContainer,
  ButtonsContainer,
  StyledAddFactFindButton,
  StyledQuikSearchButton,
  StyledGridContainer,
  StyledPaginationContainer,
  StyledButtonsContainer,
  // StyledExportContainer,
  // StyledExportImg,
  // StyledExportText,
} from './Clients.styles'

const Clients = () => {
  const {
    refetch,
    limit,
    count,
    clientsColumns,
    clientsDataSource,
    setSelectedRows,
    displayDeleteButton,
    handleDeleteModal,
    onRowClick,
    handleQuickSearch,
    addNewClient,
    handleAntdFormItemChange,
    searchValue,
  } = useClientsPage()

  return (
    <Layout>
      <MainContentContainer pageName="Clients">
        <StyledContainer>
          <ButtonsContainer>
            <StyledButtonsContainer>
              {/* <CustomSelect onChange={() => {}} defaultValue={null} placeholder="Create an event" options={[]} /> */}
              {/* <StyledExportContainer onClick={() => {}}>
                <StyledExportImg src={FileDownloadIcon} />
                <StyledExportText>Export to excel</StyledExportText>
              </StyledExportContainer> */}
            </StyledButtonsContainer>
            <StyledButtonsContainer>
              {displayDeleteButton && <EditDeleteButton onClick={handleDeleteModal} type="delete" value="Delete" />}
              <StyledQuikSearchButton onClick={handleQuickSearch} disabled={displayDeleteButton}>
                Quick Search
              </StyledQuikSearchButton>
              <StyledAddFactFindButton onClick={addNewClient} disabled={displayDeleteButton}>
                Add Fact-Find
              </StyledAddFactFindButton>
              <CustomFormItem
                width={'343px'}
                name={'search'}
                placeholder="Type to filter..."
                inputPrefixImg={Search}
                onChange={handleAntdFormItemChange}
                value={searchValue}
              />
            </StyledButtonsContainer>
          </ButtonsContainer>
          <StyledGridContainer>
            <Grid setSelectedRows={setSelectedRows} columns={clientsColumns} dataSource={clientsDataSource} checkboxColumn={true} onRowClick={onRowClick} />
          </StyledGridContainer>
          <StyledPaginationContainer>
            <CustomPagination pageSize={limit} count={count} refetch={refetch} />
          </StyledPaginationContainer>
        </StyledContainer>
      </MainContentContainer>
    </Layout>
  )
}

export default Clients
