import styled from 'styled-components'

export const FormTableContainer = styled.div`
  margin: 24px 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
`
export const TableContainer = styled.div`
  width: 100%;
  background-color: #e3f2fd;
  padding: 24px;
`

export const StyledTitle = styled.h1`
  font-size: 1.25rem;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
`
