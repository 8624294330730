import { useState, useEffect } from 'react'

const getWidth = (): number => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

const useViewportWidth = (): { width: number } => {
  const [width, setWidth] = useState(getWidth())

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null
    const resizeListener = (): void => {
      clearTimeout(timeoutId as NodeJS.Timeout)

      timeoutId = setTimeout(() => setWidth(getWidth()), 150)
    }

    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  return { width }
}

export default useViewportWidth
