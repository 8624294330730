import CustomPagination from 'components/CustomPagination'
import CustomSelect from 'components/CustomSelect'
import EditDeleteButton from 'components/EditDeleteButton'
import Grid from 'components/Grid'
import Layout from 'components/Layout'
import MainContentContainer from 'components/MainContentContainer'
import UserAddEditDrawer from 'drawers/UserAddEditDrawer/UserAddEditDrawer'
import { useUsersPage } from 'hooks'

import {
  StyledContainer,
  ButtonsContainer,
  StyledAddUserButton,
  StyledSuspendButton,
  StyledGridContainer,
  StyledPaginationContainer,
  StyledButtonsContainer,
} from './Users.styles'
import CustomFormItem from 'components/CustomFormItem'
import { Search } from 'assets/images'

const Users = () => {
  const {
    form,
    usersColumns,
    usersDataSource,
    refetch,
    handleCustomSelectChange,
    options,
    count,
    limit,
    closeDrawer,
    formData,
    open,
    loading,
    handleSubmit,
    handleFormFieldChange,
    companyUserChange,
    companyUser,
    handleCompanyChange,
    drawerType,
    handleEdit,
    handleAddUser,
    displaySuspendButton,
    displayEditButton,
    setSelectedRows,
    handleSuspend,
    handleDeleteModal,
    user,
    onRowClick,
    handleAntdFormItemChange,
    searchValue,
  } = useUsersPage()

  return (
    <Layout>
      <MainContentContainer pageName="Users">
        <StyledContainer>
          <ButtonsContainer>
            {user.role === 'superAdmin' && (
              <CustomSelect onChange={handleCustomSelectChange} defaultValue={null} placeholder="Select Company" options={options} />
            )}
            {(user.role === 'superAdmin' || user.role === 'companyUser') && (
              <StyledButtonsContainer>
                {displayEditButton && <EditDeleteButton onClick={handleEdit} type="edit" value="Edit" />}
                {displaySuspendButton && (
                  <>
                    <EditDeleteButton onClick={handleDeleteModal} type="delete" value="Delete" />
                    <StyledSuspendButton onClick={handleSuspend}>Suspend</StyledSuspendButton>
                  </>
                )}
                <StyledAddUserButton onClick={handleAddUser} disabled={displaySuspendButton}>
                  Add User
                </StyledAddUserButton>
                <CustomFormItem
                  width={'343px'}
                  name={'search'}
                  placeholder="Type to filter..."
                  inputPrefixImg={Search}
                  onChange={handleAntdFormItemChange}
                  value={searchValue}
                />
              </StyledButtonsContainer>
            )}
          </ButtonsContainer>
          <StyledGridContainer>
            <Grid setSelectedRows={setSelectedRows} columns={usersColumns} dataSource={usersDataSource} checkboxColumn={true} onRowClick={onRowClick} />
          </StyledGridContainer>
          <StyledPaginationContainer>
            <CustomPagination pageSize={limit} count={count} refetch={refetch} />
          </StyledPaginationContainer>
        </StyledContainer>
        <UserAddEditDrawer
          formData={formData}
          open={open}
          closeDrawer={closeDrawer}
          loading={loading}
          handleSubmit={handleSubmit}
          handleFormFieldChange={handleFormFieldChange}
          companyUserChange={companyUserChange}
          companyUser={companyUser}
          options={options}
          handleCompanyChange={handleCompanyChange}
          drawerType={drawerType}
          form={form}
        />
      </MainContentContainer>
    </Layout>
  )
}

export default Users
