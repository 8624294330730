import Form from 'antd/lib/form'
import CustomFormItem from 'components/CustomFormItem'
import { useState } from 'react'
import { StyledContainer, StyledFormContainer, StyledReferContainer, StyledTabTitle } from './TrackingTabContent.styles'
import Loading from 'components/Loading/Loading'
import useTrackingTabContent from 'hooks/clients/useTrackingTabContent'
import CustomFooter from 'pages/ClientDetails/CustomFooter/CustomFooter'
import { referPaidOptions } from './TrackingTabContent.helper'

const TrackingTabContent = () => {
  const [referPaid, setReferPaid] = useState<boolean | null>(null)
  const handleTrackingFormSelect = (name: string, value: boolean) => {
    setReferPaid(value)
  }

  const { handleAntdFormItemChange, trackingFormData, loading, onFinishFailed, onSubmit, setSaveType } = useTrackingTabContent()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledFormContainer>
        <StyledTabTitle>Tracking information</StyledTabTitle>
        <Form
          layout="vertical"
          size="large"
          name="basic"
          initialValues={trackingFormData}
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',
            marginTop: '8px',
          }}
          onFinishFailed={onFinishFailed}
          onFinish={onSubmit}
        >
          <CustomFormItem label="Name" name="name" placeholder="Name" onChange={handleAntdFormItemChange} />
          <CustomFormItem
            label="Telephone"
            name="telephone"
            placeholder="Telephone"
            rules={['validateTelephoneNumber']}
            onChange={handleAntdFormItemChange}
          />
          <CustomFormItem label="Postcode" name="postcode" placeholder="Postcode" onChange={handleAntdFormItemChange} />
          <CustomFormItem label="Lender" name="lender" placeholder="Lender" onChange={handleAntdFormItemChange} />
          <CustomFormItem label="Amount" name="amount" placeholder="Amount" onChange={handleAntdFormItemChange} />
          <CustomFormItem label="Value" name="value" placeholder="Value" onChange={handleAntdFormItemChange} />
          <CustomFormItem label="Application" name="applicationDate" placeholder="DD/MM/YYYY" type="date" onChange={handleAntdFormItemChange} />
          <CustomFormItem label="Valuation" name="valuation" placeholder="Valuation" onChange={handleAntdFormItemChange} />
          <CustomFormItem label="Offer" name="offer" placeholder="DD/MM/YYYY" type="date" onChange={handleAntdFormItemChange} />
          <CustomFormItem label="Solicitor" name="solicitor" placeholder="Solicitor" onChange={handleAntdFormItemChange} />
          <CustomFormItem label="Fee Authorisation Form Sent" name="feeAuthoSentDate" placeholder="DD/MM/YYYY" type="date" onChange={handleAntdFormItemChange} />
          <CustomFormItem label="Sol Visit" name="solVisit" placeholder="DD/MM/YYYY" type="date" onChange={handleAntdFormItemChange} />
          <CustomFormItem label="Completion" name="completionDate" placeholder="DD/MM/YYYY" type="date" onChange={handleAntdFormItemChange} />
          <CustomFormItem label="Commission" name="comission" placeholder="Commission" onChange={handleAntdFormItemChange} />
          <CustomFormItem label="Advice Fee" name="adviceFee" placeholder="Advice Fee" type="number" onChange={handleAntdFormItemChange} />
          <CustomFormItem label="LPA" name="LPA" placeholder="LPA" onChange={handleAntdFormItemChange} />
          <CustomFormItem label="Refer Comms" name="referComms" placeholder="Refer Comms" onChange={handleAntdFormItemChange} />
          <StyledReferContainer>
            <CustomFormItem
              onChange={handleTrackingFormSelect}
              defaultValue={referPaid}
              placeholder="Select option"
              options={referPaidOptions}
              width="100%"
              label="Refer paid"
              type="select"
            />
            <CustomFormItem
              label="Referer"
              name="referer"
              placeholder="Referer"
              $inputStyle={{ backgroundColor: '#EAEAEA' }}
              onChange={handleAntdFormItemChange}
            />
          </StyledReferContainer>
          <CustomFooter loading={loading} setSaveType={setSaveType} />
        </Form>
      </StyledFormContainer>
    </StyledContainer>
  )
}

export default TrackingTabContent
