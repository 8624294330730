import config from 'config'
import { useEffect, useState } from 'react'
import { StyledIframeContainer, StyledIframe } from './ViewPdf.styles'

const ViewPdf = () => {
  const [pdfUrl, setPdfUrl] = useState('')
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))

  useEffect(() => {
    // Fetch the PDF from the backend
    const fetchPdf = async () => {
      try {
        const formData = new FormData()
        formData.append('idNumber', idNumber.toString())
        const response = await fetch(`${config.API_URL}/pdf/generate`, {
          method: 'POST',
          body: formData,
        })
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)

        setPdfUrl(url)
      } catch (error) {
        console.error('Error fetching PDF:', error)
      }
    }

    fetchPdf()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <StyledIframeContainer>{pdfUrl ? <StyledIframe src={pdfUrl}></StyledIframe> : <p>Loading PDF...</p>}</StyledIframeContainer>
}

export default ViewPdf
