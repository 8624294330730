import { useEffect, useState } from 'react'
import { message } from 'antd'

import { commonHelper } from 'helpers'
import { commonUtils, eventManager } from 'utils'

import useGeneral from './useGeneral'
import { useGetOtherLiabilities, useGetObjectivesAndAlternativesInfo, useGetObjectives } from 'services/clients/clients.service'
import { fundsRequiredInOptions, purposeOfRaisingCapitalOptions } from 'modals/ObjectiveModal/ObjectiveModal.helper'
import { liabilityTypeOptions } from 'modals/EditLiabilityModal/EditLiabilityModal.helper'
import { addEditObjectivesAndAlternativesMutation, deleteObjectivesMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { AddEditObjectivesAndAlternativesPayload, DeleteWithIdNumberAnd_IdsPayload } from 'services/shared/mutation.payload'
import { ILabelAndValue } from 'interfaces/common/labelAndValue'

const useObjectivesAndAlternatives = () => {
  const deleteObjectives = useCustomMutation<DeleteWithIdNumberAnd_IdsPayload>({ mutation: deleteObjectivesMutation })
  const addEditObjectivesAndAlternatives = useCustomMutation<AddEditObjectivesAndAlternativesPayload>({
    mutation: addEditObjectivesAndAlternativesMutation,
    refetchQueries: ['getClientFormsProgress'],
  })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { data, loading: formInitialDataLoading, refetch } = useGetObjectivesAndAlternativesInfo(idNumber)
  const { objectives } = useGetObjectives(idNumber)
  const { otherLiabilities } = useGetOtherLiabilities(idNumber)
  const [loading, setLoading] = useState(formInitialDataLoading)
  const [formData, setFormData] = useState({
    cuttingBackCurrentSpending: data?.cuttingBackCurrentSpending,
    detailsOfCuttingBackCurrentSpending: data?.detailsOfCuttingBackCurrentSpending,
    claimStateBenefits: data?.claimStateBenefits,
    detailsOfClaimStateBenefits: data?.detailsOfClaimStateBenefits,
    takeLodger: data?.takeLodger,
    detailsOfTakeLodger: data?.detailsOfTakeLodger,
    returnToWork: data?.returnToWork,
    detailsOfReturnToWork: data?.detailsOfReturnToWork,
    askFamilyForAssistance: data?.askFamilyForAssistance,
    detailsOfAskFamilyForAssistance: data?.detailsOfAskFamilyForAssistance,
    useOtherFundsOrAssets: data?.useOtherFundsOrAssets,
    detailsOfUseOtherFundsOrAssets: data?.detailsOfUseOtherFundsOrAssets,
    standardMortgageDetails: data?.standardMortgageDetails,
    retirementInterestOnlyMortgageDetails: data?.retirementInterestOnlyMortgageDetails,
    personalLoanDetails: data?.personalLoanDetails,
    moveToCheaperProperty: data?.moveToCheaperProperty,
    detailsOfMoveToCheaperProperty: data?.detailsOfMoveToCheaperProperty,
    takeCashFromPensions: data?.takeCashFromPensions,
    detailsOfTakeCashFromPensions: data?.detailsOfTakeCashFromPensions,
    sellPropertyAndMove: data?.sellPropertyAndMove,
    detailsOfSellPropertyAndMove: data?.detailsOfSellPropertyAndMove,
    furtherAdvanceOrSecondCharge: data?.furtherAdvanceOrSecondCharge,
  })
  const [tableError, setTableError] = useState('')

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [loading])

  useEffect(() => {
    setLoading(formInitialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(data)
    delete newData._id
    setFormData({ ...newData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInitialDataLoading])

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value, e.target?.name)
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: parsedValue,
    }))
  }

  const handleCustomSelectChange = (name: string, value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = async () => {
    const { errors } = await addEditObjectivesAndAlternatives({
      idNumber,
      values: formData,
    })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const handleAddButton = () => {
    eventManager.openObjectiveModal(
      {},
      {
        confirm: () => setTableError(''),
      },
    )
  }

  let initialFundsTotalValue = 0
  let futureFundsTotalValue = 0

  const objectivesDataSource =
    objectives.map((objective: any) => {
      futureFundsTotalValue += objective.fundsRequiredIn === 'now' ? 0 : objective.requiredAmount
      initialFundsTotalValue += objective.fundsRequiredIn === 'now' ? objective.requiredAmount : 0
      return {
        id: objective._id,
        purposeOfRaisingCapital: purposeOfRaisingCapitalOptions?.find((item: ILabelAndValue) => item.value === objective.purposeOfRaisingCapital)?.label,
        requiredAmount: objective.requiredAmount,
        fundsTimescale: fundsRequiredInOptions?.find((item: ILabelAndValue) => item.value === objective.fundsRequiredIn)?.label,
        objectiveSummery: objective.description,
      }
    }) || []

  const liabilitiesDataSource =
    otherLiabilities.map((liability: any) => {
      const amount = liability.balance + liability.earlyRepaymentCharges
      initialFundsTotalValue += liability.planToRepay ? amount : 0
      const type = liabilityTypeOptions?.find((item: ILabelAndValue) => item.value === liability.liabilityType)?.label
      return {
        id: liability._id,
        purposeOfRaisingCapital: 'Debts',
        requiredAmount: amount,
        fundsTimescale: liability.planToRepay ? 'In The Future' : 'No Repayment',
        objectiveSummery: `${type} - ${liability.description}`,
        // form liability's data manipulation
        isLiability: true,
      }
    }) || []

  const [selected, setSelected] = useState({})
  const displayDeleteButton = !!Object.keys(selected).length
  const displayEditButton = Object.keys(selected).length === 1

  const setSelectedRows = (selectedRowObj: any) => {
    setSelected(selectedRowObj)
  }

  const handleDelete = async () => {
    setLoading(true)
    const objectives_IdToDelete = Object.keys(selected)
    const { errors } = await deleteObjectives({ _ids: objectives_IdToDelete, idNumber })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    setLoading(false)
    refetch()
    setSelected({})
  }

  const handleDeleteModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleDelete(),
      },
    )
  }

  const { handleSaveAndGoBack, handleSave, handleSaveAndContinue } = useGeneral({ handleSubmit, setLoading })

  const [saveType, setSaveType] = useState<'save' | 'saveAndGoBack' | 'saveAndContinue'>('save')

  const onSubmit = () => {
    if (saveType === 'saveAndGoBack') {
      handleSaveAndGoBack()
    } else if (saveType === 'saveAndContinue') {
      handleSaveAndContinue()
    } else if (saveType === 'save') {
      handleSave()
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill out every required field')
  }

  const handleEditModal = () => {
    eventManager.openObjectiveModal(
      { _id: Object.keys(selected)[0], isEdit: true },
      {
        confirm: () => {},
      },
    )
  }

  return {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    handleAntdFormItemChange,
    handleCustomSelectChange,
    handleAddButton,
    objectivesDataSource,
    liabilitiesDataSource,
    initialFundsTotalValue,
    futureFundsTotalValue,
    setSelectedRows,
    displayDeleteButton,
    handleDeleteModal,
    displayEditButton,
    handleEditModal,
    tableError,
  }
}

export default useObjectivesAndAlternatives
