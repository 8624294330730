import { useNavigate } from 'react-router-dom'

import Layout from 'components/Layout'
import MainContentContainer from 'components/MainContentContainer'
import { useResources } from 'hooks/resources'

import {
  // StyledGridContainer,
  SubPagesWrapper,
  SubPageWrapper,
  StyledImg,
  StyledTitle,
  StyledDescription,
  StyledButton,
} from './Resources.styles'

interface ISubPage {
  id: number
  name: string
  path: string
  description: string
  img: string
}

const Resources = () => {
  const { subPages } = useResources()
  const navigate = useNavigate()

  const handleRedirect = (path: string, name: string) => {
    navigate(path)
    localStorage.setItem(
      'resourcesBreadcrumbItems',
      JSON.stringify([
        { text: 'Resources', key: 'resources' },
        { text: name, key: path },
      ]),
    )
  }

  return (
    <Layout>
      <MainContentContainer pageName="Resources">
        {/* <StyledGridContainer>
          <Grid columns={usersColumns} dataSource={usersDataSource} checkboxColumn={false} />
        </StyledGridContainer> */}
        <SubPagesWrapper>
          {subPages.map((subPage: ISubPage) => (
            <SubPageWrapper
              key={subPage.id}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '24px',
              }}
            >
              <StyledImg src={subPage.img} />
              <StyledTitle>{subPage.name}</StyledTitle>
              <StyledDescription>{subPage.description}</StyledDescription>
              <StyledButton onClick={() => handleRedirect(subPage.path, subPage.name)}>Click here to view</StyledButton>
            </SubPageWrapper>
          ))}
        </SubPagesWrapper>
      </MainContentContainer>
    </Layout>
  )
}

export default Resources
