/* eslint-disable no-useless-computed-key */
import { Form, Modal } from 'antd'

import { StyledWrapper, Styled2x2Grid } from './PreviousAddressHistoryModal.styles'
import TextInput from 'components/CustomFormItem/CustomFormItem'
import Loading from 'components/Loading/Loading'
import usePreviousAddressHistoryModal from 'hooks/clients/Modals/usePreviousAddressHistoryModal'
import GoogleAddressInput from 'components/GoogleAddressInput/GoogleAddressInput'
import CustomSwitch from 'components/CustomSwitch/CustomSwitch'
import CustomFormItem from 'components/CustomFormItem/CustomFormItem'

const PreviousAddressHistoryModal = () => {
  const {
    modalState,
    handleCancelClick,
    handleConfirmClick,
    formData,
    flattenedFormData,
    handleAntdFormInputChange,
    onPlaceSelect,
    showAddressDetails,
    showAddressDetailsChange,
    loading,
    form,
    handleCustomSelectChange,
    customersOptions,
  } = usePreviousAddressHistoryModal()

  if (loading) {
    return <Loading />
  }

  return (
    <Modal
      title="Edit Address"
      open={modalState.isOpen}
      onOk={handleConfirmClick}
      onCancel={handleCancelClick}
      width={700}
      okText="Save"
      cancelText="Cancel"
      closable={false}
      className="edit-asset-modal"
      confirmLoading={modalState.loading}
    >
      {modalState.loading ? (
        <Loading />
      ) : (
        <StyledWrapper>
          <Form
            form={form}
            layout="vertical"
            size="large"
            name="basic"
            initialValues={flattenedFormData}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
              marginTop: '8px',
              width: '100%',
            }}
          >
            <CustomFormItem
              label="Select Customer *"
              width={'100%'}
              placeholder="Select Customer *"
              onChange={handleCustomSelectChange}
              options={customersOptions}
              name="residents"
              rules={['isRequired']}
              type="select"
            />
            <Styled2x2Grid>
              <GoogleAddressInput label="Search for an Address" onPlaceSelect={onPlaceSelect} value={formData?.address?.formattedAddress} />
              <CustomSwitch checked={showAddressDetails} onChange={showAddressDetailsChange} label="Show Address Details" />
              {showAddressDetails && (
                <>
                  <TextInput
                    label="Address 1"
                    name="address.address1"
                    placeholder="Address 1"
                    onChange={handleAntdFormInputChange}
                    value={formData?.address?.address1}
                  />
                  <TextInput
                    label="Address 2"
                    name="address.address2"
                    placeholder="Address 2"
                    onChange={handleAntdFormInputChange}
                    value={formData?.address?.address2}
                  />
                  <TextInput label="Town" name="address.town" placeholder="Town*" onChange={handleAntdFormInputChange} value={formData?.address?.town} />
                  <TextInput label="County" name="address.county" placeholder="County" onChange={handleAntdFormInputChange} value={formData?.address?.county} />
                  <TextInput
                    label="Country"
                    name="address.country"
                    placeholder="Country"
                    onChange={handleAntdFormInputChange}
                    value={formData?.address?.country}
                  />
                  <TextInput
                    label="Postcode"
                    name="address.postcode"
                    placeholder="Postcode"
                    onChange={handleAntdFormInputChange}
                    value={formData?.address?.postcode}
                  />
                </>
              )}
              <TextInput label="Date Moved In" name="dateMovedIn" placeholder="Date Moved In" type="date" onChange={handleAntdFormInputChange} />
              <TextInput label="Date Moved Out" name="dateMovedOut" placeholder="Date Moved Out" type="date" onChange={handleAntdFormInputChange} />
            </Styled2x2Grid>
          </Form>
        </StyledWrapper>
      )}
    </Modal>
  )
}

export default PreviousAddressHistoryModal
