import { Form } from 'antd'

import Loading from 'components/Loading'

import { yesNoOptions } from 'utils/commonOptions'
import CustomFooter from 'pages/QuickSearch/CustomFooter/CustomFooter'

import { StyledContainer, StyledTitle, StyledTitleContainer, StyledRequiredMsg, StyledRow, StyledBreakLine } from './LifestyleDetails.styles'
import CustomFormItem from 'components/CustomFormItem/CustomFormItem'
import useLifestyleDetails from 'hooks/clients/QuickSearch/MedicalDetails/useLifestyleDetails'

const LifestyleDetails = () => {
  const {
    loading,
    handleNext,
    handleAntdFormItemChange,
    flattenedFormData,
    customerFirstName,
    customerSecondName,
    enterMedicalDetailsOfCustomerFirst,
    enterMedicalDetailsOfCustomerSecond,
    form,
  } = useLifestyleDetails()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <Form
        form={form}
        layout="vertical"
        size="large"
        name="basic"
        onFinish={handleNext}
        initialValues={flattenedFormData}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '12px',
          marginTop: '8px',
          width: '100%',
        }}
      >
        {enterMedicalDetailsOfCustomerFirst && (
          <>
            <StyledTitleContainer>
              <StyledTitle>Lifestyle Details of {customerFirstName}</StyledTitle>
              <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
            </StyledTitleContainer>
            <StyledRow>
              <CustomFormItem
                label="Height in feet *"
                name="customerFirst.height.feet"
                placeholder="Feet"
                type="number"
                onChange={handleAntdFormItemChange}
                rules={['isRequired']}
              />
              <CustomFormItem
                label="Height in inches *"
                name="customerFirst.height.inches"
                placeholder="Inches"
                type="number"
                onChange={handleAntdFormItemChange}
                rules={['isRequired']}
              />
            </StyledRow>
            <StyledRow>
              <CustomFormItem
                label="Weight in stone *"
                name="customerFirst.weight.stone"
                placeholder="Stone"
                type="number"
                onChange={handleAntdFormItemChange}
                rules={['isRequired']}
              />
              <CustomFormItem
                label="Weight in pound *"
                name="customerFirst.weight.pound"
                placeholder="Pound"
                type="number"
                onChange={handleAntdFormItemChange}
                rules={['isRequired']}
              />
            </StyledRow>
            <CustomFormItem
              label="If you smoke manufactured cigarettes, have you smoked 10 or more cigarettes per day for the last 10 years? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.hasSmokedTenOrMoreCigarettes"
              type="radio"
              rules={['isRequired']}
            />
            <CustomFormItem
              label="If you smoke rolling tobacco, have you smoked more than 2.5oz or 70g per week for the last 10 years? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.hasSmokedRollingTobacco"
              type="radio"
              rules={['isRequired']}
            />
            <CustomFormItem
              label="How many units of alcohol (on average) do you consume weekly? *"
              onChange={handleAntdFormItemChange}
              name="customerFirst.consumedUnitsOfAlcohol"
              placeholder="How many units of alcohol (on average) do you consume weekly? *"
              type="number"
              rules={['isRequired']}
            />
            <CustomFormItem
              label="Have you been diagnosed with high blood pressure (hypertension), requiring prescribed daily medication? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.hasDiagnosedHighBloodPressure"
              type="radio"
              rules={['isRequired']}
            />
          </>
        )}
        {enterMedicalDetailsOfCustomerSecond && (
          <>
            {enterMedicalDetailsOfCustomerFirst && <StyledBreakLine />}
            <StyledTitleContainer>
              <StyledTitle>Lifestyle Details of {customerSecondName}</StyledTitle>
              <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
            </StyledTitleContainer>
            <StyledRow>
              <CustomFormItem
                label="Height in feet"
                name="customerSecond.height.feet"
                placeholder="Feet"
                type="number"
                onChange={handleAntdFormItemChange}
                rules={['isRequired']}
              />
              <CustomFormItem
                label="Height in inches"
                name="customerSecond.height.inches"
                placeholder="Inches"
                type="number"
                onChange={handleAntdFormItemChange}
                rules={['isRequired']}
              />
            </StyledRow>
            <StyledRow>
              <CustomFormItem
                label="Weight in stone"
                name="customerSecond.weight.stone"
                placeholder="Stone"
                type="number"
                onChange={handleAntdFormItemChange}
                rules={['isRequired']}
              />
              <CustomFormItem
                label="Weight in pound"
                name="customerSecond.weight.pound"
                placeholder="Pound"
                type="number"
                onChange={handleAntdFormItemChange}
                rules={['isRequired']}
              />
            </StyledRow>
            <CustomFormItem
              label="If you smoke manufactured cigarettes, have you smoked 10 or more cigarettes per day for the last 10 years? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.hasSmokedTenOrMoreCigarettes"
              type="radio"
              rules={['isRequired']}
            />
            <CustomFormItem
              label="If you smoke rolling tobacco, have you smoked more than 2.5oz or 70g per week for the last 10 years? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.hasSmokedRollingTobacco"
              type="radio"
              rules={['isRequired']}
            />
            <CustomFormItem
              label="How many units of alcohol (on average) do you consume weekly? *"
              toolTipText="If you consume alcohol enter whole number >0. If you do not consume alcohol enter 0 Government guidance indicates that a unit of alcohol is approximately equivalent to half a pint of normal strength beer, lager or cider, one standard glass of wine or a single measure of spirit)"
              onChange={handleAntdFormItemChange}
              name="customerSecond.consumedUnitsOfAlcohol"
              placeholder="How many units of alcohol (on average) do you consume weekly? *"
              type="number"
              rules={['isRequired']}
            />
            <CustomFormItem
              label="Have you been diagnosed with high blood pressure (hypertension), requiring prescribed daily medication? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.hasDiagnosedHighBloodPressure"
              type="radio"
              rules={['isRequired']}
            />
          </>
        )}
        <CustomFooter loading={loading} buttonText="Next" buttonTheme="light" />
      </Form>
    </StyledContainer>
  )
}

export default LifestyleDetails
