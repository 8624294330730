import { useEffect, useState } from 'react'

import { useGetContentfulDataByContentModel } from 'hooks/useContentful'
import { useNavigate } from 'react-router-dom'

interface ISubPage {
  id: number
  title: string
  type: string
  excerpt: string
  date: string
  content: any
}

const useNewsAndTraining = () => {
  const navigate = useNavigate()
  const [data, setData] = useState<ISubPage[]>([])
  const { data: initialData = [], isLoading } = useGetContentfulDataByContentModel('newsAndTraining')

  const handleDisplayPage = (title: string) => {
    navigate(`/resources/news-and-training/${encodeURIComponent(title)}`)
  }

  useEffect(() => {
    setData(
      initialData?.map((item: any, index: number) => {
        return {
          //   id: item.sys.id,
          id: `${index}`,
          title: item.fields.title,
          type: item.fields.type,
          excerpt: item.fields.excerpt,
          date: item.fields.date,
          content: item.fields.content,
        }
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const displayLoading = isLoading || !data

  return {
    displayLoading,
    data,
    handleDisplayPage,
  }
}

export default useNewsAndTraining
