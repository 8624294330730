export const relationshipOptions = [
  {
    value: 'spouse',
    label: 'Spouse',
  },
  {
    value: 'husband',
    label: 'Husband',
  },
  {
    value: 'wife',
    label: 'Wife',
  },
  {
    value: 'civilPartner',
    label: 'Civil Partner',
  },
  {
    value: 'partner',
    label: 'Partner',
  },
  {
    value: 'fiance',
    label: 'Fiance',
  },
  {
    value: 'fiancee',
    label: 'Fiancee',
  },
  {
    value: 'son',
    label: 'Son',
  },
  {
    value: 'daughter',
    label: 'Daughter',
  },
  {
    value: 'stepSon',
    label: 'Step-Son',
  },
  {
    value: 'stepDaughter',
    label: 'Step-Daughter',
  },
  {
    value: 'father',
    label: 'Father',
  },
  {
    value: 'mother',
    label: 'Mother',
  },
  {
    value: 'brother',
    label: 'Brother',
  },
  {
    value: 'sister',
    label: 'Sister',
  },
  {
    value: 'brotherInLaw',
    label: 'Brother-In-law',
  },
  {
    value: 'sisterInLaw',
    label: 'Sister-In-law',
  },
  {
    value: 'grandson',
    label: 'Grandson',
  },
  {
    value: 'grandDaughter',
    label: 'GrandDaughter',
  },
  {
    value: 'unrelated',
    label: 'Unrelated',
  },
  {
    value: 'other',
    label: 'Other',
  },
]

export const maritalStatusOptions = [
  {
    label: 'Single',
    value: 'single',
  },
  {
    label: 'Married',
    value: 'married',
  },
  {
    label: 'Divorced',
    value: 'divorced',
  },
  {
    label: 'Widowed',
    value: 'widowed',
  },
  {
    label: 'Civil Partnership',
    value: 'civilPartnership',
  },
  {
    label: 'Co-Habiting',
    value: 'coHabiting',
  },
  {
    label: 'Separated',
    value: 'separated',
  },
  {
    label: 'Not Disclosed',
    value: 'notDisclosed',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

export const contactOptions = [
  {
    label: 'EMAIL',
    value: 'email',
  },
  {
    label: 'LANDLINE TELEPHONE',
    value: 'landlineTelephone',
  },
  {
    label: 'MOBILE TELEPHONE',
    value: 'mobileTelephone',
  },
  {
    label: 'POST',
    value: 'post',
  },
]
