import moment from 'moment'

import Layout from 'components/Layout'
import MainContentContainer from 'components/MainContentContainer'
import { useBreadcrumb, useNewsAndTraining } from 'hooks/resources'
import Loading from 'components/Loading'
import CustomBreadcrumb from 'components/CustomBreadcrumb'

import {
  StyledButton,
  StyledType,
  StyledTitle,
  StyledDescription,
  StyledDate,
  SubPageWrapper,
  SubPagesWrapper,
  SubPageInternalWrapper,
  SubPageWrapperFooter,
} from './NewsAndTraining.styles'

interface ISubPage {
  id: number
  title: string
  type: string
  excerpt: string
  date: string
  content: any
}

const NewsAndTraining = () => {
  const { displayLoading, data, handleDisplayPage } = useNewsAndTraining()
  const { breadcrumbItems, handleBreadcrumbTabChange } = useBreadcrumb()

  const handleRedirect = (title: string) => {
    handleDisplayPage(title)
    localStorage.setItem('resourcesBreadcrumbItems', JSON.stringify([...breadcrumbItems, { text: title, key: 'news-and-training' }]))
  }

  return (
    <Layout>
      <MainContentContainer pageName="News And Training">
        <CustomBreadcrumb breadcrumbItems={breadcrumbItems} handleBreadcrumbTabChange={handleBreadcrumbTabChange} />
        {displayLoading ? (
          <Loading />
        ) : (
          <SubPagesWrapper>
            {data.map((subPage: ISubPage) => {
              const { type } = subPage
              const typeColor = type === 'News' ? '#A5C2DD' : type === 'Training' ? '#E562DD' : '#6C6F73'
              const formattedDate = moment(subPage.date).format('DD-MM-YYYY')

              return (
                <SubPageWrapper
                  key={subPage.id}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '24px',
                  }}
                >
                  <SubPageInternalWrapper>
                    <StyledType color={typeColor}>{type}</StyledType>
                    <StyledTitle>{subPage.title}</StyledTitle>
                    <StyledDescription>{subPage.excerpt}</StyledDescription>
                  </SubPageInternalWrapper>
                  <SubPageWrapperFooter>
                    <StyledDate>Date: {formattedDate}</StyledDate>
                    <StyledButton onClick={() => handleRedirect(subPage.title)}>Find out more</StyledButton>
                  </SubPageWrapperFooter>
                </SubPageWrapper>
              )
            })}
          </SubPagesWrapper>
        )}
      </MainContentContainer>
    </Layout>
  )
}

export default NewsAndTraining
