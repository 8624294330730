/* eslint-disable no-useless-computed-key */
import Form from 'antd/lib/form'

import CustomFormItem from 'components/CustomFormItem'
import Loading from 'components/Loading/Loading'
import CheckboxWithLabel from 'components/CheckboxWithLabel/CheckboxWithLabel'
import CustomFooter from 'pages/QuickSearch/CustomFooter/CustomFooter'
import { genderOptions, titleOptions, yesNoOptions } from 'utils/commonOptions'

import { StyledContainer, StyledFormContainer, StyledTitleContainer, StyledTitle, StyledRequiredMsg } from './ClientDetailsTab.styles'
import useClientDetailsTab from 'hooks/clients/QuickSearch/useClientDetailsTab'

const ClientDetailsTab = () => {
  const {
    loading,
    flattenedFormData,
    handleAntdFormItemChange,
    handleCheckboxWithLabel,
    handleMedicalQuestionsTabChange,
    handleSubmit,
    onFinishFailed,
  } = useClientDetailsTab()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledFormContainer>
        <StyledTitleContainer>
          <StyledTitle>Client Details</StyledTitle>
          <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
        </StyledTitleContainer>
        <Form
          layout="vertical"
          size="large"
          name="basic"
          onFinishFailed={onFinishFailed}
          onFinish={handleSubmit}
          initialValues={flattenedFormData}
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',
            marginTop: '8px',
          }}
        >
          <CustomFormItem
            label="Title *"
            onChange={handleAntdFormItemChange}
            options={titleOptions}
            name="firstLife.title"
            type="radio"
            rules={['isRequired']}
          />
          <CustomFormItem
            label="First Name *"
            name="firstLife.firstName"
            placeholder="First Name *"
            onChange={handleAntdFormItemChange}
            rules={['isRequired']}
          />
          <CustomFormItem
            label="Last Name *"
            name="firstLife.lastName"
            placeholder="Last Name *"
            onChange={handleAntdFormItemChange}
            rules={['isRequired']}
          />
          <CustomFormItem
            label="Select sex *"
            onChange={handleAntdFormItemChange}
            options={genderOptions}
            name="firstLife.gender"
            type="radio"
            rules={['isRequired']}
          />
          <CustomFormItem
            label="Date of birth *"
            name="firstLife.dateOfBirth"
            placeholder="Date of birth *"
            type="date"
            onChange={handleAntdFormItemChange}
            rules={['isRequired']}
          />
          <CheckboxWithLabel
            label="Use age next birthday"
            checked={flattenedFormData['firstLife.useAgeNextBirthday']}
            onChange={handleCheckboxWithLabel}
            name="firstLife.useAgeNextBirthday"
          />
          <CustomFormItem
            label="Enter medical details"
            onChange={handleMedicalQuestionsTabChange}
            options={yesNoOptions}
            name="firstLife.enterMedicalDetails"
            type="radio"
            rules={['isRequired']}
          />
          <CheckboxWithLabel label="Second life" checked={flattenedFormData['isSecondLife']} onChange={handleCheckboxWithLabel} name="isSecondLife" />
          {flattenedFormData['isSecondLife'] && (
            <>
              <CustomFormItem
                label="Title *"
                onChange={handleAntdFormItemChange}
                options={titleOptions}
                name="secondLife.title"
                type="radio"
                rules={['isRequired']}
              />
              <CustomFormItem
                label="First Name *"
                name="secondLife.firstName"
                placeholder="First Name *"
                onChange={handleAntdFormItemChange}
                rules={['isRequired']}
              />
              <CustomFormItem
                label="Last Name *"
                name="secondLife.lastName"
                placeholder="Last Name *"
                onChange={handleAntdFormItemChange}
                rules={['isRequired']}
              />
              <CustomFormItem
                label="Select sex *"
                onChange={handleAntdFormItemChange}
                options={genderOptions}
                name="secondLife.gender"
                type="radio"
                rules={['isRequired']}
              />
              <CustomFormItem
                label="Date of birth *"
                name="secondLife.dateOfBirth"
                placeholder="Date of birth *"
                type="date"
                onChange={handleAntdFormItemChange}
                rules={['isRequired']}
              />
              <CheckboxWithLabel
                label="Use age next birthday"
                checked={flattenedFormData['secondLife.useAgeNextBirthday']}
                onChange={handleCheckboxWithLabel}
                name="secondLife.useAgeNextBirthday"
              />
              <CustomFormItem
                label="Enter medical details"
                onChange={handleMedicalQuestionsTabChange}
                options={yesNoOptions}
                name="secondLife.enterMedicalDetails"
                value={flattenedFormData['secondLife.enterMedicalDetails']}
                type="radio"
                rules={['isRequired']}
              />
            </>
          )}
          <CustomFooter loading={loading} buttonText="Next" buttonTheme="light" />
        </Form>
      </StyledFormContainer>
    </StyledContainer>
  )
}

export default ClientDetailsTab
