import styled from 'styled-components'

export const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  background-color: #fefefe;
`

export const StyledNote = styled.p`
  font-size: 16px;
  max-width: 872px;
`
