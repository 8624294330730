export const beneficiaryInfoTypeToProvideOptions = [
  {
    label: 'Email Address',
    value: 'email',
  },
  {
    label: 'Address',
    value: 'address',
  },
  {
    label: 'Both',
    value: 'both',
  },
]
