import { Form, Modal } from 'antd'

import { StyledWrapper, Styled2x2Grid } from './ExpenditureDetailsModal.styles'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import TextInput from 'components/CustomFormItem/CustomFormItem'
import CustomTextArea from 'components/CustomTextArea/CustomTextArea'
import { expenditureTypeOptions } from './ExpenditureDetailsModal.helper'
import useExpenditureDetailsModal from 'hooks/clients/Modals/useExpenditureDetailsModal'
import Loading from 'components/Loading/Loading'
import { PoundIcon } from 'assets/images'
import { periodOptions } from 'utils/commonOptions'
import CustomFormItem from 'components/CustomFormItem/CustomFormItem'

const ExpenditureDetailsModal = () => {
  const {
    modalState,
    handleCancelClick,
    handleConfirmClick,
    formData,
    handleCustomSelectChange,
    handleAntdFormInputChange,
    handleCustomTextAreaChange,
    loading,
    form,
    customersOptions,
  } = useExpenditureDetailsModal()

  if (loading) {
    return <Loading />
  }

  return (
    <Modal
      title="Edit Expenditure"
      open={modalState.isOpen}
      onOk={handleConfirmClick}
      onCancel={handleCancelClick}
      width={700}
      okText="Save"
      cancelText="Cancel"
      closable={false}
      className="edit-asset-modal"
      confirmLoading={modalState.loading}
    >
      {modalState.loading ? (
        <Loading />
      ) : (
        <StyledWrapper>
          <Form
            form={form}
            layout="vertical"
            size="large"
            name="basic"
            initialValues={formData}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
              marginTop: '8px',
              width: '100%',
            }}
          >
            <Styled2x2Grid>
              <CustomFormItem
                label="Select Customer *"
                width={'100%'}
                placeholder="Select Customer *"
                onChange={handleCustomSelectChange}
                options={customersOptions}
                name="expenditureHolder"
                rules={['isRequired']}
                type="select"
              />
              <CustomSelect
                label="Expenditure Period? *"
                width={'100%'}
                placeholder="Expenditure Period? *"
                defaultValue={formData.expenditurePeriod}
                onChange={handleCustomSelectChange}
                options={periodOptions}
                name="expenditurePeriod"
              />
              <CustomSelect
                label="Type of Expenditure *"
                width={'100%'}
                placeholder="Type of Expenditure *"
                defaultValue={formData.expenditureType}
                onChange={handleCustomSelectChange}
                options={expenditureTypeOptions}
                name="expenditureType"
              />
              <TextInput
                label="Expenditure Amount*"
                name="expenditureAmount"
                placeholder="Expenditure Amount*"
                inputPrefixImg={PoundIcon}
                prefixImgHeight={12}
                onChange={handleAntdFormInputChange}
                type="number"
              />
            </Styled2x2Grid>
            <CustomTextArea
              label="Description"
              placeholder="Description"
              value={formData.description}
              name="description"
              onChange={handleCustomTextAreaChange}
              rows={4}
            />
          </Form>
        </StyledWrapper>
      )}
    </Modal>
  )
}

export default ExpenditureDetailsModal
