import { IProductNoteDetail } from 'interfaces/quoteResult.interface'
import eventEmitter from './eventEmitter'

export interface IData {
  header?: string
  body?: string | IProductNoteDetail[]
  _id?: string
  isEdit?: boolean
  userId?: string
}

export interface ICallback {
  confirm: () => void
}

class EventManager {
  openConfirmModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('confirm-modal', data, cb)
  }

  closeConfirmModal() {
    return eventEmitter.emit('confirm-modal')
  }

  openConfirmDeleteModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('confirm-delete-modal', data, cb)
  }

  closeConfirmDeleteModal() {
    return eventEmitter.emit('confirm-delete-modal')
  }

  openEditAssetModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('edit-asset-modal', data, cb)
  }

  closeEditAssetModal() {
    return eventEmitter.emit('edit-asset-modal')
  }

  openEditLiabilityModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('edit-liability-modal', data, cb)
  }

  closeEditLiabilityModal() {
    return eventEmitter.emit('edit-liability-modal')
  }

  openPersonPresentDuringFactFindModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('add-person-present-during-fact-find-modal', data, cb)
  }

  closePersonPresentDuringFactFindModal() {
    return eventEmitter.emit('add-person-present-during-fact-find-modal')
  }

  openPreviousAddressHistoryModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('previous-address-history-modal', data, cb)
  }

  closePreviousAddressHistoryModal() {
    return eventEmitter.emit('previous-address-history-modal')
  }

  openOtherResidentModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('other-resident-modal', data, cb)
  }

  closeOtherResidentModal() {
    return eventEmitter.emit('other-resident-modal')
  }

  openOccupantModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('occupant-modal', data, cb)
  }

  closeOccupantModal() {
    return eventEmitter.emit('occupant-modal')
  }

  openIncomeSourceModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('income-source-modal', data, cb)
  }

  closeIncomeSourceModal() {
    return eventEmitter.emit('income-source-modal')
  }

  openFuturePensionModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('future-pension-modal', data, cb)
  }

  closeFuturePensionModal() {
    return eventEmitter.emit('future-pension-modal')
  }

  openBenefitReceivingModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('receiving-benefit-modal', data, cb)
  }

  closeBenefitReceivingModal() {
    return eventEmitter.emit('receiving-benefit-modal')
  }

  openEligibleBenefitModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('eligible-benefit-modal', data, cb)
  }

  closeEligibleBenefitModal() {
    return eventEmitter.emit('eligible-benefit-modal')
  }

  openObjectiveModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('objective-modal', data, cb)
  }

  closeObjectiveModal() {
    return eventEmitter.emit('objective-modal')
  }

  openBeneficiaryModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('beneficiary-modal', data, cb)
  }

  closeBeneficiaryModal() {
    return eventEmitter.emit('beneficiary-modal')
  }

  openDependentModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('dependent-modal', data, cb)
  }

  closeDependentModal() {
    return eventEmitter.emit('dependent-modal')
  }

  openRecipientModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('recipient-modal', data, cb)
  }

  closeRecipientModal() {
    return eventEmitter.emit('recipient-modal')
  }

  openExpenditureModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('expenditure-modal', data, cb)
  }

  closeExpenditureModal() {
    return eventEmitter.emit('expenditure-modal')
  }

  openQuoteResultNoteModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('quote-result-note-modal', data, cb)
  }

  closeQuoteResultNoteModal() {
    return eventEmitter.emit('quote-result-note-modal')
  }

  openFileUploadModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('file-upload-modal', data, cb)
  }

  closeFileUploadModal() {
    return eventEmitter.emit('file-upload-modal')
  }

  openUserFileUploadModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('user-file-upload-modal', data, cb)
  }

  closeUserFileUploadModal() {
    return eventEmitter.emit('user-file-upload-modal')
  }

  openIressDetailsModal(data: IData, cb: ICallback) {
    return eventEmitter.emit('iress-details-modal', data, cb)
  }

  closeIressDetailsModal() {
    return eventEmitter.emit('iress-details-modal')
  }

  addListener(eventType: string, listener: any, context?: any) {
    return eventEmitter.addListener(eventType, listener, context)
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new EventManager()
