import { DeleteIcon, EditIcon } from 'assets/images';
import {
  ButtonsContainer,
  StyledIcon,
  StyledValue,
} from './EditDeleteButton.styles';
import { TEditDeleteButton } from './EditDeleteButton.types';

const EditDeleteButton = ({ type, value, onClick }: TEditDeleteButton) => {
  return (
    <ButtonsContainer onClick={onClick}>
      <StyledIcon src={type === 'edit' ? EditIcon : DeleteIcon} />
      <StyledValue type={type}>{value}</StyledValue>
    </ButtonsContainer>
  );
};

export default EditDeleteButton;
