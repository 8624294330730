import { Form, Modal } from 'antd'

import { StyledWrapper, Styled2x2Grid } from './EditAssetModal.styles'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import TextInput from 'components/CustomFormItem/CustomFormItem'
import CustomTextArea from 'components/CustomTextArea/CustomTextArea'
import { assetTypeOptions } from './EditAssetModal.helper'
import useEditAssetModal from 'hooks/clients/Modals/useEditAssetModal'
import Loading from 'components/Loading/Loading'
import { PoundIcon } from 'assets/images'
import CustomFormItem from 'components/CustomFormItem/CustomFormItem'

const EditAssetModal = () => {
  const {
    modalState,
    handleCancelClick,
    handleConfirmClick,
    formData,
    handleCustomSelectChange,
    handleAntdFormInputChange,
    handleCustomTextAreaChange,
    loading,
    form,
    customersOptions,
  } = useEditAssetModal()

  if (loading) {
    return <Loading />
  }

  return (
    <Modal
      title="Edit Asset"
      open={modalState.isOpen}
      onOk={handleConfirmClick}
      onCancel={handleCancelClick}
      width={700}
      okText="Save"
      cancelText="Cancel"
      closable={false}
      className="edit-asset-modal"
      confirmLoading={modalState.loading}
    >
      {modalState.loading ? (
        <Loading />
      ) : (
        <StyledWrapper>
          <Form
            form={form}
            layout="vertical"
            size="large"
            name="basic"
            initialValues={formData}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
              marginTop: '8px',
              width: '100%',
            }}
          >
            <Styled2x2Grid>
              <CustomFormItem
                label="Select Customer *"
                width={'100%'}
                placeholder="Select Customer *"
                onChange={handleCustomSelectChange}
                options={customersOptions}
                name="assetOwner"
                rules={['isRequired']}
                type="select"
              />
              <CustomSelect
                label="Type of Asset *"
                width={'100%'}
                placeholder="Type of Asset *"
                defaultValue={formData.assetType}
                onChange={handleCustomSelectChange}
                options={assetTypeOptions}
                name="assetType"
              />
            </Styled2x2Grid>
            <TextInput
              label="Value of Asset *"
              name="assetValue"
              placeholder="Value of Asset"
              inputPrefixImg={PoundIcon}
              prefixImgHeight={12}
              onChange={handleAntdFormInputChange}
              type="number"
            />
            <CustomTextArea
              label="Description"
              placeholder="Description"
              value={formData.description}
              name="description"
              onChange={handleCustomTextAreaChange}
              rows={4}
            />
          </Form>
        </StyledWrapper>
      )}
    </Modal>
  )
}

export default EditAssetModal
