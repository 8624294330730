import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from 'components/Layout'
import Loading from 'components/Loading'
import MainContentContainer from 'components/MainContentContainer'
import CustomBreadcrumb from 'components/CustomBreadcrumb'
import { useBreadcrumb, useNewsAndTrainingSubPage } from 'hooks/resources'
import { options } from './NewsAndTrainingSubPage.helper'
import { StyledType } from '../NewsAndTraining.styles'

const NewsAndTrainingSubPage = () => {
  const { data, displayLoading } = useNewsAndTrainingSubPage()
  const { breadcrumbItems, handleBreadcrumbTabChange } = useBreadcrumb()
  const typeColor = data.type === 'News' ? '#A5C2DD' : data.type === 'Training' ? '#E562DD' : '#6C6F73'

  return (
    <Layout>
      <MainContentContainer pageName={data.title || ''}>
        <CustomBreadcrumb breadcrumbItems={breadcrumbItems} handleBreadcrumbTabChange={handleBreadcrumbTabChange} />
        {displayLoading ? (
          <Loading />
        ) : (
          <>
            <StyledType color={typeColor}>{data.type}</StyledType> <div>{documentToReactComponents(data.content, options)}</div>
          </>
        )}
      </MainContentContainer>
    </Layout>
  )
}

export default NewsAndTrainingSubPage
