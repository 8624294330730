import { useLayoutEffect } from 'react'

export const useScrollLock = (isLocked: boolean): void => {
  useLayoutEffect(() => {
    if (isLocked) {
      document.documentElement.style.overflow = 'hidden'
    }

    return () => {
      document.documentElement.style.overflow = 'initial'
    }
  }, [isLocked])
}

export default useScrollLock
