import Form from 'antd/lib/form'

import CustomFormItem from 'components/CustomFormItem/CustomFormItem'
import Loading from 'components/Loading'
import useLiabilities from 'hooks/clients/DetailedForms/useLiabilities'
import CustomFooter from 'pages/ClientDetails/CustomFooter/CustomFooter'

import { liabilitiesTableColumns } from './Liabilities.helper'
import { StyledContainer, StyledDescription, StyledTitle, StyledTitleContainer, StyledRequiredMsg } from './Liabilities.styles'
import FormTable from 'components/FormTable/FormTable'
import { yesNoOptions } from 'utils/commonOptions'

const Liabilities = () => {
  const {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    handleAntdFormItemChange,
    handleAddButton,
    liabilitiesDataSource,
    liabilitiesTotalValue,
    setSelectedRows,
    displayDeleteButton,
    handleDeleteModal,
    displayEditButton,
    handleEditModal,
    tableError,
  } = useLiabilities()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <StyledTitle>Liabilities</StyledTitle>
        <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
      </StyledTitleContainer>
      <StyledDescription>
        Here is where you can enter information relating to your customer’s liabilities including other mortgages, credit or store cards, hire purchase
        agreements, overdrafts and any other liabilities. You can enter multiple liabilities by using the 'Add New Liability' button.
      </StyledDescription>
      <Form
        layout="vertical"
        size="large"
        name="basic"
        initialValues={formData}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '12px',
          marginTop: '8px',
          width: '100%',
        }}
        onFinishFailed={onFinishFailed}
        onFinish={onSubmit}
      >
        <CustomFormItem
          label="Do you have any liabilities? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          value={formData.hasLiabilities}
          name="hasLiabilities"
          type="radio"
          rules={['isRequired']}
        />
        {formData.hasLiabilities && (
          <>
            <FormTable
              columns={liabilitiesTableColumns}
              dataSource={liabilitiesDataSource}
              title="Liabilities"
              addButtonText="Add New Liability"
              handleAddButton={handleAddButton}
              total={liabilitiesTotalValue}
              setSelectedRows={setSelectedRows}
              displayDeleteButton={displayDeleteButton}
              handleDeleteModal={handleDeleteModal}
              displayEditButton={displayEditButton}
              handleEditModal={handleEditModal}
              errorMessage={tableError}
            />
            <CustomFormItem
              label="Are any un-secured debts being repaid with the advance being raised?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              value={formData.isSecuredDebtsRepaidWithAdvance}
              name="isSecuredDebtsRepaidWithAdvance"
              type="radio"
            />
            {formData.isSecuredDebtsRepaidWithAdvance && (
              <CustomFormItem
                label="Is there less than 2 years left remaining on these debts being consolidated? *"
                onChange={handleAntdFormItemChange}
                options={yesNoOptions}
                value={formData.lessThanTwoYearsBeforeDebtsConsolidation}
                name="lessThanTwoYearsBeforeDebtsConsolidation"
                type="radio"
              />
            )}
          </>
        )}
        <CustomFooter loading={loading} setSaveType={setSaveType} />
      </Form>
    </StyledContainer>
  )
}

export default Liabilities
