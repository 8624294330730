import { useEffect, useState } from 'react'
import message from 'antd/lib/message'
import * as _ from 'lodash'
import { Form } from 'antd'

import { commonHelper } from 'helpers'
import { commonUtils } from 'utils'

import useGeneral from './useGeneral'
import { useGetCustomers, useGetHealthInfo } from 'services/clients/clients.service'
import { addEditHealthPayloadMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { AddEditHealthPayload } from 'services/shared/mutation.payload'

const useHealth = () => {
  const [form] = Form.useForm()
  const addEditHealth = useCustomMutation<AddEditHealthPayload>({ mutation: addEditHealthPayloadMutation, refetchQueries: ['getClientFormsProgress'] })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { data, loading: formInitialDataLoading } = useGetHealthInfo(idNumber)
  const [showAddressDetails, setShowAddressDetails] = useState(false)
  const [loading, setLoading] = useState(formInitialDataLoading)
  const [formData, setFormData] = useState({
    customerFirst: {
      height: {
        feet: data?.health?.height?.feet,
        inches: data?.health?.height?.inches,
      },
      weight: {
        stone: data?.health?.weight?.stone,
        pound: data?.health?.weight?.pound,
      },
      isSmoking: data?.health?.isSmoking,
      smokingInfo: data?.health?.smokingInfo,
      averageAlcoholConsumptionPerWeek: data?.health?.averageAlcoholConsumptionPerWeek,
      hasDiagnosedWithAnyIllness: data?.health?.hasDiagnosedWithAnyIllness,
      diagnosedWith: data?.health?.diagnosedWith,
      hasAlterationToTypicalLifeExpectancy: data?.health?.hasAlterationToTypicalLifeExpectancy,
      alterationToTypicalLifeExpectancy: data?.health?.alterationToTypicalLifeExpectancy,
      doctorName: data?.health?.doctorName,
      surgeryName: data?.health?.surgeryName,
      surgeryContactNumber: data?.health?.surgeryContactNumber,
      surgeryAddress: {
        formattedAddress: data?.health?.surgeryAddress?.formattedAddress,
        address1: data?.health?.surgeryAddress?.address1,
        address2: data?.health?.surgeryAddress?.address2,
        town: data?.health?.surgeryAddress?.town,
        county: data?.health?.surgeryAddress?.county,
        country: data?.health?.surgeryAddress?.country,
        postcode: data?.health?.surgeryAddress?.postcode,
      },
      healthIssues: data?.health?.healthIssues,
      lifeEvents: data?.health?.lifeEvents,
      resilience: data?.health?.resilience,
      capability: data?.health?.capability,
      vulnerabilityDetails: data?.health?.vulnerabilityDetails,
      vulnerabilityDoNotApply: data?.health?.vulnerabilityDoNotApply,
      understandsLoanReducesAssetValue: data?.health?.understandsLoanReducesAssetValue,
      hasCoercionOrUndueInfluence: data?.health?.hasCoercionOrUndueInfluence,
      attitudeTowardsClientVulnerability: data?.health?.attitudeTowardsClientVulnerability,
    },
    customerSecond: {
      height: {
        feet: data?.customerSecondHealth?.height?.feet,
        inches: data?.customerSecondHealth?.height?.inches,
      },
      weight: {
        stone: data?.health?.weight?.stone,
        pound: data?.health?.weight?.pound,
      },
      isSmoking: data?.customerSecondHealth?.isSmoking,
      smokingInfo: data?.customerSecondHealth?.smokingInfo,
      averageAlcoholConsumptionPerWeek: data?.customerSecondHealth?.averageAlcoholConsumptionPerWeek,
      hasDiagnosedWithAnyIllness: data?.customerSecondHealth?.hasDiagnosedWithAnyIllness,
      diagnosedWith: data?.customerSecondHealth?.diagnosedWith,
      hasAlterationToTypicalLifeExpectancy: data?.customerSecondHealth?.hasAlterationToTypicalLifeExpectancy,
      alterationToTypicalLifeExpectancy: data?.customerSecondHealth?.alterationToTypicalLifeExpectancy,
      doctorName: data?.customerSecondHealth?.doctorName,
      surgeryName: data?.customerSecondHealth?.surgeryName,
      surgeryContactNumber: data?.customerSecondHealth?.surgeryContactNumber,
      surgeryAddress: {
        formattedAddress: data?.customerSecondHealth?.surgeryAddress?.formattedAddress,
        address1: data?.customerSecondHealth?.surgeryAddress?.address1,
        address2: data?.customerSecondHealth?.surgeryAddress?.address2,
        town: data?.customerSecondHealth?.surgeryAddress?.town,
        county: data?.customerSecondHealth?.surgeryAddress?.county,
        country: data?.customerSecondHealth?.surgeryAddress?.country,
        postcode: data?.customerSecondHealth?.surgeryAddress?.postcode,
      },
      healthIssues: data?.customerSecondHealth?.healthIssues,
      lifeEvents: data?.customerSecondHealth?.lifeEvents,
      resilience: data?.customerSecondHealth?.resilience,
      capability: data?.customerSecondHealth?.capability,
      vulnerabilityDetails: data?.customerSecondHealth?.vulnerabilityDetails,
      vulnerabilityDoNotApply: data?.customerSecondHealth?.vulnerabilityDoNotApply,
      understandsLoanReducesAssetValue: data?.customerSecondHealth?.understandsLoanReducesAssetValue,
      hasCoercionOrUndueInfluence: data?.customerSecondHealth?.hasCoercionOrUndueInfluence,
      attitudeTowardsClientVulnerability: data?.customerSecondHealth?.attitudeTowardsClientVulnerability,
    },
  })
  const flattenedFormData = commonUtils.flattenObject(formData)
  const { data: customers } = useGetCustomers(idNumber)

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [loading])

  useEffect(() => {
    setLoading(formInitialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(data)
    delete newData._id
    delete newData?.customerFirst?._id
    delete newData?.customerSecond?._id
    setFormData({
      customerFirst: {
        ...newData.health,
      },
      customerSecond: {
        ...newData.customerSecondHealth,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInitialDataLoading])

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value, e.target?.name)
    setFormData((prevState) => _.set(_.cloneDeep(prevState), e.target?.name, parsedValue))
  }

  const handleCustomSelectChange = (name: string, value: string) => {
    setFormData((prevState) => _.set(_.cloneDeep(prevState), name, value))
  }
  const handleCustomCheckbox = ({ e, field }: { e: any; field: string }) => {
    setFormData((prevState) => _.set(_.cloneDeep(prevState), field, e.target.checked))
  }

  const onPlaceSelectForCustomerFirst = ({ formattedAddress, address1, address2, town, county, country, postcode }: any) => {
    setFormData((prevState) => ({
      ...prevState,
      customerFirst: {
        ...prevState.customerFirst,
        surgeryAddress: {
          formattedAddress,
          address1,
          address2,
          town,
          county,
          country,
          postcode,
        },
      },
    }))

    form.setFieldValue('customerFirst.surgeryAddress.formattedAddress', formattedAddress)
    form.setFieldValue('customerFirst.surgeryAddress.address1', address1)
    form.setFieldValue('customerFirst.surgeryAddress.address2', address2)
    form.setFieldValue('customerFirst.surgeryAddress.town', town)
    form.setFieldValue('customerFirst.surgeryAddress.county', county)
    form.setFieldValue('customerFirst.surgeryAddress.country', country)
    form.setFieldValue('customerFirst.surgeryAddress.postcode', postcode)
  }

  const onPlaceSelectForCustomerSecond = ({ formattedAddress, address1, address2, town, county, country, postcode }: any) => {
    setFormData((prevState) => ({
      ...prevState,
      customerSecond: {
        ...prevState.customerFirst,
        surgeryAddress: {
          formattedAddress,
          address1,
          address2,
          town,
          county,
          country,
          postcode,
        },
      },
    }))

    form.setFieldValue('customerSecond.surgeryAddress.formattedAddress', formattedAddress)
    form.setFieldValue('customerSecond.surgeryAddress.address1', address1)
    form.setFieldValue('customerSecond.surgeryAddress.address2', address2)
    form.setFieldValue('customerSecond.surgeryAddress.town', town)
    form.setFieldValue('customerSecond.surgeryAddress.county', county)
    form.setFieldValue('customerSecond.surgeryAddress.country', country)
    form.setFieldValue('customerSecond.surgeryAddress.postcode', postcode)
  }

  const showAddressDetailsChange = (checked: boolean) => {
    setShowAddressDetails(checked)
  }

  const handleSubmit = async () => {
    const { errors } = await addEditHealth({
      idNumber,
      values: {
        customerFirst: {
          ...formData.customerFirst,
          surgeryContactNumber: String(formData.customerFirst.surgeryContactNumber),
        },
        customerSecond: {
          ...formData.customerSecond,
          surgeryContactNumber: String(formData.customerSecond.surgeryContactNumber),
        },
      },
    })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const { handleSaveAndGoBack, handleSave, handleSaveAndContinue } = useGeneral({ handleSubmit, setLoading })

  const [saveType, setSaveType] = useState<'save' | 'saveAndGoBack' | 'saveAndContinue'>('save')

  const onSubmit = () => {
    if (saveType === 'saveAndGoBack') {
      handleSaveAndGoBack()
    } else if (saveType === 'saveAndContinue') {
      handleSaveAndContinue()
    } else if (saveType === 'save') {
      handleSave()
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill out every required field')
  }

  const displayVulnerabilityDetailsForCustomerFirst =
    formData?.customerFirst.healthIssues?.length > 0 ||
    formData?.customerFirst.lifeEvents?.length > 0 ||
    formData?.customerFirst.resilience?.length > 0 ||
    formData?.customerFirst.healthIssues?.capability > 0

  const displayVulnerabilityDetailsForCustomerSecond =
    formData?.customerSecond.healthIssues?.length > 0 ||
    formData?.customerSecond.lifeEvents?.length > 0 ||
    formData?.customerSecond.resilience?.length > 0 ||
    formData?.customerSecond.healthIssues?.capability > 0

  return {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    flattenedFormData,
    handleAntdFormItemChange,
    handleCustomSelectChange,
    handleCustomCheckbox,
    onPlaceSelectForCustomerFirst,
    onPlaceSelectForCustomerSecond,
    showAddressDetails,
    showAddressDetailsChange,
    displayVulnerabilityDetailsForCustomerFirst,
    displayVulnerabilityDetailsForCustomerSecond,
    customers,
    form,
  }
}

export default useHealth
