import { useEffect, useState } from 'react'
import message from 'antd/lib/message'

import { initialJourneyFormData, initialTrackingRecommendFormData } from './staticData'
import useTabsGeneral from './useTabsGeneral'
import { useGetClientJourney } from 'services/clients/clients.service'
import { commonHelper } from 'helpers'
import { commonUtils } from 'utils'
import { addEditClientJourneyMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { AddClientJourneyPayload } from 'services/shared/mutation.payload'

const useJourneyTabContent = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const addEditClientJourney = useCustomMutation<AddClientJourneyPayload>({ mutation: addEditClientJourneyMutation })
  const { data, loading: initialDataLoading } = useGetClientJourney(idNumber)
  const [loading, setLoading] = useState(initialDataLoading)
  const [journeyFormData, setJourneyFormData] = useState<any>(initialJourneyFormData)
  const [trackingRecommendFormData, setTrackingRecommendFormData] = useState<any>(initialTrackingRecommendFormData)

  useEffect(() => {
    setLoading(initialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(data)
    const fetchedTrackingRecommendFormData = {
      recommendedLender: newData.recommendedLender,
      recommendedPlan: newData.recommendedPlan,
      recommendedSolicitor: newData.recommendedSolicitor,
      referredBy: newData.referredBy,
    }

    setTrackingRecommendFormData(fetchedTrackingRecommendFormData)

    delete newData.recommendedLender
    delete newData.recommendedPlan
    delete newData.recommendedSolicitor
    delete newData.referredBy

    setJourneyFormData({ ...newData })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDataLoading])

  const handleJourneyFormFieldChange = (e: any) => {
    setJourneyFormData((prevState: any) => ({
      ...prevState,
      [e.target.name]: {
        ...prevState[e.target.name],
        value: e.target.value,
      },
    }))
  }

  const handleCheckboxChange = (e: any) => {
    let newValue: boolean | null = null

    const key = e.target.name.split('_')[0]
    const yesNoField = e.target.name.split('_')[1]

    if (yesNoField === 'Yes' && e.target.checked) {
      newValue = true
    } else if (yesNoField === 'No' && e.target.checked) {
      newValue = false
    }

    setJourneyFormData((prevState: any) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        copyOnFile: newValue,
      },
    }))
  }

  const handleTrackingRecommendFormFieldChange = (e: any) => {
    setTrackingRecommendFormData((prevState: any) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const formInitialValues: any = {}

  const keys = Object.keys(journeyFormData)
  // eslint-disable-next-line array-callback-return
  keys.map((key: string) => {
    formInitialValues[key] = journeyFormData[key].value
  })

  const handleSubmit = async () => {
    const { errors } = await addEditClientJourney({
      idNumber,
      values: {
        ...journeyFormData,
        ...trackingRecommendFormData,
      },
    })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const { handleSaveAndGoBack, handleSave, handleSaveAndContinue } = useTabsGeneral({ handleSubmit, setLoading })

  const [saveType, setSaveType] = useState<'save' | 'saveAndGoBack' | 'saveAndContinue'>('save')

  const onSubmit = () => {
    if (saveType === 'saveAndGoBack') {
      handleSaveAndGoBack()
    } else if (saveType === 'saveAndContinue') {
      handleSaveAndContinue()
    } else if (saveType === 'save') {
      handleSave()
    }
  }

  return {
    loading,
    setSaveType,
    onSubmit,
    handleJourneyFormFieldChange,
    journeyFormData,
    handleCheckboxChange,
    handleTrackingRecommendFormFieldChange,
    trackingRecommendFormData,
    formInitialValues,
    keys,
  }
}

export default useJourneyTabContent
