export const expenditureTypeOptions = [
  {
    label: 'Total IVAs/Fines/CCJ payments',
    value: 'totalIVAsFinesCCJPayments',
  },
  {
    label: 'Total Alimony/Child maintenance payments',
    value: 'totalAlimonyChildMaintenancePayments',
  },
  {
    label: 'Total Savings payments',
    value: 'totalSavingsPayments',
  },
  {
    label: 'Service charge or ground rent',
    value: 'serviceChargeOrGroundRent',
  },
  {
    label: 'Water rates',
    value: 'waterRates',
  },
  {
    label: 'Council tax',
    value: 'councilTax',
  },
  {
    label: 'Electricity/Other heating/Gas',
    value: 'electricityOtherHeatingGas',
  },
  {
    label: 'Buildings/Contents insurance',
    value: 'buildingsContentsInsurance',
  },
  {
    label: 'Telephone/Mobile/Internet',
    value: 'telephoneMobileInternet',
  },
  {
    label: 'House keeping (food and washing)',
    value: 'houseKeepingFoodAndWashing',
  },
  {
    label: 'Fuel/Essential travel/Public transport (if commuting to workplace)',
    value: 'fuelEssentialTravelPublicTransportIfCommutingToWorkplace',
  },
  {
    label: 'Insurances policies (Car/Life/Medical/Pet)',
    value: 'insurancesPoliciesCarLifeMedicalPet',
  },
  {
    label: 'Household goods (Satellite/Appliance rental/TV licence) Furniture/ Repairs and maintenance',
    value: 'householdGoodsSatelliteApplianceRentalTVLicenceFurnitureRepairsAndMaintenance',
  },
  {
    label: 'Personal goods (Toiletries/Clothes)',
    value: 'personalGoodsToiletriesClothes',
  },
  {
    label: 'Childcare',
    value: 'childcare',
  },
  {
    label: 'Holidays and leisure',
    value: 'holidaysAndLeisure',
  },
  {
    label: 'Other Living Expenditure',
    value: 'otherLivingExpenditure',
  },
]

export const whenWillExpenditureChangeOptions = [
  {
    label: 'Within 12 months',
    value: 'within12months',
  },
  {
    label: '1-2 years',
    value: 'OneToTwoYears',
  },
  {
    label: '2-3 years',
    value: 'TwoToThreeYears',
  },
  {
    label: '3-5 years',
    value: 'ThreeToFiveYears',
  },
  {
    label: '5-10 years',
    value: 'FiveToTenYears',
  },
  {
    label: '10-15 years',
    value: 'TenToFifteenYears',
  },
  {
    label: 'After 15 years',
    value: 'AfterFifteenYears',
  },
]
