import { BrowserRouter as ReactRouter, Routes, Route } from 'react-router-dom'

import Login from 'pages/Login'
import SignUp from 'pages/SignUp'
import Dashboard from 'pages/Dashboard'
import Clients from 'pages/Clients'
import Learning from 'pages/Learning'
import Users from 'pages/Users'
import Companies from 'pages/Companies'
import Resources from 'pages/Resources'
import LenderDirectory from 'pages/Resources/SubPages/LenderDirectory'
import ProcFees from 'pages/Resources/SubPages/ProcFees'
import Solicitors from 'pages/Resources/SubPages/Solicitors'
import ServicesAndWills from 'pages/Resources/SubPages/ServicesAndWills'
import NewsAndTraining from 'pages/Resources/SubPages/NewsAndTraining'
import RateChanges from 'pages/Resources/SubPages/RateChanges'
import FCAGuidelines from 'pages/Resources/SubPages/FCAGuidelines'
import ComplianceSupport from 'pages/Resources/SubPages/ComplianceSupport'

import ProtectedRoute from './ProtectedRoute'
import UnProtectedRoute from './UnProtectedRoute'
import ClientDetails from 'pages/ClientDetails'
import UserProfile from 'pages/UserProfile'
import QuickSearch from 'pages/QuickSearch/QuickSearch'
import QuoteResult from 'pages/QuoteResult/QuoteResult'
import QuoteDetails from 'pages/QuoteDetails/QuoteDetails'
import NewsAndTrainingSubPage from 'pages/Resources/SubPages/NewsAndTraining/NewsAndTrainingSubPage'
import QuoteSearchForm from 'pages/QuoteSearchForm/QuoteSearchForm'
import LenderCriteria from 'pages/LenderCriteria'
import ForgetPassword from 'pages/ForgetPassword'
import ResetPassword from 'pages/ResetPassword'
import ViewPdf from 'pages/ViewPdf/ViewPdf'
import VideoResources from 'pages/Resources/SubPages/VideoResources'

const Router = () => (
  <ReactRouter>
    <Routes>
      {/* UnProtected Routes */}
      <Route
        path="/"
        element={
          <UnProtectedRoute>
            <Login />
          </UnProtectedRoute>
        }
      />
      <Route
        path="/signup"
        element={
          <UnProtectedRoute>
            <SignUp />
          </UnProtectedRoute>
        }
      />
      <Route
        path="/forget-password"
        element={
          <UnProtectedRoute>
            <ForgetPassword />
          </UnProtectedRoute>
        }
      />
      <Route
        path="/reset-password"
        element={
          <UnProtectedRoute>
            <ResetPassword />
          </UnProtectedRoute>
        }
      />
      {/* Protected Routes */}
      <Route
        path="/test-quote-search"
        element={
          <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
            <QuoteSearchForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="/clients"
        element={
          <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
            <Clients />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/client-details"
        element={
          <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
            <ClientDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/view-pdf"
        element={
          <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
            <ViewPdf />
          </ProtectedRoute>
        }
      />
      <Route
        path="/learning"
        element={
          <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
            <Learning />
          </ProtectedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedRoute roleAccess={['superAdmin']}>
            <Users />
          </ProtectedRoute>
        }
      />
      {/* <Route
        path="/user-profile"
        element={
          <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
            <UserProfile />
          </ProtectedRoute>
        }
      /> */}
      <Route
        path="/user-profile/:userId"
        element={
          <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
            <UserProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/companies"
        element={
          <ProtectedRoute roleAccess={['superAdmin']}>
            <Companies />
          </ProtectedRoute>
        }
      />
      <Route path="/resources">
        <Route
          index
          element={
            <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
              <Resources />
            </ProtectedRoute>
          }
        />
        <Route
          path="/resources/lender-directory"
          element={
            <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
              <LenderDirectory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/resources/proc-fees"
          element={
            <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
              <ProcFees />
            </ProtectedRoute>
          }
        />
        <Route
          path="/resources/solicitors"
          element={
            <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
              <Solicitors />
            </ProtectedRoute>
          }
        />
        <Route
          path="/resources/lpa-services-wills"
          element={
            <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
              <ServicesAndWills />
            </ProtectedRoute>
          }
        />
        <Route
          path="/resources/news-and-training"
          element={
            <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
              <NewsAndTraining />
            </ProtectedRoute>
          }
        />
        <Route
          path="/resources/news-and-training/:title"
          element={
            <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
              <NewsAndTrainingSubPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/resources/rate-changes"
          element={
            <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
              <RateChanges />
            </ProtectedRoute>
          }
        />
        <Route
          path="/resources/fca-guidelines-equity-release-council"
          element={
            <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
              <FCAGuidelines />
            </ProtectedRoute>
          }
        />
        <Route
          path="/resources/compliance-support"
          element={
            <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
              <ComplianceSupport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/resources/video-resources"
          element={
            <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
              <VideoResources />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="/quick-search"
        element={
          <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
            <QuickSearch />
          </ProtectedRoute>
        }
      />
      <Route
        path="/quote-result"
        element={
          <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
            <QuoteResult />
          </ProtectedRoute>
        }
      />
      <Route
        path="/quote-details"
        element={
          <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
            <QuoteDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/lenders-criteria"
        element={
          <ProtectedRoute roleAccess={['user', 'companyUser', 'superAdmin']}>
            <LenderCriteria />
          </ProtectedRoute>
        }
      />
    </Routes>
  </ReactRouter>
)
export default Router
