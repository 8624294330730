import styled from 'styled-components';

export const StyledFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledSelectorContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

export const StyledSelectorName = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #120224;
`;
