import styled from 'styled-components'

export const StyledContainer = styled.div`
  background-color: #fefefe;

  display: flex;
  justify-content: center;
  align-items: flex-start;
`

export const StyledFormContainer = styled.div`
  padding: 36px 32px;

  width: 936px;
`

export const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  
  color: #120224;
`

export const StyledRequiredMsg = styled.p`
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1.5px;
  text-transform: uppercase;

  color: #b3b3b3;
`
