import { useContext } from 'react'
import { Layout, theme, Dropdown, Form } from 'antd'
import Avatar from '@mui/material/Avatar'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import TextInput from 'components/CustomFormItem'
import { LayoutContext } from 'contexts'
import { Search } from 'assets/images'
import { useGetUserImageFromS3 } from 'services/auth/auth.service'

import {
  StyledLink,
  StyledDropDownMenuWrapper,
  StyledMenu,
  StyledButtonWrapper,
  StyledDropDownLink,
  StyledDropdownBtn,
  StyledUserName,
  StyledIRESSVerifiedStatusWrapper,
  StyledIRESSVerificationStatus,
} from './Header.styles'
import { HeaderTypes } from './Header.types'
import { eventManager } from 'utils'

const { Header } = Layout

const CustomHeader = ({ user, handleLogout, headerCustomComponent }: HeaderTypes) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const { sideBarWidth } = useContext(LayoutContext)

  const { url } = useGetUserImageFromS3({
    filePath: user?.avatar,
  })

  const { firstName, isIressCredentialsVerified } = user

  const openIRESSDetailsModal = () => {
    eventManager.openIressDetailsModal(
      {},
      {
        confirm: () => null,
      },
    )
  }

  const menu = (
    <StyledMenu>
      <Avatar
        src={url}
        style={{
          width: '32px',
          height: '32px',
          borderRadius: '4px',
        }}
      />
      <br />
      <h1>{firstName}</h1>
      <StyledButtonWrapper>
        <StyledLink to={`/user-profile/${user._id}`}>
          <StyledDropdownBtn>Profile</StyledDropdownBtn>
        </StyledLink>
        <StyledDropdownBtn onClick={handleLogout}>Log out</StyledDropdownBtn>
      </StyledButtonWrapper>
    </StyledMenu>
  )

  return (
    <Header
      style={{
        height: '80px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 96px',
        background: colorBgContainer,
        borderBottom: '1px solid #A8D6EA',
        position: 'fixed',
        width: `calc(100% - ${sideBarWidth}px)`,
        zIndex: 2,
      }}
    >
      {!!headerCustomComponent ? (
        headerCustomComponent
      ) : (
        <Form>
          <TextInput width={'343px'} name={'searchBar'} placeholder="Coming Soon..." inputPrefixImg={Search} />
        </Form>
      )}
      <StyledDropDownMenuWrapper>
        <StyledIRESSVerifiedStatusWrapper onClick={openIRESSDetailsModal}>
          IRESS Status:{' '}
          <StyledIRESSVerificationStatus verified={isIressCredentialsVerified}>
            {isIressCredentialsVerified ? 'Verified' : 'Not Verified'}
          </StyledIRESSVerificationStatus>
        </StyledIRESSVerifiedStatusWrapper>
        <Avatar
          src={url}
          style={{
            width: '32px',
            height: '32px',
            borderRadius: '4px',
          }}
        />
        <Dropdown overlay={menu} overlayStyle={{ position: 'fixed' }} trigger={['click']}>
          <StyledDropDownLink className="ant-dropdown-link" href="/" onClick={(e) => e.preventDefault()}>
            <StyledUserName className="hide-mobile">{firstName}</StyledUserName>
            <ExpandMoreIcon style={{ fill: '#323232' }} />
          </StyledDropDownLink>
        </Dropdown>
      </StyledDropDownMenuWrapper>
    </Header>
  )
}

export default CustomHeader
