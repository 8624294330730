import { CloseIcon } from 'assets/images'
import { StyledContainer, StyledHeader, StyledImg, StyledTitle, StyledMinContainer } from './ClientInfoLayout.styles'
import { ClientInfoLayoutProps } from './ClientInfoLayout.types'

const ClientInfoLayout = ({ children, title, handleDetailedFormClose }: ClientInfoLayoutProps) => {
  return (
    <StyledContainer>
      <StyledHeader>
        <StyledTitle>{title}</StyledTitle>
        {handleDetailedFormClose && <StyledImg src={CloseIcon} onClick={handleDetailedFormClose} />}
      </StyledHeader>
      <StyledMinContainer>{children}</StyledMinContainer>
    </StyledContainer>
  )
}

export default ClientInfoLayout
