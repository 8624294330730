import { useState, useEffect, useContext } from 'react'
import message from 'antd/lib/message'
import Form from 'antd/lib/form'

import { commonHelper } from 'helpers'

import { commonUtils, eventManager } from 'utils'
import { useCustomMutation } from 'services/shared/mutation'
import { EditUserIressDetailsPayloadFromModal } from 'services/shared/mutation.payload'
import { useGetUserIressDetails } from 'services/auth/auth.service'
import { editUserIressDetailsMutationFromModal } from 'gql/user'
import { AuthContext } from 'contexts'
import config from 'config'

const initialState = {
  isOpen: false,
  data: { _id: '', isEdit: false },
  cb: { confirm: () => null, cancel: () => null },
  loading: false,
}

const useIressDetailsModal = () => {
  const [form] = Form.useForm()
  const [modalState, setModalState] = useState(initialState)
  const editUserIressDetails = useCustomMutation<EditUserIressDetailsPayloadFromModal>({
    mutation: editUserIressDetailsMutationFromModal,
    refetchQueries: ['userIressDetailsQuery'],
  })
  const { user: userIressDetails, loading: formInitialDataLoading } = useGetUserIressDetails({
    skip: !modalState.isOpen,
  })
  const { user: userFromContext, refetch: refetchAuthUser } = useContext(AuthContext)

  const [loading, setLoading] = useState(formInitialDataLoading)
  const [formData, setFormData] = useState({
    email: userFromContext?.email,
    companyId: userFromContext?.companyId,
    iressLoginId: userIressDetails?.iressLoginId,
    iressPassword: userIressDetails?.iressPassword,
    iressUserId: userIressDetails?.iressUserId,
  })

  useEffect(() => {
    setLoading(formInitialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject({ ...userIressDetails, email: userFromContext?.email, companyId: userFromContext?.companyId })
    delete newData._id
    setFormData({ ...newData })
    form.setFieldsValue({ ...newData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInitialDataLoading])

  const clearFormData = () => {
    setFormData((prevState) => commonUtils.resetFormData(prevState))
    form.setFieldsValue(commonUtils.resetFormData(formData))
  }

  const handleAntdFormInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: string | number = e.target.value
    const name = e.target.name

    if (name === 'iressUserId') {
      value = Number(value)
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  useEffect(() => {
    eventManager.addListener('iress-details-modal', (data: any, cb: any) => {
      setModalState((state) => ({
        ...state,
        isOpen: !!cb,
        data,
        cb,
      }))
    })
  }, [setModalState])

  const handleClose = () => {
    setModalState((state) => ({ ...state, loading: false }))
    eventManager.closeIressDetailsModal()
  }

  const handleCancelClick = async () => {
    try {
      setModalState((state) => ({ ...state, loading: true }))
      if (modalState.cb.cancel) {
        await modalState.cb.cancel()
      }
    } catch (err) {
      // console.error(err)
    }
    handleClose()
    clearFormData()
  }

  const updateIressStatus = async () => {
    const response = await fetch(`${config.API_URL}/iress/check-iress-credentials`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
    const responseData = await response.text()
    const verified = JSON.parse(responseData)
    if (verified) {
      message.success('Successfully verified your Iress Account')
    } else {
      message.error('IRESS details verification failed. Please check your credentials and try again')
    }
  }

  const handleSubmit = async () => {
    const { errors } = await editUserIressDetails({ ...formData, _id: userFromContext?._id })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('IRESS details updated successfully')
    const hideInfoPopup = message.info('Verifying your Iress account - Please keep this screen open while we do this…', 0)
    await updateIressStatus()
    await refetchAuthUser()
    hideInfoPopup()
    eventManager.closeIressDetailsModal()
  }

  const handleConfirmClick = async () => {
    try {
      setModalState((state) => ({ ...state, loading: true }))
      await handleSubmit()
      clearFormData()
      if (modalState.cb.confirm) {
        await modalState.cb.confirm()
      }
    } catch (err) {
      // console.error(err)
    }
    setModalState((state) => ({ ...state, loading: false }))
    handleClose()
  }

  return { loading, modalState, handleCancelClick, handleConfirmClick, formData, form, handleAntdFormInputChange }
}

export default useIressDetailsModal
