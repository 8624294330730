import { useEffect, useState } from 'react'
import { message } from 'antd'
import { useParams } from 'react-router-dom'
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'

import { deleteUserNotesMutation, saveNoteMutation } from 'gql/user'
import { commonHelper } from 'helpers'
import { INewNote, INote } from 'pages/ClientDetails/DetailedForm/Forms/Notes/Notes.interface'
import { useGetUserNotes } from 'services/users/users.service'
import { useCustomMutation } from 'services/shared/mutation'
import { DeleteUserNotesPayload, SaveUserNotePayload } from 'services/shared/mutation.payload'
import { eventManager } from 'utils'

const currentDate = new Date().toISOString().split('T')[0]

const useUserNotes = () => {
  const { userId } = useParams()
  const deleteNotes = useCustomMutation<DeleteUserNotesPayload>({ mutation: deleteUserNotesMutation, refetchQueries: ['getUserNotes'] })
  const handleSaveNote = useCustomMutation<SaveUserNotePayload>({
    mutation: saveNoteMutation,
    refetchQueries: ['getUserNotes'],
  })
  const { notes: initialNotes, loading, refetch } = useGetUserNotes(userId as string)
  const [addingNewNote, setAddingNewNote] = useState(false)
  const [selectAllNotes, setSelectAllNotes] = useState(false)
  const [notes, setNotes] = useState<INote[]>([])
  const [newNote, setNewNote] = useState<INewNote>({
    description: '',
    title: '',
    createdAt: currentDate,
  })

  const displayEmptyNotesUI = notes.length === 0 && !addingNewNote && !loading

  useEffect(() => {
    setNotes(
      initialNotes.map((note: INote) => {
        const date = new Date(Number(note.createdAt))

        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0') // +1 because months are 0-indexed
        const day = date.getDate().toString().padStart(2, '0')

        const formattedDate = `${year}-${month}-${day}`
        return { ...note, selected: false, createdAt: formattedDate, edit: false, editable: false, delete: false }
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const addNote = () => {
    setAddingNewNote(true)
  }

  const cancelNewItemInsert = () => {
    setAddingNewNote(false)
    setNewNote({
      description: '',
      title: '',
      createdAt: currentDate,
    })
  }

  const cancelEdit = (_id: string) => {
    setNotes((prevNotes) => {
      setSelectAllNotes(false)
      refetch()
      const index = prevNotes.findIndex((note) => note._id === _id)
      const newArr = [...prevNotes]
      newArr[index].selected = false
      newArr[index].editable = false
      newArr[index].edit = false
      newArr[index].delete = false
      return newArr
    })
  }

  const selectAllNotesChange = (e: CheckboxChangeEvent) => {
    setSelectAllNotes(e.target.checked)
    setNotes((prevNotes) => {
      const newArr = [...prevNotes].map((note) => ({ ...note, selected: e.target.checked }))
      return newArr
    })
  }

  const selectNote = (e: CheckboxChangeEvent, _id: string) => {
    setNotes((prevNotes) => {
      const index = prevNotes.findIndex((note) => note._id === _id)
      const newArr = [...prevNotes]
      newArr[index].selected = e.target.checked
      newArr[index].edit = e.target.checked
      newArr[index].delete = e.target.checked

      if (e.target.checked) {
        newArr.every((note) => note.selected) && setSelectAllNotes(true)
      } else {
        newArr.every((note) => !note.selected) && setSelectAllNotes(false)
      }

      return newArr
    })
  }

  const handleCustomTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewNote((prevState: INewNote) => ({
      ...prevState,
      description: e.target.value,
    }))
  }

  const handleCustomFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewNote((prevState: INewNote) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const saveNote = async ({ _id }: { _id: string }) => {
    let notePayload: any = {}

    if (_id) {
      const note = notes.find((note) => note._id === _id)
      notePayload = {
        _id,
        title: note?.title,
        description: note?.description,
      }
    } else {
      notePayload = {
        ...newNote,
        _id,
      }
    }

    const { errors } = await handleSaveNote({
      userId: userId as string,
      note: notePayload,
    })
    setAddingNewNote(false)
    setNewNote({
      description: '',
      title: '',
      createdAt: currentDate,
    })
    if (errors?.length) {
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const handleDelete = async (_id?: string) => {
    let _ids: string[] = []

    if (_id) {
      // if a single note is checked, will remove that one.
      _ids = [_id]
    } else if (!_id && selectAllNotes) {
      // if all notes are checked, will remove all.
      notes.map((note) => _ids.push(note._id))
    }

    const { errors } = await deleteNotes({ _ids, userId: userId as string })
    if (errors?.length) {
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    setSelectAllNotes(false)
  }

  const handleDeleteModal = (_id?: string) => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleDelete(_id),
      },
    )
  }

  const handleEditClick = (_id: string) => {
    setNotes((prevNotes) => {
      const index = prevNotes.findIndex((note) => note._id === _id)
      const newArr = [...prevNotes]
      newArr[index].editable = true
      return newArr
    })
  }

  const handleCustomTextAreaChangeForEdit = (e: React.ChangeEvent<HTMLTextAreaElement>, _id: string) => {
    setNotes((prevState: INote[]) => {
      const index = prevState.findIndex((note) => note._id === _id)
      prevState[index].description = e.target.value
      return [...prevState]
    })
  }

  const handleCustomFormItemChangeForEdit = (e: React.ChangeEvent<HTMLInputElement>, _id: string) => {
    setNotes((prevState: INote[]) => {
      const index = prevState.findIndex((note) => note._id === _id)
      prevState[index].title = e.target.value

      return [...prevState]
    })
  }

  return {
    notes,
    addNote,
    displayEmptyNotesUI,
    addingNewNote,
    selectAllNotes,
    selectAllNotesChange,
    selectNote,
    newNote,
    cancelNewItemInsert,
    handleCustomTextAreaChange,
    handleCustomFormItemChange,
    saveNote,
    handleDeleteModal,
    handleEditClick,
    handleCustomTextAreaChangeForEdit,
    handleCustomFormItemChangeForEdit,
    cancelEdit,
  }
}

export default useUserNotes
