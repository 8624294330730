import { Switch } from 'antd'
import styled from 'styled-components'

export const StyledWrapper = styled.div`
  width: 100%;
  /* padding: 32px 16px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const StyledCheckBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  column-gap: 24px;
  margin-top: 28px;
`

export const StyledCheckBoxLabel = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #120224;
`

export const StyledCheckBox = styled(Switch)``

export const Styled2x2Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`
