import Form from 'antd/lib/form'

import CustomFormItem from 'components/CustomFormItem/CustomFormItem'
import Loading from 'components/Loading'
import FormTable from 'components/FormTable/FormTable'

import useExpenditure from 'hooks/clients/DetailedForms/useExpenditure'
import CustomFooter from 'pages/ClientDetails/CustomFooter/CustomFooter'
import { yesNoOptions } from 'utils/commonOptions'

import { expenditureTableColumns, liabilitiesTableColumns } from './Expenditure.helper'
import { StyledContainer, StyledDescription, StyledTitle, StyledTitleContainer, StyledRequiredMsg, StyledStatisticWrapper } from './Expenditure.styles'
import IncomeTable from 'components/IncomeTable'

const Expenditure = () => {
  const {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    handleAntdFormItemChange,
    liabilitiesDataSource,
    handleAddButton,
    expenditureDataSource,
    setSelectedRows,
    displayDeleteButton,
    handleDeleteModal,
    displayEditButton,
    handleEditModal,
    incomeTableDataSource,
    expenditureTableDataSource,
    disposableIncomeTableDataSource,
  } = useExpenditure()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <StyledTitle>Expenditure</StyledTitle>
        <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
      </StyledTitleContainer>
      <StyledDescription>
        This page is where you can enter current expenditure for your customers. This section is able to be skipped in order to get to the sourcing results
        page, however if you would like a Suitability Report for your customers, then you will need to ensure this section is completed. You can add new
        expenditure lines using the 'Add New Expenditure' button, and delete using the red bin button. You will notice your customers’ disposable income will
        calculate based on the figures entered on previous pages.
      </StyledDescription>
      <FormTable
        columns={liabilitiesTableColumns}
        dataSource={liabilitiesDataSource}
        title="Liabilities"
        description="Expenditure information will auto-populate here from main debt types such as loans and mortgages entered in the Liabilities section. You cannot edit these expenditures here, this can be only be only be done via the Liabilities section."
        checkboxColumn={false}
      />
      <FormTable
        columns={expenditureTableColumns}
        dataSource={expenditureDataSource}
        title="Expenditure"
        addButtonText="Add New Expenditure"
        handleAddButton={handleAddButton}
        setSelectedRows={setSelectedRows}
        displayDeleteButton={displayDeleteButton}
        handleDeleteModal={handleDeleteModal}
        displayEditButton={displayEditButton}
        handleEditModal={handleEditModal}
      />
      <StyledStatisticWrapper>
        <IncomeTable dataSource={incomeTableDataSource} title="Total Monthly Income" />
        <IncomeTable dataSource={expenditureTableDataSource} title="Total Monthly Expenditure" />
        <IncomeTable dataSource={disposableIncomeTableDataSource} title="Disposable Income" />
      </StyledStatisticWrapper>
      <Form
        layout="vertical"
        size="large"
        name="basic"
        initialValues={formData}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '12px',
          marginTop: '8px',
          width: '100%',
        }}
        onFinishFailed={onFinishFailed}
        onFinish={onSubmit}
      >
        <CustomFormItem
          label="Are you finding it difficult to pay your bills? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          value={formData.isDifficultToPayBills}
          name="isDifficultToPayBills"
          type="radio"
          rules={['isRequired']}
        />
        <CustomFooter loading={loading} setSaveType={setSaveType} />
      </Form>
    </StyledContainer>
  )
}

export default Expenditure
