import { useEffect, useState } from 'react'
import _ from 'lodash'

import {
  useGetClientFormsInfo,
  useGetExpendituresDetails,
  useGetFuturePensions,
  useGetIncomeSources,
  useGetObjectives,
  useGetOtherLiabilities,
  useGetRecommendedProduct,
} from 'services/clients/clients.service'
import { calculateTotalMonthlyIncome } from 'utils/totalMonthlyIncome'
import { calculateTotalMonthlyExpenditure } from 'utils/totalMonthlyExpenditure'
import { calculateDisposableIncome } from 'utils/disposableIncome'
import { message } from 'antd'
import { handleGenerateDoc } from 'pages/ClientDetails/Tabs/SuitabilityLetterTabContent/SuitabilityLetterTabContent.helper'
import { commonUtils } from 'utils'
import { commonHelper } from 'helpers'
import { useCustomMutation } from 'services/shared/mutation'
import { saveRecommendedProductMutation } from 'gql/client'
import { SaveRecommendedProductPayload } from 'services/shared/mutation.payload'

const useSuitabilityLetter = () => {
  const [fileName, setFileName] = useState<string | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { recommendedProduct, loading: formInitialDataLoading } = useGetRecommendedProduct(idNumber)
  const [formData, setFormData] = useState({
    lenderName: recommendedProduct?.lenderName,
    productName: recommendedProduct?.productName,
    propertyValue: recommendedProduct?.propertyValue,
    initialFundsReleased: recommendedProduct?.initialFundsReleased,
    drawDownFacility: recommendedProduct?.drawDownFacility,
    interestRateType: recommendedProduct?.interestRateType,
    monthlyInterestRate: recommendedProduct?.monthlyInterestRate,
    annualEquivalentRate: recommendedProduct?.annualEquivalentRate,
    lifetimeMortgage: recommendedProduct?.lifetimeMortgage,
    valuationFee: recommendedProduct?.valuationFee,
    productFee: recommendedProduct?.productFee,
    adviceFee: recommendedProduct?.adviceFee,
    estimatedSolicitorsFees: recommendedProduct?.estimatedSolicitorsFees,
    earlyProductRepaymentCharges: recommendedProduct?.earlyProductRepaymentCharges,
  })
  const [saveType, setSaveType] = useState<'save' | 'downloadSR'>('save')
  const { data, loading: clientLoading } = useGetClientFormsInfo(idNumber)

  const { expendituresDetails } = useGetExpendituresDetails(idNumber)
  const { otherLiabilities } = useGetOtherLiabilities(idNumber)
  const { incomeSources } = useGetIncomeSources(idNumber)
  const { futurePensions } = useGetFuturePensions(idNumber)
  const { objectives } = useGetObjectives(idNumber)

  const [loading, setLoading] = useState(formInitialDataLoading && clientLoading)
  const [saveDisabled, setSaveDisabled] = useState(true)

  useEffect(() => {
    const isFormDataComplete = Object.values(formData).every((value) => typeof value !== 'undefined' && value !== null)
    setSaveDisabled(!isFormDataComplete)
  }, [formData])

  useEffect(() => {
    setLoading(formInitialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(recommendedProduct)
    delete newData?._id
    if (Object.keys(newData).length > 0) {
      setFormData({ ...newData })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInitialDataLoading])

  const incomeTableDataSource = calculateTotalMonthlyIncome(incomeSources, futurePensions)
  const expenditureTableDataSource = calculateTotalMonthlyExpenditure(expendituresDetails, otherLiabilities)
  const disposableIncomeTableDataSource = calculateDisposableIncome(incomeTableDataSource, expenditureTableDataSource)

  const saveRecommendedProduct = useCustomMutation<SaveRecommendedProductPayload>({
    mutation: saveRecommendedProductMutation,
    refetchQueries: ['GetRecommendedProduct'],
  })

  let initialFundsTotalValue = 0

  // eslint-disable-next-line array-callback-return
  objectives.map((objective: any) => {
    initialFundsTotalValue += objective.fundsRequiredIn === 'now' ? objective.requiredAmount : 0
  })

  const disposableIncomeForNow = disposableIncomeTableDataSource[0]?.total || 0

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Note: update the form data
    const parsedValue = commonUtils.parseValue(e.target?.value, e.target?.name)
    setFormData((prevState) => _.set(_.cloneDeep(prevState), e.target.name, parsedValue))
  }

  const handleSubmit = async () => {
    const { errors } = await saveRecommendedProduct({
      idNumber,
      values: formData,
    })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const handleSaveAndDownload = () => {
    setShowModal(true)
  }

  const handleDownload = async () => {
    handleSubmit()
    handleGenerateDoc(data, disposableIncomeForNow, initialFundsTotalValue, formData, uploadedFile)
    closeModal()
    setUploadedFile(null)
    setFileName(null)
  }

  const onSubmit = () => {
    if (saveType === 'downloadSR') {
      handleSaveAndDownload()
    } else if (saveType === 'save') {
      handleSubmit()
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill out every required field')
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const handleFileUpload = (file: File) => {
    setUploadedFile(file)
  }

  return {
    loading,
    setSaveType,
    onSubmit,
    onFinishFailed,
    formData,
    handleAntdFormItemChange,
    saveDisabled,
    closeModal,
    handleDownload,
    setFileName,
    fileName,
    handleFileUpload,
    showModal,
  }
}

export default useSuitabilityLetter
