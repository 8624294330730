import { useState, useEffect } from 'react'
import message from 'antd/lib/message'
import Form from 'antd/lib/form'
import * as _ from 'lodash'

import { commonHelper } from 'helpers'

import { commonUtils, eventManager } from 'utils'
import { useGetBeneficiary } from 'services/clients/clients.service'
import { addEditBeneficiaryMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { AddEditBeneficiaryPayload } from 'services/shared/mutation.payload'
import { TCustomCheckboxValue } from 'components/CustomCheckbox/CustomCheckbox.types'

const initialState = {
  isOpen: false,
  data: { _id: '', isEdit: false },
  cb: { confirm: () => null, cancel: () => null },
  loading: false,
}

const useBeneficiaryModal = () => {
  const [form] = Form.useForm()
  const addEditBeneficiary = useCustomMutation<AddEditBeneficiaryPayload>({ mutation: addEditBeneficiaryMutation, refetchQueries: ['getBeneficiaries'] })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const [showAddressDetails, setShowAddressDetails] = useState(false)

  // this should be fetched to use this for editing as well, new it only adds new
  const [modalState, setModalState] = useState(initialState)
  const [beneficiaryInfoTypeToProvide, setBeneficiaryInfoTypeToProvide] = useState('both')
  const beneficiaryId = modalState?.data?._id
  const { beneficiary, loading: initialDataLoading } = useGetBeneficiary({ idNumber, beneficiaryId, skip: !modalState.isOpen || !modalState.data.isEdit })
  const [loading, setLoading] = useState(initialDataLoading)
  const [formData, setFormData] = useState({
    name: beneficiary.name,
    age: beneficiary.age,
    relationship: beneficiary.relationship,
    isFinanciallyDependentOnClient: beneficiary.isFinanciallyDependentOnClient || false,
    email: beneficiary.email,
    address: {
      formattedAddress: beneficiary?.address?.formattedAddress,
      address1: beneficiary?.address?.address1,
      address2: beneficiary?.address?.address2,
      town: beneficiary?.address?.town,
      county: beneficiary?.address?.county,
      country: beneficiary?.address?.country,
      postcode: beneficiary?.address?.postcode,
    },
  })

  useEffect(() => {
    setLoading(initialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(beneficiary)
    delete newData._id
    setFormData({ ...newData })
    form.setFieldsValue({ ...newData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDataLoading])

  const clearFormData = () => {
    setFormData((prevState) => commonUtils.resetFormData(prevState))
    setBeneficiaryInfoTypeToProvide('both')
    form.setFieldsValue(commonUtils.resetFormData(formData))
  }

  const handleAntdFormInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => _.set(_.cloneDeep(prevState), e.target.name, e.target.value))
  }

  const handleCustomCheckboxChange = ({ name, value }: { name: string; value: TCustomCheckboxValue }) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleBeneficiaryInfoTypeToProvideChange = ({ name, value }: { name: string; value: TCustomCheckboxValue }) => {
    setBeneficiaryInfoTypeToProvide(String(value))
  }

  const onPlaceSelect = ({ formattedAddress, address1, address2, town, county, country, postcode }: any) => {
    setFormData((prevState) => ({
      ...prevState,
      address: {
        formattedAddress,
        address1,
        address2,
        town,
        county,
        country,
        postcode,
      },
    }))

    form.setFieldValue('address.formattedAddress', formattedAddress)
    form.setFieldValue('address.address1', address1)
    form.setFieldValue('address.address2', address2)
    form.setFieldValue('address.town', town)
    form.setFieldValue('address.county', county)
    form.setFieldValue('address.country', country)
    form.setFieldValue('address.postcode', postcode)
  }

  useEffect(() => {
    eventManager.addListener('beneficiary-modal', (data: any, cb: any) => {
      setModalState((state) => ({
        ...state,
        isOpen: data !== undefined,
        data,
        cb,
      }))
    })
  }, [setModalState])

  const handleClose = () => {
    setModalState((state) => ({ ...state, loading: false }))
    eventManager.closeBeneficiaryModal()
  }

  const handleCancelClick = async () => {
    try {
      setModalState((state) => ({ ...state, loading: true }))
      if (modalState.cb.cancel) {
        await modalState.cb.cancel()
      }
    } catch (err) {
      // console.error(err)
    }
    handleClose()
    clearFormData()
  }

  const handleSubmit = async () => {
    const { errors } = await addEditBeneficiary({
      idNumber,
      beneficiaryToEdit: beneficiaryId,
      values: {
        ...formData,
        age: Number(formData.age),
      },
    })
    if (errors?.length) {
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const handleConfirmClick = async () => {
    try {
      setModalState((state) => ({ ...state, loading: true }))
      await handleSubmit()
      clearFormData()
      if (modalState.cb.confirm) {
        await modalState.cb.confirm()
      }
    } catch (err) {
      // console.error(err)
    }
    setModalState((state) => ({ ...state, loading: false }))
    handleClose()
  }

  const showAddressDetailsChange = (checked: boolean) => {
    setShowAddressDetails(checked)
  }

  return {
    modalState,
    handleCancelClick,
    handleConfirmClick,
    formData,
    beneficiaryInfoTypeToProvide,
    handleAntdFormInputChange,
    handleCustomCheckboxChange,
    handleBeneficiaryInfoTypeToProvideChange,
    onPlaceSelect,
    showAddressDetails,
    showAddressDetailsChange,
    loading,
    form,
  }
}

export default useBeneficiaryModal
