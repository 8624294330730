import { StyledContainer, StyledLightButton } from './FilesTabContent.styles'
import useFilesTabContents from 'hooks/clients/useFilesTabContents'
import { FormTable } from 'components/FormTable'

const FilesTabContent = () => {
  const { filesColumns, filesDataSource, handleAddFile, displayDeleteButton, setSelectedRows, handleDeleteModal } = useFilesTabContents()

  return (
    <StyledContainer>
      <StyledLightButton onClick={handleAddFile}>Upload File</StyledLightButton>
      <FormTable
        columns={filesColumns}
        dataSource={filesDataSource}
        setSelectedRows={setSelectedRows}
        displayDeleteButton={displayDeleteButton}
        handleDeleteModal={handleDeleteModal}
        spacesAroundGrid={false}
        tableContainerBgColor="#fefefe"
      />
    </StyledContainer>
  )
}

export default FilesTabContent
