import Form from 'antd/lib/form'

import { useSignUp } from 'hooks'
import TextInput from 'components/CustomFormItem'

import {
  StyledSignUpContainer,
  StyledLeftContainer,
  StyledRightContainer,
  StyledBackground,
  StyledLogo,
  StyledDesc,
  StyledContainerPosition,
  StyledDescContainer,
  StyledBreakLine,
  StyledSignUpButton,
  StyledFormContainer,
  StyledRegisterMsg,
  StyledMinMsg,
  StyledLinksContainer,
  StyledCreateAccount,
  StyledLink,
  StyledFormsWrapper,
  StyledMainFieldsWrapper,
  StyledIressFieldsWrapper,
  // StyledChooseLogoText,
  // StyledAvatar,
  // StyledPlaceholderAvatar,
} from './SignUp.styles'

import {
  // FileUploadIcon,
  LoginPageImg,
  LogoLight,
} from 'assets/images'
import { Switch } from '@mui/material'

const SignUp = () => {
  const {
    handleSubmit,
    loading,
    // avatar,
    handleAvatarUpload,
    hasIressDetails,
    handleSwitchChange,
  } = useSignUp()

  const landingUrl = process.env.REACT_APP_STAGE === 'production' ? 'https://premiererc.co.uk/contact/' : 'https://dev.premiererc.co.uk/contact/'

  return (
    <StyledSignUpContainer>
      <StyledLeftContainer>
        <StyledBackground img={LoginPageImg}>
          <StyledLogo src={LogoLight} />
          <StyledContainerPosition>
            <StyledDescContainer>
              <StyledBreakLine />
              <StyledDesc>The platform for later life lending professionals</StyledDesc>
            </StyledDescContainer>
          </StyledContainerPosition>
        </StyledBackground>
      </StyledLeftContainer>
      <StyledRightContainer>
        <StyledFormContainer>
          <StyledRegisterMsg>Register now</StyledRegisterMsg>
          <StyledMinMsg>And share the right way to do equity release. Please complete one per each advisor that wishes to be registered.</StyledMinMsg>
          <StyledMinMsg
            style={{
              color: 'red',
            }}
          >
            If you don't have IRESS details please visit
            <a href={landingUrl} target="_blank" rel="noreferrer" style={{ padding: '0 10px' }}>
              PERC contact page
            </a>
            for more information.
          </StyledMinMsg>
          {/* Note: avatar upload logic is commented on handleSubmit too */}
          {/* {avatar ? (
            <StyledAvatar src={avatar} alt="company logo" />
          ) : (
            <StyledChooseLogoText htmlFor="user_avatar">
              Upload Avatar{" "}
              <StyledPlaceholderAvatar
                src={FileUploadIcon}
                alt="company placeholder logo"
              />
            </StyledChooseLogoText>
          )} */}
          <input onChange={handleAvatarUpload} name="user_avatar" id="user_avatar" hidden type="file" accept=".png,.svg,.jpg,.jpeg" />
          <Form
            layout="vertical"
            size="large"
            name="basic"
            onFinish={handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
            }}
          >
            <StyledFormsWrapper>
              <StyledMainFieldsWrapper
                style={{
                  width: hasIressDetails ? '100%' : '50%',
                }}
              >
                <TextInput label="First Name *" name="firstName" placeholder="Enter Your First Name" rules={['isRequired']} />
                <TextInput label="Last Name *" name="lastName" placeholder="Enter Your Last Name" rules={['isRequired']} />
                <TextInput label="Email *" name="email" placeholder="Enter Your Email" rules={['isRequired', 'validateEmail']} />
                <TextInput label="Phone Number *" name="phoneNumber" placeholder="Enter Your Phone Number" rules={['isRequired']} />
                <TextInput label="Password *" name="password" type="password" placeholder="Enter a password" rules={['isRequired']} />
                <TextInput label="FCA Number" name="fcaNumber" placeholder="Enter Your FCA Number" type="number" />

                <label htmlFor="hasIressDetails">
                  <Switch onChange={handleSwitchChange} checked={hasIressDetails} name="hasIressDetails" id="hasIressDetails" />I have IRESS Details
                </label>
              </StyledMainFieldsWrapper>
              {hasIressDetails && (
                <StyledIressFieldsWrapper>
                  <TextInput label="Iress Login ID *" name="iressLoginId" placeholder="Enter Your Iress Login ID" rules={['isRequired']} />
                  <TextInput label="Iress Password *" name="iressPassword" placeholder="Enter Your Iress Password" rules={['isRequired']} />
                  <TextInput label="Iress User ID *" name="iressUserId" placeholder="Enter Your Iress User ID" type="number" rules={['isRequired']} />
                  <StyledMinMsg
                    style={{
                      color: 'red',
                    }}
                  >
                    Please double check IRESS details for accuracy!
                  </StyledMinMsg>
                </StyledIressFieldsWrapper>
              )}
            </StyledFormsWrapper>
            <StyledSignUpButton loading={loading} type="primary" htmlType="submit">
              REGISTER
            </StyledSignUpButton>
            <StyledLinksContainer>
              <StyledCreateAccount>Already have an account?</StyledCreateAccount>
              <StyledLink to="/">Log In</StyledLink>
            </StyledLinksContainer>
          </Form>
        </StyledFormContainer>
      </StyledRightContainer>
    </StyledSignUpContainer>
  )
}

export default SignUp
