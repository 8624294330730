import { Checkbox } from 'antd'
import styled from 'styled-components'

export const StyledCheckboxContainer = styled.div`
  align-self: flex-start;
  height: 45px;

  display: flex;
  justify-content: center;
  align-items: center;

  column-gap: 12px;
`

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-wrapper .ant-checkbox-inner,
  .ant-checkbox .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    width: 18px !important;
    height: 18px !important;
    border-color: #323232;
  }
`

export const StyledCheckboxLabel = styled.div`
  font-size: 16px;
`
