import config from 'config'
import { useState } from 'react'

interface IUseClientDetailsActionResponse {
  handleDownload: () => void
  handlePdfView: () => void
  loading: boolean
  showModal: boolean
  handleFileUpload: (file: File) => void
  closeModal: () => void
  handleDownloadModal: () => void
  setFileName: React.Dispatch<React.SetStateAction<string | null>>
  fileName: string | null
}

const useClientDetailsAction = (): IUseClientDetailsActionResponse => {
  const [fileName, setFileName] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))

  const handleDownload = async () => {
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append('idNumber', idNumber.toString())
      if (uploadedFile) {
        formData.append('file', uploadedFile)
      }

      const response = await fetch(`${config.API_URL}/pdf/generate`, {
        method: 'POST',
        body: formData,
      })
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.download = 'Fact-Find.pdf'
      link.click()

      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Download failed', error)
    } finally {
      setLoading(false)
      setShowModal(false)
      setUploadedFile(null)
      setFileName(null)
    }
  }

  const handleDownloadModal = () => {
    setShowModal(true)
  }

  const handlePdfView = () => {
    window.open(`/view-pdf?idNumber=${idNumber}`, '_blank')
  }

  const handleFileUpload = (file: File) => {
    setUploadedFile(file)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  return { handleDownload, handlePdfView, loading, showModal, handleFileUpload, closeModal, handleDownloadModal, setFileName, fileName }
}

export default useClientDetailsAction
