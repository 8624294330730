import Tabs from 'antd/lib/tabs'
import { Dropdown } from 'antd'

import Layout from 'components/Layout'
import MainContentContainer from 'components/MainContentContainer'
import Loading from 'components/Loading/Loading'
import useQuoteResult from 'hooks/clients/QuoteResult/useQuoteResult'
import { KeyIcon } from 'assets/images'

import {
  StyledContainer,
  StyledHeaderContainer,
  StyledLeftContainer,
  StyledRightContainer,
  StyledImg,
  StyledImgBorder,
  StyledDescription,
  StyledMenu,
  KeysTitle,
  KeysWrapper,
  KeyWrapper,
  KeyBox,
  KeyDescDelimiter,
  KeyDescription,
  LoaderContentWrapper,
  LoaderText,
} from './QuoteResult.styles'
import UnsuccessfulQuotesTab from './Tabs/UnsuccessfulQuotesTab/UnsuccessfulQuotesTab'
import QuoteInputTab from './Tabs/QuoteInputTab/QuoteInputTab'
import LifeTimeMortgagesTab from './Tabs/LifeTimeMortgagesTab/LifeTimeMortgagesTab'
import { keys, tabs } from './staticData'
import BackButton from 'components/BackButton/BackButton'
import CustomFooter from './CustomFooter/CustomFooter'

const { TabPane } = Tabs

const QuoteResult = () => {
  const {
    loading,
    clientDetails,
    furtherInformation,
    formattedDate,
    quoteResultActiveTab,
    handleTabChange,
    handleGoBackBtnClick,
    error,
    handlePdfReport,
    pdfReportLoading,
    handleRequote,
    serviceProviderReference,
  } = useQuoteResult()

  const menu = (
    <StyledMenu>
      <KeysTitle>Keys</KeysTitle>
      <KeysWrapper>
        {keys.map((key) => (
          <KeyWrapper key={key.key}>
            <KeyBox>{key.key}</KeyBox>
            <KeyDescDelimiter>-</KeyDescDelimiter> <KeyDescription>{key.label}</KeyDescription>
          </KeyWrapper>
        ))}
      </KeysWrapper>
    </StyledMenu>
  )

  if (loading) {
    return (
      <Layout>
        <MainContentContainer pageName="Equity release - Quote Results">
          <LoaderContentWrapper>
            <Loading fontSize={48} />
            <LoaderText>
              Thank you for your patience.
              <br /> We are currently awaiting a response from IRESS
            </LoaderText>
          </LoaderContentWrapper>
        </MainContentContainer>
      </Layout>
    )
  }

  return (
    <Layout headerCustomComponent={<BackButton handleClick={handleGoBackBtnClick} />}>
      <MainContentContainer pageName="Equity release - Quote Results">
        {error ? (
          <p>Please check user input as IRESS return empty response for this user input </p>
        ) : (
          <StyledContainer>
            <StyledHeaderContainer>
              <StyledLeftContainer>
                <StyledDescription>
                  Quote produced for{' '}
                  <strong>
                    {clientDetails.title} {clientDetails.firstName} {clientDetails.lastName},
                  </strong>{' '}
                  obtained on <strong>{formattedDate},</strong> for <strong>{furtherInformation.productResearchEnvironment},</strong> with reference{' '}
                  <strong>Q-12345.</strong> Transaction Number <strong>{serviceProviderReference}.</strong>
                </StyledDescription>
              </StyledLeftContainer>
              <StyledRightContainer>
                <Dropdown overlay={menu} overlayStyle={{ position: 'fixed' }} trigger={['click']}>
                  <StyledImgBorder>
                    <StyledImg src={KeyIcon} />
                  </StyledImgBorder>
                </Dropdown>
              </StyledRightContainer>
            </StyledHeaderContainer>
            <Tabs style={{ width: '100%' }} activeKey={quoteResultActiveTab} type="card" onChange={handleTabChange}>
              <TabPane tab={tabs.lifeTimeMortgages} key={tabs.lifeTimeMortgages}>
                <LifeTimeMortgagesTab />
              </TabPane>
              <TabPane tab={tabs.unsuccessfulQuotes} key={tabs.unsuccessfulQuotes}>
                <UnsuccessfulQuotesTab />
              </TabPane>
              <TabPane tab={tabs.quoteInput} key={tabs.quoteInput}>
                <QuoteInputTab />
              </TabPane>
            </Tabs>
          </StyledContainer>
        )}
        <CustomFooter loading={pdfReportLoading} handlePdfReport={handlePdfReport} handleRequote={handleRequote} disabled={!!error} />
      </MainContentContainer>
    </Layout>
  )
}

export default QuoteResult
