import { IBeneficiariesData } from './Family.interface'

export const beneficiariesTableColumns = [
  {
    name: 'name',
    header: 'Name',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'age',
    header: 'Age',
    defaultFlex: 1,
    type: 'number',
  },
  {
    name: 'relationship',
    header: 'Relationship',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'isFinanciallyDependentOnClient',
    header: 'Financially Dependent On Client',
    defaultFlex: 2,
    type: 'text',
    render: ({ data }: IBeneficiariesData) => (data.isFinanciallyDependentOnClient ? 'Yes' : 'No'),
  },
]

export const recipientsTableColumns = [
  {
    name: 'name',
    header: 'Name',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'relationship',
    header: 'Relationship',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'postcode',
    header: 'Postcode',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'email',
    header: 'Email',
    defaultFlex: 1,
    type: 'text',
  },
]

export const contactDetailsOfWillBeneficiariesOptions = [
  {
    label: 'All have been involved in meetings with Adviser',
    value: 'allHaveBeenInvolvedInMeetingsWithAdviser',
  },
  {
    label: 'Adviser will and has contacted all',
    value: 'adviserWillAndHasContactedAll',
  },
  {
    label: 'Customer has contacted all',
    value: 'customerHasContactedAll',
  },
  {
    label: 'Customer will contact all, but wants to proceed, despite strong recommendation to make contact first',
    value: 'customerWillContactAllButWantsToProceedDespiteStrongRecommendationToMakeContactFirst',
  },
  {
    label: 'Customer does not wish to contact all, but wants to proceed for the following reason',
    value: 'customerDoesNotWishToContactAllButWantsToProceedForTheFollowingReason',
  },
]
