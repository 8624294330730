import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'

import CustomFormItem from 'components/CustomFormItem'
import useIressDetailsModal from 'hooks/clients/Modals/useIressDetailsModal'

import { StyledWrapper } from './IressDetailsModal.styles'

const IressDetailsModal = () => {
  const { modalState, handleCancelClick, handleConfirmClick, formData, form, handleAntdFormInputChange } = useIressDetailsModal()

  return (
    <Modal
      title="Check IRESS Details"
      open={modalState.isOpen}
      onOk={handleConfirmClick}
      onCancel={handleCancelClick}
      width={700}
      okText="Save"
      cancelText="Cancel"
      closable={false}
      className="edit-asset-modal"
      confirmLoading={modalState.loading}
    >
      <StyledWrapper>
        <Form
          form={form}
          layout="vertical"
          size="large"
          name="basic"
          initialValues={formData}
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',
            marginTop: '8px',
            width: '100%',
          }}
        >
          <CustomFormItem
            onChange={handleAntdFormInputChange}
            label="Iress Login ID *"
            name="iressLoginId"
            placeholder="Enter Your Iress Login ID"
            rules={['isRequired']}
            value={formData?.iressLoginId}
          />
          <CustomFormItem
            onChange={handleAntdFormInputChange}
            label="Iress Password *"
            name="iressPassword"
            placeholder="Enter Your Iress Password"
            rules={['isRequired']}
            value={formData?.iressPassword}
          />
          <CustomFormItem
            onChange={handleAntdFormInputChange}
            label="Iress User ID *"
            name="iressUserId"
            placeholder="Enter Your Iress User ID"
            type="number"
            rules={['isRequired']}
            value={formData?.iressUserId}
          />
        </Form>
      </StyledWrapper>
    </Modal>
  )
}

export default IressDetailsModal
