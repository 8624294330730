import { Form, Modal } from 'antd'

import { Styled2x2Grid, StyledWrapper } from './FuturePensionModal.styles'
import TextInput from 'components/CustomFormItem/CustomFormItem'
import CustomTextArea from 'components/CustomTextArea/CustomTextArea'
import { whenWillPensionMatureOptions, pensionTypeOptions } from './FuturePensionModal.helper'
import useFuturePensionModal from 'hooks/clients/Modals/useFuturePensionModal'
import Loading from 'components/Loading/Loading'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import { PoundIcon } from 'assets/images'
import CustomFormItem from 'components/CustomFormItem/CustomFormItem'

const FuturePensionModal = () => {
  const {
    modalState,
    handleCancelClick,
    handleConfirmClick,
    formData,
    handleCustomSelectChange,
    handleAntdFormInputChange,
    handleCustomTextAreaChange,
    loading,
    form,
    customersOptions,
  } = useFuturePensionModal()

  if (loading) {
    return <Loading />
  }

  return (
    <Modal
      title="Edit Future Pension"
      open={modalState.isOpen}
      onOk={handleConfirmClick}
      onCancel={handleCancelClick}
      width={700}
      okText="Save"
      cancelText="Cancel"
      closable={false}
      className="edit-asset-modal"
      confirmLoading={modalState.loading}
    >
      {modalState.loading ? (
        <Loading />
      ) : (
        <StyledWrapper>
          <Form
            form={form}
            layout="vertical"
            size="large"
            name="basic"
            initialValues={formData}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
              marginTop: '8px',
              width: '100%',
            }}
          >
            <Styled2x2Grid>
              <CustomFormItem
                label="Select Customer *"
                width={'100%'}
                placeholder="Select Customer *"
                onChange={handleCustomSelectChange}
                options={customersOptions}
                name="pensionOwner"
                type="select"
                rules={['isRequired']}
              />
              <CustomSelect
                label="Type of Pension *"
                width={'100%'}
                placeholder="Type of Pension *"
                defaultValue={formData.pensionType}
                onChange={handleCustomSelectChange}
                options={pensionTypeOptions}
                name="pensionType"
              />
              <CustomSelect
                label="When will pension mature? (approximately, if known)"
                width={'100%'}
                placeholder="When will pension mature? *"
                defaultValue={formData.whenWillPensionMature}
                onChange={handleCustomSelectChange}
                options={whenWillPensionMatureOptions}
                name="whenWillPensionMature"
              />
              <TextInput
                label="Pension fund amount (approximately, if known)"
                name="pensionFundAmount"
                placeholder="Pension fund amount"
                inputPrefixImg={PoundIcon}
                prefixImgHeight={12}
                onChange={handleAntdFormInputChange}
                type="number"
              />
              <TextInput
                label="If taking Cash from fund, enter appropriate amount"
                name="cashFromFund"
                placeholder="If taking Cash from fund, enter appropriate amount"
                inputPrefixImg={PoundIcon}
                prefixImgHeight={12}
                onChange={handleAntdFormInputChange}
                type="number"
              />
              <TextInput
                label="Estimated monthly income from this Pension *"
                name="monthlyIncomeFromPension"
                placeholder="Estimated monthly income from this Pension"
                inputPrefixImg={PoundIcon}
                prefixImgHeight={12}
                onChange={handleAntdFormInputChange}
                type="number"
              />
            </Styled2x2Grid>
          </Form>
          <CustomTextArea
            label="Description"
            placeholder="Description"
            value={formData.description}
            name="description"
            onChange={handleCustomTextAreaChange}
            rows={4}
          />
        </StyledWrapper>
      )}
    </Modal>
  )
}

export default FuturePensionModal
