import { useQuery } from '@apollo/client'
import { getSignedUrl } from 'gql'
import { getCompaniesQuery, getCompanyQuery } from 'gql/company'

export const useGetCompanies = (variables?: { skip: number; limit: number }) => {
  const { data, error, loading, refetch } = useQuery(getCompaniesQuery, {
    variables,
  })
  return {
    companies: data?.getCompanies?.companies || [],
    count: data?.getCompanies?.count || 0,
    loading,
    error,
    refetch,
  }
}

export const useGetCompany = () => {
  const { data, error, loading, refetch } = useQuery(getCompanyQuery)
  return {
    company: (data && data.getCompany) || {},
    loading,
    error,
    refetch,
  }
}

export const useGetCompanyAvatarFromS3 = (variables: any) => {
  const { data, error, loading, refetch } = useQuery(getSignedUrl, {
    variables,
  })
  return {
    url: data?.getSignedUrl?.url || '',
    loading,
    error,
    refetch,
  }
}
