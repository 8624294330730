import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const ControlsWrapper = styled.div`
  display: flex;
  gap: 16px;
`

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const StyledHeader = styled.th`
  background-color: #f2f2f2;
  color: #333;
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
`

export const StyledCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`

export const StyledSearchInput = styled.input`
  padding: 12px 16px;
  background: #fefefe;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  font-size: 1rem;
`

export const StyledFileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

export const FileInputLabel = styled.label`
  padding: 12px 16px;
  transition: 0.3s;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #90258c;
  color: #90258c;
  cursor: pointer;
`

export const StyledDownloadButton = styled.a`
  padding: 12px 16px;
  transition: 0.3s;
  background-color: #90258c;
  border-color: #90258c;
  border-radius: 4px;
  color: #fefefe;
  cursor: pointer;
`
