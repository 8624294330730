import { Drawer } from 'antd';

import { CloseIcon } from 'assets/images';

import {
  StyledCloseIcon,
  StyledButtonsContainer,
  StyledCancelButton,
  StyledSaveButton,
} from './CustomDrawer.styles';
import { TCustomDrawer } from './CustomDrawer.types';

const CustomDrawer = ({
  children,
  open = false,
  closeDrawer,
  title,
  disableSaveButton = false,
  loading = false,
  handleSubmit,
}: TCustomDrawer) => {
  return (
    <>
      <Drawer
        title={title}
        placement="right"
        width={584}
        closable={false}
        onClose={closeDrawer}
        open={open}
        extra={
          <StyledCloseIcon onClick={closeDrawer} src={CloseIcon} alt="X" />
        }
      >
        {children}

        <StyledButtonsContainer>
          <StyledCancelButton onClick={closeDrawer}>Cancel</StyledCancelButton>
          <StyledSaveButton
            disabled={disableSaveButton}
            onClick={handleSubmit}
            loading={loading}
          >
            Save
          </StyledSaveButton>
        </StyledButtonsContainer>
      </Drawer>
    </>
  );
};

export default CustomDrawer;
