export const tabs = {
  lifeTimeMortgages: 'LIFE TIME MORTGAGES',
  unsuccessfulQuotes: 'UNSUCCESSFUL QUOTES',
  quoteInput: 'QUOTE INPUT',
}

export const keys = [
  { key: 'N', label: 'This individual quote has product notes' },
  { key: 'CB', label: 'This individual quote offers cashback' },
  { key: 'CBD', label: 'The cashback available for this individual quote has been deducted from the requested initial loan amount' },
  { key: 'FOR', label: 'This individual quote offers free optional repayments' },
  { key: 'FV', label: 'This individual quote offers free valuations' },
  { key: 'Special', label: 'This individual quote has a special deal' },
  { key: 'GQ', label: 'This individual quote is guaranteed.' },
  { key: '(F)', label: 'Fixed rate' },
  { key: '(V)', label: 'Variable rate' },
  { key: '(C)', label: 'Capped rate' },
]
