import { ReactElement } from 'react'

import { StyledModalContainer, StyledModalTitle, StyledModal } from './DesktopAppSuggestion.styles'
import useViewportWidth from 'hooks/useViewportWidth'
import useScrollLock from 'hooks/useScrollLock'

const DesktopAppSuggestion = (): ReactElement | null => {
  const { width } = useViewportWidth()

  const showModal = width < 900

  useScrollLock(showModal)

  if (!showModal) return null

  return (
    <StyledModalContainer $show={showModal}>
      <StyledModal>
        <StyledModalTitle>
          For the best experience,
          <p>please use our desktop app.</p>
        </StyledModalTitle>
      </StyledModal>
    </StyledModalContainer>
  )
}

export default DesktopAppSuggestion
