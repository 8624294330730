import { Form } from 'antd'
import { AlertIcon } from 'assets/images'
import TextInput from 'components/CustomFormItem'
import { usePasswordTabContent } from 'hooks'

import {
  StyledContainer,
  StyledMinContainer,
  StyledFormContainer,
  StyledTitle,
  StylesRequirementsContainer,
  StyledReqTitleContainer,
  StyledReqImg,
  StyledReqTitle,
  StyledReqUl,
  StyledReqLi,
} from './PasswordTabContent.styles'
import CustomFooter from 'pages/UserProfile/CustomFooter/CustomFooter'

const PasswordTabContent = () => {
  const { handleFormFieldChange, loading, handleSubmit } = usePasswordTabContent()

  return (
    <StyledContainer>
      <StyledMinContainer>
        <StyledTitle>Change password</StyledTitle>
        <StyledFormContainer>
          <Form
            layout="vertical"
            size="large"
            name="basic"
            onChange={handleFormFieldChange}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
            }}
          >
            <TextInput label="Current" name="currentPassword" placeholder="Type your password here" rules={['isRequired']} type="password" />
            <TextInput label="New" name="newPassword" placeholder="Enter your new password" rules={['isRequired']} type="password" />
            <TextInput label="Confirm" name="confirmPassword" placeholder="Repeat your password" rules={['isRequired']} type="password" />
            <CustomFooter loading={loading} handleSave={handleSubmit} />
          </Form>
          <StylesRequirementsContainer>
            <StyledReqTitleContainer>
              <StyledReqImg src={AlertIcon} />
              <StyledReqTitle>Password requirements</StyledReqTitle>
            </StyledReqTitleContainer>
            <StyledReqUl>
              <StyledReqLi>At least 10 characters long.</StyledReqLi>
              <StyledReqLi>A combination of uppercase letters, lowercase letters, numbers, and symbols.</StyledReqLi>
            </StyledReqUl>
          </StylesRequirementsContainer>
        </StyledFormContainer>
      </StyledMinContainer>
    </StyledContainer>
  )
}

export default PasswordTabContent
