import FormTable from 'components/FormTable/FormTable'

import { generateMortgagesColumns } from './staticData'
import { StyledButtonsContainer, StyledFilterButton, StyledRemoveFiltersButton, StyledWrapper } from './LifeTimeMortgagesTab.styles'
import QuotesFilterDrawer from 'drawers/QuotesFilterDrawer/QuotesFilterDrawer'
import useLifeTimeMortgagesTab from 'hooks/quotes/useLifeTimeMortgagesTab'

const LifeTimeMortgagesTab = () => {
  const { areFilterApplied, clearFilters, filteredData, open, setOpen, filterData, setFilterData, onRowClick, closeDrawer, handleFilter } =
    useLifeTimeMortgagesTab()
  const mortgagesColumns = generateMortgagesColumns(filteredData)

  return (
    <StyledWrapper>
      <StyledButtonsContainer>
        {areFilterApplied && <StyledRemoveFiltersButton onClick={clearFilters}>Clear Filters</StyledRemoveFiltersButton>}
        <StyledFilterButton onClick={() => setOpen(true)}>Filter Quotes</StyledFilterButton>
      </StyledButtonsContainer>
      <FormTable
        columns={mortgagesColumns}
        dataSource={filteredData}
        checkboxColumn={false}
        spacesAroundGrid={false}
        headerGridGap="8px"
        rowHeight={164}
        headerHeight={80}
        onRowClick={onRowClick}
      />
      <QuotesFilterDrawer open={open} closeDrawer={closeDrawer} handleSubmit={handleFilter} setFilterData={setFilterData} filterData={filterData} />
      {/* <CustomPagination pageSize={10} count={successFullQuote.length} refetch={refetch} style={{ paddingBottom: '24px' }} /> */}
    </StyledWrapper>
  )
}

export default LifeTimeMortgagesTab
