import { IBenefitsData, IEligibleBenefitsData } from './Benefits.interface'

export const benefitsTableColumns = [
  {
    name: 'benefitReceiver',
    header: 'Customer',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IBenefitsData) => data.benefitReceiver || 'N/A',
  },
  {
    name: 'benefitType',
    header: 'Type of Benefit',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'benefitPeriod',
    header: 'Benefit Period',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IBenefitsData) => data.benefitPeriod || 'N/A',
  },
  {
    name: 'benefitAmount',
    header: 'Benefit Amount',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IBenefitsData) => `£${data.benefitAmount}`,
  },
  {
    name: 'isMeansBenefit',
    header: 'Means Benefit',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IBenefitsData) => `${data.isMeansBenefit ? 'Yes' : 'No'}`,
  },
]

export const eligibleBenefitsTableColumns = [
  {
    name: 'benefitReceiver',
    header: 'Customer',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IEligibleBenefitsData) => data.benefitReceiver || 'N/A',
  },
  {
    name: 'benefitType',
    header: 'Type of Benefit',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'benefitPeriod',
    header: 'Benefit Period',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IEligibleBenefitsData) => data.benefitPeriod || 'N/A',
  },
  {
    name: 'benefitAmount',
    header: 'Benefit Amount',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IEligibleBenefitsData) => `£${data.benefitAmount}`,
  },
]

export const givenCustomerGuidanceOptions = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Advised To Check Their Own Entitlement',
    value: 'advisedToCheckTheirOwnEntitlement',
  },
]
