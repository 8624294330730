import React, { useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import { Spin } from 'antd'

import {
  ControlsWrapper,
  FileInputLabel,
  StyledCell,
  StyledDownloadButton,
  StyledFileInput,
  StyledHeader,
  StyledSearchInput,
  StyledTable,
  StyledWrapper,
} from './XLSXViewer.styles'
import config from 'config'
import { message } from 'antd'

const resource_url =
  process.env.REACT_APP_STAGE === 'production'
    ? 'https://perc-resources-prod.s3.eu-west-2.amazonaws.com/lenders_criteria.xlsx'
    : 'https://perc-resources.s3.eu-west-2.amazonaws.com/lenders_criteria.xlsx'

const XLSXViewer: React.FC = () => {
  const [data, setData] = useState<any[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [fileUploaded, setFileUploaded] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        // Add a cache-busting query parameter
        const cacheBuster = new Date().getTime()
        const response = await fetch(`${resource_url}?cb=${cacheBuster}`)
        const arrayBuffer = await response.arrayBuffer()
        const workbook = XLSX.read(arrayBuffer, { type: 'array' })
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: true, defval: '' })

        setData(jsonData)
      } catch (error) {
        console.error('Error reading XLSX file:', error)
      }
      setIsLoading(false)
    }

    fetchData()
  }, [fileUploaded])

  const handleSearchChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setSearchValue(event.currentTarget.value)
    }
  }
  const filteredData = data?.slice(1).filter((row: any[]) => row.some((cell: any) => String(cell)?.toLowerCase().includes(searchValue?.toLowerCase())))

  const highlightText = (text: string, highlight: string) => {
    if (text === undefined || text === null) {
      return '-'
    }
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
    return <span>{parts.map((part) => (part?.toLowerCase() === highlight?.toLowerCase() ? <mark>{part}</mark> : part))}</span>
  }

  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]

      const formData = new FormData()

      // Append the file to the formData only if it is not null
      if (file) {
        formData.append('file', file)
      }

      const response = await fetch(`${config.API_URL}/users/lender-file-upload`, {
        method: 'POST',
        body: formData,
      })

      if (!response.ok) {
        message.error('Upload failed')
        throw new Error('Upload failed')
      }

      message.success('Operation finished successfully')
      setFileUploaded((prevState) => !prevState)
    }
  }

  return (
    <StyledWrapper>
      <ControlsWrapper>
        <StyledSearchInput type="text" onKeyDown={handleSearchChange} placeholder="Search..." />
        <StyledFileInput id="fileInput" type="file" onChange={handleFileInput} required />
        <FileInputLabel htmlFor="fileInput">Update The File</FileInputLabel>
        <StyledDownloadButton href={resource_url} download>
          Download File
        </StyledDownloadButton>
      </ControlsWrapper>
      {isLoading ? ( // Conditionally render loading indicator
        <Spin size="large" />
      ) : (
        <StyledTable>
          <thead>
            {data?.slice(0, 1)?.map((row, index) => (
              <tr key={index}>
                {Object.values(row).map((cell, index) => (
                  <StyledHeader key={index}>{highlightText(String(cell), searchValue)}</StyledHeader>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {filteredData?.length > 0 ? (
              filteredData.map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((cell, index) => (
                    <StyledCell key={index}>{highlightText(String(cell), searchValue)}</StyledCell>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={data[0]?.length || 1}>No data found</td>
              </tr>
            )}
          </tbody>
        </StyledTable>
      )}
    </StyledWrapper>
  )
}

export default XLSXViewer
