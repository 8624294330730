import { Form, Modal } from 'antd'

import { StyledWrapper, Styled2x2Grid } from './PersonPresentDuringFactFindModal.styles'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import TextInput from 'components/CustomFormItem/CustomFormItem'
import { relationshipWithCustomerOptions } from './PersonPresentDuringFactFindModal.helper'
import Loading from 'components/Loading/Loading'
import usePersonPresentDuringFactFindModal from 'hooks/clients/Modals/usePersonPresentDuringFactFindModal'
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox'
import GoogleAddressInput from 'components/GoogleAddressInput/GoogleAddressInput'
import CustomSwitch from 'components/CustomSwitch/CustomSwitch'
import { titleOptions } from 'utils/commonOptions'

const PersonPresentDuringFactFindModal = () => {
  const {
    modalState,
    handleCancelClick,
    handleConfirmClick,
    formData,
    handleCustomCheckboxChange,
    handleAntdFormInputChange,
    handleCustomSelectChange,
    onPlaceSelect,
    showAddressDetails,
    showAddressDetailsChange,
    loading,
    form,
  } = usePersonPresentDuringFactFindModal()

  if (loading) {
    return <Loading />
  }

  return (
    <Modal
      title="Edit Person"
      open={modalState.isOpen}
      onOk={handleConfirmClick}
      onCancel={handleCancelClick}
      width={700}
      okText="Save"
      cancelText="Cancel"
      closable={false}
      className="edit-asset-modal"
      confirmLoading={modalState.loading}
    >
      {modalState.loading ? (
        <Loading />
      ) : (
        <StyledWrapper>
          <Form
            form={form}
            layout="vertical"
            size="large"
            name="basic"
            initialValues={formData}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
              marginTop: '8px',
              width: '100%',
            }}
          >
            <CustomCheckbox label="Title" onChange={handleCustomCheckboxChange} options={titleOptions} value={formData.title} name="title" />
            <TextInput label="Name *" name="name" placeholder="Name" onChange={handleAntdFormInputChange} />
            <CustomSelect
              label="Relationship with the customer"
              width={'100%'}
              placeholder="Relationship with the customer"
              defaultValue={formData.relationshipWithCustomer}
              onChange={handleCustomSelectChange}
              options={relationshipWithCustomerOptions}
              name="relationshipWithCustomer"
            />
            <Styled2x2Grid>
              <GoogleAddressInput label="Search for an Address" onPlaceSelect={onPlaceSelect} value={formData?.address?.formattedAddress} />
              <CustomSwitch checked={showAddressDetails} onChange={showAddressDetailsChange} label="Show Address Details" />
              {showAddressDetails && (
                <>
                  <TextInput
                    label="Address 1"
                    name="address.address1"
                    placeholder="Address 1"
                    onChange={handleAntdFormInputChange}
                    value={formData?.address?.address1}
                  />
                  <TextInput
                    label="Address 2"
                    name="address.address2"
                    placeholder="Address 2"
                    onChange={handleAntdFormInputChange}
                    value={formData?.address?.address2}
                  />
                  <TextInput label="Town" name="address.town" placeholder="Town*" onChange={handleAntdFormInputChange} value={formData?.address?.town} />
                  <TextInput label="County" name="address.county" placeholder="County" onChange={handleAntdFormInputChange} value={formData?.address?.county} />
                  <TextInput
                    label="Country"
                    name="address.country"
                    placeholder="Country"
                    onChange={handleAntdFormInputChange}
                    value={formData?.address?.country}
                  />
                  <TextInput
                    label="Postcode"
                    name="address.postcode"
                    placeholder="Postcode"
                    onChange={handleAntdFormInputChange}
                    value={formData?.address?.postcode}
                  />
                </>
              )}
              <TextInput label="Telephone" name="telephone" placeholder="Telephone" onChange={handleAntdFormInputChange} rules={['validateTelephoneNumber']} />
              <TextInput label="Email" name="email" placeholder="Email" onChange={handleAntdFormInputChange} rules={['validateEmail']} />
            </Styled2x2Grid>
          </Form>
        </StyledWrapper>
      )}
    </Modal>
  )
}

export default PersonPresentDuringFactFindModal
