import styled from 'styled-components'

export const FormTableContainer = styled.div<{ spacesAroundGrid: boolean }>`
  margin: ${({ spacesAroundGrid }) => (spacesAroundGrid ? '24px 0' : 'unset')};
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
`
export const TableContainer = styled.div<{ tableContainerBgColor: string; spacesAroundGrid: boolean; error: boolean }>`
  width: 100%;
  ${({ error }) => error && 'border: 1px solid #ff4d4f'};
  background-color: ${({ tableContainerBgColor }) => tableContainerBgColor || '#f8f8f8'};
  padding: ${({ spacesAroundGrid }) => (spacesAroundGrid ? '24px' : 'unset')};
`

export const FooterContainer = styled.div`
  width: 100%;
  background-color: #e3f2fd;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const TotalContainer = styled.div`
  width: 100%;
  background-color: #e3f2fd;

  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;

  padding: 24px;
`

export const StyledTableHeaderContainer = styled.div<{ headerGridGap: string | undefined }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: ${({ headerGridGap }) => (!!headerGridGap ? headerGridGap : '24px')};
`

export const StyledTableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledDescription = styled.p`
  margin-top: 8px;
  align-self: flex-start;
`

export const StyledErrorMessage = styled.p`
  padding: 8px;
  color: #ff4d4f;
`

export const StyledTitle = styled.h1`
  font-size: 1.25rem;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
`

export const StyledButtonsContainer = styled.div`
  display: flex;
  column-gap: 24px;
`

export const StyledButton = styled.button`
  padding: 8px 16px;
  font-size: 1rem;
`

export const StyledTotalText = styled.p`
  font-size: 16px;
`

export const StyledTotal = styled.p`
  font-weight: 600;
`
