/* eslint-disable no-useless-computed-key */
import { Form } from 'antd'
import Loading from 'components/Loading'
import CustomFormItem from 'components/CustomFormItem'
import useProperty from 'hooks/clients/DetailedForms/useProperty'
import CustomFooter from 'pages/ClientDetails/CustomFooter/CustomFooter'
import {
  ownershipOptions,
  houseOptions,
  solarPanelOptions,
  listedGradeOptions,
  moveToHomePeriodOptions,
  yesNoPossiblyOptions,
  loanRepaymentOnHomeMoveOptions,
  addressTableColumns,
  occupantsTableColumns,
} from '../Properties.helper'
import { StyledContainer, StyledTitle, StyledTitleContainer, StyledRequiredMsg, Styled2x2Grid } from '../Properties.styles'
import FormTable from 'components/FormTable/FormTable'
import { PoundIcon } from 'assets/images'
import GoogleAddressInput from 'components/GoogleAddressInput/GoogleAddressInput'
import CustomSwitch from 'components/CustomSwitch/CustomSwitch'
import { propertyTypeOptions, tenureTypeOptions, yesNoOptions } from 'utils/commonOptions'

const Properties = () => {
  const {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    flattenedFormData,
    handleAntdFormItemChange,
    handleCustomSelectChange,
    onPlaceSelect,
    showAddressDetails,
    showAddressDetailsChange,
    residentsOptions,
    handleAddPreviousAddress,
    addressDataSource,
    setSelectedAddressRows,
    displayAddressDeleteButton,
    handleDeleteAddressModal,
    handleAddOccupant,
    occupantsDataSource,
    displayOccupantDeleteButton,
    setSelectedOccupantsRows,
    handleDeleteOccupantModal,
    displayPreviousAddressEditButton,
    handlePreviousAddressEditModal,
    displayOccupantEditButton,
    handleOccupantEditModal,
    handleAddOtherResident,
    setSelectedOtherResidentRows,
    displayOtherResidentsDeleteButton,
    handleDeleteOtherResidentModal,
    displayOtherResidentEditButton,
    handleOtherResidentEditModal,
    otherResidentsDataSource,
    floodRiskResourceLink,
    form,
  } = useProperty()

  if (loading) {
    return <Loading />
  }
  return (
    <StyledContainer>
      <StyledTitleContainer>
        <StyledTitle>About Your Property</StyledTitle>
        <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
      </StyledTitleContainer>
      <Form
        form={form}
        layout="vertical"
        size="large"
        name="basic"
        initialValues={flattenedFormData}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '12px',
          marginTop: '8px',
          width: '100%',
        }}
        onFinishFailed={onFinishFailed}
        onFinish={onSubmit}
      >
        <CustomFormItem
          label="Are you purchasing the property to be mortgaged? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="purchasingPropertyToBeMortgaged"
          value={formData.purchasingPropertyToBeMortgaged}
          type="radio"
          rules={['isRequired']}
        />
        <Styled2x2Grid>
          <GoogleAddressInput label="Search for an Address" onPlaceSelect={onPlaceSelect} value={formData?.address?.formattedAddress} />
          <CustomSwitch checked={showAddressDetails} onChange={showAddressDetailsChange} label="Show Address Details" />
          {showAddressDetails && (
            <>
              <CustomFormItem
                label="Address 1"
                name="address.address1"
                placeholder="Address 1"
                onChange={handleAntdFormItemChange}
                value={formData?.address?.address1}
              />
              <CustomFormItem
                label="Address 2"
                name="address.address2"
                placeholder="Address 2"
                onChange={handleAntdFormItemChange}
                value={formData?.address?.address2}
              />
              <CustomFormItem label="Town" name="address.town" placeholder="Town*" onChange={handleAntdFormItemChange} value={formData?.address?.town} />
              <CustomFormItem label="County" name="address.county" placeholder="County" onChange={handleAntdFormItemChange} value={formData?.address?.county} />
              <CustomFormItem
                label="Country"
                name="address.country"
                placeholder="Country"
                onChange={handleAntdFormItemChange}
                value={formData?.address?.country}
              />
              <CustomFormItem
                label="Postcode"
                name="address.postcode"
                placeholder="Postcode"
                onChange={handleAntdFormItemChange}
                value={formData?.address?.postcode}
              />
            </>
          )}
        </Styled2x2Grid>
        <CustomFormItem
          label="In Residence? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="inResidence"
          value={formData.inResidence}
          type="radio"
          rules={['isRequired']}
        />
        {formData.inResidence && (
          <>
            <FormTable
              columns={occupantsTableColumns}
              dataSource={otherResidentsDataSource}
              title="Other Residents"
              addButtonText="Add New Resident"
              handleAddButton={handleAddOtherResident}
              setSelectedRows={setSelectedOtherResidentRows}
              displayDeleteButton={displayOtherResidentsDeleteButton}
              handleDeleteModal={handleDeleteOtherResidentModal}
              displayEditButton={displayOtherResidentEditButton}
              handleEditModal={handleOtherResidentEditModal}
            />
            <CustomFormItem
              label="Who resides / will reside here?"
              width={'100%'}
              placeholder="Who resides / will reside here?"
              onChange={handleCustomSelectChange}
              options={residentsOptions}
              name="residents"
              type="select"
              mode="multiple"
            />
            <CustomFormItem
              label="Lived at this address for longer than 3 years?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="longTermResident"
              value={formData.longTermResident}
              type="radio"
            />
            {formData.longTermResident === false && (
              <FormTable
                columns={addressTableColumns}
                dataSource={addressDataSource}
                title="Previous Address History"
                addButtonText="Add New Address"
                handleAddButton={handleAddPreviousAddress}
                setSelectedRows={setSelectedAddressRows}
                displayDeleteButton={displayAddressDeleteButton}
                handleDeleteModal={handleDeleteAddressModal}
                displayEditButton={displayPreviousAddressEditButton}
                handleEditModal={handlePreviousAddressEditModal}
              />
            )}
          </>
        )}
        <CustomFormItem
          label="Registered on Title Deeds?"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="registeredOnTitleDeeds"
          value={formData.registeredOnTitleDeeds}
          type="radio"
        />
        {formData.registeredOnTitleDeeds && (
          <CustomFormItem
            label="People - Registered on Title Deeds"
            width={'100%'}
            placeholder="People - Registered on Title Deeds"
            onChange={handleCustomSelectChange}
            options={residentsOptions}
            name="registeredOnTitleDeedsPeople"
            type="select"
            mode="multiple"
          />
        )}
        <StyledTitle style={{ marginTop: '44px' }}>Property Details</StyledTitle>
        <CustomFormItem
          label="Type of Ownership *"
          width={'100%'}
          placeholder="Type of Ownership *"
          onChange={handleCustomSelectChange}
          options={ownershipOptions}
          name="ownershipType"
          type="select"
          rules={['isRequired']}
        />
        <CustomFormItem
          label="Property Value *"
          name="propertyValue"
          placeholder="Property Value *"
          toolTipText="Note that min property value at the moment is £70000"
          onChange={handleAntdFormItemChange}
          inputPrefixImg={PoundIcon}
          prefixImgHeight={12}
          value={formData.propertyValue}
          type="number"
          rules={['isRequired']}
          min={70000}
        />
        <CustomFormItem
          label="Date of Purchase"
          onChange={handleAntdFormItemChange}
          name="purchaseDate"
          placeholder="DD/MM/YYYY"
          type="date"
          value={formData.purchaseDate}
        />
        <CustomFormItem
          label="Is this property currently mortgaged? STD *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="isPropertyCurrentlyMortgaged"
          value={formData.isPropertyCurrentlyMortgaged}
          type="radio"
          rules={['isRequired']}
        />
        <CustomFormItem
          label="Tenure Type *"
          onChange={handleAntdFormItemChange}
          options={tenureTypeOptions}
          name="tenureType"
          value={formData.tenureType}
          type="radio"
          rules={['isRequired']}
        />
        {formData.tenureType === 'Leasehold' && (
          <CustomFormItem
            label="Lease term remaining(years)"
            name="leasePeriod"
            placeholder="Lease term remaining(years)"
            onChange={handleAntdFormItemChange}
            type="number"
          />
        )}
        <CustomFormItem
          label="Have you had the property valued in the last 6 months? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="isPropertyValuedInLastSixMonth"
          value={formData.isPropertyValuedInLastSixMonth}
          type="radio"
          rules={['isRequired']}
        />
        {formData.isPropertyValuedInLastSixMonth && (
          <CustomFormItem
            label="Date valued, By whom and valuation given"
            name="valuationDetails"
            placeholder="Date valued, By whom and valuation given"
            onChange={handleAntdFormItemChange}
          />
        )}
        <CustomFormItem
          label="Property Type *"
          width={'100%'}
          placeholder="Property Type "
          onChange={handleCustomSelectChange}
          options={propertyTypeOptions}
          name="propertyType"
          type="select"
          rules={['isRequired']}
        />
        <CustomFormItem
          label="Is this property Age Restricted or Sheltered Housing? *"
          onChange={handleAntdFormItemChange}
          options={houseOptions}
          name="isPropertyAgeRestrictedOrShelteredHousing"
          value={formData.isPropertyAgeRestrictedOrShelteredHousing}
          type="radio"
          rules={['isRequired']}
        />
        {formData.isPropertyAgeRestrictedOrShelteredHousing === 'ageRestricted' && (
          <CustomFormItem
            label='If you know the "Sell on fee (%)" otherwise known as "Sinking Fund", please enter this here'
            name="sinkingFund"
            placeholder='If you know the "Sell on fee (%)" otherwise known as "Sinking Fund", please enter this here'
            onChange={handleAntdFormItemChange}
            type="number"
          />
        )}
        {formData.isPropertyAgeRestrictedOrShelteredHousing === 'other' && (
          <CustomFormItem label="Other, Please describe" name="housingDescription" placeholder="Other, Please describe" onChange={handleAntdFormItemChange} />
        )}
        <CustomFormItem
          label="Annual service charges & Ground rent (if any) - leave blank if not applicable"
          name="annualServiceChargesGroundRent"
          placeholder="Annual service charges & Ground rent (if any) - leave blank if not applicable"
          onChange={handleAntdFormItemChange}
          value={formData.annualServiceChargesGroundRent}
          inputPrefixImg={PoundIcon}
          prefixImgHeight={12}
          type="number"
        />
        <CustomFormItem
          label="Is this property Ex-Local Authority or MOD?"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="isExLocalAuthorityOrMODProperty"
          value={formData.isExLocalAuthorityOrMODProperty}
          type="radio"
        />
        {formData.isExLocalAuthorityOrMODProperty && (
          <CustomFormItem
            label="Ex Local Authority; Please describe"
            name="propertyAuthorityDescription"
            placeholder="Ex Local Authority; Please describe"
            onChange={handleAntdFormItemChange}
          />
        )}
        <CustomFormItem
          label="Is this property a buy to let? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="isPropertyBTL"
          value={formData.isPropertyBTL}
          type="radio"
          rules={['isRequired']}
        />
        <CustomFormItem
          label="Is this property completely standard in its type, construction, location and free of any issue that may present a lending criteria issue? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="isPropertyStandardAndFreeOfAnyIssue"
          value={formData.isPropertyStandardAndFreeOfAnyIssue}
          type="radio"
          rules={['isRequired']}
        />
        {formData.isPropertyStandardAndFreeOfAnyIssue === false && (
          <>
            <CustomFormItem
              label="Thatched roof?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="hasThatchedRoof"
              value={formData.hasThatchedRoof}
              type="radio"
            />
            <CustomFormItem
              label="Steel frame construction?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="isSteelFrameConstruction"
              value={formData.isSteelFrameConstruction}
              type="radio"
            />
            {formData.isSteelFrameConstruction && (
              <CustomFormItem
                label="Please describe the type of steel frame (if known) and any other relevant information"
                name="steelFrameDescription"
                placeholder="Please describe the type of steel frame"
                onChange={handleAntdFormItemChange}
              />
            )}
            <CustomFormItem
              label="Timber Frame construction?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="isTimberFrameConstruction"
              value={formData.isTimberFrameConstruction}
              type="radio"
            />
            {formData.isTimberFrameConstruction && (
              <CustomFormItem
                label="Please describe the type of timber frame (if known) and any other relevant information, including any treatments, housebuilder etc"
                name="timberFrameDescription"
                placeholder="Please describe the type of timber frame"
                onChange={handleAntdFormItemChange}
              />
            )}
            <CustomFormItem
              label="Is this property situated close to Commercial Premises?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="isPropertyCloseToCommercialPremises"
              value={formData.isPropertyCloseToCommercialPremises}
              type="radio"
            />
            {formData.isPropertyCloseToCommercialPremises && (
              <CustomFormItem
                label="Property close to Commercial Premises; please provide details"
                name="propertyCloseToCommercialPremisesDescription"
                placeholder="Property close to Commercial Premises; please provide details"
                onChange={handleAntdFormItemChange}
              />
            )}
            <CustomFormItem
              label="Is the property listed?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="isPropertyListed"
              value={formData.isPropertyListed}
              type="radio"
            />
            {formData.isPropertyListed && (
              <CustomFormItem
                label="Grade?"
                onChange={handleAntdFormItemChange}
                options={listedGradeOptions}
                name="listedGrade"
                value={formData.listedGrade}
                type="radio"
              />
            )}
            <CustomFormItem
              label="Are any walls of property single skin?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="hasPropertySingleSkinWall"
              value={formData.hasPropertySingleSkinWall}
              type="radio"
            />
            {formData.hasPropertySingleSkinWall && (
              <CustomFormItem
                label="What percentage this represents of the total wall area?"
                name="singleSkinWallPercentage"
                placeholder="What percentage this represents of the total wall area?"
                onChange={handleAntdFormItemChange}
                type="number"
              />
            )}
            <CustomFormItem
              label="Does property have any element of flat roof?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="hasElementOfFlatRoof"
              value={formData.hasElementOfFlatRoof}
              type="radio"
            />
            {formData.hasElementOfFlatRoof && (
              <CustomFormItem
                label="What percentage this represents of the total wall area?"
                name="flatRoofElementPercentage"
                placeholder="What percentage this represents of the total wall area?"
                onChange={handleAntdFormItemChange}
                type="number"
              />
            )}
            <CustomFormItem
              label="Does property have more than 1 acre of land?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="hasMoreThanOneAcreOfLand"
              value={formData.hasMoreThanOneAcreOfLand}
              type="radio"
            />
            {formData.hasMoreThanOneAcreOfLand && (
              <CustomFormItem label="Acreage" name="propertyLandAcreage" placeholder="Acreage" onChange={handleAntdFormItemChange} />
            )}
            <CustomFormItem
              label="Is this property subject to any agricultural ties?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="isSubjectToAgriculturalTies"
              value={formData.isSubjectToAgriculturalTies}
              type="radio"
            />
            {formData.isSubjectToAgriculturalTies && (
              <CustomFormItem label="Details" name="agriculturalTiesDetails" placeholder="Details" onChange={handleAntdFormItemChange} />
            )}
            <CustomFormItem
              label="Is this property used for commercial purposes?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="usedForCommercialPurposes"
              value={formData.usedForCommercialPurposes}
              type="radio"
            />
            {formData.usedForCommercialPurposes && (
              <CustomFormItem label="Details" name="usedForCommercialPurposesDetails" placeholder="Details" onChange={handleAntdFormItemChange} />
            )}
            <CustomFormItem
              label="Has the property has ever suffered from subsidence, heave or landslip?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="hasSufferedNaturalDisaster"
              value={formData.hasSufferedNaturalDisaster}
              type="radio"
            />
            {formData.hasSufferedNaturalDisaster && (
              <>
                <CustomFormItem label="Details" name="naturalDisasterDetails" placeholder="Details" onChange={handleAntdFormItemChange} />
                <CustomFormItem label="Date" name="naturalDisasterDate" placeholder="Date" type="date" onChange={handleAntdFormItemChange} />
                <CustomFormItem
                  label="Remedial work undertaken to correct"
                  name="workTakenToCorrect"
                  placeholder="Remedial work undertaken to correct"
                  onChange={handleAntdFormItemChange}
                />
              </>
            )}
          </>
        )}
        <CustomFormItem
          label="Are there any solar panels attached to property? *"
          onChange={handleAntdFormItemChange}
          options={solarPanelOptions}
          name="solarPanels"
          value={formData.solarPanels}
          type="radio"
          rules={['isRequired']}
        />
        {(formData.solarPanels === 'leased' || formData.solarPanels === 'owned') && (
          <CustomFormItem
            onChange={handleAntdFormItemChange}
            label="Solar Panels: Any other details"
            name="solarPanelDetails"
            placeholder="Solar Panels: Any other details"
          />
        )}
        <CustomFormItem
          onChange={handleAntdFormItemChange}
          label="What year was the property built?"
          name="propertyBuiltYear"
          placeholder="What year was the proper "
          type="number"
        />
        <CustomFormItem
          label="Have you any plans to move home in the future? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="hasAnyPlanToMoveHomeInFuture"
          value={formData.hasAnyPlanToMoveHomeInFuture}
          type="radio"
          rules={['isRequired']}
        />
        {formData.hasAnyPlanToMoveHomeInFuture && (
          <>
            <Styled2x2Grid>
              <CustomFormItem
                label="In what time frame are you planning to move home?"
                width={'100%'}
                placeholder="In what time frame are you planning to move home?"
                onChange={handleCustomSelectChange}
                options={moveToHomePeriodOptions}
                name="moveToHomePeriod"
                type="select"
              />
              <CustomFormItem
                label="What are your reasons for planning a house move?"
                name="houseMoveReasons"
                placeholder="What are your reasons for planning a house move?"
                onChange={handleAntdFormItemChange}
              />
            </Styled2x2Grid>
            <CustomFormItem
              label="Have you any plans to move to a property designed for later living (i.e. sheltered housing / warden assisted)"
              onChange={handleAntdFormItemChange}
              options={yesNoPossiblyOptions}
              name="plansToMoveToLaterLivingProperty"
              value={formData.plansToMoveToLaterLivingProperty}
              type="radio"
            />
            <CustomFormItem
              label="Are you likely to repay part or all of loan when you move home or transfer the loan to new property?"
              onChange={handleAntdFormItemChange}
              options={loanRepaymentOnHomeMoveOptions}
              name="loanRepaymentOnHomeMove"
              value={formData.loanRepaymentOnHomeMove}
              type="radio"
            />
          </>
        )}
        <CustomFormItem
          label="Do you have any occupants over the age of 17 living with you? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="hasAdultOccupantsLivingWith"
          value={formData.hasAdultOccupantsLivingWith}
          type="radio"
          rules={['isRequired']}
        />
        {formData.hasAdultOccupantsLivingWith && (
          <FormTable
            columns={occupantsTableColumns}
            dataSource={occupantsDataSource}
            title="Occupants"
            addButtonText="Add New Occupant"
            handleAddButton={handleAddOccupant}
            setSelectedRows={setSelectedOccupantsRows}
            displayDeleteButton={displayOccupantDeleteButton}
            handleDeleteModal={handleDeleteOccupantModal}
            displayEditButton={displayOccupantEditButton}
            handleEditModal={handleOccupantEditModal}
          />
        )}
        <CustomFormItem
          label="Is the property held in Trust?"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="isPropertyHeldInTrust"
          value={formData.isPropertyHeldInTrust}
          type="radio"
        />
        {formData.isPropertyHeldInTrust && (
          <CustomFormItem
            label="Provide details"
            placeholder="Provide details"
            value={formData.whyIsPropertyHeldInTrust}
            name="whyIsPropertyHeldInTrust"
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        <CustomFormItem
          label="Does the property have Spray Foam Insulation?"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="hasPropertySprayFoamInsulation"
          value={formData.hasPropertySprayFoamInsulation}
          type="radio"
        />
        {formData.hasPropertySprayFoamInsulation && (
          <CustomFormItem
            label="Provide details"
            placeholder="Provide details"
            value={formData.sprayFoamInsulationDetails}
            name="sprayFoamInsulationDetails"
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        <CustomFormItem
          label="Is the property close to an electricity pylon?"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="isPropertyCloseToElectricityPylon"
          value={formData.isPropertyCloseToElectricityPylon}
          type="radio"
        />
        {formData.isPropertyCloseToElectricityPylon && (
          <CustomFormItem
            label="Provide details"
            placeholder="Provide details"
            value={formData.electricityPylonDetails}
            name="electricityPylonDetails"
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        <CustomFormItem
          label="Has the property ever flooded or is it on a flood risk zone?"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="hasPropertyFloodedOrIsItInRiskZone"
          value={formData.hasPropertyFloodedOrIsItInRiskZone}
          type="radio"
        />
        {formData.hasPropertyFloodedOrIsItInRiskZone && (
          <>
            <a
              rel="noreferrer"
              target="_blank"
              href={floodRiskResourceLink}
              style={{
                textDecoration: 'underline',
              }}
            >
              Flood Risk - Please Check
            </a>
            <CustomFormItem
              label="Provide details"
              placeholder="Provide details"
              value={formData.floodingDetails}
              name="floodingDetails"
              onChange={handleAntdFormItemChange}
              rows={4}
              type="textarea"
            />
          </>
        )}
        <CustomFooter loading={loading} setSaveType={setSaveType} />
      </Form>
    </StyledContainer>
  )
}

export default Properties
