import Grid from 'components/Grid'
import CustomPagination from 'components/CustomPagination'

import {
  StyledButtonsContainer,
  StyledContainer,
  StyledLinkClientButton,
  StyledPaginationContainer,
  StyledTableContainer,
  StyledTableTitle,
} from './WrittenBusiness.styles'
import { IWrittenBusinessProps } from './WrittenBusiness.interface'
import LinkClientDrawer from 'drawers/LinkClientDrawer/LinkClientDrawer'
import { generateDetailsColumns, writtenBusinessReportColumns, writtenBusinessReportDataSource } from './constants'
import { useContext } from 'react'
import { AuthContext } from 'contexts'
import EditDeleteButton from 'components/EditDeleteButton'

const WrittenBusiness = ({
  open,
  handleDrawerClose,
  handleOpenDrawer,
  loading,
  clientsOptions,
  handleCustomSelectChange,
  formData,
  handleFormFieldChange,
  handleSubmit,
  form,
  detailsDataSource,
  count,
  limit,
  refetch,
  statusOptions,
  setSelectedRows,
  displayEditButton,
  handleEdit,
  displayDeleteButton,
  handleDeleteModal,
  formState,
}: IWrittenBusinessProps) => {
  const { user: currentUser } = useContext(AuthContext)
  const detailsColumns = generateDetailsColumns(currentUser)
  return (
    <StyledContainer>
      <StyledTableContainer>
        <StyledTableTitle>Report</StyledTableTitle>
        <Grid columns={writtenBusinessReportColumns} dataSource={writtenBusinessReportDataSource} checkboxColumn={false} />
        <StyledButtonsContainer>
          {displayEditButton && <EditDeleteButton onClick={handleEdit} type="edit" value="Edit" />}
          {displayDeleteButton && <EditDeleteButton onClick={handleDeleteModal} type="delete" value="Delete" />}
          <StyledLinkClientButton onClick={handleOpenDrawer}>Link Client</StyledLinkClientButton>
        </StyledButtonsContainer>
        <StyledTableTitle>Details</StyledTableTitle>
        <Grid setSelectedRows={setSelectedRows} columns={detailsColumns} dataSource={detailsDataSource} checkboxColumn={true} />
      </StyledTableContainer>
      <StyledPaginationContainer>
        <CustomPagination pageSize={limit} count={count} refetch={refetch} />
      </StyledPaginationContainer>
      <LinkClientDrawer
        loading={loading}
        open={open}
        handleDrawerClose={handleDrawerClose}
        clientsOptions={clientsOptions}
        handleCustomSelectChange={handleCustomSelectChange}
        formData={formData}
        handleFormFieldChange={handleFormFieldChange}
        handleSubmit={handleSubmit}
        form={form}
        statusOptions={statusOptions}
        formState={formState}
      />
    </StyledContainer>
  )
}

export default WrittenBusiness
