import { Checkbox } from 'antd'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  width: 100%;
  background-color: #fefefe;

  display: flex;
  justify-content: center;
  align-items: flex-start;
`

export const StyledLabelsContainer = styled.div``

export const StyledFieldsContainer = styled.div``

export const StyledCheckboxesContainer = styled.div``

export const StyledInnerContainer = styled.div`
  padding: 36px 32px;

  width: 1192px;

  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  column-gap: 24px;
`

export const StyledTabTitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;

  color: #120224;
`

export const StyledBreakLine = styled.div`
  margin: 20px 0 16px 0;

  width: 100%;
  height: 0;
  border: 1px solid #d8d8d8;
  background-color: #d8d8d8;
`

export const StyledLabel = styled.p`
  height: 48px;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #120224;

  display: flex;
  align-items: center;

  margin-bottom: 12px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const CheckboxesWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

export const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-wrapper .ant-checkbox-inner,
  .ant-checkbox .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    width: 18px !important;
    height: 18px !important;
    border-color: #323232;
  }
`

export const CheckBoxWithLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 12px;

  height: 48px;
  margin-bottom: 12px;
`

export const StyledCheckboxLabel = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #120224;
`
