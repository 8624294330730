import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useGetContentfulDataByTitle } from 'hooks/useContentful'

interface INewsAndTrainingSubPage {
  title?: string
  type?: string
  excerpt?: string
  date?: string
  content?: any
}

const useNewsAndTrainingSubPage = () => {
  const [data, setData] = useState<INewsAndTrainingSubPage>({})
  const { title } = useParams()
  const { data: initialData = [], isLoading } = useGetContentfulDataByTitle('newsAndTraining', title || '')

  useEffect(() => {
    setData({
      title: initialData?.fields?.title,
      type: initialData?.fields?.type,
      excerpt: initialData?.fields?.excerpt,
      date: initialData?.fields?.date,
      content: initialData?.fields?.content,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const displayLoading = isLoading || !data

  return { data, displayLoading }
}

export default useNewsAndTrainingSubPage
