import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import message from 'antd/lib/message'

import { commonHelper } from 'helpers'
import AuthContext from 'contexts/AuthContext'
import { useCustomMutation } from 'services/shared/mutation'
import { SignUpPayload } from 'services/shared/mutation.payload'
import { signUpMutation } from 'gql/user'
import { eventManager } from 'utils'

const useSignUp = () => {
  const { refetch } = useContext(AuthContext)
  const signUp = useCustomMutation<SignUpPayload>({ mutation: signUpMutation })
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [avatar, setAvatar] = useState<any>()
  const [hasIressDetails, setHasIressDetails] = useState(false)

  const handleSwitchChange = (e: any) => {
    setHasIressDetails(e.target.checked)
  }

  const handleAvatarUpload = (e: any) => {
    e.persist()
    const file = e.target.files[0]

    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = function () {
      const dataUrl = reader.result
      setAvatar(dataUrl)
      // Use the data URL to display the image or upload it to a server
    }

    reader.onerror = function () {
      console.error('File could not be read!')
    }
  }

  const handleSubmit = async ({
    email,
    password,
    firstName,
    lastName,
    phoneNumber,
    // iress fields
    iressLoginId,
    iressPassword,
    iressUserId,
    fcaNumber,
  }: {
    email: string
    password: string
    firstName: string
    lastName: string
    phoneNumber: string
    // iress fields
    iressLoginId: string
    iressPassword: string
    iressUserId: number
    fcaNumber: string
  }): Promise<void> => {
    try {
      setLoading(true)

      if (!(fcaNumber?.length === 6 || fcaNumber?.length === 7)) {
        message.info('Please check FCA number, it should be 6 or 7 digits')
        setLoading(false)
        return
      }

      const { errors, data } = await signUp({
        email,
        password,
        firstName,
        lastName,
        phoneNumber,
        avatar,

        // iress fields
        iressLoginId,
        iressPassword,
        iressUserId: Number(iressUserId),
        fcaNumber: Number(fcaNumber),
        isPERCUser: true,
      })
      if (errors?.length) {
        setLoading(false)
        const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
        message.error(errMessage)
        return
      }

      const accessToken = data.signUp.accessToken
      localStorage.setItem('accessToken', accessToken)

      await refetch()
      setLoading(false)

      navigate('/clients')

      if (iressLoginId || iressPassword || iressUserId) {
        eventManager.openIressDetailsModal(
          {},
          {
            confirm: refetch,
          },
        )
      }
    } catch (err) {
      // pass.
    }
  }

  return {
    handleAvatarUpload,
    avatar,
    handleSubmit,
    loading,
    hasIressDetails,
    handleSwitchChange,
  }
}

export default useSignUp
