import { StyledCheckboxContainer, StyledCheckbox, StyledCheckboxLabel } from './CheckboxWithLabel.styles'
import { CheckboxWithLabelProps } from './CheckboxWithLabel.types'

const CheckboxWithLabel = ({ checked, onChange, label, name }: CheckboxWithLabelProps) => {
  return (
    <StyledCheckboxContainer>
      <StyledCheckbox checked={checked} onChange={onChange.bind(this, name)} />
      <StyledCheckboxLabel>{label}</StyledCheckboxLabel>
    </StyledCheckboxContainer>
  )
}

export default CheckboxWithLabel
