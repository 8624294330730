import { useState } from 'react'
import Layout from 'components/Layout'
import MainContentContainer from 'components/MainContentContainer'
import Loading from 'components/Loading'
import CustomBreadcrumb from 'components/CustomBreadcrumb'

import { useBreadcrumb, useVideoResources } from 'hooks/resources'

// Simple Modal Component
const Modal = ({ children, onClose }: any) => {
  const handleClickOutside = (e: any) => {
    if (e.target.id === 'modal-overlay') {
      onClose()
    }
  }

  return (
    <div
      id="modal-overlay"
      onClick={handleClickOutside}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          width: '800px',
          height: '500px',
          backgroundColor: 'black', // Add black background
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </div>
    </div>
  )
}

const VideoResources = () => {
  const { videos, displayLoading } = useVideoResources()
  const { breadcrumbItems, handleBreadcrumbTabChange } = useBreadcrumb()
  const [selectedVideo, setSelectedVideo] = useState<{ title: string; url: string } | null>(null)

  const handleVideoClick = (video: any) => {
    setSelectedVideo(video)
  }

  const handleCloseModal = () => {
    setSelectedVideo(null)
  }

  return (
    <Layout>
      <MainContentContainer pageName="Video Resources">
        <CustomBreadcrumb breadcrumbItems={breadcrumbItems} handleBreadcrumbTabChange={handleBreadcrumbTabChange} />
        <div
          style={{
            padding: '24px 20px',
          }}
        >
          {displayLoading ? (
            <Loading />
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {videos.map((video: any, index: number) => (
                <div key={index} style={{ cursor: 'pointer' }} onClick={() => handleVideoClick(video)}>
                  <h3 style={{ marginBottom: '10px', textDecoration: 'underline', color: 'blue' }}>{video.title}</h3>
                </div>
              ))}
            </div>
          )}
        </div>
        {selectedVideo && (
          <Modal onClose={handleCloseModal}>
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <video controls style={{ maxWidth: '100%', maxHeight: '100%' }}>
                <source src={selectedVideo.url} />
                Your browser does not support the video tag.
              </video>
            </div>
          </Modal>
        )}
      </MainContentContainer>
    </Layout>
  )
}

export default VideoResources
