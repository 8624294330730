export const benefitTypeOptions = [
  {
    label: 'Armed Forces Independence Payment',
    value: 'armedForcesIndependencePayment',
  },
  {
    label: 'Attendance Allowance',
    value: 'attendanceAllowance',
  },
  {
    label: 'Bereavement Allowance',
    value: 'bereavementAllowance',
  },
  {
    label: 'Bereavement Payment',
    value: 'bereavementPayment',
  },
  {
    label: 'Bereavement Support Payment',
    value: 'bereavementSupportPayment',
  },
  {
    label: "Blind Person's Allowance",
    value: 'blindPersonAllowance',
  },
  {
    label: 'Budgeting Loan / Budgeting Advance',
    value: 'budgetingLoanOrAdvance',
  },
  {
    label: "Carer's Benefit / Carer's Allowance",
    value: 'carersBenefitOrAllowance',
  },
  {
    label: 'Child Benefit',
    value: 'childBenefit',
  },
  {
    label: 'Cold Weather Payment',
    value: 'coldWeatherPayment',
  },
  {
    label: 'Council Tax Reduction',
    value: 'councilTaxReduction',
  },
  {
    label: 'Disability Living Allowance',
    value: 'disabilityLivingAllowance',
  },
  {
    label: 'Disabled Facilities Grants',
    value: 'disabledFacilitiesGrants',
  },
  {
    label: 'Discretionary Assistance Fund',
    value: 'discretionaryAssistanceFund',
  },
  {
    label: 'Discretionary Housing Payment',
    value: 'discretionaryHousingPayment',
  },
  {
    label: 'Employment and Support Allowance',
    value: 'employmentAndSupportAllowance',
  },
  {
    label: 'Free School Meals',
    value: 'freeSchoolMeals',
  },
  {
    label: 'Funeral Payments',
    value: 'funeralPayments',
  },
  {
    label: 'Guardians Allowance',
    value: 'guardiansAllowance',
  },
  {
    label: 'Healthy Start Food Vouchers',
    value: 'healthyStartFoodVouchers',
  },
  {
    label: 'Housing Benefit',
    value: 'housingBenefit',
  },
  {
    label: 'Incapacity Benefit',
    value: 'incapacityBenefit',
  },
  {
    label: 'Income Support',
    value: 'incomeSupport',
  },
  {
    label: 'Industrial Injuries Disablement Benefit',
    value: 'industrialInjuriesDisablementBenefit',
  },
  {
    label: 'Job Seekers Allowance',
    value: 'jobSeekersAllowance',
  },
  {
    label: 'Local Housing Allowance',
    value: 'localHousingAllowance',
  },
  {
    label: 'Local Welfare Assistance',
    value: 'localWelfareAssistance',
  },
  {
    label: 'Pension Credit',
    value: 'pensionCredit',
  },
  {
    label: 'Personal Independence Payment (PIP)',
    value: 'personalIndependencePayment',
  },
  {
    label: 'Reduced Earnings Allowance',
    value: 'reducedEarningsAllowance',
  },
  {
    label: 'Severe Disablement Premium',
    value: 'severeDisablementPremium',
  },
  {
    label: 'Statutory Adoption Pay',
    value: 'statutoryAdoptionPay',
  },
  {
    label: 'Statutory Paternity Pay',
    value: 'statutoryPaternityPay',
  },
  {
    label: 'Statutory Sick Pay',
    value: 'statutorySickPay',
  },
  {
    label: 'Support For Mortgage Interest (SMI)',
    value: 'supportForMortgageInterest',
  },
  {
    label: 'Tax Credits',
    value: 'taxCredits',
  },
  {
    label: 'Universal Credits',
    value: 'universalCredits',
  },
  {
    label: 'War Pensions Scheme',
    value: 'warPensionsScheme',
  },
  {
    label: 'Warm Homes Discount',
    value: 'warmHomesDiscount',
  },
  {
    label: 'Widows Parents Allowance',
    value: 'widowsParentsAllowance',
  },
  {
    label: 'Winter Fuel Payment',
    value: 'winterFuelPayment',
  },
  {
    label: 'Other (use notes)',
    value: 'other',
  },
]
