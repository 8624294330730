import PropertyCard from 'components/PropertyCard'
import useProperties from 'hooks/clients/DetailedForms/useProperties'
import { StyledContainer, StyledDescription, StyledCustomerCardsContainer, StyledAddNewPropertyBtn, StyledTitle } from './Properties.styles'

const Properties = ({ handleItemClick }: any) => {
  const { data, onEditClick, handleAddProperty, handleRemoveProperty } = useProperties({ handleItemClick })

  return (
    <StyledContainer>
      <StyledTitle>Properties</StyledTitle>
      <StyledDescription>
        Here is where you can enter and manage your customer’s property information. This information should relate to the property which Equity Release is
        being taken out on. If you add a property by mistake, please click the black pencil to edit.
      </StyledDescription>
      <StyledCustomerCardsContainer>
        {data.map((item: any) => (
          <PropertyCard
            key={item._id}
            editButtonClick={onEditClick.bind(this, item._id)}
            address={item?.address?.formattedAddress}
            handleRemoveProperty={handleRemoveProperty.bind(this, item._id)}
            displayDeleteButton={data?.length > 1}
          />
        ))}
      </StyledCustomerCardsContainer>
      <StyledAddNewPropertyBtn onClick={handleAddProperty}>{data?.length === 0 ? 'Add Property' : 'Add Another Property'}</StyledAddNewPropertyBtn>
    </StyledContainer>
  )
}

export default Properties
