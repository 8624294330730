import { useState, useEffect } from 'react'

import { eventManager } from 'utils'

const initialState = {
  isOpen: false,
  data: { header: '', desc: '', body: [] },
  cb: { confirm: () => null, cancel: () => null },
  loading: false,
}

const useQuoteResultNoteModal = () => {
  const [modalState, setModalState] = useState(initialState)

  useEffect(() => {
    eventManager.addListener('quote-result-note-modal', (data: any, cb: any) => {
      setModalState((state) => ({
        ...state,
        isOpen: data !== undefined,
        data,
        cb,
      }))
    })
  }, [setModalState])

  const handleClose = () => {
    setModalState((state) => ({ ...state, loading: false }))
    eventManager.closeQuoteResultNoteModal()
  }

  const handleCancelClick = async () => {
    try {
      setModalState((state) => ({ ...state, loading: true }))
      if (modalState.cb.cancel) {
        await modalState.cb.cancel()
      }
    } catch (err) {
      // console.error(err)
    }
    handleClose()
  }

  const handleConfirmClick = async () => {
    try {
      setModalState((state) => ({ ...state, loading: true }))

      if (modalState.cb.confirm) {
        await modalState.cb.confirm()
      }
    } catch (err) {
      // console.error(err)
    }
    setModalState((state) => ({ ...state, loading: false }))
    handleClose()
  }

  return {
    modalState,
    handleCancelClick,
    handleConfirmClick,
  }
}

export default useQuoteResultNoteModal
