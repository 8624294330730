import { useContext } from 'react'
import { Navigate } from 'react-router-dom'

import { AuthContext } from 'contexts'

interface ProtectedRouteProps {
  redirectPath?: string
  roleAccess: string[]
  children: any
}

const ProtectedRoute = ({ redirectPath = '/', roleAccess = [], children }: ProtectedRouteProps) => {
  const { user, loading } = useContext(AuthContext)
  const isAuth = !!localStorage.getItem('accessToken')
  if (!isAuth) {
    return <Navigate to={redirectPath} replace />
  }

  if (!loading) {
    if (roleAccess.includes(user.role)) {
      return children
    } else {
      return <Navigate to={redirectPath} replace />
    }
  }
}

export default ProtectedRoute
