import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from 'components/Layout'
import MainContentContainer from 'components/MainContentContainer'
import Loading from 'components/Loading'
import CustomBreadcrumb from 'components/CustomBreadcrumb'
import { contentfulDefaultOptions } from 'services/contentful'
import { useBreadcrumb, useFCAGuidelines } from 'hooks/resources'
import { PurpleArrowDown } from 'assets/images'

import { DropDownWrapper, DropdownTile, DropdownTileContent } from './FCAGuidelines.styles'

const FCAGuidelines = () => {
  const { data, displayLoading, handleClick } = useFCAGuidelines()
  const { breadcrumbItems, handleBreadcrumbTabChange } = useBreadcrumb()

  return (
    <Layout>
      <MainContentContainer pageName="Equity Release Guidelines">
      <CustomBreadcrumb breadcrumbItems={breadcrumbItems} handleBreadcrumbTabChange={handleBreadcrumbTabChange} />
        <DropDownWrapper>
          {displayLoading ? (
            <Loading />
          ) : (
            <>
              {' '}
              {data?.map((item) => (
                <div key={item.id}>
                  <DropdownTile onClick={() => handleClick(item.id)}>
                    <p>{item.title}</p>
                    <img src={PurpleArrowDown} alt='' width={33} height={25} />
                  </DropdownTile>
                  {item.isShown && <DropdownTileContent>{documentToReactComponents(item.content, contentfulDefaultOptions)}</DropdownTileContent>}
                </div>
              ))}
            </>
          )}
        </DropDownWrapper>
      </MainContentContainer>
    </Layout>
  )
}

export default FCAGuidelines
