import { useEffect, useState } from 'react'
import { Form, message } from 'antd'
import * as _ from 'lodash'

import { commonHelper } from 'helpers'
import { useGetClientCaseOverviewInfo, useGetPersonsPresentDuringFactFind } from 'services/clients/clients.service'
import { commonUtils, eventManager } from 'utils'

import useGeneral from './useGeneral'
import { addEditCaseOverviewMutation, deletePersonPresentDuringFactFindMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { AddEditCaseOverviewPayload, DeleteWithIdNumberAnd_IdsPayload } from 'services/shared/mutation.payload'

const useCaseOverView = () => {
  const [form] = Form.useForm()
  const deletePersonPresentDuringFactFind = useCustomMutation<DeleteWithIdNumberAnd_IdsPayload>({ mutation: deletePersonPresentDuringFactFindMutation })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const [showAddressDetails, setShowAddressDetails] = useState(false)

  const {
    data: { caseOverview },
    loading: formInitialDataLoading,
    refetch,
  } = useGetClientCaseOverviewInfo(idNumber)
  const { personsPresentDuringFactFind } = useGetPersonsPresentDuringFactFind(idNumber)

  const [loading, setLoading] = useState(formInitialDataLoading)
  const addEditCaseOverview = useCustomMutation<AddEditCaseOverviewPayload>({
    mutation: addEditCaseOverviewMutation,
    refetchQueries: ['getClientFormsProgress'],
  })
  const [formData, setFormData] = useState({
    factFindStartDate: caseOverview?.factFindStartDate,
    contactType: caseOverview?.contactType,
    customerQuantity: caseOverview?.customerQuantity || 1,
    detailsAndTermsOfIntroduction: caseOverview?.detailsAndTermsOfIntroduction,
    hasPowerOfAttorney: caseOverview?.hasPowerOfAttorney,
    powerOfAttorney: {
      name: caseOverview?.powerOfAttorney?.name,
      address: {
        formattedAddress: caseOverview?.powerOfAttorney?.address?.formattedAddress,
        address1: caseOverview?.powerOfAttorney?.address?.address1,
        address2: caseOverview?.powerOfAttorney?.address?.address2,
        town: caseOverview?.powerOfAttorney?.address?.town,
        county: caseOverview?.powerOfAttorney?.address?.county,
        country: caseOverview?.powerOfAttorney?.address?.country,
        postcode: caseOverview?.powerOfAttorney?.address?.postcode,
      },
      contactNumber: caseOverview?.powerOfAttorney?.contactNumber,
    },
    wasAnyOtherPersonPresent: caseOverview?.wasAnyOtherPersonPresent,
    mainPurposeOfDiscussion: caseOverview?.mainPurposeOfDiscussion,
    productResearchEnvironment: caseOverview?.productResearchEnvironment,
    isThirdPartyInvolved: caseOverview?.isThirdPartyInvolved,
    isWholeOfMarketOrTied: caseOverview?.isWholeOfMarketOrTied,
  })
  const flattenedFormData = commonUtils.flattenObject(formData)

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [loading])

  useEffect(() => {
    setLoading(formInitialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(caseOverview)
    delete newData?._id
    setFormData({ ...newData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInitialDataLoading])

  const handleCustomSelectChange = (name: string, value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value, e.target?.name)
    setFormData((prevState) => _.set(_.cloneDeep(prevState), e.target.name, parsedValue))
  }

  const handleThirdPartyInvolved = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      isThirdPartyInvolved: e.target.checked,
    }))
  }

  const handleWholeOfMarketOrTied = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      isWholeOfMarketOrTied: e.target.checked,
    }))
  }

  const handleSubmit = async () => {
    const { errors } = await addEditCaseOverview({
      idNumber,
      values: {
        ...formData,
        powerOfAttorney: {
          ...formData.powerOfAttorney,
          contactNumber: String(formData?.powerOfAttorney?.contactNumber || ''),
        },
      },
    })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const onPlaceSelect = ({ formattedAddress, address1, address2, town, county, country, postcode }: any) => {
    setFormData((prevState) => ({
      ...prevState,
      powerOfAttorney: {
        ...prevState.powerOfAttorney,
        contactNumber: String(prevState.powerOfAttorney.contactNumber),
        address: {
          formattedAddress,
          address1,
          address2,
          town,
          county,
          country,
          postcode,
        },
      },
    }))
    form.setFieldValue('powerOfAttorney.address.formattedAddress', formattedAddress)
    form.setFieldValue('powerOfAttorney.address.address1', address1)
    form.setFieldValue('powerOfAttorney.address.address2', address2)
    form.setFieldValue('powerOfAttorney.address.town', town)
    form.setFieldValue('powerOfAttorney.address.county', county)
    form.setFieldValue('powerOfAttorney.address.country', country)
    form.setFieldValue('powerOfAttorney.address.postcode', postcode)
  }

  const showAddressDetailsChange = (checked: boolean) => {
    setShowAddressDetails(checked)
  }

  const { handleSaveAndGoBack, handleSave, handleSaveAndContinue } = useGeneral({ handleSubmit, setLoading })

  const [saveType, setSaveType] = useState<'save' | 'saveAndGoBack' | 'saveAndContinue'>('save')

  const onSubmit = () => {
    if (saveType === 'saveAndGoBack') {
      handleSaveAndGoBack()
    } else if (saveType === 'saveAndContinue') {
      handleSaveAndContinue()
    } else if (saveType === 'save') {
      handleSave()
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill out every required field')
  }

  const handleAddButton = () => {
    eventManager.openPersonPresentDuringFactFindModal(
      {},
      {
        confirm: () => {},
      },
    )
  }

  const personsDataSource =
    personsPresentDuringFactFind.map((person: any) => ({
      id: person._id,
      name: person.name,
      relationshipWithCustomer: person.relationshipWithCustomer,
    })) || []

  const [selected, setSelected] = useState({})
  const displayDeleteButton = !!Object.keys(selected).length
  const displayEditButton = Object.keys(selected).length === 1

  const setSelectedRows = (selectedRowObj: any) => {
    setSelected(selectedRowObj)
  }

  const handleDelete = async () => {
    setLoading(true)
    const persons_IdToDelete = Object.keys(selected)
    const { errors } = await deletePersonPresentDuringFactFind({ _ids: persons_IdToDelete, idNumber })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    setLoading(false)
    refetch()
    setSelected({})
  }

  const handleDeleteModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleDelete(),
      },
    )
  }

  const handleEditModal = () => {
    eventManager.openPersonPresentDuringFactFindModal(
      { _id: Object.keys(selected)[0], isEdit: true },
      {
        confirm: () => {},
      },
    )
  }

  return {
    form,
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    flattenedFormData,
    handleCustomSelectChange,
    handleAntdFormItemChange,
    handleThirdPartyInvolved,
    handleWholeOfMarketOrTied,
    onPlaceSelect,
    showAddressDetails,
    showAddressDetailsChange,
    handleAddButton,
    personsDataSource,
    setSelectedRows,
    displayDeleteButton,
    handleDeleteModal,
    displayEditButton,
    handleEditModal,
  }
}

export default useCaseOverView
