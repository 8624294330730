import { Form } from 'antd'

import Loading from 'components/Loading'

import { yesNoOptions } from 'utils/commonOptions'
import CustomFooter from 'pages/QuickSearch/CustomFooter/CustomFooter'

import { StyledContainer, StyledTitle, StyledTitleContainer, StyledRequiredMsg, StyledBreakLine } from './CancerDetails.styles'
import CustomFormItem from 'components/CustomFormItem/CustomFormItem'
import useCancerDetails from 'hooks/clients/QuickSearch/MedicalDetails/useCancerDetails'

const CancerDetails = () => {
  const {
    loading,
    handleNext,
    handleAntdFormItemChange,
    flattenedFormData,
    customerFirstName,
    customerSecondName,
    enterMedicalDetailsOfCustomerFirst,
    enterMedicalDetailsOfCustomerSecond,
  } = useCancerDetails()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <Form
        layout="vertical"
        size="large"
        name="basic"
        onFinish={handleNext}
        initialValues={flattenedFormData}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '12px',
          marginTop: '8px',
          width: '100%',
        }}
      >
        {enterMedicalDetailsOfCustomerFirst && (
          <>
            <StyledTitleContainer>
              <StyledTitle>Cancer Details of {customerFirstName}</StyledTitle>
              <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
            </StyledTitleContainer>
            <CustomFormItem
              label="Have you been diagnosed with cancer including leukaemia, Hodgkin’s disease, lymphoma, and malignant growths/tumours? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerFirst.isDiagnosedWithCancer"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerFirst.isDiagnosedWithCancer'] && (
              <>
                <CustomFormItem
                  label="Was this skin cancer? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerFirst.hasSkinCancer"
                  type="radio"
                  rules={['isRequired']}
                />
                <CustomFormItem
                  label="Did this require surgery? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerFirst.skinCanerRequiredSurgery"
                  type="radio"
                  rules={['isRequired']}
                />
                <CustomFormItem
                  label="Did this require chemotherapy or radiotherapy? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerFirst.skinCanerRequiredChemotherapyOrRadiotherapy"
                  type="radio"
                  rules={['isRequired']}
                />
                <CustomFormItem
                  label="Date of diagnosis (YYYY-MM) *"
                  onChange={handleAntdFormItemChange}
                  name="customerFirst.skinCancerDiagnosisDate"
                  rules={['isRequired']}
                />
              </>
            )}
          </>
        )}
        {enterMedicalDetailsOfCustomerSecond && (
          <>
            {enterMedicalDetailsOfCustomerFirst && <StyledBreakLine />}
            <StyledTitleContainer>
              <StyledTitle>Cancer Details of {customerSecondName}</StyledTitle>
              <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
            </StyledTitleContainer>
            <CustomFormItem
              label="Have you been diagnosed with cancer including leukaemia, Hodgkin’s disease, lymphoma, and malignant growths/tumours? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.isDiagnosedWithCancer"
              type="radio"
              rules={['isRequired']}
            />
            {flattenedFormData['customerSecond.isDiagnosedWithCancer'] && (
              <>
                <CustomFormItem
                  label="Was this skin cancer? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerSecond.hasSkinCancer"
                  type="radio"
                  rules={['isRequired']}
                />
                <CustomFormItem
                  label="Did this require surgery? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerSecond.skinCanerRequiredSurgery"
                  type="radio"
                  rules={['isRequired']}
                />
                <CustomFormItem
                  label="Did this require chemotherapy or radiotherapy? *"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="customerSecond.skinCanerRequiredChemotherapyOrRadiotherapy"
                  type="radio"
                  rules={['isRequired']}
                />
                <CustomFormItem
                  label="Date of diagnosis (YYYY-MM) *"
                  onChange={handleAntdFormItemChange}
                  name="customerSecond.skinCancerDiagnosisDate"
                  rules={['isRequired']}
                />
              </>
            )}
          </>
        )}
        <CustomFooter loading={loading} buttonText="Next" buttonTheme="light" />
      </Form>
    </StyledContainer>
  )
}

export default CancerDetails
