export const assetTypeOptions = [
  {
    label: 'Bank',
    value: 'bank',
  },
  {
    label: 'Investment',
    value: 'investment',
  },
  {
    label: 'Property (Excluding Main Residence)',
    value: 'property',
  },
  {
    label: 'Pension Funds',
    value: 'pensionFunds',
  },
  {
    label: 'Business',
    value: 'business',
  },
  {
    label: 'Other Assets',
    value: 'other',
  },
]
