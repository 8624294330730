import { Form } from 'antd'
import Loading from 'components/Loading'
import FormTable from 'components/FormTable/FormTable'
import CustomFormItem from 'components/CustomFormItem/CustomFormItem'

import { PoundIcon } from 'assets/images'
import { yesNoOptions } from 'utils/commonOptions'
import useAssets from 'hooks/clients/DetailedForms/useAssets'
import CustomFooter from 'pages/ClientDetails/CustomFooter/CustomFooter'

import { assetsTableColumns } from './Assets.helper'
import { StyledContainer, StyledDescription, StyledTitle, StyledTitleContainer, StyledRequiredMsg } from './Assets.styles'

const Assets = () => {
  const {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    handleAntdFormItemChange,
    handleAddButton,
    assetsDataSource,
    assetsTotalValue,
    setSelectedRows,
    displayDeleteButton,
    handleDeleteModal,
    displayEditButton,
    handleEditModal,
  } = useAssets()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <StyledTitle>Assets</StyledTitle>
        <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
      </StyledTitleContainer>
      <StyledDescription>
        Here is where you can enter information relating to your customer’s Assets including investments, other properties, pension funds and business assets
        for example. You can enter multiple assets by using the 'Add New Asset' button.
      </StyledDescription>
      <Form
        layout="vertical"
        size="large"
        name="basic"
        initialValues={formData}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '12px',
          marginTop: '8px',
          width: '100%',
        }}
        onFinishFailed={onFinishFailed}
        onFinish={onSubmit}
      >
        <CustomFormItem
          label="Do you have any assets (excluding your home)? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          value={formData.hasOtherAssets}
          name="hasOtherAssets"
          type="radio"
          rules={['isRequired']}
        />
        {formData.hasOtherAssets && (
          <>
            <FormTable
              columns={assetsTableColumns}
              dataSource={assetsDataSource}
              title="Assets"
              addButtonText="Add New Asset"
              handleAddButton={handleAddButton}
              total={assetsTotalValue}
              setSelectedRows={setSelectedRows}
              displayDeleteButton={displayDeleteButton}
              handleDeleteModal={handleDeleteModal}
              displayEditButton={displayEditButton}
              handleEditModal={handleEditModal}
            />
            <CustomFormItem
              label="Would you like to utilise any of these assets towards the capital you require?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              value={formData.canUtilizeOtherAssets}
              name="canUtilizeOtherAssets"
              type="radio"
            />
            {formData.canUtilizeOtherAssets && (
              <CustomFormItem
                onChange={handleAntdFormItemChange}
                label="How much?"
                name="howMuchWantsToUtilize"
                placeholder="How much? *"
                inputPrefixImg={PoundIcon}
                prefixImgHeight={12}
                type="number"
              />
            )}
            {formData.canUtilizeOtherAssets === false && (
              <CustomFormItem
                label="Enter reason for not wanting to utilise these assets towards objectives?"
                placeholder="E.g will be shown here"
                value={formData.reasonNotUtilizingOtherAssets}
                name="reasonNotUtilizingOtherAssets"
                onChange={handleAntdFormItemChange}
                rows={4}
                type="textarea"
              />
            )}
          </>
        )}
        <CustomFormItem
          label="Do you have cash on deposit for emergency purposes? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          value={formData.hasCashInDeposit}
          name="hasCashInDeposit"
          type="radio"
          rules={['isRequired']}
        />
        {formData.hasCashInDeposit && (
          <CustomFormItem
            onChange={handleAntdFormItemChange}
            label="How much?"
            name="howMuchCashHasInDeposit"
            placeholder="How much?"
            inputPrefixImg={PoundIcon}
            prefixImgHeight={12}
            type="number"
          />
        )}
        {formData.hasCashInDeposit === false && (
          <CustomFormItem
            label="In customers words, how would non insured emergencies be funded?"
            placeholder="E.g will be shown here"
            value={formData.howNonInsuredEmergenciesAreFunded}
            name="howNonInsuredEmergenciesAreFunded"
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        <CustomFooter loading={loading} setSaveType={setSaveType} />
      </Form>
    </StyledContainer>
  )
}

export default Assets
