import Form from 'antd/lib/form'
import CustomDrawer from 'components/CustomDrawer'
import CustomSelect from 'components/CustomSelect'
import TextInput from 'components/CustomFormItem'
import AuthContext from 'contexts/AuthContext'
import { useContext } from 'react'

import {
  StyledContainer,
  StyledCompanyOptionContainer,
  StyledSwitchContainer,
  StyledSwitchLabel,
  StyledSwitch,
  StyledSelectContainer,
} from './UserAddEditDrawer.styles'
import { TCompanyAddEditDrawer } from './UserAddEditDrawer.types'

const UserAddEditDrawer = ({
  open,
  closeDrawer,
  loading,
  handleSubmit,
  handleFormFieldChange,
  formData,
  companyUserChange,
  companyUser,
  options,
  handleCompanyChange,
  drawerType,
  form,
}: TCompanyAddEditDrawer) => {
  const { user } = useContext(AuthContext)
  return (
    <CustomDrawer
      loading={loading}
      open={open}
      closeDrawer={closeDrawer}
      title={drawerType === 'add' ? 'Add User' : 'Edit User'}
      disableSaveButton={false}
      handleSubmit={handleSubmit}
    >
      <StyledContainer>
        <Form
          form={form}
          layout="vertical"
          size="large"
          name="basic"
          onChange={handleFormFieldChange}
          initialValues={formData}
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',
          }}
        >
          <TextInput label="First Name  *" rules={['isRequired']} name="firstName" placeholder="First Name  *" />
          <TextInput label="Last Name  *" rules={['isRequired']} name="lastName" placeholder="Last Name  *" />
          <TextInput label="Email  *" name="email" placeholder="Email  *" rules={['isRequired', 'validateEmail']} />
          <TextInput label="Phone *" name="phoneNumber" placeholder="Phone *" rules={['isRequired']} />
          <TextInput label="Iress Login ID" name="iressLoginId" placeholder="Enter Your Iress Login ID" />
          <TextInput label="Iress Password" name="iressPassword" placeholder="Enter Your Iress Password" />
          <TextInput label="Iress User ID" name="iressUserId" placeholder="Enter Your Iress User ID" type="number" />
          <TextInput label="FCA Number" name="fcaNumber" placeholder="Enter Your FCA Number" type="number" />
          {user.role === 'superAdmin' && (
            <StyledCompanyOptionContainer>
              <StyledSwitchContainer>
                <StyledSwitchLabel>Chose Another Company</StyledSwitchLabel>
                <StyledSwitch checked={companyUser} onChange={companyUserChange} />
              </StyledSwitchContainer>
              <StyledSelectContainer>
                <CustomSelect
                  disabled={!companyUser}
                  onChange={handleCompanyChange}
                  defaultValue={formData.companyId}
                  placeholder="Select Company"
                  options={options}
                  width="100%"
                />
              </StyledSelectContainer>
            </StyledCompanyOptionContainer>
          )}
        </Form>
      </StyledContainer>
    </CustomDrawer>
  )
}

export default UserAddEditDrawer
