import { commonUtils } from 'utils'
import { IDetailsColumnProp, IWrittenBusinessReportColumnProp } from './WrittenBusiness.interface'
import { IContextUser } from 'contexts/AuthContext'
import { CellProps } from '@inovua/reactdatagrid-community/types'
import { Status } from 'hooks/dashboard/useLinkClient'

export const writtenBusinessReportDataSource = [
  {
    writtenBusiness: 6891.25,
    completedOnRisk: 4041.25,
    completedOnRiskYtd: 4041.25,
    ntu: 4041.25,
    ntuYtd: 4041.25,
    totalPipelineLeft: 4041.25,
    totalPipelineLeftYtd: 4041.25,
  },
]

export const writtenBusinessReportColumns = [
  {
    name: 'writtenBusiness',
    header: 'Written Business',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IWrittenBusinessReportColumnProp) => (data.writtenBusiness ? `£${commonUtils.formatNumberWithCommas(data.writtenBusiness)}` : '-'),
  },
  {
    name: 'completedOnRisk',
    header: 'Completed/ On Risk',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IWrittenBusinessReportColumnProp) => (data.completedOnRisk ? `£${commonUtils.formatNumberWithCommas(data.completedOnRisk)}` : '-'),
  },
  {
    name: 'completedOnRiskYtd',
    header: 'Completed/ On Risk YTD',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IWrittenBusinessReportColumnProp) => (data.completedOnRiskYtd ? `£${commonUtils.formatNumberWithCommas(data.completedOnRiskYtd)}` : '-'),
  },
  {
    name: 'ntu',
    header: "NTU'd",
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IWrittenBusinessReportColumnProp) => (data.ntu ? `£${commonUtils.formatNumberWithCommas(data.ntu)}` : '-'),
  },
  {
    name: 'ntuYtd',
    header: "NTU'd YTD",
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IWrittenBusinessReportColumnProp) => (data.ntuYtd ? `£${commonUtils.formatNumberWithCommas(data.ntuYtd)}` : '-'),
  },
  {
    name: 'totalPipelineLeft',
    header: 'Total pipeline left',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IWrittenBusinessReportColumnProp) => (data.totalPipelineLeft ? `£${commonUtils.formatNumberWithCommas(data.totalPipelineLeft)}` : '-'),
  },
  {
    name: 'totalPipelineLeftYtd',
    header: 'Total Pipeline left YTD',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: IWrittenBusinessReportColumnProp) =>
      data.totalPipelineLeftYtd ? `£${commonUtils.formatNumberWithCommas(data.totalPipelineLeftYtd)}` : '-',
  },
]

export const generateBgColor = (status: string) => {
  switch (status) {
    case Status.writtenBusiness:
      return '#F2F2F2'
    case Status.onRisk:
      return '#E1F2D9'
    case Status.commissionPaid:
      return '#F5E2EE'
    case Status.ntu:
      return '#FDF9DD'
    default:
      return '#F2F2F2'
  }
}

export const generateDetailsColumns = (currentUser: IContextUser) => {
  const columns = [
    {
      name: 'companyName',
      header: 'Company Name',
      defaultFlex: 1,
      type: 'text',
      hide: currentUser.role !== 'superAdmin',
      onRender: (cellProps: CellProps, { data }: IDetailsColumnProp) => {
        if (cellProps.style && typeof cellProps.style === 'object') {
          const bgColor = generateBgColor(data.status)
          ;(cellProps.style as React.CSSProperties).background = bgColor
        }
      },
    },
    {
      name: 'advisorName',
      header: 'Adviser Name',
      defaultFlex: 1,
      type: 'text',
      hide: currentUser.role !== 'superAdmin',
      onRender: (cellProps: CellProps, { data }: IDetailsColumnProp) => {
        if (cellProps.style && typeof cellProps.style === 'object') {
          const bgColor = generateBgColor(data.status)
          ;(cellProps.style as React.CSSProperties).background = bgColor
        }
      },
    },
    {
      name: 'clientName',
      header: 'Client name',
      defaultFlex: 1,
      type: 'text',
      onRender: (cellProps: CellProps, { data }: IDetailsColumnProp) => {
        if (cellProps.style && typeof cellProps.style === 'object') {
          const bgColor = generateBgColor(data.status)
          ;(cellProps.style as React.CSSProperties).background = bgColor
        }
      },
    },
    {
      name: 'dateOfApplication',
      header: 'Date of application',
      defaultFlex: 1,
      type: 'date',
      onRender: (cellProps: CellProps, { data }: IDetailsColumnProp) => {
        if (cellProps.style && typeof cellProps.style === 'object') {
          const bgColor = generateBgColor(data.status)
          ;(cellProps.style as React.CSSProperties).background = bgColor
        }
      },
      render: ({ data }: IDetailsColumnProp) => (data.dateOfApplication ? commonUtils.formatDate(data.dateOfApplication) : '-'),
    },
    {
      name: 'lenderProvider',
      header: 'LENDER/PROVIDER',
      defaultFlex: 1,
      type: 'text',
      onRender: (cellProps: CellProps, { data }: IDetailsColumnProp) => {
        if (cellProps.style && typeof cellProps.style === 'object') {
          const bgColor = generateBgColor(data.status)
          ;(cellProps.style as React.CSSProperties).background = bgColor
        }
      },
    },
    {
      name: 'feeType',
      header: 'FEE TYPE',
      defaultFlex: 1,
      type: 'text',
      onRender: (cellProps: CellProps, { data }: IDetailsColumnProp) => {
        if (cellProps.style && typeof cellProps.style === 'object') {
          const bgColor = generateBgColor(data.status)
          ;(cellProps.style as React.CSSProperties).background = bgColor
        }
      },
    },
    {
      name: 'amount',
      header: 'Amount',
      defaultFlex: 1,
      type: 'number',
      render: ({ data }: IDetailsColumnProp) => (data.amount ? `£${commonUtils.formatNumberWithCommas(data.amount)}` : '-'),
      onRender: (cellProps: CellProps, { data }: IDetailsColumnProp) => {
        if (cellProps.style && typeof cellProps.style === 'object') {
          const bgColor = generateBgColor(data.status)
          ;(cellProps.style as React.CSSProperties).background = bgColor
        }
      },
    },
  ]

  return columns.filter((column) => !column?.hide)
}
