import { Form } from 'antd'

import Loading from 'components/Loading'
import TextInput from 'components/CustomFormItem'
import { useIRESSDetailsContent } from 'hooks'

import { StyledContainer, StyledMinContainer, StyledFormContainer, StyledTitle } from './IRESSDetailsContent.styles'
import CustomFooter from 'pages/UserProfile/CustomFooter/CustomFooter'

const IRESSDetailsContent = () => {
  const { formData, loading, handleAntdFormItemChange, handleSubmit } = useIRESSDetailsContent()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledMinContainer>
        <StyledTitle>IRESS Details</StyledTitle>
        <StyledFormContainer>
          <Form
            layout="vertical"
            size="large"
            name="basic"
            initialValues={{
              iressLoginId: formData?.iressLoginId,
              iressPassword: formData?.iressPassword,
              iressUserId: formData?.iressUserId,
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
            }}
          >
            <TextInput
              onChange={handleAntdFormItemChange}
              label="Iress Login ID *"
              name="iressLoginId"
              placeholder="Enter Your Iress Login ID"
              rules={['isRequired']}
            />
            <TextInput
              onChange={handleAntdFormItemChange}
              label="Iress Password *"
              name="iressPassword"
              placeholder="Enter Your Iress Password"
              rules={['isRequired']}
            />
            <TextInput
              onChange={handleAntdFormItemChange}
              label="Iress User ID *"
              name="iressUserId"
              placeholder="Enter Your Iress User ID"
              type="number"
              rules={['isRequired']}
            />
            <CustomFooter loading={loading} handleSave={handleSubmit} />
          </Form>
        </StyledFormContainer>
      </StyledMinContainer>
    </StyledContainer>
  )
}

export default IRESSDetailsContent
