import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'

import { commonUtils, eventManager } from 'utils'
import Forms from 'contexts/Forms'
import { commonHelper } from 'helpers'

import {
  policySummaryColumns,
  generatePolicySummaryDataSource,
  generateFreeSummaryDataSource,
  freeSummaryColumns,
  annualInterestColumns,
  generateGroups,
  generateAnnualInterestDataSource,
} from './helper'
import { addQuoteDetailsMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { AddQuoteDetailsPayload } from 'services/shared/mutation.payload'
import { IQuickSearch } from 'interfaces/quickSearch.interface'
import { ISuccessfulQuote } from 'interfaces/quoteResult.interface'

const useQuoteDetails = () => {
  const [loading, setLoading] = useState(false)
  const addQuoteDetails = useCustomMutation<AddQuoteDetailsPayload>({ mutation: addQuoteDetailsMutation })
  const [propertyGrowth, setPropertyGrowth] = useState(2)
  const { setQuoteResultActiveTab } = useContext(Forms)
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(window.location.search)
  const id = urlParams.get('id')
  const idNumber = Number(urlParams.get('idNumber'))
  const quoteData: ISuccessfulQuote = JSON.parse(localStorage.getItem('quoteResult') || '{}').successFullQuotes.find(
    (item: any) => item.$['perqresp:quote_response_identifier'] === id,
  )

  const data = quoteData.provider_success?.[0].quote_results?.[0]

  const policySummaryDataSource = generatePolicySummaryDataSource(data)
  const freeSummaryDataSource = generateFreeSummaryDataSource(data)
  const groups = generateGroups(propertyGrowth)
  const annualInterestDataSource = generateAnnualInterestDataSource(data, propertyGrowth)

  const quickSearchData: IQuickSearch = JSON.parse(localStorage.getItem('quickSearch') || '{}')
  const clientDetails: any = quickSearchData.clientDetails.firstLife

  const handleLinkRedirect = () => {
    window.open(quoteData.provider_success[0].quote_results[0].documents?.[0].ltv_table?.[0].document_details[0], '_blank')
  }

  const currentDate = new Date()
  const formattedDate = currentDate.toLocaleDateString('en-GB')
  const {
    propertyAndLanding: { propertyValue },
  }: IQuickSearch = JSON.parse(localStorage.getItem('quickSearch') || '{}')

  const avatar = commonUtils.getAvatarFromProviderName(quoteData.provider_success[0].provider_product_data[0].provider_name[0])

  const handleQuoteInputRedirect = () => {
    navigate('/quote-result')
    setQuoteResultActiveTab('QUOTE INPUT')
  }

  const handleSliderChange = (value: number) => {
    setPropertyGrowth(value)
  }

  const handleGoBackBtnClick = (clientIdNumber?: number) => {
    navigate(`/quote-result?idNumber=${clientIdNumber ? clientIdNumber : idNumber}`)
  }

  const handleConvert = async () => {
    const transactionNumber = Number(quoteData.$['perqresp:service_provider_reference'])
    let clientIdNumber

    if (idNumber > 0) {
      clientIdNumber = idNumber
    } else {
      clientIdNumber = commonUtils.generateIdNumber()
    }

    const dataToSave = {
      idNumber: Number(clientIdNumber),
      transactionNumber,
      userInput: {
        ...quickSearchData,
        propertyAndLanding: {
          ...quickSearchData.propertyAndLanding,
          propertyValue: Number(quickSearchData.propertyAndLanding.propertyValue),
          paymentAmountOnInitialAdvance: Number(quickSearchData.propertyAndLanding.paymentAmountOnInitialAdvance),
          leasePeriod: Number(quickSearchData.propertyAndLanding.leasePeriod),
          houseNameOrNumber: String(quickSearchData.propertyAndLanding.houseNameOrNumber),
        },
        furtherInformation: {
          ...quickSearchData.furtherInformation,
          adviceFee: Number(quickSearchData.furtherInformation.adviceFee),
        },
      },
      quoteDetails: {
        isCashbackConsidered: data.cashback_considered_ind?.[0] === 'true' ? true : false,
        isCashbackOffered: data.cashback_ind[0] === 'true' ? true : false,
        hasDownsizingProtection: data.downsizing_protection_ind[0] === 'true' ? true : false,
        hasFreeRepaymentOnFirstDeath: data.free_repayment_on_first_death_ind[0] === 'true' ? true : false,
        hasFreeValuations: data.free_valuations_ind[0] === 'true' ? true : false,
        hasGuaranteedDrawdown: data.guaranteed_drawdown_ind[0] === 'true' ? true : false,
        hasMedicalUnderwriting: data.medical_underwriting_ind[0] === 'true' ? true : false,
        hasOptionalRepayments: data.optional_repayments_ind[0] === 'true' ? true : false,
        optionalRepaymentType: data.optional_repayment_type[0],
        commissionPaidByProduct: Number(data.commission_paid[0]._),
        totalCostOfLoan: Number(data.total_cost_of_loan?.[0]._),
        netLoanAmount: Number(data.net_loan_amount[0]._),
        costPerPound: Number(data.cost_per_pound?.[0]._),
        notes: data.notes?.[0],
        interestRates: {
          aer: Number(data.interest_rates[0].aer[0]),
          apr: Number(data.interest_rates[0].apr[0]),
          mer: Number(data.interest_rates[0].mer[0]),
          toBeApplied: data.interest_rates[0].to_be_applied[0],
          type: data.interest_rates[0].type[0],
        },
        documents: {
          application: data.documents?.[0].application?.[0],
          illustration: data.documents?.[0].illustration?.[0],
          key_features_document: data.documents?.[0].key_features_document?.[0],
          ltv_table: data.documents?.[0].ltv_table?.[0].document_details?.[0],
          other: data.documents?.[0].other?.[0].document_details?.[0],
        },
        earlyRepaymentCharge: {
          description: data.early_repayment_charge?.[0].description?.[0],
          type: data.early_repayment_charge?.[0].type?.[0],
          years: Number(data.early_repayment_charge?.[0].years?.[0] || 0),
        },
        initialAmount: Number(data.payments?.[0]?.initial_only?.[0]?.initial_amount?.[0]?._),
        fees: {
          applicationFee: Number(data.fees[0].application_fee[0]._),
          canApplicationFeeBeAddedToLoan: data.fees[0].application_fee_can_be_added_to_loan[0] === 'true' ? true : false,
          applicationFeeType: data.fees[0].application_fee_type[0],
          completionFee: Number(data.fees[0].completion_fee[0]._),
          canCompletionFeeBeAddedToLoan: data.fees[0].completion_fee_can_be_added_to_loan[0] === 'true' ? true : false,
          completionFeeType: data.fees[0].completion_fee_type[0],
          feeContributionAmount: Number(data.fees?.[0].fee_contribution_details?.[0].fee_contribution_amount?.[0]._),
          total: Number(data.fees?.[0].total?.[0]._),
          valuationFee: Number(data.fees?.[0].valuation_fee?.[0]._),
          canValuationFeeBeAddedToLoan: data.fees?.[0].valuation_fee_can_be_added_to_loan?.[0] === 'true' ? true : false,
          valuationFeeType: data.fees?.[0].valuation_fee_type?.[0],
        },
      },
    }

    const { errors } = await addQuoteDetails(dataToSave)
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
    handleGoBackBtnClick(clientIdNumber)
  }

  const handleConvertModal = () => {
    eventManager.openConfirmModal(
      { header: 'Do you want to convert this lead as “Fact Find“' },
      {
        confirm: () => handleConvert(),
      },
    )
  }

  return {
    policySummaryColumns,
    policySummaryDataSource,
    freeSummaryColumns,
    freeSummaryDataSource,
    annualInterestColumns,
    annualInterestDataSource,
    groups,
    quoteData,
    clientDetails,
    handleLinkRedirect,
    formattedDate,
    propertyValue,
    avatar,
    handleQuoteInputRedirect,
    handleSliderChange,
    propertyGrowth,
    handleGoBackBtnClick,
    loading,
    handleConvertModal,
  }
}

export default useQuoteDetails
