import Form from 'antd/lib/form'

import CustomFormItem from 'components/CustomFormItem'
import Loading from 'components/Loading/Loading'
import CheckboxOptions from 'components/CheckboxOptions/CheckboxOptions'
import CustomFooter from 'pages/QuickSearch/CustomFooter/CustomFooter'
import { RadioOptions } from 'components/RadioOptions'

import { StyledContainer, StyledFormContainer, StyledTitleContainer, StyledTitle, StyledRequiredMsg } from './FurtherInformationTab.styles'
import {
  earlyRepaymentChargeOptions,
  // interestServicingLevelOptions,
  // requiredFundingOptions,
  residenceTypeOptions,
} from './FurtherInformationTab.helper'
import useFurtherInformationTab from 'hooks/clients/QuickSearch/useFurtherInformationTab'
import { productResearchOptions } from 'utils/commonOptions'

const FurtherInformationTab = () => {
  const { loading, formData, handleCustomCheckboxChange, handleAntdFormItemChange, handleSubmit, onFinishFailed } = useFurtherInformationTab()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledFormContainer>
        <StyledTitleContainer>
          <StyledTitle>Further Information</StyledTitle>
          <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
        </StyledTitleContainer>
        <Form
          layout="vertical"
          size="large"
          name="basic"
          initialValues={formData}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',
            marginTop: '8px',
          }}
        >
          <CustomFormItem
            label="Will your product research be Whole of Market or from a Panel of Providers? *"
            onChange={handleAntdFormItemChange}
            options={productResearchOptions}
            value={formData.productResearchEnvironment}
            name="productResearchEnvironment"
            type="radio"
            rules={['isRequired']}
          />
          {/* <CheckboxOptions
          label="Funding required *"
          onChange={handleCustomCheckboxChange}
          options={requiredFundingOptions}
          name="requiredFunding"
          value={formData.requiredFunding}
        /> */}
          {/* <CustomCheckbox
          label="Interest servicing level"
          onChange={handleCustomCheckboxChange}
          options={interestServicingLevelOptions}
          name="interestServicingLevel"
          value={formData.interestServicingLevel}
        /> */}
          <RadioOptions
            label="Residence type"
            onChange={handleCustomCheckboxChange}
            options={residenceTypeOptions}
            name="residenceType"
            initialValue={formData.residenceType}
          />
          <CheckboxOptions
            label="Early repayment charges *"
            onChange={handleCustomCheckboxChange}
            options={earlyRepaymentChargeOptions}
            name="earlyRepaymentCharge"
            value={formData.earlyRepaymentCharge}
          />
          <CustomFormItem
            onChange={handleAntdFormItemChange}
            label="Advice fee (£) *"
            name="adviceFee"
            placeholder="E.g will be shown here"
            type="number"
            value={formData.adviceFee}
            rules={['isRequired']}
          />
          <CustomFooter loading={loading} buttonText="Get Quote" buttonTheme="dark" />
        </Form>
      </StyledFormContainer>
    </StyledContainer>
  )
}

export default FurtherInformationTab
