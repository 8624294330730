import styled from 'styled-components'

export const StyledIframeContainer = styled.div`
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
`

export const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`
