import Form from 'antd/lib/form'

import { useLogin } from 'hooks'
import TextInput from 'components/CustomFormItem'

import {
  StyledLoginButton,
  StyledLoginContainer,
  StyledLeftContainer,
  StyledRightContainer,
  StyledBackground,
  StyledLogo,
  StyledDesc,
  StyledDs,
  StyledDescContainer,
  StyledBreakLine,
  StyledFormContainer,
  StyledWelcomeMsg,
  StyledMinMsg,
  StyledLinksContainer,
  StyledCreateAccount,
  StyledLink,
  JoinUsWrapper,
} from './Login.styles'
import { LoginPageImg, LogoLight } from 'assets/images'

const Login = () => {
  const { handleSubmit, loading } = useLogin()

  return (
    <StyledLoginContainer>
      <StyledLeftContainer>
        <StyledBackground img={LoginPageImg}>
          <StyledLogo src={LogoLight} />
          <StyledDs>
            <StyledDescContainer>
              <StyledBreakLine />
              <StyledDesc>The platform for later life lending professionals</StyledDesc>
            </StyledDescContainer>
          </StyledDs>
        </StyledBackground>
      </StyledLeftContainer>
      <StyledRightContainer>
        <StyledFormContainer>
          <StyledWelcomeMsg>Welcome!</StyledWelcomeMsg>
          <StyledMinMsg>Log in to access your personal information</StyledMinMsg>
          <Form
            layout="vertical"
            size="large"
            name="basic"
            onFinish={handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
            }}
          >
            <TextInput label="Email" name="email" placeholder="Enter a email" rules={['isRequired', 'validateEmail']} />
            <TextInput label="Password" rules={['isRequired']} name="password" type="password" placeholder="Enter a password" />
            <StyledLoginButton loading={loading} type="primary" htmlType="submit">
              Log in
            </StyledLoginButton>
          </Form>
          <StyledLinksContainer>
            <JoinUsWrapper>
              <StyledCreateAccount> Don't have an account? </StyledCreateAccount>
              <StyledLink to="/signup">Join us</StyledLink>
            </JoinUsWrapper>
            <StyledLink to="/forget-password">Forget Password?</StyledLink>
          </StyledLinksContainer>
        </StyledFormContainer>
      </StyledRightContainer>
    </StyledLoginContainer>
  )
}

export default Login
