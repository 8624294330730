import Form from 'antd/lib/form'
import { FileUploadIcon } from 'assets/images'
import CustomDrawer from 'components/CustomDrawer'
import TextInput from 'components/CustomFormItem'

import {
  StyledContainer,
  StyledCompanyLogoTitle,
  StyledLogoUploaderContainer,
  StyledLogoAndDescContainer,
  StyledLogoPlaceholderWrapper,
  StyledPlaceholderLogo,
  StyledDesc,
  StyledChooseLogoText,
  StyledCompanyLogo,
} from './CompanyAddEditDrawer.styles'
import { TCompanyAddEditDrawer } from './CompanyAddEditDrawer.types'

const CompanyAddEditDrawer = ({
  open,
  closeDrawer,
  handleLogoUpload,
  companyLogo,
  avatarUrl,
  loading,
  handleSubmit,
  handleFormFieldChange,
  formData,
  drawerType,
}: TCompanyAddEditDrawer) => {
  // check if the company logo is a url or a file
  const avatar = companyLogo?.includes('companies') ? avatarUrl : companyLogo
  return (
    <CustomDrawer
      loading={loading}
      open={open}
      closeDrawer={closeDrawer}
      title={drawerType === 'add' ? 'Add Company' : 'Edit Company'}
      disableSaveButton={false}
      handleSubmit={handleSubmit}
    >
      <StyledContainer>
        <StyledCompanyLogoTitle>Company logo</StyledCompanyLogoTitle>
        <StyledLogoUploaderContainer>
          <StyledLogoAndDescContainer>
            {avatar ? (
              <StyledCompanyLogo src={avatar} alt="company logo" />
            ) : (
              <StyledLogoPlaceholderWrapper>
                <StyledPlaceholderLogo src={FileUploadIcon} alt="company placeholder logo" />
              </StyledLogoPlaceholderWrapper>
            )}
            <StyledDesc>JPEG, JPG or PNG are allowed. Maximum size of the photo should be 25MB</StyledDesc>
          </StyledLogoAndDescContainer>
          <StyledChooseLogoText htmlFor="company_logo">Choose file</StyledChooseLogoText>
          <input onChange={handleLogoUpload} name="company_logo" id="company_logo" hidden type="file" accept=".png,.svg,.jpg,.jpeg" />
        </StyledLogoUploaderContainer>
        <Form
          layout="vertical"
          size="large"
          name="basic"
          onChange={handleFormFieldChange}
          initialValues={formData}
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',
          }}
        >
          <TextInput label="Name" rules={['isRequired']} name="name" placeholder="Name" />
          <TextInput label="Phone" name="phoneNumber" placeholder="Phone" rules={['isRequired']} />
          <TextInput label="Email" name="email" placeholder="Email" rules={['isRequired', 'validateEmail']} />
          <TextInput label="Address" name="address" placeholder="Address" rules={['isRequired']} />
          <TextInput label="Key contact" name="keyContact" placeholder="Key contact" rules={['isRequired']} />
        </Form>
      </StyledContainer>
    </CustomDrawer>
  )
}

export default CompanyAddEditDrawer
