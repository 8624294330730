/* eslint-disable no-useless-computed-key */
import { Form } from 'antd'

import Loading from 'components/Loading'
import CustomFormItem from 'components/CustomFormItem'
import GoogleAddressInput from 'components/GoogleAddressInput/GoogleAddressInput'
import useSuitabilityAndNextSteps from 'hooks/clients/DetailedForms/useSuitabilityAndNextSteps'
import CustomFooter from 'pages/ClientDetails/CustomFooter/CustomFooter'
import { yesNoOptions } from 'utils/commonOptions'

import { recommendationOptions, yesNoOtherOptions } from './SuitabilityAndNextSteps.helper'
import {
  StyledContainer,
  StyledDescription,
  StyledTitle,
  StyledTitleContainer,
  StyledRequiredMsg,
  LendingIssuesContainer,
  StyledLendingIssuesHeader,
  StyledIssueTitle,
  StyledDetailsContainer,
  StyledHeaderWarning,
  StyledWarningsContainer,
  StyledWarningContainer,
  StyledWarningWithIndex,
  StyledWarningDescription,
  StyledIndex,
  StyledBoldText,
  StyledText,
  Styled2x2Grid,
} from './SuitabilityAndNextSteps.styles'
import CustomSwitch from 'components/CustomSwitch/CustomSwitch'

const SuitabilityAndNextSteps = () => {
  const {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    flattenedFormData,
    handleAntdFormItemChange,
    handleCustomSelectChange,
    onPlaceSelect,
    showAddressDetails,
    showAddressDetailsChange,
    warnings,
    form,
  } = useSuitabilityAndNextSteps()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <StyledTitle>Suitability and Next Steps</StyledTitle>
        <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
      </StyledTitleContainer>
      <StyledDescription>
        Please use this page to detail your recommendation, and reasons why you think Equity Release is the best fit for your customers.
      </StyledDescription>
      {warnings.length > 0 && (
        <LendingIssuesContainer>
          <StyledLendingIssuesHeader>
            <StyledIssueTitle>Lending Criteria Issues</StyledIssueTitle>
          </StyledLendingIssuesHeader>
          <StyledDetailsContainer>
            <StyledHeaderWarning>
              It is important to check lending criteria for all lenders. If there is anything unusual about your property or case then please refer to our help
              desk who can help you place your case.
            </StyledHeaderWarning>
          </StyledDetailsContainer>
          <StyledWarningsContainer>
            {warnings.map((warning: any, index: number) => (
              <StyledWarningContainer key={index}>
                <StyledWarningWithIndex>
                  <StyledIndex>{index + 1}</StyledIndex>
                  <StyledBoldText>{warning.formName}</StyledBoldText> | {warning.answer && <StyledBoldText>{warning.answer}</StyledBoldText>}
                  <StyledText>given as the answer for</StyledText>
                  <StyledBoldText>{warning.question}</StyledBoldText>
                </StyledWarningWithIndex>
                <StyledWarningDescription>{warning.description}</StyledWarningDescription>
              </StyledWarningContainer>
            ))}
          </StyledWarningsContainer>
        </LendingIssuesContainer>
      )}
      <StyledTitle>Your Recommendation</StyledTitle>
      <Form
        form={form}
        layout="vertical"
        size="large"
        name="basic"
        initialValues={flattenedFormData}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '12px',
          marginTop: '8px',
          width: '100%',
        }}
        onFinishFailed={onFinishFailed}
        onFinish={onSubmit}
      >
        <CustomFormItem
          label="What recommendation do you plan to make? *"
          width={'100%'}
          placeholder="What recommendation do you plan to make? *"
          onChange={handleCustomSelectChange}
          options={recommendationOptions}
          name="mortgageRecommendation"
          type="select"
          rules={['isRequired']}
        />
        <CustomFormItem
          label="Repaying Unsecured Debts; Please detail any conversation around transferring unsecured debts to property and the higher cost over a longer period"
          placeholder="E.g will be shown here"
          value={formData.transferringUnsecuredDebtsDetails}
          name="transferringUnsecuredDebtsDetails"
          onChange={handleAntdFormItemChange}
          rows={4}
          type="textarea"
        />
        <CustomFormItem
          label="Has customer been told about risks of transferring debt to property and any short term benefit being potentially outweighed by higher cost?"
          onChange={handleAntdFormItemChange}
          options={yesNoOtherOptions}
          name="hasCustomerBeenToldAboutDebtTransferringRisks"
          value={formData.hasCustomerBeenToldAboutDebtTransferringRisks}
          type="radio"
        />
        <CustomFormItem
          label="Has any information not been disclosed? if yes, give details, including reasons why."
          placeholder="E.g will be shown here"
          value={formData.undisclosedInformationDetails}
          name="undisclosedInformationDetails"
          onChange={handleAntdFormItemChange}
          rows={4}
          type="textarea"
        />
        <CustomFormItem
          label="Any other notes on rational for this form of borrowing taking into account customer age and income status"
          placeholder="E.g will be shown here"
          value={formData.notesForBorrowing}
          name="notesForBorrowing"
          onChange={handleAntdFormItemChange}
          rows={4}
          type="textarea"
        />
        <StyledTitle style={{ marginTop: '40px' }}>Your Solicitor</StyledTitle>
        <CustomFormItem
          label="Have you recommended a panel of specialist solicitors for client?"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="hasRecommendedSolicitor"
          value={formData.hasRecommendedSolicitor}
          type="radio"
        />
        {formData.hasRecommendedSolicitor && (
          <CustomFormItem
            label="Has customer accepted recommendation?"
            onChange={handleAntdFormItemChange}
            options={yesNoOptions}
            name="hasCustomerAcceptedRecommendedSolicitor"
            value={formData.hasCustomerAcceptedRecommendedSolicitor}
            type="radio"
          />
        )}
        <CustomFormItem
          label="Estimated Solicitors Fees"
          name="estimatedSolicitorsFees"
          placeholder="E.g will be shown here"
          type="number"
          onChange={handleAntdFormItemChange}
        />
        <CustomFormItem label="Solicitor Name" name="solicitorName" placeholder="E.g will be shown here" onChange={handleAntdFormItemChange} />
        <Styled2x2Grid>
          <GoogleAddressInput label="Solicitor Address" onPlaceSelect={onPlaceSelect} value={formData?.solicitorAddress?.formattedAddress} marginTop={12} />
          <CustomSwitch checked={showAddressDetails} onChange={showAddressDetailsChange} label="Show Address Details" />
        </Styled2x2Grid>
        {showAddressDetails && (
          <Styled2x2Grid>
            <CustomFormItem
              label="Address 1"
              name="solicitorAddress.address1"
              placeholder="Address 1"
              onChange={handleAntdFormItemChange}
              value={formData?.solicitorAddress?.address1}
            />
            <CustomFormItem
              label="Address 2"
              name="solicitorAddress.address2"
              placeholder="Address 2"
              onChange={handleAntdFormItemChange}
              value={formData?.solicitorAddress?.address2}
            />
            <CustomFormItem
              label="Town"
              name="solicitorAddress.town"
              placeholder="Town*"
              onChange={handleAntdFormItemChange}
              value={formData?.solicitorAddress?.town}
            />
            <CustomFormItem
              label="County"
              name="solicitorAddress.county"
              placeholder="County"
              onChange={handleAntdFormItemChange}
              value={formData?.solicitorAddress?.county}
            />
            <CustomFormItem
              label="Country"
              name="solicitorAddress.country"
              placeholder="Country"
              onChange={handleAntdFormItemChange}
              value={formData?.solicitorAddress?.country}
            />
            <CustomFormItem
              label="Postcode"
              name="solicitorAddress.postcode"
              placeholder="Postcode"
              onChange={handleAntdFormItemChange}
              value={formData?.solicitorAddress?.postcode}
            />
          </Styled2x2Grid>
        )}
        <StyledTitle style={{ marginTop: '40px' }}>Fees</StyledTitle>
        <CustomFormItem label="Advice Fee" name="adviceFee" placeholder="E.g will be shown here" type="number" onChange={handleAntdFormItemChange} />
        <CustomFooter loading={loading} setSaveType={setSaveType} />
      </Form>
    </StyledContainer>
  )
}

export default SuitabilityAndNextSteps
