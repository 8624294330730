import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from 'components/Layout'
import MainContentContainer from 'components/MainContentContainer'
import Loading from 'components/Loading'
import CustomBreadcrumb from 'components/CustomBreadcrumb'

import { contentfulDefaultOptions } from 'services/contentful'
import { useBreadcrumb, useComplianceSupport } from 'hooks/resources'

const ComplianceSupport = () => {
  const { data, displayLoading } = useComplianceSupport()
  const { breadcrumbItems, handleBreadcrumbTabChange } = useBreadcrumb()

  return (
    <Layout>
      <MainContentContainer pageName="Compliance Support">
        <CustomBreadcrumb breadcrumbItems={breadcrumbItems} handleBreadcrumbTabChange={handleBreadcrumbTabChange} />
        <div
          style={{
            padding: '24px 20px',
          }}
        >
          {displayLoading ? <Loading /> : <>{documentToReactComponents(data[0].content, contentfulDefaultOptions)}</>}
        </div>
      </MainContentContainer>
    </Layout>
  )
}

export default ComplianceSupport
