export const yesNoSomeOptions = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'Some of it',
    value: 'someOfIt',
  },
  {
    label: 'No',
    value: 'no',
  },
]

export const yesNoSureOptions = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
  {
    label: 'Not Sure',
    value: 'notSure',
  },
]

export const funeralDiscussOptions = [
  {
    label: 'No',
    value: 'no',
  },
  {
    label: 'Now',
    value: 'now',
  },
  {
    label: 'Another Time',
    value: 'anotherTime',
  },
  {
    label: 'Details Sent',
    value: 'detailsSent',
  },
]

export const specialistAdviceOptions = [
  {
    label: 'No',
    value: 'no',
  },
  {
    label: 'Yes, from me',
    value: 'yesFromMe',
  },
  {
    label: 'Yes, from another practitioner',
    value: 'yesFromAnotherPractitioner',
  },
  {
    label: 'Advised to seek advice',
    value: 'advisedToSeekAdvice',
  },
  {
    label: 'Being assisted by family',
    value: 'beingAssistedByFamily',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

export const initialSubstituteDecisionMakerOptions = [
  {
    label: 'Other - See Notes',
    value: 'other',
  },
]

export const powerOfAttorneyOptions = [
  {
    label: 'General / Ordinary Power of Attorney',
    value: 'generalOrdinaryPowerOfAttorney',
  },
  {
    label: 'Lasting Power of Attorney',
    value: 'lastingPowerOfAttorney',
  },
  {
    label: 'Enduring Power of Attorney',
    value: 'enduringPowerOfAttorney',
  },
  {
    label: 'Deputy ship Order',
    value: 'deputyShipOrder',
  },
  {
    label: 'Scottish POA',
    value: 'scottishPOA',
  },
  {
    label: 'N.Ireland POA',
    value: 'nIrelandPOA',
  },
  {
    label: 'None in Place',
    value: 'noneInPlace',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

export const coverFuneralCostOptions = [
  {
    label: 'I Already Have Provision',
    value: 'iAlreadyHaveProvision',
  },
  {
    label: 'I Would Like To Make Provision',
    value: 'iWouldLikeToMakeProvision',
  },
  {
    label: 'No',
    value: 'no',
  },
]

export const careOptions = [
  {
    label: 'Need Care',
    value: 'needCare',
  },
  {
    label: 'Already In Care',
    value: 'alreadyInCare',
  },
  {
    label: 'No Immediate Need For Care',
    value: 'noImmediateNeedForCare',
  },
  {
    label: 'Not Applicable (Outside Scope Of Advice)',
    value: 'notApplicableOutsideScopeOfAdvice',
  },
]

export const disclosedBeneficiariesTableColumns = [
  {
    name: 'name',
    header: 'Name',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'age',
    header: 'Age',
    defaultFlex: 1,
    type: 'number',
  },
  {
    name: 'relationship',
    header: 'Relationship',
    defaultFlex: 1,
    type: 'text',
  },
]

export const howToPayPrePaidFuneralPlanOptions = [
  {
    label: 'From Own Funds',
    value: 'fromOwnFunds',
  },
  {
    label: 'Partially From Loan',
    value: 'partiallyFromLoan',
  },
  {
    label: 'Fully From The Loan',
    value: 'fullyFromTheLoan',
  },
]

export const hasUndergoneNHSCareAssessmentOptions = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
  {
    label: 'Awaiting Date',
    value: 'awaitingDate',
  },
]

export const ownershipTypeOptions = [
  {
    value: 'soleOwnership',
    label: 'Sole Ownership',
  },
  {
    value: 'jointTenancy',
    label: 'Joint Tenancy',
  },
  {
    value: 'jointTenancyWithRightsOfSurvivorship',
    label: 'Joint Tenancy With Rights Of Survivorship',
  },
  {
    value: 'tenancyInCommon',
    label: 'tenants in common',
  },
  {
    value: 'communityProperty',
    label: 'Community Property',
  },
  {
    value: 'notSure',
    label: 'Not Sure',
  },
]

export const timeToReleaseSaleProceedsOptions = [
  {
    label: 'Already Sold',
    value: 'alreadySold',
  },
  {
    label: 'Sold Subject To Contract',
    value: 'soldSubjectToContract',
  },
  {
    label: 'Under 6 Months',
    value: 'underSixMonths',
  },
  {
    label: '1 - 12 Months',
    value: 'oneToTwelveMonths',
  },
  {
    label: '12 Months +',
    value: 'moreThanTwelveMonths',
  },
]

export const importanceOptions = [
  {
    label: 'Highest Priority',
    value: 'highestPriority',
  },
  {
    label: 'Important',
    value: 'important',
  },
  {
    label: 'Preferred (Not Essential)',
    value: 'preferredNotEssential',
  },
  {
    label: 'Not Important',
    value: 'notImportant',
  },
]

export const escalationOptions = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Fixed %',
    value: 'fixedPercent',
  },
  {
    label: 'RPI',
    value: 'retailPriceIndex',
  },
  {
    label: 'RPI + %',
    value: 'retailPriceIndexPlusPercent',
  },
]

export const additionalDeathBenefitsOptions = [
  {
    label: '100% for month 1 only',
    value: 'option1',
  },
  {
    label: '100% for month 1 & 75% for month 2-6',
    value: 'option2',
  },
  {
    label: '100% for month 1 & 75% for months 2-6  & 50% for months 7-12',
    value: 'option3',
  },
  {
    label: '100% for month 1 & x% protection with no limit',
    value: 'option4',
  },
  {
    label: 'Other - please state in notes section',
    value: 'other',
  },
]

export const deferredPeriodOptions = [
  {
    label: '1 Year',
    value: 'oneYear',
  },
  {
    label: '2 Year',
    value: 'twoYears',
  },
  {
    label: '3 Year',
    value: 'threeYears',
  },
  {
    label: '4 Year',
    value: 'fourYears',
  },
  {
    label: '5 Year',
    value: 'fiveYears',
  },
  {
    label: 'Other - State in notes section',
    value: 'other',
  },
]
