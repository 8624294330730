import { useEffect, useState } from 'react'

import { useGetContentfulDataByContentModel } from 'hooks/useContentful'

const useServicesAndWills = () => {
  const [data, setData] = useState<
    {
      id: number
      title: string
      content: any
      isShown: boolean
    }[]
  >([])
  const { data: initialData = [], isLoading } = useGetContentfulDataByContentModel('servicesAndWills', 'fields.title')

  useEffect(() => {
    setData(
      initialData?.map((item: any, index: number) => {
        return {
          //   id: item.sys.id,
          id: `${index}`,
          title: item.fields.title,
          content: item.fields.content,
          isShown: false,
        }
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const handleClick = (id: number) => {
    const index = data.findIndex((item) => item.id === id)
    const newData = [...data]
    newData[index].isShown = !newData[index].isShown
    setData(newData)
  }

  const displayLoading = isLoading || !data

  return {
    data,
    displayLoading,
    handleClick,
  }
}

export default useServicesAndWills
