import { Navigate } from 'react-router-dom'

const UnProtectedRoute = ({ redirectPath = '/clients', children }: any) => {
  const isAuth = !!localStorage.getItem('accessToken')
  if (isAuth) {
    return <Navigate to={redirectPath} replace />
  }

  return children
}

export default UnProtectedRoute
