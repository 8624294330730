import { useGetQuoteHistory } from 'services/clients/clients.service'
import { quoteHistoryColumns } from './staticData'

const useQuoteHistoryTab = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { data, loading } = useGetQuoteHistory(idNumber)

  const quoteHistoryDataSource: any = data.map((item: any) => ({
    id: item._id,
    name: item.createdAt,
    option: item._id,
    transactionNumber: item.transactionNumber,
  }))
  return { quoteHistoryColumns, quoteHistoryDataSource, loading }
}

export default useQuoteHistoryTab
