import { useContext } from 'react'
import { Footer } from 'antd/es/layout/layout'
import { LayoutContext } from 'contexts'
import { StyledButton, StyledButtonsContainer, StyledLightButton } from './CustomFooter.styles'
import { CustomFooterProps } from './CustomFooter.types'

const CustomFooter = ({ loading, buttonText, buttonTheme }: CustomFooterProps) => {
  const { sideBarWidth } = useContext(LayoutContext)
  return (
    <Footer
      style={{
        backgroundColor: '#fff',
        position: 'fixed',
        bottom: 0,
        left: `${sideBarWidth}px`,
        right: 0,
        width: '100%',
        padding: '0 40px',
        borderTop: '1px solid #a8d6ea',
      }}
    >
      <StyledButtonsContainer sideBarWidth={sideBarWidth}>
        {buttonTheme === 'light' ? (
          <StyledLightButton loading={loading} htmlType="submit">
            {buttonText}
          </StyledLightButton>
        ) : (
          <StyledButton loading={loading} htmlType="submit">
            {buttonText}
          </StyledButton>
        )}
      </StyledButtonsContainer>
    </Footer>
  )
}

export default CustomFooter
