import { ILiabilitiesData } from './Liabilities.interface'

export const liabilitiesTableColumns = [
  {
    name: 'liabilityHolder',
    header: 'Customer',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: ILiabilitiesData) => data.liabilityHolder || 'N/A',
  },
  {
    name: 'liabilityType',
    header: 'Type of Liability',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'monthlyCost',
    header: 'Monthly Cost',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: ILiabilitiesData) => `£${data.monthlyCost}`,
  },
  {
    name: 'remainingTerm',
    header: 'Remaining Term',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: ILiabilitiesData) => (typeof data.remainingTerm === 'number' ? `${data.remainingTerm} Month(s)` : 'N/A'),
  },
  {
    name: 'balance',
    header: 'Balance',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: ILiabilitiesData) => `£${data.balance}`,
  },
  {
    name: 'earlyRepaymentCharges',
    header: 'Early Repayment Charges',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: ILiabilitiesData) => `£${data.earlyRepaymentCharges}`,
  },
  {
    name: 'planToRepay',
    header: 'Plan to Repay',
    defaultFlex: 1,
    type: 'text',
  },
]
