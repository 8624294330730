import styled from 'styled-components'

export const StyledWrapper = styled.div`
  width: 100%;
  /* padding: 32px 16px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
