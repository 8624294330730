export const recommendationOptions = [
  {
    label: 'Alternative (non borrowing) as documented',
    value: 'alternativeAsDocumented',
  },
  {
    label: 'Non-Secured Loan',
    value: 'nonSecuredLoan',
  },
  {
    label: 'Traditional mortgage',
    value: 'traditionalMortgage',
  },
  {
    label: 'Later life mortgage (with fixed tern)',
    value: 'laterLifeMortgageWithFixedTern',
  },
  {
    label: 'A RIO Mortgage (with lifetime term)',
    value: 'aRIOMortgageWithLifetimeTerm',
  },
  {
    label: 'Lifetime mortgage',
    value: 'lifetimeMortgage',
  },
  {
    label: 'Home reversion plan',
    value: 'homeReversionPlan',
  },
  {
    label: 'Other (use notes)',
    value: 'other',
  },
]

export const yesNoOtherOptions = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
  {
    label: 'Other - See Notes',
    value: 'other',
  },
]

export const generateWarnings = ({ caseOverview, property }: any) => {
  return [
    ...(caseOverview.hasPowerOfAttorney
      ? [
          {
            formName: 'Case Overview',
            answer: 'Yes',
            question: 'Do you have a Power of Attorney?',
            description: 'Check any chosen lenders criteria regarding Power of Attorneys',
          },
        ]
      : []),
    ...(property.propertyValue < 100000
      ? [
          {
            formName: 'Property',
            answer: property.propertyValue,
            question: 'Property value',
            description: 'Property value below £100,000 - check lenders criteria for minimum property value.',
          },
        ]
      : []),
    ...(property.isPropertyAgeRestrictedOrShelteredHousing === 'ageRestricted' || property.isPropertyAgeRestrictedOrShelteredHousing === 'shelteredHousing'
      ? [
          {
            formName: 'Property',
            answer: property.isPropertyAgeRestrictedOrShelteredHousing ? 'Yes' : 'No',
            question: 'Is property Age Restricted or Sheltered Housing?',
            description:
              'Age Restricted/Sheltered, Premier Equity will indicate which products are available and on what terms. You are advised however to obtain a copy of the lease for the chosen lender prior to application. Contact the Premier Equity Support Team if you need our help to do this for you.',
          },
        ]
      : []),
    ...(property.isExLocalAuthorityOrMODProperty
      ? [
          {
            formName: 'Property',
            answer: property.isExLocalAuthorityOrMODProperty ? 'Yes' : 'No',
            question: ' Is property Ex-Local Authority or MOD?',
            description:
              'Ex-Local Authority/MOD, Premier Equity will indicate which products allow this property type however if property is located in an area that is still largely local authority/MOD owned, it may be declined at valuation stage. You can submit an Enquiry to the Premier Equity Support Team who will check compatibility with all lenders on your behalf.',
          },
        ]
      : []),
    ...(property.isPropertyBTL
      ? [
          {
            formName: 'Property',
            answer: property.isPropertyBTL ? 'Yes' : 'No',
            question: 'Is this property a buy to let?',
            description: 'Please check criteria prior to submitting an application',
          },
        ]
      : []),
    ...(property.isPropertyStandardAndFreeOfAnyIssue
      ? [
          {
            formName: 'Property',
            answer: property.isPropertyStandardAndFreeOfAnyIssue ? 'Yes' : 'No',
            question:
              'Is this property completely standard in its type, construction, location and free of any issue that may present a lending criteria issue?',
            description: 'Please check criteria prior to submitting an application',
          },
        ]
      : []),
    ...(property.hasThatchedRoof
      ? [
          {
            formName: 'Property',
            answer: property.hasThatchedRoof ? 'Yes' : 'No',
            question: 'Thatched roof?',
            description: 'Please check criteria prior to submitting an application',
          },
        ]
      : []),
    ...(property.isSteelFrameConstruction
      ? [
          {
            formName: 'Property',
            answer: property.isSteelFrameConstruction ? 'Yes' : 'No',
            question: 'Steel frame construction?',
            description: 'Please check criteria prior to submitting an application',
          },
        ]
      : []),
    ...(property.isTimberFrameConstruction
      ? [
          {
            formName: 'Property',
            answer: property.isTimberFrameConstruction ? 'Yes' : 'No',
            question: 'Timber Frame construction?',
            description: 'Please check criteria prior to submitting an application',
          },
        ]
      : []),
    ...(property.isPropertyCloseToCommercialPremises
      ? [
          {
            formName: 'Property',
            answer: property.isPropertyCloseToCommercialPremises ? 'Yes' : 'No',
            question: 'Is this property situated close to Commercial Premises?',
            description: 'Please check criteria prior to submitting an application',
          },
        ]
      : []),
    ...(property.isPropertyListed
      ? [
          {
            formName: 'Property',
            answer: property.isPropertyListed ? 'Yes' : 'No',
            question: 'Is the property listed?',
            description: 'Please check criteria prior to submitting an application',
          },
        ]
      : []),
    ...(property.hasPropertySingleSkinWall
      ? [
          {
            formName: 'Property',
            answer: property.hasPropertySingleSkinWall ? 'Yes' : 'No',
            question: 'Are any walls of property single skin?',
            description: 'Please check criteria prior to submitting an application',
          },
        ]
      : []),
    ...(property.hasElementOfFlatRoof
      ? [
          {
            formName: 'Property',
            answer: property.hasElementOfFlatRoof ? 'Yes' : 'No',
            question: 'Does property have any element of flat roof?',
            description: 'Please check criteria prior to submitting an application',
          },
        ]
      : []),
    ...(property.hasMoreThanOneAcreOfLand
      ? [
          {
            formName: 'Property',
            answer: property.hasMoreThanOneAcreOfLand ? 'Yes' : 'No',
            question: 'Does property have more than 1 acre of land?',
            description: 'Please check criteria prior to submitting an application',
          },
        ]
      : []),
    ...(property.isSubjectToAgriculturalTies
      ? [
          {
            formName: 'Property',
            answer: property.isSubjectToAgriculturalTies ? 'Yes' : 'No',
            question: 'Is this property subject to any agricultural ties?',
            description: 'Please check criteria prior to submitting an application',
          },
        ]
      : []),
    ...(property.usedForCommercialPurposes
      ? [
          {
            formName: 'Property',
            answer: property.usedForCommercialPurposes ? 'Yes' : 'No',
            question: 'Is this property used for commercial purposes?',
            description: 'Please check criteria prior to submitting an application',
          },
        ]
      : []),
    ...(property.hasSufferedNaturalDisaster
      ? [
          {
            formName: 'Property',
            answer: property.hasSufferedNaturalDisaster ? 'Yes' : 'No',
            question: 'Has the property has ever suffered from subsidence, heave or landslip?',
            description: 'Please check criteria prior to submitting an application',
          },
        ]
      : []),
  ]
}
