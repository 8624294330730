import Form from 'antd/lib/form'

import CustomFormItem from 'components/CustomFormItem'
import useSuitabilityLetter from 'hooks/clients/ClientDetails/useSuitabilityLetter'

import CustomFooter from './CustomFooter/CustomFooter'
import { StyledNote, StyledWrapper } from './SuitabilityLetterTabContent.styles'
import { PercentIcon, PoundIcon } from 'assets/images'
import { Button, Modal } from 'antd'
import LogoUploadModal from 'modals/LogoUploadModal'

const SuitabilityLetterTabContent = ({ progressData }: any) => {
  const {
    loading,
    setSaveType,
    onSubmit,
    onFinishFailed,
    formData,
    handleAntdFormItemChange,
    saveDisabled,
    showModal,
    closeModal,
    handleDownload,
    setFileName,
    fileName,
    handleFileUpload,
  } = useSuitabilityLetter()

  if (loading) {
    return <div>Loading...</div>
  }
  return (
    <StyledWrapper>
      {progressData?.averageProgress === 100 ? (
        <>
          <StyledNote>
            You can now download and print your client’s suitability letter. Please ensure that all sections of the fact-find have been completed and that a
            copy of the Key Facts Illustration (KFI) has been uploaded to the system. After completing these steps, you must enter the essential information
            below. This will ensure that all sections of the suitability letter are accurate and personalised for your client.
          </StyledNote>
          <Form
            // form={form}
            layout="vertical"
            size="large"
            name="basic"
            initialValues={formData}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
              marginTop: '8px',
              width: '100%',
              maxWidth: '872px',
            }}
            onFinishFailed={onFinishFailed}
            onFinish={onSubmit}
          >
            <CustomFormItem
              label="Lender Name"
              placeholder="Lender Name"
              value={formData.lenderName}
              name="lenderName"
              onChange={handleAntdFormItemChange}
              rules={['isRequired']}
            />
            <CustomFormItem
              label="Product Name"
              placeholder="Product Name"
              value={formData.productName}
              name="productName"
              onChange={handleAntdFormItemChange}
              rules={['isRequired']}
            />
            <CustomFormItem
              label="Property Value"
              placeholder="Property Value"
              value={formData.propertyValue}
              name="propertyValue"
              onChange={handleAntdFormItemChange}
              rules={['isRequired']}
              type="number"
              inputPrefixImg={PoundIcon}
              prefixImgHeight={12}
            />
            <CustomFormItem
              label="Initial Funds Released"
              placeholder="Initial Funds Released"
              value={formData.initialFundsReleased}
              name="initialFundsReleased"
              onChange={handleAntdFormItemChange}
              rules={['isRequired']}
              type="number"
              inputPrefixImg={PoundIcon}
              prefixImgHeight={12}
            />
            <CustomFormItem
              label="Draw-Down Facility"
              placeholder="Draw-Down Facility"
              value={formData.drawDownFacility}
              name="drawDownFacility"
              onChange={handleAntdFormItemChange}
              rules={['isRequired']}
              type="number"
              inputPrefixImg={PoundIcon}
              prefixImgHeight={12}
            />
            <CustomFormItem
              label="Interest Rate Type"
              placeholder="Interest Rate Type"
              value={formData.interestRateType}
              name="interestRateType"
              onChange={handleAntdFormItemChange}
              rules={['isRequired']}
            />
            <CustomFormItem
              label="Monthly Interest Rate"
              placeholder="Monthly Interest Rate"
              value={formData.monthlyInterestRate}
              name="monthlyInterestRate"
              onChange={handleAntdFormItemChange}
              rules={['isRequired']}
              type="number"
              inputPrefixImg={PercentIcon}
              prefixImgHeight={12}
            />
            <CustomFormItem
              label="Annual Equivalent Rate"
              placeholder="Annual Equivalent Rate"
              value={formData.annualEquivalentRate}
              name="annualEquivalentRate"
              onChange={handleAntdFormItemChange}
              rules={['isRequired']}
              type="number"
              inputPrefixImg={PercentIcon}
              prefixImgHeight={12}
            />
            <CustomFormItem
              label="Lifetime mortgage"
              placeholder="Lifetime mortgage"
              value={formData.lifetimeMortgage}
              name="lifetimeMortgage"
              onChange={handleAntdFormItemChange}
              rules={['isRequired']}
            />
            <CustomFormItem
              label="Valuation Fee (due at application)"
              placeholder="Valuation Fee (due at application)"
              value={formData.valuationFee}
              name="valuationFee"
              onChange={handleAntdFormItemChange}
              rules={['isRequired']}
              type="number"
              inputPrefixImg={PoundIcon}
              prefixImgHeight={12}
            />
            <CustomFormItem
              label="Product Fee (due on completion of loan)"
              placeholder="Product Fee (due on completion of loan)"
              value={formData.productFee}
              name="productFee"
              onChange={handleAntdFormItemChange}
              rules={['isRequired']}
              type="number"
              inputPrefixImg={PoundIcon}
              prefixImgHeight={12}
            />
            <CustomFormItem
              label="Advice Fee"
              placeholder="Advice Fee"
              value={formData.adviceFee}
              name="adviceFee"
              onChange={handleAntdFormItemChange}
              rules={['isRequired']}
              type="number"
              inputPrefixImg={PoundIcon}
              prefixImgHeight={12}
            />
            <CustomFormItem
              label="Estimated Solicitors Fees"
              placeholder="Estimated Solicitors Fees"
              value={formData.estimatedSolicitorsFees}
              name="estimatedSolicitorsFees"
              onChange={handleAntdFormItemChange}
              rules={['isRequired']}
              type="number"
              inputPrefixImg={PoundIcon}
              prefixImgHeight={12}
            />
            <CustomFormItem
              label="Early Product Repayment Charges"
              placeholder="Early Product Repayment Charges"
              value={formData.earlyProductRepaymentCharges}
              name="earlyProductRepaymentCharges"
              onChange={handleAntdFormItemChange}
              rules={['isRequired']}
              type="number"
              inputPrefixImg={PoundIcon}
              prefixImgHeight={12}
            />
            <CustomFooter loading={loading} setSaveType={setSaveType} disabled={saveDisabled} />
          </Form>
          <Modal
            title="Upload Logo"
            visible={showModal}
            onCancel={closeModal}
            footer={[
              <Button key="cancel" onClick={closeModal}>
                Cancel
              </Button>,
              <Button key="download" type="primary" onClick={handleDownload}>
                Download
              </Button>,
            ]}
          >
            <LogoUploadModal
              setFileName={setFileName}
              fileName={fileName}
              loading={loading}
              handleFileUpload={handleFileUpload}
              description="Please note if you upload a logo that will be used in file header"
            />
          </Modal>
        </>
      ) : (
        <>
          <StyledNote>Note that Suitability Letter will be generated based on Fact Find and quote response</StyledNote>
          <StyledNote style={{ color: 'red' }}>Please finish the Fact Find forms to 100% to download suitability letter </StyledNote>
        </>
      )}
    </StyledWrapper>
  )
}

export default SuitabilityLetterTabContent
