import styled from 'styled-components'

export const StyledContainer = styled.div`
  width: 100%;
`

export const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

  color: #120224;
`

export const StyledRequiredMsg = styled.p`
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1.5px;
  text-transform: uppercase;

  color: #b3b3b3;
`

export const StyledCustomerCardsContainer = styled.div`
  width: 50%;

  display: flex;
  column-gap: 24px;
  justify-content: center;
  align-items: center;
`

export const Styled2x2Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`

export const StyledBreakLine = styled.div`
  margin: 16px 0;

  width: 100%;
  height: 0;
  border: 1px solid #d8d8d8;
  background-color: #d8d8d8;
`
