import { Checkbox } from 'antd'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  width: 100%;
`

export const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

  color: #120224;
`

export const StyledRequiredMsg = styled.p`
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1.5px;
  text-transform: uppercase;

  color: #b3b3b3;
`

export const StyledFormContainer = styled.div`
  width: 100%;

  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledInvolvementContainer = styled.div`
  width: 100%;
  margin-top: 32px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const StyledInvolvementLabel = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #120224;
`

export const StyledItemContainer = styled.div`
  margin-top: 16px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 16px;
`

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-wrapper .ant-checkbox-inner,
  .ant-checkbox .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    width: 18px !important;
    height: 18px !important;
    border-color: #323232;
  }
`

export const StyledCheckboxMsg = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #120224;
`

export const Styled2x2Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  justify-content: center;
  align-items: center;
`
