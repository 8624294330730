import { Form } from 'antd'
import Loading from 'components/Loading'
import CustomFormItem from 'components/CustomFormItem'
import useCustomerDetails from 'hooks/clients/DetailedForms/useCustomerDetails'
import CustomFooter from 'pages/ClientDetails/CustomFooter/CustomFooter'
import { contactOptions, maritalStatusOptions, relationshipOptions } from '../Customers.helper'
import { StyledContainer, StyledTitle, StyledTitleContainer, StyledRequiredMsg } from '../Customers.styles'
import { nationalityOptions, genderOptions, titleOptions, yesNoOptions } from 'utils/commonOptions'

const CustomerDetails = () => {
  const { onFinishFailed, onSubmit, setSaveType, loading, handleAntdFormItemChange, handleCustomSelectChange, formData } = useCustomerDetails()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <StyledTitle>Customer Details</StyledTitle>
        <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
      </StyledTitleContainer>
      <Form
        layout="vertical"
        size="large"
        name="basic"
        initialValues={formData}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '12px',
          marginTop: '8px',
          width: '100%',
        }}
        onFinishFailed={onFinishFailed}
        onFinish={onSubmit}
      >
        <CustomFormItem
          label="Title *"
          onChange={handleAntdFormItemChange}
          options={titleOptions}
          value={formData.title}
          name="title"
          type="radio"
          rules={['isRequired']}
        />
        <CustomFormItem label="First Name *" name="firstName" placeholder="First Name *" rules={['isRequired']} onChange={handleAntdFormItemChange} />
        <CustomFormItem label="Middle Names" name="middleNames" placeholder="Middle Names" onChange={handleAntdFormItemChange} />
        <CustomFormItem label="Last Name *" name="lastName" placeholder="Last Name *" rules={['isRequired']} onChange={handleAntdFormItemChange} />
        <CustomFormItem
          label="Relationship to Other Customer (if applicable)"
          width={'100%'}
          placeholder="Relationship to Other Customer (if applicable)"
          onChange={handleCustomSelectChange}
          options={relationshipOptions}
          name="relationshipToOtherCustomer"
          type="select"
        />
        <StyledTitle style={{ marginTop: '48px' }}>Personal Details</StyledTitle>
        <CustomFormItem
          label="Gender *"
          onChange={handleAntdFormItemChange}
          options={genderOptions}
          value={formData.gender}
          name="gender"
          type="radio"
          rules={['isRequired']}
        />
        <CustomFormItem
          label="Date of birth *"
          name="dateOfBirth"
          placeholder="Date of birth *"
          type="date"
          rules={['isRequired']}
          onChange={handleAntdFormItemChange}
        />
        <CustomFormItem
          label="Marital Status"
          width={'100%'}
          placeholder="Marital Status"
          onChange={handleCustomSelectChange}
          options={maritalStatusOptions}
          name="maritalStatus"
          type="select"
        />
        <CustomFormItem
          label="Nationality *"
          name="nationality"
          placeholder="Nationality *"
          onChange={handleCustomSelectChange}
          type="select"
          options={nationalityOptions}
          rules={['isRequired']}
        />
        <CustomFormItem
          label="Lived here since birth? *"
          name="livedHereSinceBirth"
          placeholder="Lived here since birth? *"
          onChange={handleAntdFormItemChange}
          type="radio"
          options={yesNoOptions}
          rules={['isRequired']}
        />
        {formData.livedHereSinceBirth === false && (
          <CustomFormItem
            label="Do you have right to remain? *"
            name="hasRightToRemain"
            placeholder="Do you have right to remain? *"
            onChange={handleAntdFormItemChange}
            type="radio"
            options={yesNoOptions}
            rules={['isRequired']}
          />
        )}
        <CustomFormItem
          label="Landline Telephone"
          name="landlineTelephone"
          placeholder="Landline Telephone"
          onChange={handleAntdFormItemChange}
          rules={['validateTelephoneNumber']}
        />
        <CustomFormItem
          label="Mobile Telephone"
          name="mobileTelephone"
          placeholder="Mobile Telephone"
          onChange={handleAntdFormItemChange}
          rules={['validateTelephoneNumber']}
        />
        <CustomFormItem label="Email" name="email" placeholder="Email" onChange={handleAntdFormItemChange} rules={['validateEmail']} />
        <CustomFormItem
          label="Preferred contact method for updates"
          onChange={handleCustomSelectChange}
          options={contactOptions}
          value={formData.preferredContactMethod}
          name="preferredContactMethod"
          type="select"
          mode="multiple"
        />
        <CustomFooter loading={loading} setSaveType={setSaveType} />
      </Form>
    </StyledContainer>
  )
}

export default CustomerDetails
