import { useState } from 'react'
import config from 'config'

import { StyledContainer, StyledGoogleAutocomplete, StyledLabel } from './GoogleAddressInput.styles'
import { GoogleAddressInputProps } from './GoogleAddressInput.types'

const GoogleAddressInput = ({ label, placeholder, onPlaceSelect, value, marginTop }: GoogleAddressInputProps) => {
  const [formattedValue, setFormattedValue] = useState(value)

  const onChange = (e: any) => setFormattedValue(e.target.value)

  const handlePlaceSelect = (place: any) => {
    // Use the place object to retrieve the address components
    const addressComponents = place.address_components
    const formattedAddress = place.formatted_address
    setFormattedValue(formattedAddress)

    // Extract the individual address components
    let address1 = null
    let address2 = null
    let town = null
    let county = null
    let country: string | null = null
    let postcode = null

    addressComponents.forEach((component: any) => {
      if (component.types.includes('street_number')) address1 = component.long_name
      if (component.types.includes('route')) address2 = component.long_name
      if (component.types.includes('postal_town')) town = component.long_name
      if (component.types.includes('administrative_area_level_2')) county = component.long_name
      if (component.types.includes('administrative_area_level_1') && !country) country = component.long_name
      if (component.types.includes('country') && !country) country = component.long_name // Set country from this type as a fallback
      if (component.types.includes('postal_code')) postcode = component.long_name
    })

    onPlaceSelect({
      formattedAddress,
      address1,
      address2,
      town,
      county,
      country,
      postcode,
    })
  }

  return (
    <StyledContainer marginTop={marginTop}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledGoogleAutocomplete
        apiKey={config.GOOGLE_API_KEY}
        onPlaceSelected={handlePlaceSelect}
        options={{
          componentRestrictions: { country: ['uk', 'ie'] },
          fields: ['formatted_address', 'address_components'],
          // Note: you can put: 'geocode', 'address' and 'establishment' here.
          // it takes array as an argument but works only when it got 1 argument.
          // Note that 'geocode' works with establishments, addresses and with postcodes.
          types: ['geocode'],
        }}
        placeholder={placeholder || 'Search for an Address'}
        value={formattedValue}
        onChange={onChange}
      />
    </StyledContainer>
  )
}

export default GoogleAddressInput
