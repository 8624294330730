import Layout from 'components/Layout'
import MainContentContainer from 'components/MainContentContainer'
import XLSXViewer from 'components/XLSXViewer'

const LenderCriteria = () => {
  return (
    <Layout>
      <MainContentContainer pageName="Lender Criteria">
        <XLSXViewer />
      </MainContentContainer>
    </Layout>
  )
}

export default LenderCriteria
