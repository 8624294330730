import styled from 'styled-components'

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StylesImg = styled.img`
  margin: 56px 0 70px 0;
`

export const StyledDescription = styled.p`
  width: 459px;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;

  color: #603363;
`

export const StyledDownloadButton = styled.div`
  /* cursor: pointer; */
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #219653;
  text-align: center;

  /* Button/Button - 16px */
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`

export const StyledApplyButton = styled.p`
  cursor: pointer;
  padding: 8px 16px;
  align-items: center;

  color: #91258c;
  text-align: center;

  font-weight: 500;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
`
