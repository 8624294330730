import { useState, useEffect, useContext } from 'react'
import message from 'antd/lib/message'
import Form from 'antd/lib/form'
import * as _ from 'lodash'

import { commonHelper } from 'helpers'

import { commonUtils, eventManager } from 'utils'
import { FormsContext } from 'contexts'
import { useGetOccupant } from 'services/clients/clients.service'
import { addEditOccupantMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { AddEditOccupantPayload } from 'services/shared/mutation.payload'
import { TCustomCheckboxValue } from 'components/CustomCheckbox/CustomCheckbox.types'

const initialState = {
  isOpen: false,
  data: { _id: '', isEdit: false },
  cb: { confirm: () => null, cancel: () => null },
  loading: false,
}

const useOccupantModal = () => {
  const [form] = Form.useForm()
  const { propertyToEdit } = useContext(FormsContext)
  const addEditOccupant = useCustomMutation<AddEditOccupantPayload>({ mutation: addEditOccupantMutation, refetchQueries: ['getOccupants'] })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const [showAddressDetails, setShowAddressDetails] = useState(false)
  const [modalState, setModalState] = useState(initialState)
  const [occupantInfoTypeToProvide, setOccupantInfoTypeToProvide] = useState('both')
  const occupantId = modalState?.data?._id
  const { occupant, loading: initialDataLoading } = useGetOccupant({
    idNumber,
    occupantId,
    skip: !modalState.isOpen || !modalState.data.isEdit,
  })
  const [loading, setLoading] = useState(initialDataLoading)
  const [formData, setFormData] = useState({
    name: occupant.name,
    age: occupant.age,
    relationshipWithCustomer: occupant.relationshipWithCustomer,
    isBeneficiary: occupant.isBeneficiary,
    financiallyDependantOnCustomer: occupant.financiallyDependantOnCustomer,
    email: occupant.email,
    address: {
      formattedAddress: occupant?.address?.formattedAddress,
      address1: occupant?.address?.address1,
      address2: occupant?.address?.address2,
      town: occupant?.address?.town,
      county: occupant?.address?.county,
      country: occupant?.address?.country,
      postcode: occupant?.address?.postcode,
    },
  })
  const flattenedFormData = commonUtils.flattenObject(formData)

  useEffect(() => {
    setLoading(initialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(occupant)
    delete newData._id
    setFormData({ ...newData })
    form.setFieldsValue({ ...newData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDataLoading])

  const clearFormData = () => {
    setFormData((prevState) => commonUtils.resetFormData(prevState))
    setOccupantInfoTypeToProvide('both')
    form.setFieldsValue(commonUtils.resetFormData(formData))
  }

  const handleAntdFormInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => _.set(_.cloneDeep(prevState), e.target.name, e.target.value))
  }

  const handleCustomCheckboxChange = ({ name, value }: { name: string; value: TCustomCheckboxValue }) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleOccupantInfoTypeToProvideChange = ({ name, value }: { name: string; value: TCustomCheckboxValue }) => {
    setOccupantInfoTypeToProvide(String(value))
  }

  const onPlaceSelect = ({ formattedAddress, address1, address2, town, county, country, postcode }: any) => {
    setFormData((prevState) => ({
      ...prevState,
      address: {
        formattedAddress,
        address1,
        address2,
        town,
        county,
        country,
        postcode,
      },
    }))

    form.setFieldValue('address.formattedAddress', formattedAddress)
    form.setFieldValue('address.address1', address1)
    form.setFieldValue('address.address2', address2)
    form.setFieldValue('address.town', town)
    form.setFieldValue('address.county', county)
    form.setFieldValue('address.country', country)
    form.setFieldValue('address.postcode', postcode)
  }

  useEffect(() => {
    eventManager.addListener('occupant-modal', (data: any, cb: any) => {
      setModalState((state) => ({
        ...state,
        isOpen: data !== undefined,
        data,
        cb,
      }))
    })
  }, [setModalState])

  const handleClose = () => {
    setModalState((state) => ({ ...state, loading: false }))
    eventManager.closeOccupantModal()
  }

  const handleCancelClick = async () => {
    try {
      setModalState((state) => ({ ...state, loading: true }))
      if (modalState.cb.cancel) {
        await modalState.cb.cancel()
      }
    } catch (err) {
      // console.error(err)
    }
    handleClose()
    clearFormData()
  }

  const handleSubmit = async () => {
    const { errors } = await addEditOccupant({
      idNumber,
      occupantToEdit: occupantId,
      propertyToEdit,
      values: {
        ...formData,
        age: Number(formData.age),
      },
    })
    if (errors?.length) {
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const handleConfirmClick = async () => {
    try {
      setModalState((state) => ({ ...state, loading: true }))
      await handleSubmit()
      clearFormData()
      if (modalState.cb.confirm) {
        await modalState.cb.confirm()
      }
    } catch (err) {
      // console.error(err)
    }
    setModalState((state) => ({ ...state, loading: false }))
    handleClose()
  }

  const showAddressDetailsChange = (checked: boolean) => {
    setShowAddressDetails(checked)
  }

  return {
    modalState,
    handleCancelClick,
    handleConfirmClick,
    formData,
    flattenedFormData,
    occupantInfoTypeToProvide,
    handleAntdFormInputChange,
    handleCustomCheckboxChange,
    handleOccupantInfoTypeToProvideChange,
    onPlaceSelect,
    showAddressDetails,
    showAddressDetailsChange,
    loading,
    form,
  }
}

export default useOccupantModal
