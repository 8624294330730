import styled from 'styled-components'

export const DropDownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 20px;
  gap: 24px;
`

// export const DropdownTile = styled.div`
//   width: 100%;
//   height: 50px;
//   background-color: ${({ theme }) => theme.colors.white};
//   border-radius: 4px;
//   border: 1px solid ${({ theme }) => theme.colors.grey};
//   display: flex;
//   align-items: center;
//   padding: 0 20px;
//   cursor: pointer;
//   transition: all 0.3s ease-in-out;
//   &:hover {
//     background-color: ${({ theme }) => theme.colors.grey};
//   }
// `

export const DropdownTile = styled.div`
  /* width: 800px;
  max-width: 100%; */
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 50px;
  background-color: #fff;
  border: 2px solid #90258c;
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  font-size: 24px;
  font-weight: 700;
`

export const DropdownTileContent = styled.div`
  width: 100%;
  padding: 16px 8px;
  border-left: 2px solid #c3c3c3;
  border-right: 2px solid #c3c3c3;
  border-bottom: 2px solid #c3c3c3;
  background-color: #fff;
`
