import Form from 'antd/lib/form'
import CustomFormItem from 'components/CustomFormItem'

import { StyledContainer } from './LinkClientDrawer.styles'
import { ILinkClientDrawerProps } from './LinkClientDrawer.interface'
import CustomDrawer from 'components/CustomDrawer'
import CustomSelect from 'components/CustomSelect'
import { PoundIcon } from 'assets/images'

const LinkClientDrawer = ({
  loading,
  open,
  handleDrawerClose,
  clientsOptions,
  handleCustomSelectChange,
  formData,
  handleFormFieldChange,
  handleSubmit,
  form,
  statusOptions,
  formState,
}: ILinkClientDrawerProps) => {
  return (
    <CustomDrawer loading={loading} open={open} closeDrawer={handleDrawerClose} title={'Link Client'} disableSaveButton={false} handleSubmit={handleSubmit}>
      <StyledContainer>
        <Form
          form={form}
          layout="vertical"
          size="large"
          name="basic"
          onChange={handleFormFieldChange}
          initialValues={formData}
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',
          }}
        >
          {formState === 'add' ? (
            <CustomSelect
              label="Your Clients (Use idNumber for searching) *"
              width={'100%'}
              placeholder="Your Clients (Use idNumber for searching) *"
              defaultValue={formData.idNumber}
              onChange={handleCustomSelectChange}
              options={clientsOptions}
              name="idNumber"
            />
          ) : (
            <CustomFormItem label="Client Name  *" rules={['isRequired']} name="clientName" placeholder="Client Name  *" disabled />
          )}
          <CustomSelect
            label="Application Status *"
            width={'100%'}
            placeholder="Application Status *"
            defaultValue={formData.status}
            onChange={handleCustomSelectChange}
            options={statusOptions}
            name="status"
            disabled={!formData.idNumber}
          />
          <CustomFormItem
            label="Date of application  *"
            rules={['isRequired']}
            name="dateOfApplication"
            placeholder="Date of application  *"
            type="date"
            disabled={!formData.idNumber}
          />
          <CustomFormItem
            label="Lender/Provider  *"
            rules={['isRequired']}
            name="lenderOrProvider"
            placeholder="LENDER/PROVIDER  *"
            disabled={!formData.idNumber}
          />
          <CustomFormItem label="Fee Type *" rules={['isRequired']} name="feeType" placeholder="FEE TYPE *" disabled={!formData.idNumber} />
          <CustomFormItem
            label="Amount *"
            rules={['isRequired']}
            name="amount"
            placeholder="Amount *"
            type="number"
            inputPrefixImg={PoundIcon}
            prefixImgHeight={12}
            disabled={!formData.idNumber}
          />
          <CustomFormItem label="Completion Date" name="completionDate" placeholder="Completion Date" type="date" disabled={!formData.idNumber} />
          <CustomFormItem label="NTU Date" name="ntuDate" placeholder="NTU Date" type="date" disabled={!formData.idNumber} />
        </Form>
      </StyledContainer>
    </CustomDrawer>
  )
}

export default LinkClientDrawer
