import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;

  cursor: pointer;
`

export const StyledImg = styled.img``

export const StyledText = styled.p`
  color: #90258c;
  text-align: center;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`
