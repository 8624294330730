import { useEffect, useState } from 'react'
import _ from 'lodash'
import { Form } from 'antd'

import { commonUtils } from 'utils'
import useQuickSearchPage from 'hooks/clients/useQuickSearchPage'
import { IClientDetails, ILifeStyleDetails, IQuickSearch } from 'interfaces/quickSearch.interface'

const useLifestyleDetails = () => {
  const [form] = Form.useForm()
  const [
    loading,
    // setLoading
  ] = useState(false)

  const clientDetails: IClientDetails = JSON.parse(localStorage.getItem('quickSearch') || '{}').clientDetails
  const customerFirstName = clientDetails?.firstLife?.firstName
  const customerSecondName = clientDetails?.secondLife?.firstName
  const enterMedicalDetailsOfCustomerFirst = clientDetails?.firstLife?.enterMedicalDetails
  const enterMedicalDetailsOfCustomerSecond = clientDetails?.secondLife?.enterMedicalDetails && clientDetails?.isSecondLife

  const localData: ILifeStyleDetails = JSON.parse(localStorage.getItem('quickSearch') || '{}').medicalDetails?.lifeStyleDetails

  const [formData, setFormData] = useState({
    customerFirst: {
      consumedUnitsOfAlcohol: localData?.customerFirst?.consumedUnitsOfAlcohol,
      hasDiagnosedHighBloodPressure: localData?.customerFirst?.hasDiagnosedHighBloodPressure,
      hasSmokedRollingTobacco: localData?.customerFirst?.hasSmokedRollingTobacco,
      hasSmokedTenOrMoreCigarettes: localData?.customerFirst?.hasSmokedTenOrMoreCigarettes,
      height: {
        feet: localData?.customerFirst?.height?.feet,
        inches: localData?.customerFirst?.height?.inches,
      },
      weight: {
        stone: localData?.customerFirst?.weight?.stone,
        pound: localData?.customerFirst?.weight?.pound,
      },
    },
    customerSecond: {
      consumedUnitsOfAlcohol: localData?.customerSecond?.consumedUnitsOfAlcohol,
      hasDiagnosedHighBloodPressure: localData?.customerSecond?.hasDiagnosedHighBloodPressure,
      hasSmokedRollingTobacco: localData?.customerSecond?.hasSmokedRollingTobacco,
      hasSmokedTenOrMoreCigarettes: localData?.customerSecond?.hasSmokedTenOrMoreCigarettes,
      height: {
        feet: localData?.customerSecond?.height?.feet,
        inches: localData?.customerSecond?.height?.inches,
      },
      weight: {
        stone: localData?.customerSecond?.weight?.stone,
        pound: localData?.customerSecond?.weight?.pound,
      },
    },
  })

  const flattenedFormData = commonUtils.flattenObject(formData)

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])

  const { handleNextFormNavigation } = useQuickSearchPage()

  const submitData = () => {
    const localStorageData: IQuickSearch = JSON.parse(localStorage.getItem('quickSearch') || '{}')
    const newState = {
      ...localStorageData,
      medicalDetails: {
        ...localStorageData.medicalDetails,
        lifeStyleDetails: formData,
      },
    }
    localStorage.setItem('quickSearch', JSON.stringify(newState))
  }

  const handleNext = () => {
    handleNextFormNavigation(submitData)
  }

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value, e.target?.name)
    setFormData((prevState) => _.set(_.cloneDeep(prevState), e.target.name, parsedValue))
  }

  return {
    loading,
    handleNext,
    handleAntdFormItemChange,
    flattenedFormData,
    customerFirstName,
    customerSecondName,
    enterMedicalDetailsOfCustomerFirst,
    enterMedicalDetailsOfCustomerSecond,
    form,
  }
}

export default useLifestyleDetails
