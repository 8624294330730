import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from 'antd'

export const StyledDropDownMenuWrapper = styled.div`
  position: relative;
  font-size: 1rem;
  top: 4px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    top: 0;
  }
`

export const StyledDropDownLink = styled.a`
  display: flex;
  place-items: center;
  margin-left: 8px;
  color: #707070;
  font-size: 17px;
`

export const StyledLink = styled(Link)`
  color: #605c5c;
  align-self: center;
  display: flex;
`

export const StyledMenu = styled.div`
  display: grid;
  justify-items: center;

  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 5px 1px rgba(224, 224, 224, 1);
`

export const StyledButtonWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  a {
    margin-right: 5px;
  }
`

export const StyledDropdownBtn = styled(Button)<{ col?: string }>`
  font-weight: bold !important;
  text-transform: capitalize;
  color: #90258c;
  border: 2px solid #90258c;
`

export const StyledUserName = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #120224;
`

export const StyledIRESSVerifiedStatusWrapper = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: #120224;
  padding: 0 10px;
  cursor: pointer;
`

export const StyledIRESSVerificationStatus = styled.span<{ verified: boolean }>`
  color: ${({ verified }) => (verified ? '#4caf50' : '#f44336')};
  font-weight: 600;
`
