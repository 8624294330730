/* eslint-disable no-useless-computed-key */
import { Form, Modal } from 'antd'

import { StyledWrapper, Styled2x2Grid } from './OtherResidentModal.styles'
import TextInput from 'components/CustomFormItem/CustomFormItem'
import Loading from 'components/Loading/Loading'
import useOtherResidentModal from 'hooks/clients/Modals/useOtherResidentModal'
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox'
import { occupantInfoTypeToProvideOptions } from './OtherResidentModal.helper'
import GoogleAddressInput from 'components/GoogleAddressInput/GoogleAddressInput'
import CustomSwitch from 'components/CustomSwitch/CustomSwitch'
import { yesNoOptions } from 'utils/commonOptions'

const OtherResidentModal = () => {
  const {
    modalState,
    handleCancelClick,
    handleConfirmClick,
    formData,
    flattenedFormData,
    otherResidentInfoTypeToProvide,
    handleAntdFormInputChange,
    handleCustomCheckboxChange,
    handleOtherResidentInfoTypeToProvideChange,
    onPlaceSelect,
    showAddressDetails,
    showAddressDetailsChange,
    loading,
    form,
  } = useOtherResidentModal()

  if (loading) {
    return <Loading />
  }

  return (
    <Modal
      title="Edit Occupant"
      open={modalState.isOpen}
      onOk={handleConfirmClick}
      onCancel={handleCancelClick}
      width={700}
      okText="Save"
      cancelText="Cancel"
      closable={false}
      className="edit-asset-modal"
      confirmLoading={modalState.loading}
    >
      {modalState.loading ? (
        <Loading />
      ) : (
        <StyledWrapper>
          <Form
            form={form}
            layout="vertical"
            size="large"
            name="basic"
            initialValues={formData}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
              marginTop: '8px',
              width: '100%',
            }}
          >
            <Styled2x2Grid>
              <TextInput label="Name *" name="name" placeholder="Name *" onChange={handleAntdFormInputChange} />
              <TextInput label="Age *" name="age" placeholder="Age *" onChange={handleAntdFormInputChange} type="number" />
            </Styled2x2Grid>
            <TextInput label="Relationship *" name="relationshipWithCustomer" placeholder="Relationship *" onChange={handleAntdFormInputChange} />
          </Form>
          <CustomCheckbox
            label="Is Occupant also Beneficiary?"
            onChange={handleCustomCheckboxChange}
            options={yesNoOptions}
            name="isBeneficiary"
            value={formData.isBeneficiary}
          />
          <CustomCheckbox
            label="Are they financially dependant on you?"
            onChange={handleCustomCheckboxChange}
            options={yesNoOptions}
            name="financiallyDependantOnCustomer"
            value={formData.financiallyDependantOnCustomer}
          />
          <CustomCheckbox
            label="Select type of information to provide"
            onChange={handleOtherResidentInfoTypeToProvideChange}
            options={occupantInfoTypeToProvideOptions}
            name="occupantInfoTypeToProvide"
            value={otherResidentInfoTypeToProvide}
          />
          <Form
            layout="vertical"
            size="large"
            name="basic"
            initialValues={flattenedFormData}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
              marginTop: '8px',
              width: '100%',
            }}
          >
            {otherResidentInfoTypeToProvide === 'email' && (
              <TextInput label="Email *" name="email" placeholder="Email *" onChange={handleAntdFormInputChange} rules={['validateEmail']} />
            )}
            {otherResidentInfoTypeToProvide === 'address' && (
              <Styled2x2Grid>
                <GoogleAddressInput label="Search for an Address" onPlaceSelect={onPlaceSelect} value={formData?.address?.formattedAddress} />
                <CustomSwitch checked={showAddressDetails} onChange={showAddressDetailsChange} label="Show Address Details" />
                {showAddressDetails && (
                  <>
                    <TextInput
                      label="Address 1"
                      name="address.address1"
                      placeholder="Address 1"
                      onChange={handleAntdFormInputChange}
                      value={formData?.address?.address1}
                    />
                    <TextInput
                      label="Address 2"
                      name="address.address2"
                      placeholder="Address 2"
                      onChange={handleAntdFormInputChange}
                      value={formData?.address?.address2}
                    />
                    <TextInput label="Town" name="address.town" placeholder="Town*" onChange={handleAntdFormInputChange} value={formData?.address?.town} />
                    <TextInput
                      label="County"
                      name="address.county"
                      placeholder="County"
                      onChange={handleAntdFormInputChange}
                      value={formData?.address?.county}
                    />
                    <TextInput
                      label="Country"
                      name="address.country"
                      placeholder="Country"
                      onChange={handleAntdFormInputChange}
                      value={formData?.address?.country}
                    />
                    <TextInput
                      label="Postcode"
                      name="address.postcode"
                      placeholder="Postcode"
                      onChange={handleAntdFormInputChange}
                      value={formData?.address?.postcode}
                    />
                  </>
                )}
              </Styled2x2Grid>
            )}
            {otherResidentInfoTypeToProvide === 'both' && (
              <>
                <TextInput label="Email *" name="email" placeholder="Email *" onChange={handleAntdFormInputChange} rules={['validateEmail']} />
                <Styled2x2Grid>
                  <GoogleAddressInput label="Search for an Address" onPlaceSelect={onPlaceSelect} value={formData?.address?.formattedAddress} />
                  <CustomSwitch checked={showAddressDetails} onChange={showAddressDetailsChange} label="Show Address Details" />
                  {showAddressDetails && (
                    <>
                      <TextInput
                        label="Address 1"
                        name="address.address1"
                        placeholder="Address 1"
                        onChange={handleAntdFormInputChange}
                        value={formData?.address?.address1}
                      />
                      <TextInput
                        label="Address 2"
                        name="address.address2"
                        placeholder="Address 2"
                        onChange={handleAntdFormInputChange}
                        value={formData?.address?.address2}
                      />
                      <TextInput label="Town" name="address.town" placeholder="Town*" onChange={handleAntdFormInputChange} value={formData?.address?.town} />
                      <TextInput
                        label="County"
                        name="address.county"
                        placeholder="County"
                        onChange={handleAntdFormInputChange}
                        value={formData?.address?.county}
                      />
                      <TextInput
                        label="Country"
                        name="address.country"
                        placeholder="Country"
                        onChange={handleAntdFormInputChange}
                        value={formData?.address?.country}
                      />
                      <TextInput
                        label="Postcode"
                        name="address.postcode"
                        placeholder="Postcode"
                        onChange={handleAntdFormInputChange}
                        value={formData?.address?.postcode}
                      />
                    </>
                  )}
                </Styled2x2Grid>
              </>
            )}
          </Form>
        </StyledWrapper>
      )}
    </Modal>
  )
}

export default OtherResidentModal
