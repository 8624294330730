import styled from 'styled-components'

export const StyledGridContainer = styled.div`
  margin-top: 24px;
`

export const SubPagesWrapper = styled.div`
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(50px, 250px)); */
  grid-template-columns: repeat(6, minmax(50px, 250px));
  grid-gap: 24px;
`

export const SubPageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 340px;
  padding: 16px 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start !important;
  gap: 12px !important;

  border: 3px solid #90258c;
`

export const StyledImg = styled.img`
  width: 100%;
  height: auto;
`

export const StyledTitle = styled.h3`
  align-self: flex-start;

  font-size: 16px;
  font-weight: 800;
`

export const StyledDescription = styled.p`
  align-self: flex-start;
`

export const StyledButton = styled.a`
  position: absolute;
  bottom: 16px;
  left: 24px;

  color: #fff;
  background-color: #90258c;
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 0;
`
