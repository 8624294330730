import { FormsContext } from 'contexts'
import { useContext } from 'react'

const useTabsGeneral = ({ handleSubmit, setLoading }: { handleSubmit: any; setLoading: any }) => {
  const { antdActiveTabKey, setAntdActiveTabKey, setCustomerToEdit } = useContext(FormsContext)

  const handleNavigateBack = () => {
    setAntdActiveTabKey(`${Number(antdActiveTabKey) - 1}`)
    window.scroll({ top: 0, behavior: 'smooth' })
  }

  const handleNavigateNext = () => {
    setAntdActiveTabKey(`${Number(antdActiveTabKey) + 1}`)
    window.scroll({ top: 0, behavior: 'smooth' })
  }
  const handleSaveAndGoBack = async () => {
    setLoading(true)
    await handleSubmit()
    handleNavigateBack()
    setLoading(false)
  }

  const handleSave = async () => {
    setLoading(true)
    await handleSubmit()
    setLoading(false)
  }

  const handleSaveAndContinue = async () => {
    setLoading(true)
    await handleSubmit()
    setCustomerToEdit(2)
    handleNavigateNext()
    setLoading(false)
  }

  return {
    handleSaveAndGoBack,
    handleSave,
    handleSaveAndContinue,
    setLoading,
  }
}

export default useTabsGeneral
