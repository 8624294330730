import { loader } from 'graphql.macro';

// Note: Mutations
export const createCompanyMutation = loader('./createCompanyMutation.graphql');
export const suspendCompaniesMutation = loader(
  './suspendCompaniesMutation.graphql',
);
export const editCompanyMutation = loader('./editCompanyMutation.graphql');
export const deleteCompaniesMutation = loader(
  './deleteCompaniesMutation.graphql',
);

// Note: Queries
export const getCompaniesQuery = loader('./companies.graphql');
export const getCompanyQuery = loader('./getCompanyQuery.graphql');
