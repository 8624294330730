import { useQuery } from '@apollo/client'
import {
  caseOverviewInfoQuery,
  getClientsPagination,
  getCustomerFirstInfoQuery,
  getCustomerSecondInfoQuery,
  liabilitiesInfoQuery,
  liabilityQuery,
  assetsInfoQuery,
  creditHistoryInfoQuery,
  objectivesAndAlternativesInfoQuery,
  borrowingSolutionAndPrioritiesInfoQuery,
  familyInfoQuery,
  estatePlanningInfoQuery,
  propertyInfoQuery,
  incomeInfoQuery,
  benefitsInfoQuery,
  expenditureInfoQuery,
  suitabilityAndNextStepsInfoQuery,
  healthInfoQuery,
  propertiesInfoQuery,
  getCustomersQuery,
  clientDataForTrackingQuery,
  clientFormsProgressQuery,
  getQuoteHistoryQuery,
  getQuickSearchDataForClientDetailsQuery,
  getQuickSearchDataForPropertyAndLandingQuery,
  getQuickSearchDataForFurtherInformationQuery,
  getClientCountQuery,
  beneficiaryQuery,
  dependentQuery,
  recipientQuery,
  receivingBenefitQuery,
  otherAssetQuery,
  expenditureDetailsQuery,
  incomeSourceQuery,
  futurePensionQuery,
  objectiveQuery,
  previousAddressQuery,
  occupantQuery,
  personPresentDuringFactFindQuery,
  eligibleBenefitQuery,
  previousAddressesQuery,
  occupantsQuery,
  beneficiariesQuery,
  dependentsQuery,
  recipientsQuery,
  benefitsReceivingQuery,
  eligibleBenefitsQuery,
  otherAssetsQuery,
  otherLiabilitiesQuery,
  expendituresDetailsQuery,
  incomeSourcesQuery,
  futurePensionsQuery,
  objectivesQuery,
  personsPresentDuringFactFindQuery,
  caseOverviewForWarningsQuery,
  propertiesInfoForWarningsQuery,
  clientJourneyQuery,
  otherResidentQuery,
  otherResidentsQuery,
  getClientFilesQuery,
  getClientNotesQuery,
  getClientFormsInfoQuery,
  getGetClientAgentQuery,
  getRecommendedProductQuery,
  getClientsForLinkingInStats,
  getClientsStatisticsPagination,
} from 'gql/client'

export const useGetClients = (variables: any) => {
  const { data, error, loading, refetch } = useQuery(getClientsPagination, {
    variables,
  })
  return {
    clients: data?.getClientsPagination?.clients || [],
    count: data?.getClientsPagination?.count || 0,
    loading,
    error,
    refetch,
  }
}

interface IGetClientsStatisticsPaginationVars {
  companyId?: string
  limit?: number
  skip: number
  status?: string
}

export const useGetClientsStatisticsPagination = (variables: IGetClientsStatisticsPaginationVars) => {
  const { data, error, loading, refetch } = useQuery(getClientsStatisticsPagination, {
    variables,
  })
  return {
    clientsStatistics: data?.getClientsStatisticsPagination?.clients || [],
    count: data?.getClientsStatisticsPagination?.count || 0,
    loading,
    error,
    refetch,
  }
}

interface IGetClientsForLinkingInStatsVars {
  companyId?: string
  limit?: number
  skip: number
  search?: string
  isAddedInStatistics?: boolean
}

export const useGetClientsForLinkingInStats = (variables: IGetClientsForLinkingInStatsVars) => {
  const { data, error, loading, refetch } = useQuery(getClientsForLinkingInStats, {
    variables,
  })
  return {
    clients: data?.getClientsPagination?.clients || [],
    count: data?.getClientsPagination?.count || 0,
    loading,
    error,
    refetch,
  }
}

export const useGetCaseOverviewInfoForWarnings = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(caseOverviewForWarningsQuery, {
    variables: { idNumber },
  })
  return {
    caseOverview: data?.getCaseOverviewInfo?.caseOverview || {},
    loading,
    error,
    refetch,
  }
}

export const useGetClientCaseOverviewInfo = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(caseOverviewInfoQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getCaseOverviewInfo || {},
    loading,
    error,
    refetch,
  }
}

export const useGetRecommendedProduct = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(getRecommendedProductQuery, {
    variables: { idNumber },
  })
  return {
    recommendedProduct: data?.getClientFormsInfo?.recommendedProduct || {},
    loading,
    error,
    refetch,
  }
}

export const useGetPersonsPresentDuringFactFind = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(personsPresentDuringFactFindQuery, {
    variables: { idNumber },
  })
  return {
    personsPresentDuringFactFind: data?.getCaseOverviewInfo?.caseOverview?.personsPresentDuringFactFind || [],
    loading,
    error,
    refetch,
  }
}

export const useGetPersonPresentDuringFactFind = ({
  idNumber,
  personPresentDuringFactFindId,
  skip,
}: {
  idNumber: number
  personPresentDuringFactFindId: string
  skip: boolean
}) => {
  const { data, error, loading, refetch } = useQuery(personPresentDuringFactFindQuery, {
    variables: { idNumber, personPresentDuringFactFindId },
    skip,
  })

  return {
    personPresentDuringFactFind: data?.getPersonPresentDuringFactFind || {},
    loading,
    error,
    refetch,
  }
}

export const useGetClientCount = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(getClientCountQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getCaseOverviewInfo?.caseOverview || {},
    loading,
    error,
    refetch,
  }
}

export const useGetCustomerInfo = (idNumber: number, customer: number) => {
  const { data, error, loading, refetch } = useQuery(customer === 1 ? getCustomerFirstInfoQuery : getCustomerSecondInfoQuery, {
    variables: { idNumber },
  })

  const customerInfo = customer === 1 ? data?.getCustomerFirstInfo?.customerFirst : data?.getCustomerSecondInfo?.customerSecond || {}
  return {
    customer: customerInfo,
    loading,
    error,
    refetch,
  }
}

export const useGetClientAgent = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(getGetClientAgentQuery, {
    variables: { idNumber },
  })

  return {
    user: data?.getClientAgent || {},
    loading,
    error,
    refetch,
  }
}

// Note: this "ICustomersDetails" interface corresponds to this query
export const useGetCustomers = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(getCustomersQuery, {
    variables: { idNumber },
  })

  return {
    data: data?.getCustomers || {},
    loading,
    error,
    refetch,
  }
}

export const useGetPropertyInfo = (idNumber: number, property_id: string) => {
  const { data, error, loading, refetch } = useQuery(propertyInfoQuery, {
    variables: { idNumber, property_id },
  })
  return {
    data: data?.getPropertyInfo.property || {},
    loading,
    error,
    refetch,
  }
}

export const useGetPreviousAddresses = (idNumber: number, property_id: string) => {
  const { data, error, loading, refetch } = useQuery(previousAddressesQuery, {
    variables: { idNumber, property_id },
  })
  return {
    previousAddresses: data?.getPropertyInfo?.property?.previousAddressHistory || [],
    loading,
    error,
    refetch,
  }
}

export const useGetOccupants = (idNumber: number, property_id: string) => {
  const { data, error, loading, refetch } = useQuery(occupantsQuery, {
    variables: { idNumber, property_id },
  })
  return {
    occupants: data?.getPropertyInfo?.property?.occupants || [],
    loading,
    error,
    refetch,
  }
}

export const useGetOtherResidents = (idNumber: number, property_id: string) => {
  const { data, error, loading, refetch } = useQuery(otherResidentsQuery, {
    variables: { idNumber, property_id },
  })
  return {
    otherResidents: data?.getPropertyInfo?.property?.otherResidents || [],
    loading,
    error,
    refetch,
  }
}

export const useGetPropertiesInfo = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(propertiesInfoQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getPropertiesInfo?.properties || [],
    loading,
    error,
    refetch,
  }
}

export const useGetPropertiesInfoForWarnings = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(propertiesInfoForWarningsQuery, {
    variables: { idNumber },
  })
  return {
    property: data?.getPropertiesInfo.properties[0] || {},
    loading,
    error,
    refetch,
  }
}

export const useGetPreviousAddress = ({ idNumber, previousAddressId, skip }: { idNumber: number; previousAddressId: string; skip: boolean }) => {
  const { data, error, loading, refetch } = useQuery(previousAddressQuery, {
    variables: { idNumber, previousAddressId },
    skip,
  })

  return {
    previousAddress: data?.getPreviousAddress || {},
    loading,
    error,
    refetch,
  }
}

export const useGetOccupant = ({ idNumber, occupantId, skip }: { idNumber: number; occupantId: string; skip: boolean }) => {
  const { data, error, loading, refetch } = useQuery(occupantQuery, {
    variables: { idNumber, occupantId },
    skip,
  })

  return {
    occupant: data?.getOccupant || {},
    loading,
    error,
    refetch,
  }
}

export const useGetOtherResident = ({ idNumber, otherResidentId, skip }: { idNumber: number; otherResidentId: string; skip: boolean }) => {
  const { data, error, loading, refetch } = useQuery(otherResidentQuery, {
    variables: { idNumber, otherResidentId },
    skip,
  })

  return {
    otherResident: data?.getOtherResident || {},
    loading,
    error,
    refetch,
  }
}

export const useGetLiabilitiesInfo = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(liabilitiesInfoQuery, {
    variables: { idNumber },
  })

  return {
    liabilities: data?.getLiabilitiesInfo?.liabilities || {},
    loading,
    error,
    refetch,
  }
}

export const useGetOtherLiabilities = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(otherLiabilitiesQuery, {
    variables: { idNumber },
  })

  return {
    otherLiabilities: data?.getLiabilitiesInfo?.liabilities?.otherLiabilities || [],
    loading,
    error,
    refetch,
  }
}

export const useGetLiability = ({ idNumber, liabilityId, skip }: { idNumber: number; liabilityId: string; skip: boolean }) => {
  const { data, error, loading, refetch } = useQuery(liabilityQuery, {
    variables: { idNumber, liabilityId },
    skip,
  })

  return {
    liability: data?.getLiability || {},
    loading,
    error,
    refetch,
  }
}

export const useGetAssetsInfo = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(assetsInfoQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getAssetsInfo?.assets || {},
    loading,
    error,
    refetch,
  }
}

export const useGetOtherAssets = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(otherAssetsQuery, {
    variables: { idNumber },
  })
  return {
    otherAssets: data?.getAssetsInfo?.assets?.otherAssets || [],
    loading,
    error,
    refetch,
  }
}

export const useGetOtherAsset = ({ idNumber, otherAssetId, skip }: { idNumber: number; otherAssetId: string; skip: boolean }) => {
  const { data, error, loading, refetch } = useQuery(otherAssetQuery, {
    variables: { idNumber, otherAssetId },
    skip,
  })

  return {
    otherAsset: data?.getOtherAsset || {},
    loading,
    error,
    refetch,
  }
}

export const useGetCreditHistoryInfo = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(creditHistoryInfoQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getCreditHistoryInfo || {},
    loading,
    error,
    refetch,
  }
}

export const useGetIncomeInfo = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(incomeInfoQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getIncomeInfo || {},
    loading,
    error,
    refetch,
  }
}

export const useGetIncomeSources = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(incomeSourcesQuery, {
    variables: { idNumber },
  })
  return {
    incomeSources: data?.getIncomeInfo?.income?.incomeSources || [],
    loading,
    error,
    refetch,
  }
}

export const useGetFuturePensions = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(futurePensionsQuery, {
    variables: { idNumber },
  })
  return {
    futurePensions: data?.getIncomeInfo?.income?.futurePensions || [],
    loading,
    error,
    refetch,
  }
}

export const useGetIncomeSource = ({ idNumber, incomeSourceId, skip }: { idNumber: number; incomeSourceId: string; skip: boolean }) => {
  const { data, error, loading, refetch } = useQuery(incomeSourceQuery, {
    variables: { idNumber, incomeSourceId },
    skip,
  })

  return {
    incomeSource: data?.getIncomeSource || {},
    loading,
    error,
    refetch,
  }
}

export const useGetFuturePension = ({ idNumber, futurePensionId, skip }: { idNumber: number; futurePensionId: string; skip: boolean }) => {
  const { data, error, loading, refetch } = useQuery(futurePensionQuery, {
    variables: { idNumber, futurePensionId },
    skip,
  })

  return {
    futurePension: data?.getFuturePension || {},
    loading,
    error,
    refetch,
  }
}

export const useGetBenefitsInfo = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(benefitsInfoQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getBenefitsInfo?.benefits || {},
    loading,
    error,
    refetch,
  }
}

export const useGetBenefitsReceiving = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(benefitsReceivingQuery, {
    variables: { idNumber },
  })
  return {
    benefitsReceiving: data?.getBenefitsInfo?.benefits?.benefitsReceiving || [],
    loading,
    error,
    refetch,
  }
}

export const useGetEligibleBenefits = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(eligibleBenefitsQuery, {
    variables: { idNumber },
  })
  return {
    eligibleBenefits: data?.getBenefitsInfo?.benefits?.eligibleBenefits || [],
    loading,
    error,
    refetch,
  }
}

export const useGetReceivingBenefit = ({ idNumber, receivingBenefitId, skip }: { idNumber: number; receivingBenefitId: string; skip: boolean }) => {
  const { data, error, loading, refetch } = useQuery(receivingBenefitQuery, {
    variables: { idNumber, receivingBenefitId },
    skip,
  })

  return {
    receivingBenefit: data?.getReceivingBenefit || {},
    loading,
    error,
    refetch,
  }
}

export const useGetEligibleBenefit = ({ idNumber, eligibleBenefitId, skip }: { idNumber: number; eligibleBenefitId: string; skip: boolean }) => {
  const { data, error, loading, refetch } = useQuery(eligibleBenefitQuery, {
    variables: { idNumber, eligibleBenefitId },
    skip,
  })

  return {
    eligibleBenefit: data?.getEligibleBenefit || {},
    loading,
    error,
    refetch,
  }
}

export const useGetExpenditureInfo = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(expenditureInfoQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getExpenditureInfo?.expenditure || {},
    loading,
    error,
    refetch,
  }
}

export const useGetExpendituresDetails = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(expendituresDetailsQuery, {
    variables: { idNumber },
  })
  return {
    expendituresDetails: data?.getExpenditureInfo?.expenditure?.expendituresDetails || [],
    loading,
    error,
    refetch,
  }
}

export const useGetExpenditureDetails = ({ idNumber, expenditureDetailsId, skip }: { idNumber: number; expenditureDetailsId: string; skip: boolean }) => {
  const { data, error, loading, refetch } = useQuery(expenditureDetailsQuery, {
    variables: { idNumber, expenditureDetailsId },
    skip,
  })

  return {
    expenditureDetails: data?.getExpenditureDetails || {},
    loading,
    error,
    refetch,
  }
}

export const useGetObjectivesAndAlternativesInfo = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(objectivesAndAlternativesInfoQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getObjectivesAndAlternativesInfo?.objectivesAndAlternatives || {},
    loading,
    error,
    refetch,
  }
}

export const useGetObjectives = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(objectivesQuery, {
    variables: { idNumber },
  })
  return {
    objectives: data?.getObjectivesAndAlternativesInfo?.objectivesAndAlternatives?.objectives || [],
    loading,
    error,
    refetch,
  }
}

export const useGetObjective = ({ idNumber, objectiveId, skip }: { idNumber: number; objectiveId: string; skip: boolean }) => {
  const { data, error, loading, refetch } = useQuery(objectiveQuery, {
    variables: { idNumber, objectiveId },
    skip,
  })

  return {
    objective: data?.getObjective || {},
    loading,
    error,
    refetch,
  }
}

export const useGetFamilyInfo = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(familyInfoQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getFamilyInfo?.family || {},
    loading,
    error,
    refetch,
  }
}

export const useGetBeneficiaries = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(beneficiariesQuery, {
    variables: { idNumber },
  })
  return {
    beneficiaries: data?.getFamilyInfo?.family?.beneficiaries || [],
    loading,
    error,
    refetch,
  }
}

export const useGetDependents = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(dependentsQuery, {
    variables: { idNumber },
  })
  return {
    dependents: data?.getFamilyInfo?.family?.dependents || [],
    loading,
    error,
    refetch,
  }
}

export const useGetRecipients = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(recipientsQuery, {
    variables: { idNumber },
  })
  return {
    recipients: data?.getFamilyInfo?.family?.recipients || [],
    loading,
    error,
    refetch,
  }
}

export const useGetBeneficiary = ({ idNumber, beneficiaryId, skip }: { idNumber: number; beneficiaryId: string; skip: boolean }) => {
  const { data, error, loading, refetch } = useQuery(beneficiaryQuery, {
    variables: { idNumber, beneficiaryId },
    skip,
  })

  return {
    beneficiary: data?.getBeneficiary || {},
    loading,
    error,
    refetch,
  }
}

export const useGetDependent = ({ idNumber, dependentId, skip }: { idNumber: number; dependentId: string; skip: boolean }) => {
  const { data, error, loading, refetch } = useQuery(dependentQuery, {
    variables: { idNumber, dependentId },
    skip,
  })

  return {
    dependent: data?.getDependent || {},
    loading,
    error,
    refetch,
  }
}

export const useGetRecipient = ({ idNumber, recipientId, skip }: { idNumber: number; recipientId: string; skip: boolean }) => {
  const { data, error, loading, refetch } = useQuery(recipientQuery, {
    variables: { idNumber, recipientId },
    skip,
  })

  return {
    recipient: data?.getRecipient || {},
    loading,
    error,
    refetch,
  }
}

export const useGetHealthInfo = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(healthInfoQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getHealthInfo || {},
    loading,
    error,
    refetch,
  }
}

export const useGetBorrowingSolutionAndPrioritiesInfo = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(borrowingSolutionAndPrioritiesInfoQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getBorrowingSolutionAndPrioritiesInfo?.borrowingSolutionAndPriorities || {},
    loading,
    error,
    refetch,
  }
}

export const useGetEstatePlanningInfo = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(estatePlanningInfoQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getEstatePlanningInfo || {},
    loading,
    error,
    refetch,
  }
}

export const useGetSuitabilityAndNextStepsInfo = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(suitabilityAndNextStepsInfoQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getSuitabilityAndNextStepsInfo?.suitabilityAndNextSteps || {},
    loading,
    error,
    refetch,
  }
}

export const useGetClientFormsInfo = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(getClientFormsInfoQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getClientFormsInfo || {},
    loading,
    error,
    refetch,
  }
}

export const useGetClientDataForTrackingQuery = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(clientDataForTrackingQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getClientDataForTrackingQuery || {},
    loading,
    error,
    refetch,
  }
}

export const useGetClientJourney = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(clientJourneyQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getClientJourney?.journey || {},
    loading,
    error,
    refetch,
  }
}

export const useGetClientFormsProgress = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(clientFormsProgressQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getClientFormsProgress || {},
    loading,
    error,
    refetch,
  }
}

export const useGetQuoteHistory = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(getQuoteHistoryQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getQuoteHistory || [],
    loading,
    error,
    refetch,
  }
}

export const useGetQuickSearchDataForClientDetails = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(getQuickSearchDataForClientDetailsQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getQuickSearchData || {},
    loading,
    error,
    refetch,
  }
}

export const useGetQuickSearchDataForPropertyAndLanding = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(getQuickSearchDataForPropertyAndLandingQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getQuickSearchData?.properties[0] || {},
    loading,
    error,
    refetch,
  }
}

export const useGetQuickSearchDataForFurtherInformation = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(getQuickSearchDataForFurtherInformationQuery, {
    variables: { idNumber },
  })
  return {
    data: data?.getQuickSearchData || {},
    loading,
    error,
    refetch,
  }
}

export const useGetClientFilesQuery = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(getClientFilesQuery, {
    variables: { idNumber },
  })

  return {
    data: data?.getClientFiles?.files || [],
    loading,
    error,
    refetch,
  }
}

export const useGetNotes = (idNumber: number) => {
  const { data, error, loading, refetch } = useQuery(getClientNotesQuery, {
    variables: { idNumber },
  })

  return {
    notes: data?.getNotes?.notes || [],
    loading,
    error,
    refetch,
  }
}
