import Button from 'antd/lib/button';
import styled from 'styled-components';

export const StyledCloseIcon = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 24px;
  margin-top: 32px;
`;

export const StyledCancelButton = styled.button`
  cursor: pointer;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;

  width: 140px;
  height: 48px;

  border: 2px solid #90258c;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  background-color: #f8f8f8;
  color: #90258c;
`;

export const StyledSaveButton = styled(Button)`
  cursor: pointer;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;

  width: 140px;
  height: 48px;

  border: 2px solid #90258c;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  background-color: #f8f8f8;
  color: #90258c;
`;
