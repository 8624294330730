import Tabs from 'antd/lib/tabs'

import Layout from 'components/Layout'
import MainContentContainer from 'components/MainContentContainer'

import { IRESSLogo } from 'assets/images'

import {
  StyledContainer,
  StyledHeaderContainer,
  StyledLeftContainer,
  StyledRightContainer,
  StyledImg,
  StyledName,
  StyledDescription,
  StyledColumn,
} from './QuickSearch.styles'
import ClientDetailsTab from './Tabs/ClientDetailsTab/ClientDetailsTab'
import PropertyAndLandingTab from './Tabs/PropertyAndLandingTab/PropertyAndLandingTab'
import FurtherInformationTab from './Tabs/FurtherInformationTab/FurtherInformationTab'
import useQuickSearchPage from 'hooks/clients/useQuickSearchPage'
import { useContext } from 'react'
import MedicalQuestionsTab from './Tabs/MedicalQuestionsTab/MedicalQuestionsTab'
import Forms from 'contexts/Forms'

const { TabPane } = Tabs

const QuickSearch = () => {
  const {
    quickSearch: { activeTab, tabs },
  } = useContext(Forms)

  const {
    handleTabChange,

    displayDetailForm,
    handleDetailedFormClose,
    medicalQuestionsTabItems,
    breadcrumbItems,
    handleBreadcrumbTabChange,
    handleItemClick,
    detailedFormName,
  } = useQuickSearchPage()

  return (
    <Layout>
      <MainContentContainer pageName="Search With IRESS">
        <StyledContainer>
          <StyledHeaderContainer>
            <StyledLeftContainer>
              <StyledImg src={IRESSLogo} />
            </StyledLeftContainer>
            <StyledRightContainer>
              <StyledColumn>
                <StyledName>Quick search</StyledName>
                <StyledDescription>Note: search process is performed via Iress</StyledDescription>
              </StyledColumn>
            </StyledRightContainer>
          </StyledHeaderContainer>
          <Tabs style={{ width: '100%' }} activeKey={activeTab} type="card" onChange={handleTabChange}>
            <TabPane tab={tabs.clientDetails} key={tabs.clientDetails}>
              <ClientDetailsTab />
            </TabPane>
            {!!tabs?.medicalQuestions && (
              <TabPane tab={tabs.medicalQuestions} key={tabs.medicalQuestions}>
                <MedicalQuestionsTab
                  displayDetailForm={displayDetailForm}
                  handleDetailedFormClose={handleDetailedFormClose}
                  items={medicalQuestionsTabItems}
                  breadcrumbItems={breadcrumbItems}
                  handleBreadcrumbTabChange={handleBreadcrumbTabChange}
                  handleItemClick={handleItemClick}
                  detailedFormName={detailedFormName}
                />
              </TabPane>
            )}
            <TabPane tab={tabs.propertyAndLanding} key={tabs.propertyAndLanding}>
              <PropertyAndLandingTab />
            </TabPane>
            <TabPane tab={tabs.furtherInformation} key={tabs.furtherInformation}>
              <FurtherInformationTab />
            </TabPane>
          </Tabs>
        </StyledContainer>
      </MainContentContainer>
    </Layout>
  )
}

export default QuickSearch
