import { useContext, useState } from 'react'
import { message } from 'antd'

import AuthContext from 'contexts/AuthContext'
import { commonHelper } from 'helpers'
import { commonUtils } from 'utils'

import { useCustomMutation } from 'services/shared/mutation'
import { ChangePasswordPayload } from 'services/shared/mutation.payload'
import { changePasswordMutation } from 'gql/user'

const usePasswordTabContent = () => {
  const { refetch } = useContext(AuthContext)
  const changePassword = useCustomMutation<ChangePasswordPayload>({ mutation: changePasswordMutation })

  const [loading, setLoading] = useState(false)
  const [changePasswordForm, setChangePasswordForm] = useState<any>({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  })

  const handleSubmit = async () => {
    setLoading(true)

    if (!commonUtils.isValidPassword(changePasswordForm.newPassword)) {
      message.info('Invalid password, please have a look at password requirements')
      setLoading(false)
      return
    }
    if (!commonUtils.compareStringEquality(changePasswordForm.newPassword, changePasswordForm.confirmPassword)) {
      message.info('Passwords do not match')
      setLoading(false)
      return
    }
    const { errors } = await changePassword({
      currentPassword: changePasswordForm.currentPassword,
      newPassword: changePasswordForm.newPassword,
    })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Password updated successfully')

    setLoading(false)
    refetch()
  }

  const handleFormFieldChange = (e: any) => {
    setChangePasswordForm((prevState: any) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  return { handleFormFieldChange, loading, handleSubmit }
}

export default usePasswordTabContent
