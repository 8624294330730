const generatePDF = (base64String: string, filename: string = 'download.pdf'): void => {
  // Decode the Base64 string to binary data
  const binaryString: string = window.atob(base64String)

  // Convert binary string to an array of bytes
  const len: number = binaryString.length
  const bytes: Uint8Array = new Uint8Array(len)
  for (let i: number = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }

  // Create a Blob from the byte array
  const blob: Blob = new Blob([bytes], { type: 'application/pdf' })

  // Create a URL for the Blob
  const blobUrl: string = URL.createObjectURL(blob)

  // Create an anchor element and set the URL and default filename
  const a: HTMLAnchorElement = document.createElement('a')
  a.href = blobUrl
  a.download = filename
  document.body.appendChild(a)

  // Trigger the download
  a.click()

  // Clean up
  document.body.removeChild(a)
  URL.revokeObjectURL(blobUrl)
}

export default generatePDF
