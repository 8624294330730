import { Form } from 'antd'
import { useParams } from 'react-router-dom'

import CustomSelect from 'components/CustomSelect'
import Loading from 'components/Loading'
import TextInput from 'components/CustomFormItem'
import CustomFooter from 'pages/UserProfile/CustomFooter/CustomFooter'
import { useProfileTabContent } from 'hooks'
import { useGetUserById } from 'services/users/users.service'

import {
  StyledContainer,
  StyledMinContainer,
  StyledFormContainer,
  StyledTitle,
  StyledCompanyOptionContainer,
  StyledCheckBoxContainer,
  StyledCheckBoxLabel,
  StyledCheckBox,
  StyledSelectContainer,
} from './ProfileTabContent.styles'

const ProfileTabContent = () => {
  const { userId } = useParams()
  const { form, companyUser, companyUserChange, handleCompanyChange, options, handleSubmit } = useProfileTabContent()
  const { user, loading } = useGetUserById(userId as string)

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledMinContainer>
        <StyledTitle>Details</StyledTitle>
        <StyledFormContainer>
          <Form
            layout="vertical"
            size="large"
            name="basic"
            form={form}
            initialValues={{
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              phoneNumber: user.phoneNumber,
              fcaNumber: user.fcaNumber,
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
            }}
          >
            <TextInput label="Email *" name="email" placeholder="Email" />
            <TextInput label="First Name *" rules={['isRequired']} name="firstName" placeholder="First Name" />
            <TextInput label="Last Name *" rules={['isRequired']} name="lastName" placeholder="Last Name" />
            <TextInput label="Phone *" name="phoneNumber" placeholder="Phone" rules={['isRequired']} />
            <TextInput label="FCA Number" name="fcaNumber" placeholder="Enter Your FCA Number" type="number" />
            {user.role === 'superAdmin' && (
              <StyledCompanyOptionContainer>
                <StyledCheckBoxContainer>
                  <StyledCheckBoxLabel>Company user</StyledCheckBoxLabel>
                  <StyledCheckBox checked={companyUser} onChange={companyUserChange} />
                </StyledCheckBoxContainer>
                <StyledSelectContainer>
                  <CustomSelect
                    disabled={!companyUser}
                    onChange={handleCompanyChange}
                    defaultValue={null}
                    placeholder="Select Company"
                    options={options}
                    width="100%"
                  />
                </StyledSelectContainer>
              </StyledCompanyOptionContainer>
            )}
            <CustomFooter loading={loading} handleSave={handleSubmit} />
          </Form>
        </StyledFormContainer>
      </StyledMinContainer>
    </StyledContainer>
  )
}

export default ProfileTabContent
