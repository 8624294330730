import React from 'react'
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined'
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined'
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import { IContextUser } from 'contexts/AuthContext'

interface ISidebarItem {
  key: string
  label: string
  icon: JSX.Element
  isShown: boolean
}

const InitialItems = (user: IContextUser) => [
  {
    key: 'Clients',
    label: 'Clients',
    icon: React.createElement(PortraitOutlinedIcon),
    isShown: true,
  },
  {
    key: 'Dashboard',
    label: 'Dashboard',
    icon: React.createElement(DashboardOutlinedIcon),
    isShown: true,
  },
  {
    key: 'Learning',
    label: 'Learning',
    icon: React.createElement(LocalLibraryOutlinedIcon),
    isShown: true,
  },
  {
    key: 'Users',
    label: 'Users',
    icon: React.createElement(PeopleOutlinedIcon),
    isShown: user.role === 'superAdmin',
  },
  {
    key: 'Companies',
    label: 'Companies',
    icon: React.createElement(DomainOutlinedIcon),
    isShown: user.role === 'superAdmin',
  },
  {
    key: 'Resources',
    label: 'Resources',
    icon: React.createElement(LayersOutlinedIcon),
    isShown: true,
  },
  {
    key: 'lenders-criteria',
    label: 'Lenders Criteria',
    icon: React.createElement(ArticleOutlinedIcon),
    isShown: true,
  },
]

export const filteredSidebarItems = (user: IContextUser) => {
  const items = InitialItems(user)
  const filteredItems = items
    .filter((item: ISidebarItem) => item.isShown === true)
    .map((item) => ({
      key: item.key,
      label: item.label,
      icon: item.icon,
    }))
  return filteredItems
}
