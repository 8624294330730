import styled from 'styled-components'

export const StyledContainer = styled.div`
  margin-bottom: 56px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledHeaderContainer = styled.div`
  box-sizing: border-box;
  padding: 16px;
  background: #eaeaea;
  border-radius: 4px;

  max-width: 100%;
  width: 100%;
  min-height: 80px;

  margin-bottom: 24px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  column-gap: 40px;
`

export const StyledLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
  max-width: 80%;
`

export const StyledRightContainer = styled.div`
  display: flex;
  flex-direction: row !important;
  flex-direction: column;
  justify-content: center;
  column-gap: 20px;
`

export const StyledImgBorder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  border-radius: 4px;
  border: 2px solid #120124;
  box-sizing: border-box;
  padding: 12px;
`

export const StyledImg = styled.img``

export const StyledDescription = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #120224;
`

export const StyledMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 16px;

  background-color: #fff;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 1px 5px 1px rgba(224, 224, 224, 1);

  max-width: 100vw;
  width: 500px;
`

export const KeysTitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #120224;
`

export const KeysWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  row-gap: 4px;
`

export const KeyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const KeyBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #fefefe;
  border: 1px solid #a8a8a8;
  border-radius: 4px;

  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: right;

  color: #120224;
  padding: 8px;
`

export const KeyDescDelimiter = styled.span`
  margin: 0 8px;
`

export const KeyDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #120224;
`

export const LoaderContentWrapper = styled.div`
  margin-top: 128px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
`

export const LoaderText = styled.p`
  color: #603363;
  text-align: center;
  font-size: 18px;
  font-family: Inter;
  line-height: 26px;
`
