import { FormTable } from 'components/FormTable'
import Loading from 'components/Loading/Loading'

import { QuoteHistoryPlaceholder } from 'assets/images'

import useQuoteHistoryTab from 'hooks/clients/useQuoteHistoryTab'
import { StyledContainer, StylesImg, StyledDescription } from './QuoteHistoryTabContent.styles'

const QuoteHistoryTabContent = () => {
  const { quoteHistoryColumns, quoteHistoryDataSource, loading } = useQuoteHistoryTab()

  if (loading) {
    return <Loading fontSize={48} />
  }

  return (
    <StyledContainer>
      {quoteHistoryDataSource.length > 0 ? (
        <FormTable columns={quoteHistoryColumns} dataSource={quoteHistoryDataSource} checkboxColumn={false} spacesAroundGrid={false} />
      ) : (
        <>
          <StylesImg src={QuoteHistoryPlaceholder} alt="Quote history" />
          <StyledDescription>There are no quote results yet.</StyledDescription>
        </>
      )}
    </StyledContainer>
  )
}

export default QuoteHistoryTabContent
