import { useState } from 'react'
import message from 'antd/lib/message'

import { commonHelper } from 'helpers'
import { useCustomMutation } from 'services/shared/mutation'
import { ForgetPasswordPayload } from 'services/shared/mutation.payload'
import { forgetPasswordMutation } from 'gql/user'

const useForgetPassword = () => {
  const forgetPassword = useCustomMutation<ForgetPasswordPayload>({ mutation: forgetPasswordMutation })
  const [loading, setLoading] = useState(false)

  const handleSubmit = async ({ email }: { email: string }): Promise<void> => {
    try {
      setLoading(true)

      if (!email) {
        message.info('Please fill in your email address.')
        setLoading(false)
        return
      }

      const { errors } = await forgetPassword({
        email,
      })

      if (errors?.length) {
        setLoading(false)
        const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
        message.error(errMessage)
        return
      }

      message.success('Email sent successfully. Please check your inbox.')

      setLoading(false)
    } catch (err) {
      // pass.
    }
  }

  return {
    handleSubmit,
    loading,
  }
}

export default useForgetPassword
