import { Tabs } from 'antd'
import { UserAvatarPlaceholder } from 'assets/images'
import Layout from 'components/Layout'
import Loading from 'components/Loading'
import MainContentContainer from 'components/MainContentContainer'
import PasswordTabContent from './Tabs/PasswordTabContent'
import ProfileTabContent from './Tabs/ProfileTabContent'
import IRESSDetailsContent from './Tabs/IRESSDetailsContent'
import { useUserProfile } from 'hooks'

import {
  StyledContainer,
  StyledClientHeaderContainer,
  StyledLeftContainer,
  StyledAvatar,
  StyledInfoContainer,
  StyledName,
  StyledChangeAvatarLabel,
} from './UserProfile.styles'
import NotesContent from './Tabs/NotesContent'
import FilesContent from './Tabs/FilesContent'

const { TabPane } = Tabs

const UserProfile = () => {
  const { activeKey, handleTabChange, handleAvatarUpload, avatar, avatarLoading, user, isOwnProfile, displayNotesAndFilesTab } = useUserProfile()

  return (
    <Layout>
      <MainContentContainer pageName="Profile">
        <StyledContainer>
          <StyledClientHeaderContainer>
            <StyledLeftContainer>
              {avatarLoading ? <Loading /> : <StyledAvatar src={avatar || UserAvatarPlaceholder} />}
              <StyledInfoContainer>
                <StyledName>{`${user?.firstName} ${user?.lastName}`}</StyledName>
                {isOwnProfile && (
                  <>
                    <StyledChangeAvatarLabel htmlFor="user_avatar">Change photo</StyledChangeAvatarLabel>
                    <input onChange={handleAvatarUpload} name="user_avatar" id="user_avatar" hidden type="file" accept=".png,.svg,.jpg,.jpeg" />
                  </>
                )}
              </StyledInfoContainer>
            </StyledLeftContainer>
          </StyledClientHeaderContainer>
          <Tabs style={{ width: '100%' }} activeKey={activeKey} type="card" onChange={handleTabChange}>
            <TabPane tab="PROFILE" key="1">
              <ProfileTabContent />
            </TabPane>
            {isOwnProfile && (
              <TabPane tab="PASSWORD" key="2">
                <PasswordTabContent />
              </TabPane>
            )}
            <TabPane tab="IRESS DETAILS" key="3">
              <IRESSDetailsContent />
            </TabPane>
            {displayNotesAndFilesTab && (
              <>
                <TabPane tab="NOTES" key="4">
                  <NotesContent />
                </TabPane>
                <TabPane tab="FILES" key="5">
                  <FilesContent />
                </TabPane>
              </>
            )}
          </Tabs>
        </StyledContainer>
      </MainContentContainer>
    </Layout>
  )
}

export default UserProfile
