import { BLOCKS, Block, Inline } from '@contentful/rich-text-types'

import { contentfulDefaultOptions } from 'services/contentful'

const paragraphStyles = {
  padding: '4px 0',
}

export const options = {
  renderNode: {
    ...contentfulDefaultOptions.renderNode,
    // ... other renderers
    [BLOCKS.PARAGRAPH]: (_: Block | Inline, children: React.ReactNode) => <p style={paragraphStyles}>{children}</p>,
    // ...other custom renderers
  },
}
