import styled from 'styled-components'

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-left: 12px;
`

export const StyledLogo = styled.img`
  padding: 24px 0 8px 0;
  cursor: pointer;
`
