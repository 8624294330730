import { Form, Modal } from 'antd'

import { StyledWrapper, Styled2x2Grid } from './EligibleBenefitModal.styles'
import TextInput from 'components/CustomFormItem/CustomFormItem'
import CustomTextArea from 'components/CustomTextArea/CustomTextArea'
import { benefitTypeOptions } from './EligibleBenefitModal.helper'
import useEligibleBenefitModal from 'hooks/clients/Modals/useEligibleBenefitModal'
import Loading from 'components/Loading/Loading'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import { PoundIcon } from 'assets/images'
import CustomFormItem from 'components/CustomFormItem/CustomFormItem'
import { periodOptions } from 'utils/commonOptions'

const EligibleBenefitModal = () => {
  const {
    modalState,
    handleCancelClick,
    handleConfirmClick,
    formData,
    handleCustomSelectChange,
    handleAntdFormInputChange,
    handleCustomTextAreaChange,
    loading,
    form,
    customersOptions,
  } = useEligibleBenefitModal()

  if (loading) {
    return <Loading />
  }

  return (
    <Modal
      title="Edit Benefit"
      open={modalState.isOpen}
      onOk={handleConfirmClick}
      onCancel={handleCancelClick}
      width={700}
      okText="Save"
      cancelText="Cancel"
      closable={false}
      className="edit-asset-modal"
      confirmLoading={modalState.loading}
    >
      {modalState.loading ? (
        <Loading />
      ) : (
        <StyledWrapper>
          <Form
            form={form}
            layout="vertical"
            size="large"
            name="basic"
            initialValues={formData}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
              marginTop: '8px',
              width: '100%',
            }}
          >
            <Styled2x2Grid>
              <CustomFormItem
                label="Select Customer *"
                width={'100%'}
                placeholder="Select Customer *"
                onChange={handleCustomSelectChange}
                options={customersOptions}
                name="benefitReceiver"
                rules={['isRequired']}
                type="select"
              />
              <CustomSelect
                label="Type of Benefit *"
                width={'100%'}
                placeholder="Type of Benefit *"
                defaultValue={formData.benefitType}
                onChange={handleCustomSelectChange}
                options={benefitTypeOptions}
                name="benefitType"
              />
              <CustomSelect
                label="Benefit Period *"
                width={'100%'}
                placeholder="Benefit Period *"
                defaultValue={formData.benefitPeriod}
                onChange={handleCustomSelectChange}
                options={periodOptions}
                name="benefitPeriod"
              />
              <TextInput
                label="Benefit Amount *"
                name="benefitAmount"
                placeholder="Benefit Amount"
                inputPrefixImg={PoundIcon}
                prefixImgHeight={12}
                onChange={handleAntdFormInputChange}
                type="number"
              />
            </Styled2x2Grid>
          </Form>
          <CustomTextArea
            label="Description"
            placeholder="Description"
            value={formData.description}
            name="description"
            onChange={handleCustomTextAreaChange}
            rows={4}
          />
        </StyledWrapper>
      )}
    </Modal>
  )
}

export default EligibleBenefitModal
