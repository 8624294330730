import { useEffect, useRef, useState } from 'react'
import { XAxis, YAxis, CartesianGrid, Tooltip, Area, AreaChart, Legend } from 'recharts'
import { commonUtils } from 'utils'

interface ILabels {
  [key: string]: string
}

export interface IAnnualInterestDataItem {
  id: number
  year: number
  startingBalance: number
  additionalPayment: number | null
  annualInterest: number
  endingBalance: number
  noPropertyGrowthEndingValue: number
  noPropertyGrowthEndingEstate: number
  propertyGrowthEndingValue: number
  propertyGrowthEndingEstate: number
}

interface IChartProps {
  annualInterestDataSource: IAnnualInterestDataItem[]
  propertyGrowth: number
}

const Chart = ({ annualInterestDataSource, propertyGrowth }: IChartProps) => {
  const data = annualInterestDataSource
  const chartContainerRef = useRef<HTMLDivElement>(null)
  const [containerWidth, setContainerWidth] = useState(0)

  const labels: ILabels = {
    endingBalance: 'Ending balance',
    propertyGrowthEndingValue: `Property value with ${propertyGrowth}% growth`,
    propertyGrowthEndingEstate: `Ending estate with ${propertyGrowth}% growth`,
  }

  useEffect(() => {}, [containerWidth])

  useEffect(() => {
    const updateContainerWidth = () => {
      if (chartContainerRef.current) {
        setContainerWidth(chartContainerRef.current.offsetWidth)
      }
    }

    updateContainerWidth() // Initial width

    window.addEventListener('resize', updateContainerWidth)

    return () => {
      window.removeEventListener('resize', updateContainerWidth)
    }
  }, [])

  return (
    <div ref={chartContainerRef} style={{ width: '90%', marginTop: '16px' }}>
      <AreaChart width={containerWidth} height={400} data={data}>
        <defs>
          <linearGradient id="colorPropertyValue" x1="0" y1="0" x2="0" y2="1">
            <stop offset="100%" stopColor="#219653" stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <XAxis dataKey="year" />
        <YAxis tickCount={7} tickFormatter={(value) => `£${commonUtils.formatNumberWithCommas(value)}`} width={100} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip
          formatter={(value, name) => [`£${commonUtils.formatNumberWithCommas(Number(value))}`, labels[name]]}
          labelFormatter={(label) => `Year: ${label}`}
        />
        <Legend verticalAlign="top" align="right" iconType="circle" height={36} formatter={(value) => labels[value]} />
        <Area type="monotone" dataKey="endingBalance" stroke="#E73F3F" fillOpacity={0} />
        <Area type="monotone" dataKey="propertyGrowthEndingValue" stroke="#219653" fillOpacity={1} fill="url(#colorPropertyValue)" />
        <Area type="monotone" dataKey="propertyGrowthEndingEstate" stroke="#2080F6" fillOpacity={0} />
      </AreaChart>
    </div>
  )
}

export default Chart
