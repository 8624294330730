import { useContext, useEffect, useState } from 'react'

import AuthContext from 'contexts/AuthContext'

import { useGetUserImageFromS3 } from 'services/auth/auth.service'
import { useCustomMutation } from 'services/shared/mutation'
import { ChangeAvatarPayload } from 'services/shared/mutation.payload'
import { changeAvatarMutation } from 'gql/user'
import { useParams } from 'react-router-dom'
import { useGetUserById } from 'services/users/users.service'
import { RoleEnum } from 'enum/user.enum'

const useUserProfile = () => {
  const { userId } = useParams()
  const { user } = useGetUserById(userId as string)
  const { user: currentUser } = useContext(AuthContext)
  const isOwnProfile = currentUser._id === user._id
  const displayNotesAndFilesTab = !isOwnProfile && currentUser.role === RoleEnum.superAdmin
  const changeAvatar = useCustomMutation<ChangeAvatarPayload>({ mutation: changeAvatarMutation })
  const [activeKey, setActiveKey] = useState('1')
  const [avatar, setAvatar] = useState<any>()
  const [avatarLoading, setAvatarLoading] = useState(false)

  const { url, loading: userAvatarLoading } = useGetUserImageFromS3({
    filePath: user.avatar,
  })

  useEffect(() => {
    setAvatarLoading(userAvatarLoading)
    setAvatar(url)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAvatarLoading])

  const handleTabChange = (key: string) => {
    setActiveKey(key)
  }

  const handleAvatarUpload = (e: any) => {
    e.persist()
    setAvatarLoading(true)
    const file = e.target.files[0]

    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = function () {
      const dataUrl = reader.result
      setAvatar(dataUrl)

      const avatarImg: string = dataUrl?.toString() || ''

      changeAvatar({ avatar: avatarImg, userId: user._id }).then(() => {
        setAvatarLoading(false)
      })
      // Use the data URL to display the image or upload it to a server
    }

    reader.onerror = function () {
      console.error('File could not be read!')
    }
  }

  return { activeKey, handleTabChange, handleAvatarUpload, avatar, avatarLoading, user, isOwnProfile, displayNotesAndFilesTab }
}

export default useUserProfile
