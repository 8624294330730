import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout, Menu } from 'antd'

import { Logo, LogoWithoutText } from 'assets/images'
import AuthContext from 'contexts/AuthContext'
import LayoutContext from 'contexts/LayoutContext'
import { useWindowDimensions } from 'hooks'

import { filteredSidebarItems } from './useMenuInfo'
import { LogoContainer, StyledLogo } from './SideMenu.styles'

const { Sider } = Layout

const SideMenu = () => {
  const { user } = useContext(AuthContext)
  const { sideBarWidth, setSideBarWidth, collapsed, setCollapsed } = useContext(LayoutContext)
  const items = filteredSidebarItems(user)
  const navigate = useNavigate()
  const openSiderWidth = 250
  const closedSiderWidth = 80

  const handleRedirect = (pageData: any) => {
    const label = pageData.keyPath[0]
    const path = `/${label.toLocaleLowerCase()}`
    navigate(path)
  }

  const handleCollapse = (collapsed: any) => {
    if (collapsed) {
      setSideBarWidth(closedSiderWidth)
    } else {
      setSideBarWidth(openSiderWidth)
    }
    setCollapsed(collapsed)
  }

  const { width } = useWindowDimensions()

  useEffect(() => {
    if (width < 1300) {
      setSideBarWidth(closedSiderWidth)
      setCollapsed(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width])

  return (
    <Sider
      width={sideBarWidth}
      style={{
        borderRight: '1px solid #A8D6EA',
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        backgroundColor: '#fff',
      }}
      collapsible
      collapsed={collapsed}
      collapsedWidth={80}
      onCollapse={handleCollapse}
    >
      <LogoContainer>
        {collapsed ? (
          <StyledLogo width={48} src={LogoWithoutText} onClick={() => navigate('/clients')} />
        ) : (
          <StyledLogo src={Logo} width={200} height={112} onClick={() => navigate('/clients')} />
        )}
      </LogoContainer>
      <Menu
        style={{
          backgroundColor: '#fff',
          color: '#000',
        }}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={['4']}
        items={items}
        onClick={handleRedirect}
      />
    </Sider>
  )
}

export default SideMenu
