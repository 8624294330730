/* eslint-disable no-useless-computed-key */
import Form from 'antd/lib/form'
import Loading from 'components/Loading'
import CustomFormItem from 'components/CustomFormItem'
import useCaseOverView from 'hooks/clients/DetailedForms/useCaseOverView'
import CustomFooter from 'pages/ClientDetails/CustomFooter/CustomFooter'
import { options, customerCountOptions, mainPurposeOfDiscussionOptions, caseOverviewTableColumns } from './CaseOverview.helper'
import {
  StyledCheckbox,
  StyledCheckboxMsg,
  StyledContainer,
  StyledFormContainer,
  StyledInvolvementContainer,
  StyledInvolvementLabel,
  StyledItemContainer,
  StyledRequiredMsg,
  StyledTitle,
  StyledTitleContainer,
  Styled2x2Grid,
} from './CaseOverview.styles'
import FormTable from 'components/FormTable/FormTable'
import GoogleAddressInput from 'components/GoogleAddressInput/GoogleAddressInput'
import CustomSwitch from 'components/CustomSwitch/CustomSwitch'
import { productResearchOptions, yesNoOptions } from 'utils/commonOptions'

const CaseOverview = () => {
  const {
    form,
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    flattenedFormData,
    handleCustomSelectChange,
    handleAntdFormItemChange,
    handleThirdPartyInvolved,
    handleWholeOfMarketOrTied,
    onPlaceSelect,
    showAddressDetails,
    showAddressDetailsChange,
    handleAddButton,
    personsDataSource,
    setSelectedRows,
    displayDeleteButton,
    handleDeleteModal,
    displayEditButton,
    handleEditModal,
  } = useCaseOverView()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <StyledTitle>Case overview</StyledTitle>
        <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
      </StyledTitleContainer>
      <StyledFormContainer>
        <Form
          form={form}
          layout="vertical"
          size="large"
          name="basic"
          initialValues={flattenedFormData}
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',
            marginTop: '8px',
            width: '100%',
          }}
          onFinishFailed={onFinishFailed}
          onFinish={onSubmit}
        >
          <CustomFormItem
            label="Date fact-find started *"
            placeholder="E.g will be shown here"
            value={formData.factFindStartDate}
            name="factFindStartDate"
            type="date"
            onChange={handleAntdFormItemChange}
            rules={['isRequired']}
          />
          <CustomFormItem
            label="Face to Face or Telephone fact find *"
            placeholder="E.g will be shown here"
            onChange={handleCustomSelectChange}
            options={options}
            name="contactType"
            type="select"
            width="100%"
            rules={['isRequired']}
          />
          <CustomFormItem
            label="How many customers/applicants? *"
            onChange={handleAntdFormItemChange}
            options={customerCountOptions}
            value={formData.customerQuantity}
            name="customerQuantity"
            type="radio"
            rules={['isRequired']}
          />
          <CustomFormItem
            label="If introducer; Details & terms of introduction"
            placeholder="E.g will be shown here"
            value={formData.detailsAndTermsOfIntroduction}
            name="detailsAndTermsOfIntroduction"
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
          <CustomFormItem
            label="Do you have a Power of Attorney? *"
            onChange={handleAntdFormItemChange}
            options={yesNoOptions}
            value={formData.hasPowerOfAttorney}
            name="hasPowerOfAttorney"
            rules={['isRequired']}
            type="radio"
          />
          {formData.hasPowerOfAttorney && (
            <>
              <StyledTitle style={{ marginTop: '32px' }}>Power of Attorney Details</StyledTitle>
              <Styled2x2Grid>
                <CustomFormItem label="Name" name="powerOfAttorney.name" placeholder="Name" onChange={handleAntdFormItemChange} />
                <CustomFormItem
                  label="Contact Number"
                  name="powerOfAttorney.contactNumber"
                  placeholder="Contact Number"
                  onChange={handleAntdFormItemChange}
                  rules={['validateTelephoneNumber']}
                />
                <GoogleAddressInput label="Search for an Address" onPlaceSelect={onPlaceSelect} value={formData?.powerOfAttorney?.address?.formattedAddress} />
                <CustomSwitch checked={showAddressDetails} onChange={showAddressDetailsChange} label="Show Address Details" />
                {showAddressDetails && (
                  <>
                    <CustomFormItem
                      label="Address 1"
                      name="powerOfAttorney.address.address1"
                      placeholder="Address 1"
                      onChange={handleAntdFormItemChange}
                      value={formData?.powerOfAttorney?.address?.address1}
                    />
                    <CustomFormItem
                      label="Address 2"
                      name="powerOfAttorney.address.address2"
                      placeholder="Address 2"
                      onChange={handleAntdFormItemChange}
                      value={formData?.powerOfAttorney?.address?.address2}
                    />
                    <CustomFormItem
                      label="Town"
                      name="powerOfAttorney.address.town"
                      placeholder="Town"
                      onChange={handleAntdFormItemChange}
                      value={formData?.powerOfAttorney?.address?.town}
                    />
                    <CustomFormItem
                      label="County"
                      name="powerOfAttorney.address.county"
                      placeholder="County"
                      onChange={handleAntdFormItemChange}
                      value={formData?.powerOfAttorney?.address?.county}
                    />
                    <CustomFormItem
                      label="Country"
                      name="powerOfAttorney.address.country"
                      placeholder="Country"
                      onChange={handleAntdFormItemChange}
                      value={formData?.powerOfAttorney?.address?.country}
                    />
                    <CustomFormItem
                      label="Postcode"
                      name="powerOfAttorney.address.postcode"
                      placeholder="Postcode"
                      onChange={handleAntdFormItemChange}
                      value={formData?.powerOfAttorney?.address?.postcode}
                    />
                  </>
                )}
              </Styled2x2Grid>
            </>
          )}
          <CustomFormItem
            label="Was anyone other than customers/applicants/power of attorney present? *"
            onChange={handleAntdFormItemChange}
            options={yesNoOptions}
            value={formData.wasAnyOtherPersonPresent}
            name="wasAnyOtherPersonPresent"
            rules={['isRequired']}
            type="radio"
          />
          {formData.wasAnyOtherPersonPresent && (
            <FormTable
              columns={caseOverviewTableColumns}
              dataSource={personsDataSource}
              title="Who is present during the fact find?"
              addButtonText="Add New Person"
              handleAddButton={handleAddButton}
              setSelectedRows={setSelectedRows}
              displayDeleteButton={displayDeleteButton}
              handleDeleteModal={handleDeleteModal}
              displayEditButton={displayEditButton}
              handleEditModal={handleEditModal}
            />
          )}
          <CustomFormItem
            label="What is the main purpose of this discussion? *"
            onChange={handleAntdFormItemChange}
            options={mainPurposeOfDiscussionOptions}
            value={formData.mainPurposeOfDiscussion}
            name="mainPurposeOfDiscussion"
            rules={['isRequired']}
            type="radio"
          />
          <CustomFormItem
            label="Will your product research be Whole of Market or from a Panel of Providers? *"
            onChange={handleAntdFormItemChange}
            options={productResearchOptions}
            value={formData.productResearchEnvironment}
            name="productResearchEnvironment"
            rules={['isRequired']}
            type="radio"
          />
          <StyledInvolvementContainer>
            <StyledInvolvementLabel>Involvement</StyledInvolvementLabel>
            <StyledItemContainer>
              <StyledCheckbox onChange={handleThirdPartyInvolved} name="isThirdPartyInvolved" checked={formData.isThirdPartyInvolved} />
              <StyledCheckboxMsg>Have you confirmed that third party involvement is welcomed?</StyledCheckboxMsg>
            </StyledItemContainer>
            <StyledItemContainer>
              <StyledCheckbox onChange={handleWholeOfMarketOrTied} name="isWholeOfMarketOrTied" checked={formData.isWholeOfMarketOrTied} />
              <StyledCheckboxMsg>
                Have you confirmed your whole of market or panel of lender status. If you offer advice from a panel of lenders, have you informed the client
                which lenders you offer home finance / home reversion from.
              </StyledCheckboxMsg>
            </StyledItemContainer>
          </StyledInvolvementContainer>
          <CustomFooter loading={loading} setSaveType={setSaveType} />
        </Form>
      </StyledFormContainer>
    </StyledContainer>
  )
}

export default CaseOverview
