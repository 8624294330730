/* eslint-disable no-useless-computed-key */
import { Form } from 'antd'

import Loading from 'components/Loading'
import CustomFormItem from 'components/CustomFormItem'
import GoogleAddressInput from 'components/GoogleAddressInput/GoogleAddressInput'
import CustomSwitch from 'components/CustomSwitch/CustomSwitch'
import FormTable from 'components/FormTable/FormTable'
import useFamily from 'hooks/clients/DetailedForms/useFamily'
import CustomFooter from 'pages/ClientDetails/CustomFooter/CustomFooter'
import { yesNoOptions, countryOptions } from 'utils/commonOptions'

import { beneficiariesTableColumns, contactDetailsOfWillBeneficiariesOptions, recipientsTableColumns } from './Family.helper'
import { StyledContainer, StyledTitle, StyledTitleContainer, StyledRequiredMsg, Styled2x2Grid, StyledBreakLine } from './Family.styles'

const Family = () => {
  const {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    flattenedFormData,
    handleAntdFormItemChange,
    handleCustomSelectChange,
    onPlaceSelect,
    showAddressDetails,
    showAddressDetailsChange,
    handleAddBeneficiary,
    beneficiariesDataSource,
    setSelectedBeneficiariesRows,
    displayBeneficiariesDeleteButton,
    handleDeleteBeneficiariesModal,
    dependentsDataSource,
    handleAddDependent,
    setSelectedDependentsRows,
    displayDependentsDeleteButton,
    handleDeleteDependentsModal,
    recipientsDataSource,
    handleAddRecipient,
    setSelectedRecipientsRows,
    displayRecipientsDeleteButton,
    handleDeleteRecipientsModal,
    displayBeneficiaryEditButton,
    handleBeneficiaryEditModal,
    displayDependentEditButton,
    handleDependentEditModal,
    displayRecipientEditButton,
    handleRecipientEditModal,
    form,
  } = useFamily()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <StyledTitle style={{ fontSize: '32px' }}>Family</StyledTitle>
        <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
      </StyledTitleContainer>
      <Form
        form={form}
        layout="vertical"
        size="large"
        name="basic"
        initialValues={flattenedFormData}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '12px',
          marginTop: '8px',
          width: '100%',
        }}
        onFinishFailed={onFinishFailed}
        onFinish={onSubmit}
      >
        <CustomFormItem
          label="Do you have any beneficiaries? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="hasAnyBeneficiaries"
          value={formData.hasAnyBeneficiaries}
          type="radio"
          rules={['isRequired']}
        />
        {formData.hasAnyBeneficiaries && (
          <>
            <FormTable
              columns={beneficiariesTableColumns}
              dataSource={beneficiariesDataSource}
              title="Beneficiaries"
              addButtonText="Add New Beneficiary"
              handleAddButton={handleAddBeneficiary}
              setSelectedRows={setSelectedBeneficiariesRows}
              displayDeleteButton={displayBeneficiariesDeleteButton}
              handleDeleteModal={handleDeleteBeneficiariesModal}
              displayEditButton={displayBeneficiaryEditButton}
              handleEditModal={handleBeneficiaryEditModal}
            />
            <CustomFormItem
              label="Equity release will potentially impact on the value left to beneficiaries. Customers feelings about this?"
              placeholder="E.g will be shown here"
              value={formData.impactOnValueLeftToBeneficiaries}
              name="impactOnValueLeftToBeneficiaries"
              onChange={handleAntdFormItemChange}
              rows={4}
              type="textarea"
            />
          </>
        )}
        <CustomFormItem
          label="Do you have anyone who is financially dependant on you?"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="hasSomeoneFinanciallyDependentOn"
          value={formData.hasSomeoneFinanciallyDependentOn}
          type="radio"
        />
        {formData.hasSomeoneFinanciallyDependentOn && (
          <FormTable
            columns={beneficiariesTableColumns}
            dataSource={dependentsDataSource}
            title="Dependants"
            addButtonText="Add New Dependant"
            handleAddButton={handleAddDependent}
            setSelectedRows={setSelectedDependentsRows}
            displayDeleteButton={displayDependentsDeleteButton}
            handleDeleteModal={handleDeleteDependentsModal}
            displayEditButton={displayDependentEditButton}
            handleEditModal={handleDependentEditModal}
          />
        )}
        <CustomFormItem
          label="Have you discussed your plans with your family / beneficiaries? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="hasDiscussedPlansWithFamilyOrBeneficiaries"
          value={formData.hasDiscussedPlansWithFamilyOrBeneficiaries}
          type="radio"
          rules={['isRequired']}
        />
        {formData.hasDiscussedPlansWithFamilyOrBeneficiaries && (
          <CustomFormItem
            label="Details, are they supportive for example?"
            placeholder="E.g will be shown here"
            name="discussionDetails"
            value={formData.discussionDetails}
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        {formData.hasDiscussedPlansWithFamilyOrBeneficiaries === false && (
          <>
            <CustomFormItem
              label="Do you plan to discuss with your family / beneficiaries?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="willDiscussPlansWithFamilyOrBeneficiaries"
              value={formData.willDiscussPlansWithFamilyOrBeneficiaries}
              type="radio"
            />
            {formData.willDiscussPlansWithFamilyOrBeneficiaries === false && (
              <CustomFormItem
                label="Details, why you don't plan to discuss with your family / beneficiaries?"
                placeholder="E.g will be shown here"
                name="whyNotDiscussingWithFamilyOrBeneficiaries"
                value={formData.whyNotDiscussingWithFamilyOrBeneficiaries}
                onChange={handleAntdFormItemChange}
                rows={4}
                type="textarea"
              />
            )}
          </>
        )}
        <CustomFormItem
          label="Is leaving an inheritance to beneficiaries important? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="isLeavingInheritanceToBeneficiariesImportant"
          value={formData.isLeavingInheritanceToBeneficiariesImportant}
          type="radio"
          rules={['isRequired']}
        />
        {formData.isLeavingInheritanceToBeneficiariesImportant && (
          <CustomFormItem
            label="Record details including preferences for their estate"
            placeholder="E.g will be shown here"
            name="detailsOfLeavingInheritanceToBeneficiaries"
            value={formData.detailsOfLeavingInheritanceToBeneficiaries}
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        {formData.isLeavingInheritanceToBeneficiariesImportant === false && (
          <CustomFormItem
            label="Details, why leaving an inheritance to beneficiaries isn't important?"
            placeholder="E.g will be shown here"
            name="whyLeavingInheritanceToBeneficiariesIsNotImportant"
            value={formData.whyLeavingInheritanceToBeneficiariesIsNotImportant}
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        <CustomFormItem
          label="Equity release will impact estate value left to beneficiaries. Customers feelings about this? *"
          name="customerFeelingsAboutEquityReleaseImpactOnAsset"
          placeholder="E.g will be shown here"
          onChange={handleAntdFormItemChange}
          value={formData.customerFeelingsAboutEquityReleaseImpactOnAsset}
          rules={['isRequired']}
        />
        <CustomFormItem
          label="Is the customer gifting any released money?"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="isCustomerGiftingAnyReleasedMoney"
          value={formData.isCustomerGiftingAnyReleasedMoney}
          type="radio"
        />
        {formData.isCustomerGiftingAnyReleasedMoney && (
          <>
            <CustomFormItem
              label="Is the gift within HMRC limits and exemptions?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="isGiftWithinHMRCLimitsAndExemptions"
              value={formData.isGiftWithinHMRCLimitsAndExemptions}
              type="radio"
            />
            <CustomFormItem
              label="Which of the following is true in relation to contacting the gift recipients and key beneficiaries of the will?"
              width={'100%'}
              placeholder="Which of the following is true in relation to contacting the gift recipients and key beneficiaries of the will?"
              name="contactDetailsOfWillBeneficiaries"
              defaultValue={formData.contactDetailsOfWillBeneficiaries}
              onChange={handleCustomSelectChange}
              options={contactDetailsOfWillBeneficiariesOptions}
              type="select"
            />
          </>
        )}
        <CustomFormItem
          label="Are there any reasons why you don't want family / beneficiaries involved? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="isReasonForNotInvolvingFamilyOrBeneficiaries"
          value={formData.isReasonForNotInvolvingFamilyOrBeneficiaries}
          type="radio"
          rules={['isRequired']}
        />
        {formData.isReasonForNotInvolvingFamilyOrBeneficiaries && (
          <CustomFormItem
            label="What are those reasons?"
            placeholder="E.g will be shown here"
            value={formData.detailsOfNotInvolvingFamilyOrBeneficiaries}
            name="detailsOfNotInvolvingFamilyOrBeneficiaries"
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        <CustomFormItem
          label="If ONE beneficiary will benefit from this financial transaction but not others do you plan to inform them all? Record full discussion"
          placeholder="E.g will be shown here"
          value={formData.communicationStrategyForExclusiveBeneficiaryGain}
          name="communicationStrategyForExclusiveBeneficiaryGain"
          onChange={handleAntdFormItemChange}
          rows={4}
          type="textarea"
        />
        <CustomFormItem
          label="Would you like a copy of any recommendation to be forwarded to your family / beneficiaries?"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="wantsRecommendationForwardedToFamilyOrBeneficiaries"
          value={formData.wantsRecommendationForwardedToFamilyOrBeneficiaries}
          type="radio"
        />
        {formData.wantsRecommendationForwardedToFamilyOrBeneficiaries && (
          <FormTable
            columns={recipientsTableColumns}
            dataSource={recipientsDataSource}
            title="Recipients"
            addButtonText="Add New Recipient"
            handleAddButton={handleAddRecipient}
            setSelectedRows={setSelectedRecipientsRows}
            displayDeleteButton={displayRecipientsDeleteButton}
            handleDeleteModal={handleDeleteRecipientsModal}
            displayEditButton={displayRecipientEditButton}
            handleEditModal={handleRecipientEditModal}
          />
        )}
        <CustomFormItem
          label="Would you like a copy of any recommendation to be forwarded to an independent representative or Solicitor? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="wantsRecommendationForwardedToRepresentativeOrSolicitor"
          value={formData.wantsRecommendationForwardedToRepresentativeOrSolicitor}
          type="radio"
          rules={['isRequired']}
        />
        {formData.wantsRecommendationForwardedToRepresentativeOrSolicitor && (
          <>
            <StyledBreakLine />
            <StyledTitle>Independent Representative or Solicitor</StyledTitle>
            <CustomFormItem
              label="Name"
              name="agent.name"
              placeholder="E.g will be shown here"
              onChange={handleAntdFormItemChange}
              value={formData.agent?.name}
              rules={['isRequired']}
            />
            <Styled2x2Grid>
              <GoogleAddressInput label="Search for an Address" onPlaceSelect={onPlaceSelect} value={formData.agent?.address?.formattedAddress} />
              <CustomSwitch checked={showAddressDetails} onChange={showAddressDetailsChange} label="Show Address Details" />
              {showAddressDetails && (
                <>
                  <CustomFormItem
                    label="Address 1"
                    name="agent.address.address1"
                    placeholder="Address 1"
                    onChange={handleAntdFormItemChange}
                    value={formData?.agent?.address?.address1}
                  />
                  <CustomFormItem
                    label="Address 2"
                    name="agent.address.address2"
                    placeholder="Address 2"
                    onChange={handleAntdFormItemChange}
                    value={formData?.agent?.address?.address2}
                  />
                  <CustomFormItem
                    label="Town"
                    name="agent.address.town"
                    placeholder="Town*"
                    onChange={handleAntdFormItemChange}
                    value={formData?.agent?.address?.town}
                  />
                  <CustomFormItem
                    label="County"
                    name="agent.address.county"
                    placeholder="County"
                    onChange={handleAntdFormItemChange}
                    value={formData?.agent?.address?.county}
                  />
                  <CustomFormItem
                    label="Country"
                    name="agent.address.country"
                    placeholder="Country"
                    onChange={handleAntdFormItemChange}
                    value={formData?.agent?.address?.country}
                  />
                  <CustomFormItem
                    label="Postcode"
                    name="agent.address.postcode"
                    placeholder="Postcode"
                    onChange={handleAntdFormItemChange}
                    value={formData?.agent?.address?.postcode}
                  />
                </>
              )}
            </Styled2x2Grid>
            <CustomFormItem
              label="Country"
              width={'100%'}
              placeholder="Country"
              name="agent.country"
              defaultValue={formData.agent?.country}
              onChange={handleCustomSelectChange}
              options={countryOptions}
              type="select"
            />
            <CustomFormItem
              label="Email"
              name="agent.email"
              placeholder="Email"
              onChange={handleAntdFormItemChange}
              rules={['validateEmail']}
            />
            <StyledBreakLine />
          </>
        )}
        <CustomFooter loading={loading} setSaveType={setSaveType} />
      </Form>
    </StyledContainer>
  )
}

export default Family
