import {
  AccountIcon,
  CrisisAlertIcon,
  DiversityIcon,
  FormatListNumberedIcon,
  GroupsIcon,
  HandshakeIcon,
  ParliamentIcon,
  PendingActionsIcon,
  PropertiesIcon,
  WalletIcon,
  PriceCheckIcon,
  SavingsIcon,
  CurrencyExchangeIcon,
  HomeIcon,
  RealEstateAgentIcon,
  CreditHistoryIcon,
  FamilyIcon,
  HealthIcon,
  RunningManIcon,
  MonitorHeartIcon,
  ThinkingHeadIcon,
  VirusIcon,
  HealthSafetyIcon,
} from 'assets/images'
import {
  StyledApplyButton,
  // StyledDownloadButton
} from 'pages/ClientDetails/Tabs/QuoteHistoryTabContent/QuoteHistoryTabContent.styles'
import { commonUtils } from 'utils'

interface IClientData {
  name: string
  phoneNumber: string
  postcode: string
  lender: string
  amount: string
  value: string
  applicationDate: string
  valuation: string
  offerDate: string
  solicitor: string
  feeAuthoSentDate: string
}

interface IClientColumn {
  data: IClientData
}

export const clientsColumns = [
  {
    name: 'name',
    header: 'Name',
    defaultFlex: 2,
    type: 'text',
  },
  {
    name: 'phoneNumber',
    header: 'Telephone',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'postcode',
    header: 'Postcode',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'lender',
    header: 'Lender',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'amount',
    header: 'Amount',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'value',
    header: 'Value',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'applicationDate',
    header: 'Application',
    defaultFlex: 1,
    type: 'date',
    render: ({ data }: IClientColumn) => (data.applicationDate ? commonUtils.formatDate(data.applicationDate) : ''),
  },
  {
    name: 'valuation',
    header: 'Valuation',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'offerDate',
    header: 'Offer',
    defaultFlex: 1,
    type: 'date',
  },
  {
    name: 'solicitor',
    header: 'Solicitor',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'feeAuthoSentDate',
    header: 'Fee Autho Sent',
    defaultFlex: 1,
    type: 'date',
    render: ({ data }: IClientColumn) => (data.feeAuthoSentDate ? commonUtils.formatDate(data.feeAuthoSentDate) : ''),
  },
]

export const initialJourneyFormData = {
  firstContact: {
    value: null,
    copyOnFile: null,
  },
  firstMeeting: {
    value: null,
    copyOnFile: null,
  },
  businessCardGiven: {
    value: null,
    copyOnFile: null,
  },
  iddGivenToClient: {
    value: null,
    copyOnFile: null,
  },
  gdprCompleted: {
    value: null,
    copyOnFile: null,
  },
  factfindCompleted: {
    value: null,
    copyOnFile: null,
  },
  clientSignedFactfind: {
    value: null,
    copyOnFile: null,
  },
  secondMeeting: {
    value: null,
    copyOnFile: null,
  },
  identificationVerified: {
    value: null,
    copyOnFile: null,
  },
  kfiGivenSentToClient: {
    value: null,
    copyOnFile: null,
  },
  applicationSigned: {
    value: null,
    copyOnFile: null,
  },
  suitabilityLetterIssued: {
    value: null,
    copyOnFile: null,
  },
  feePaymentAuthoritySigned: {
    value: null,
    copyOnFile: null,
  },
  solicitorAuthoritySigned: {
    value: null,
    copyOnFile: null,
  },
  applicationSubmitted: {
    value: null,
    copyOnFile: null,
  },
  solicitorInstructionsSent: {
    value: null,
    copyOnFile: null,
  },
  valuation: {
    value: null,
    copyOnFile: null,
  },
  addendumLetter: {
    value: null,
    copyOnFile: null,
  },
  offerIssued: {
    value: null,
    copyOnFile: null,
  },
  feePaymentSentToSolicitor: {
    value: null,
    copyOnFile: null,
  },
  solicitorAppointment: {
    value: null,
    copyOnFile: null,
  },
  offerSigned: {
    value: null,
    copyOnFile: null,
  },
  completion: {
    value: null,
    copyOnFile: null,
  },
  feePaymentReceived: {
    value: null,
    copyOnFile: null,
  },
}

export const initialTrackingRecommendFormData = {
  recommendedLender: '',
  recommendedPlan: '',
  recommendedSolicitor: '',
  referredBy: '',
}

export interface IGeneralTabItem {
  label: string
  icon: any
  percentage: number
  hasChildren: boolean
  itemName: string
  children?: IGeneralTabItem[]
}

// Note: if you add new label please update tabItems object in this file as well
export const initialGeneralTabItemsData: IGeneralTabItem[] = [
  {
    label: 'Case overview',
    icon: FormatListNumberedIcon,
    percentage: 0,
    hasChildren: false,
    itemName: 'caseOverview',
  },
  {
    label: 'Customers',
    icon: AccountIcon,
    percentage: 0,
    hasChildren: false,
    itemName: 'customers',
  },
  {
    label: 'Properties',
    icon: PropertiesIcon,
    percentage: 0,
    hasChildren: false,
    itemName: 'properties',
  },
  {
    label: 'Income, Benefits And Expenditure',
    icon: WalletIcon,
    percentage: 0,
    hasChildren: true,
    itemName: 'incomeAndExpenditure',
    children: [
      {
        label: 'Income',
        icon: PriceCheckIcon,
        percentage: 0,
        hasChildren: false,
        itemName: 'income',
      },
      {
        label: 'Benefits',
        icon: SavingsIcon,
        percentage: 0,
        hasChildren: false,
        itemName: 'benefits',
      },
      {
        label: 'Expenditure',
        icon: CurrencyExchangeIcon,
        percentage: 0,
        hasChildren: false,
        itemName: 'expenditure',
      },
    ],
  },
  {
    label: 'Assets and Liabilities',
    icon: HandshakeIcon,
    percentage: 0,
    hasChildren: true,
    itemName: 'assetsAndLiabilities',
    children: [
      {
        label: 'Assets',
        icon: HomeIcon,
        percentage: 0,
        hasChildren: false,
        itemName: 'assets',
      },
      {
        label: 'Liabilities',
        icon: RealEstateAgentIcon,
        percentage: 0,
        hasChildren: false,
        itemName: 'liabilities',
      },
      {
        label: 'Credit History',
        icon: CreditHistoryIcon,
        percentage: 0,
        hasChildren: false,
        itemName: 'creditHistory',
      },
    ],
  },
  {
    label: 'Objectives and Alternatives',
    icon: CrisisAlertIcon,
    percentage: 0,
    hasChildren: false,
    itemName: 'objectivesAndAlternatives',
  },
  {
    label: 'Family and Health',
    icon: GroupsIcon,
    percentage: 0,
    hasChildren: true,
    itemName: 'familyAndHealth',
    children: [
      {
        label: 'Family',
        icon: FamilyIcon,
        percentage: 0,
        hasChildren: false,
        itemName: 'family',
      },
      {
        label: 'Health',
        icon: HealthIcon,
        percentage: 0,
        hasChildren: false,
        itemName: 'health',
      },
    ],
  },
  {
    label: 'Estate Planning',
    icon: PendingActionsIcon,
    percentage: 0,
    hasChildren: false,
    itemName: 'estatePlanning',
  },
  {
    label: 'Borrowing Solution and Priorities',
    icon: ParliamentIcon,
    percentage: 0,
    hasChildren: false,
    itemName: 'borrowingSolutionAndPriorities',
  },
  {
    label: 'Suitability and Next Steps',
    icon: DiversityIcon,
    percentage: 0,
    hasChildren: false,
    itemName: 'suitabilityAndNextSteps',
  },
]

// Note: initially medicalQuestions is not display if user doesn't check it in clientDetails
export const initialQuickSearchTabs: {
  clientDetails: string
  medicalQuestions?: string
  propertyAndLanding: string
  furtherInformation: string
} = {
  clientDetails: 'CLIENT DETAILS',
  // medicalQuestions: 'MEDICAL QUESTIONS',
  propertyAndLanding: 'PROPERTY AND LENDING',
  furtherInformation: 'FURTHER INFORMATION',
}

// Note: if you add new label please update medicalQuestionsTabItems object in this file as well
export const initialMedicalQuestionsTabItemsData = [
  {
    label: 'Lifestyle details',
    icon: RunningManIcon,
    percentage: 0,
    hasChildren: false,
    itemName: 'lifestyleDetails',
  },
  {
    label: 'Heart and vascular details',
    icon: MonitorHeartIcon,
    percentage: 0,
    hasChildren: false,
    itemName: 'heartAndVascularDetails',
  },
  {
    label: 'Stroke details',
    icon: ThinkingHeadIcon,
    percentage: 0,
    hasChildren: false,
    itemName: 'strokeDetails',
  },
  {
    label: 'Cancer details',
    icon: VirusIcon,
    percentage: 0,
    hasChildren: false,
    itemName: 'cancerDetails',
  },
  {
    label: 'Other condition details',
    icon: HealthSafetyIcon,
    percentage: 0,
    hasChildren: false,
    itemName: 'otherConditionDetails',
  },
]

export const medicalQuestionsTabItems = {
  lifestyleDetails: 'Lifestyle details',
  heartAndVascularDetails: 'Heart and vascular details',
  strokeDetails: 'Stroke details',
  cancerDetails: 'Cancer details',
  otherConditionDetails: 'Other condition details',
}

export const tabItems = {
  caseOverview: 'Case overview',
  customers: 'Customers',
  customerFirst: 'Customer First Details',
  customerSecond: 'Customer Second Details',
  properties: 'Properties',
  propertyDetails: 'Property Details',
  assetsAndLiabilities: 'Assets and Liabilities',
  assets: 'Assets',
  liabilities: 'Liabilities',
  creditHistory: 'Credit History',
  incomeAndExpenditure: 'Income, Benefits And Expenditure',
  income: 'Income',
  benefits: 'Benefits',
  expenditure: 'Expenditure',
  objectivesAndAlternatives: 'Objectives and Alternatives',
  familyAndHealth: 'Family and Health',
  family: 'Family',
  health: 'Health',
  borrowingSolutionAndPriorities: 'Borrowing Solution and Priorities',
  estatePlanning: 'Estate Planning',
  suitabilityAndNextSteps: 'Suitability and Next Steps',
  notes: 'Notes',
}

export const initialBreadcrumbData = [
  {
    key: '1',
    text: 'Home',
  },
]

export const generateFormNavItems = ({ customerQuantity }: { customerQuantity: number }) => {
  return [
    {
      label: 'Home',
      displayForm: false,
    },
    {
      label: 'Case overview',
      displayForm: true,
    },
    {
      label: 'Customers',
      displayForm: true,
    },
    {
      label: 'Customer First Details',
      displayForm: true,
    },
    ...(customerQuantity === 2
      ? [
          {
            label: 'Customer Second Details',
            displayForm: true,
          },
        ]
      : []),
    {
      label: 'Properties',
      displayForm: true,
    },
    {
      label: 'Property Details',
      displayForm: true,
    },
    {
      label: 'Income, Benefits And Expenditure',
      displayForm: false,
    },
    {
      label: 'Income',
      displayForm: true,
    },
    {
      label: 'Benefits',
      displayForm: true,
    },
    {
      label: 'Expenditure',
      displayForm: true,
    },
    {
      label: 'Assets and Liabilities',
      displayForm: false,
    },
    {
      label: 'Assets',
      displayForm: true,
    },
    {
      label: 'Liabilities',
      displayForm: true,
    },
    {
      label: 'Credit History',
      displayForm: true,
    },
    {
      label: 'Objectives and Alternatives',
      displayForm: true,
    },
    {
      label: 'Family and Health',
      displayForm: false,
    },
    {
      label: 'Family',
      displayForm: true,
    },
    {
      label: 'Health',
      displayForm: true,
    },
    {
      label: 'Estate Planning',
      displayForm: true,
    },
    {
      label: 'Borrowing Solution and Priorities',
      displayForm: true,
    },
    {
      label: 'Suitability and Next Steps',
      displayForm: true,
    },
  ]
}

interface IQuoteHistoryData {
  data: {
    id: string
    name: string
    option: string
    transactionNumber: string
  }
}

const handleApply = (transactionNumber: string) => {
  const fullUrl = `${window.location.origin}/test-quote-search?transactionNumber=${transactionNumber}&testing=false`
  window.open(fullUrl, '_blank')
}

export const quoteHistoryColumns = [
  {
    name: 'name',
    header: 'Name',
    defaultFlex: 5,
    type: 'text',
    render: ({ data }: IQuoteHistoryData) => {
      const timestamp = new Date(Number(data.name))
      // Convert to British date format
      const britishDate = timestamp.toLocaleDateString('en-GB')

      return britishDate
    },
  },
  // {
  //   name: 'option',
  //   header: 'Option',
  //   defaultFlex: 1,
  //   type: 'text',
  //   render: ({ data }: IQuoteHistoryData) => (
  //     <StyledDownloadButton
  //       onClick={(e) => {
  //         e.stopPropagation()
  //         // Note: here is quote result _id that can be used to retrieve data and generate pdf
  //         console.log(data.option)
  //       }}
  //     >
  //       Download
  //     </StyledDownloadButton>
  //   ),
  // },
  {
    name: 'transactionNumber',
    header: 'Link',
    defaultFlex: 1,
    type: 'text',
    render: ({ data }: IQuoteHistoryData) =>
      data.transactionNumber ? (
        <StyledApplyButton
          onClick={(e) => {
            e.stopPropagation()
            handleApply(String(data.transactionNumber))
          }}
        >
          Continue
        </StyledApplyButton>
      ) : null,
  },
]
