import styled from 'styled-components'
import { Radio } from 'antd'

export const StyledContainer = styled.div`
  margin-top: 28px;
  width: max-content;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  max-width: 100%;
`

export const StyledLabel = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #120224;

  max-width: 100%;
`

export const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  column-gap: 1px;

  margin-top: 12px;
`

export const StyledRequiredError = styled.p`
  margin-top: 8px;
  line-height: 12px;
  color: #e73f3f;
`

export const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 16px;
`

export const StyledRadio = styled(Radio)`
  font-size: 16px;
`
