import { useContext } from 'react'
import { Footer } from 'antd/es/layout/layout'
import { LayoutContext } from 'contexts'
import { StyledButton, StyledButtonsContainer, StyledLeftContainer, StyledLightButton, StyledRightContainer } from './CustomFooter.styles'
import { CustomFooterProps } from './CustomFooter.types'

const CustomFooter = ({ setSaveType, loading }: CustomFooterProps) => {
  const { sideBarWidth } = useContext(LayoutContext)
  return (
    <Footer
      style={{
        backgroundColor: '#fff',
        position: 'fixed',
        bottom: 0,
        left: `${sideBarWidth}px`,
        right: 0,
        width: '100%',
        padding: '0 40px',
        borderTop: '1px solid #a8d6ea',
        zIndex: 1,
      }}
    >
      <StyledButtonsContainer sideBarWidth={sideBarWidth}>
        <StyledLeftContainer>
          {/* <StyledLightButton loading={loading} onClick={handleSaveAndGoBack}>
            Save and go back
          </StyledLightButton> */}
        </StyledLeftContainer>
        <StyledRightContainer>
          <StyledLightButton loading={loading} onClick={() => setSaveType('save')} htmlType="submit">
            Save
          </StyledLightButton>
          <StyledButton loading={loading} onClick={() => setSaveType('saveAndContinue')} htmlType="submit">
            Save and continue
          </StyledButton>
        </StyledRightContainer>
      </StyledButtonsContainer>
    </Footer>
  )
}

export default CustomFooter
