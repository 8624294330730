import styled from 'styled-components'

export const StyledModalContainer = styled.div<{ $show: boolean }>`
  position: fixed;
  color: black;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(2, 2, 16, 0.16);
  backdrop-filter: blur(8px);
  z-index: 1000;
  opacity: ${({ $show }): string => ($show ? '1' : '0')};
  visibility: ${({ $show }): string => ($show ? 'visible' : 'hidden')};
  padding: 16px;
`

export const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 24px 16px 16px;
  max-width: 546px;
  width: 100%;
  background-color: var(--neutral-colors-light);
  border-radius: 8px;
`

export const StyledModalTitle = styled.h1`
  color: var(--neutral-colors-dark, #020210);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`
