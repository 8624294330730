import styled from 'styled-components'

export const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
`

export const StyledTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const StyledTableTitle = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #38003c;
  margin: 20px 0 24px 0;
`

export const StyledPaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
`

export const StyledButtonsContainer = styled.div`
  display: flex;
  column-gap: 24px;
  justify-content: flex-end;

  padding: 36px 0 0 0;
`

export const StyledLinkClientButton = styled.button`
  width: 200px;
  height: 48px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`
