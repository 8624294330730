import Grid from 'components/Grid/Grid'
import {
  FormTableContainer,
  TableContainer,
  FooterContainer,
  StyledTableHeaderContainer,
  StyledTableHeader,
  StyledDescription,
  StyledErrorMessage,
  StyledTitle,
  StyledButtonsContainer,
  StyledButton,
  StyledTotalText,
  StyledTotal,
  TotalContainer,
} from './FormTable.styles'
import { FormTableProps } from './FormTable.types'
import EditDeleteButton from 'components/EditDeleteButton/EditDeleteButton'

const FormTable = ({
  columns,
  dataSource,
  groups,
  title,
  description,
  addButtonText,
  handleAddButton,
  total,
  totalText,
  secondTotal,
  secondTotalText,
  setSelectedRows,
  onRowClick,
  displayDeleteButton,
  handleDeleteModal,
  displayEditButton,
  handleEditModal,
  checkboxColumn = true,
  tableContainerBgColor = '#f8f8f8',
  spacesAroundGrid = true,
  headerGridGap,
  rowHeight,
  headerHeight,
  errorMessage,
}: FormTableProps) => {
  return (
    <FormTableContainer spacesAroundGrid={spacesAroundGrid}>
      <TableContainer tableContainerBgColor={tableContainerBgColor} spacesAroundGrid={spacesAroundGrid} error={!!errorMessage}>
        <StyledTableHeaderContainer headerGridGap={headerGridGap}>
          <StyledTableHeader>
            {title && <StyledTitle>{title}</StyledTitle>}
            <StyledButtonsContainer>
              {displayEditButton && handleEditModal && <EditDeleteButton onClick={handleEditModal} type="edit" value="Edit" />}
              {displayDeleteButton && handleDeleteModal && <EditDeleteButton onClick={handleDeleteModal} type="delete" value="Delete" />}
              {handleAddButton && (
                <StyledButton onClick={handleAddButton} type="button">
                  {addButtonText || 'Add New Item'}
                </StyledButton>
              )}
            </StyledButtonsContainer>
          </StyledTableHeader>
          {description && <StyledDescription>{description}</StyledDescription>}
        </StyledTableHeaderContainer>
        <Grid
          setSelectedRows={!!setSelectedRows ? setSelectedRows : () => {}}
          columns={columns}
          dataSource={dataSource}
          checkboxColumn={checkboxColumn}
          rowHeight={rowHeight}
          groups={groups}
          headerHeight={headerHeight}
          onRowClick={onRowClick}
        />
        {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
      </TableContainer>
      {typeof total === 'number' && (
        <FooterContainer>
          <TotalContainer>
            <StyledTotalText>{totalText || 'Total'}:</StyledTotalText>
            <StyledTotal>{`£${total}`}</StyledTotal>
          </TotalContainer>
          {typeof secondTotal === 'number' && (
            <TotalContainer style={{ backgroundColor: '#fff3e0' }}>
              <StyledTotalText>{secondTotalText || 'Total'}:</StyledTotalText>
              <StyledTotal>{`£${secondTotal}`}</StyledTotal>
            </TotalContainer>
          )}
        </FooterContainer>
      )}
    </FormTableContainer>
  )
}

export default FormTable
