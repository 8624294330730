import { useState } from 'react'
import { message } from 'antd'

import { commonHelper } from 'helpers'
import { useGetCompanies, useGetCompanyAvatarFromS3 } from 'services/company/company-service'
import { companyGridColumns } from './data'
import { eventManager } from 'utils'
import { useCustomMutation } from 'services/shared/mutation'
import { CreateCompanyPayload, DeleteCompaniesPayload, EditCompanyPayload, SuspendCompaniesPayload } from 'services/shared/mutation.payload'
import { createCompanyMutation, deleteCompaniesMutation, editCompanyMutation, suspendCompaniesMutation } from 'gql/company'

const useCompanyPage = () => {
  const createCompany = useCustomMutation<CreateCompanyPayload>({ mutation: createCompanyMutation })
  const suspendCompanies = useCustomMutation<SuspendCompaniesPayload>({ mutation: suspendCompaniesMutation })
  const editCompany = useCustomMutation<EditCompanyPayload>({ mutation: editCompanyMutation })
  const deleteCompanies = useCustomMutation<DeleteCompaniesPayload>({ mutation: deleteCompaniesMutation })

  const [open, setOpen] = useState(false)
  const [companyLogo, setCompanyLogo] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [drawerType, setDrawerType] = useState('add')
  const [formData, setFormData] = useState<any>({
    _id: '',
    name: '',
    phoneNumber: '',
    email: '',
    address: '',
    keyContact: '',
  })
  const limit = 10
  const { companies, refetch, count } = useGetCompanies({
    skip: 0,
    limit,
  })

  const { url: avatarUrl } = useGetCompanyAvatarFromS3({
    filePath: companyLogo,
  })

  const [selected, setSelected] = useState({})

  // Note: works in delete button too
  const displaySuspendButton = !!Object.keys(selected).length
  const displayEditButton = Object.keys(selected).length === 1

  const setSelectedRows = (selectedRowObj: any) => {
    setSelected(selectedRowObj)
  }

  const handleSuspend = async () => {
    setLoading(true)
    const companiesIdsToSuspend = Object.keys(selected)
    const { errors } = await suspendCompanies({ ids: companiesIdsToSuspend })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Suspended successfully')
    setLoading(false)
    refetch()
  }

  const handleDelete = async () => {
    setLoading(true)
    const companyIdsToDelete = Object.keys(selected)
    const { errors } = await deleteCompanies({ ids: companyIdsToDelete })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    setLoading(false)
    refetch()
  }

  const handleDeleteModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleDelete(),
      },
    )
  }

  const companyGridDataSource = companies.map((company: any) => {
    return {
      id: company._id,
      name: company.name,
      phoneNumber: company.phoneNumber,
      email: company.email,
      address: company.address,
      keyContact: company.keyContact,
    }
  })

  const clearDrawerData = () => {
    setFormData({
      name: '',
      phoneNumber: '',
      email: '',
      address: '',
      keyContact: '',
    })
    setCompanyLogo('')
  }

  const openDrawer = () => setOpen(true)

  const closeDrawer = () => {
    setDrawerType('add')
    setOpen(false)
  }

  const handleEdit = () => {
    setDrawerType('edit')

    const companyToEditId = Object.keys(selected)[0]
    const companyToEdit = companies.find((company: any) => company._id === companyToEditId)
    setFormData({
      _id: companyToEditId,
      name: companyToEdit.name,
      phoneNumber: companyToEdit.phoneNumber,
      email: companyToEdit.email,
      address: companyToEdit.address,
      keyContact: companyToEdit.keyContact,
    })
    setCompanyLogo(companyToEdit.companyLogo)
    openDrawer()
  }

  const handleLogoUpload = (e: any) => {
    e.persist()
    const file = e.target.files[0]

    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = function () {
      const dataUrl = reader.result
      setCompanyLogo(dataUrl)
      // Use the data URL to display the image or upload it to a server
    }

    reader.onerror = function () {
      console.error('File could not be read!')
    }
  }

  const handleSubmit = async () => {
    setLoading(true)

    const { _id, ...rest } = formData

    const isEveryFiledFilled = Object.keys(rest).every((fieldKey: string) => !!rest[fieldKey] !== false)
    if (!isEveryFiledFilled) {
      message.info('Please fill out all the fields')
      setLoading(false)
      return
    }

    if (drawerType === 'add') {
      const { errors } = await createCompany({
        ...rest,
        companyLogo,
      })
      if (errors?.length) {
        setLoading(false)
        const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
        message.error(errMessage)
        return
      }
      message.success('New company created successfully')
    } else if (drawerType === 'edit') {
      const { errors } = await editCompany({
        ...formData,
        companyLogo,
      })
      if (errors?.length) {
        setLoading(false)
        const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
        message.error(errMessage)
        return
      }
      message.success('Company edited successfully')
    }

    setLoading(false)
    refetch()
    clearDrawerData()
    setOpen(false)
  }

  const handleFormFieldChange = (e: any) => {
    setFormData((prevState: any) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  return {
    count,
    limit,
    refetch,
    companyGridColumns,
    companyGridDataSource,
    handleSubmit,
    handleFormFieldChange,
    formData,
    loading,
    companyLogo,
    avatarUrl,
    open,
    openDrawer,
    closeDrawer,
    handleLogoUpload,
    handleEdit,
    drawerType,
    displaySuspendButton,
    displayEditButton,
    setSelectedRows,
    handleSuspend,
    handleDeleteModal,
  }
}

export default useCompanyPage
