import styled from 'styled-components';

export const StyledContainer = styled.div`
  margin-top: 24px;
  background-color: #fff;
  box-shadow: 0px 0px 24px rgba(18, 2, 36, 0.08);

  display: flex;
  flex-direction: column;
`;

export const StyledHeader = styled.div`
  height: 56px;
  background: #f8f8f8;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  color: #120224;
`;

export const StyledImg = styled.img`
  cursor: pointer;

  position: absolute;
  right: 16px;
`;

export const StyledMinContainer = styled.div`
  padding: 32px 16px;
  max-width: 100%;
  width: 872px;

  align-self: center;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
