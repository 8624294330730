import Form from 'antd/lib/form'

import Loading from 'components/Loading'
import FormTable from 'components/FormTable/FormTable'
import CustomFormItem from 'components/CustomFormItem/CustomFormItem'
import CustomWarnings from 'components/CustomWarnings/CustomWarnings'

import useBorrowingSolutionAndPriorities from 'hooks/clients/DetailedForms/useBorrowingSolutionAndPriorities'
import CustomFooter from 'pages/ClientDetails/CustomFooter/CustomFooter'
import { yesNoOptions } from 'utils/commonOptions'

import {
  repayOptions,
  importanceOptions,
  facilitatingLoanOptions,
  changesOptions,
  objectivesTableColumns,
  minimumWithdrawalAmountOptions,
  paymentTypeOptions,
  timeFrameForLoanRepaymentOptions,
  howToRepayLoanEarlyOptions,
  fixedPeriodForEarlyRepaymentChargeOptions,
  lendersToExcludeOptions,
  lenderPreferenceOptions,
  attitudeToRiskOptions,
} from './BorrowingSolutionAndPriorities.helper'
import {
  StyledContainer,
  StyledDescription,
  StyledTitle,
  StyledTitleContainer,
  StyledRequiredMsg,
  StyledInternalFormLink,
  StyledBreakLine,
} from './BorrowingSolutionAndPriorities.styles'

const BorrowingSolutionAndPriorities = () => {
  const {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    handleAntdFormItemChange,
    handleCustomSelectChange,
    handleCustomCheckboxChange,
    dataSource,
    initialFundsTotalValue,
    futureFundsTotalValue,
    redirectToForm,
  } = useBorrowingSolutionAndPriorities()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <StyledTitle style={{ fontSize: '32px' }}>Borrowing Solution and Priorities</StyledTitle>
        <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
      </StyledTitleContainer>
      <StyledDescription>
        This page summarises your customers’ needs in relation to their objectives for raising capital, and their preferences in terms of product features. It
        is important these are answered accurately so that the products presented on the results page fit your customers’ needs as closely as possible.
      </StyledDescription>
      <FormTable
        columns={objectivesTableColumns}
        dataSource={dataSource}
        title="Objectives Summary"
        total={initialFundsTotalValue}
        totalText="Initial Funds Total"
        secondTotal={futureFundsTotalValue}
        secondTotalText="Future Funds Total"
        checkboxColumn={false}
        tableContainerBgColor="#fce4ec"
      />
      <StyledTitle style={{ marginTop: '20px' }}>Priorities</StyledTitle>
      <Form
        layout="vertical"
        size="large"
        name="basic"
        initialValues={formData}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '12px',
          marginTop: '8px',
          width: '100%',
        }}
        onFinishFailed={onFinishFailed}
        onFinish={onSubmit}
      >
        <CustomFormItem
          label="Summarise the objectives. Does this provide enough capital for your immediate and all future needs? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="isThisEnoughCapital"
          value={formData.isThisEnoughCapital}
          type="radio"
          rules={['isRequired']}
        />
        {formData.isThisEnoughCapital === false && (
          <StyledInternalFormLink onClick={redirectToForm.bind(this, 'Objectives and Alternatives')}>
            Go to objectives section to update your objectives
          </StyledInternalFormLink>
        )}
        <CustomFormItem
          label="Is it necessary to request the MAXIMUM initial advance available? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="isMaximumAdvanceNecessary"
          value={formData.isMaximumAdvanceNecessary}
          type="radio"
          rules={['isRequired']}
        />
        <CustomFormItem
          label="Do you want to utilise any product cash backs available to make up required initial funds? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="wantToUtilizeAnyProductCashBack"
          value={formData.wantToUtilizeAnyProductCashBack}
          type="radio"
          rules={['isRequired']}
        />
        <CustomFormItem
          label="Is it important that you retain full ownership of your home *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="isImportantToRetainHomeFullOwnership"
          value={formData.isImportantToRetainHomeFullOwnership}
          type="radio"
          rules={['isRequired']}
        />
        <CustomFormItem
          label="Do you require a Cash Reserve / Drawdown Facility? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="isCashReserveRequired"
          value={formData.isCashReserveRequired}
          type="radio"
          rules={['isRequired']}
        />
        {formData.isCashReserveRequired && (
          <>
            <CustomFormItem
              label="The best value Drawdown schemes may require a MINIMUM of £5,000 for each withdrawal from facility. Is this acceptable?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="isDrawdownSchemaFeeAcceptable"
              value={formData.isDrawdownSchemaFeeAcceptable}
              type="radio"
            />
            {formData.isDrawdownSchemaFeeAcceptable === false && (
              <CustomFormItem
                label="How much would be acceptable for a MINIMUM withdrawal?"
                onChange={handleAntdFormItemChange}
                options={minimumWithdrawalAmountOptions}
                name="minimumWithdrawalAmount"
                value={formData.minimumWithdrawalAmount}
                type="radio"
              />
            )}
          </>
        )}
        <CustomFormItem
          label="Would you like the option of making ad-hoc lump sum payments OR regular interest payments? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="isAdHocOrRegularPaymentLikable"
          value={formData.isAdHocOrRegularPaymentLikable}
          type="radio"
          rules={['isRequired']}
        />
        {formData.isAdHocOrRegularPaymentLikable && (
          <>
            <CustomFormItem
              label="Are you willing to make monthly payments to service the interest?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="willingToMakeMonthlyPaymentsToServiceInterest"
              value={formData.willingToMakeMonthlyPaymentsToServiceInterest}
              type="radio"
            />
            {formData.willingToMakeMonthlyPaymentsToServiceInterest && (
              <>
                <CustomFormItem
                  label="Would you like to service the full monthly interest?"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="wantsToServiceFullMonthlyInterest"
                  value={formData.wantsToServiceFullMonthlyInterest}
                  type="radio"
                />
                {formData.wantsToServiceFullMonthlyInterest === false && (
                  <>
                    <CustomFormItem
                      label="What payment option would you prefer?"
                      width={'100%'}
                      placeholder="What payment option would you prefer?"
                      defaultValue={formData.paymentType}
                      onChange={handleCustomSelectChange}
                      options={paymentTypeOptions}
                      name="paymentType"
                      type="select"
                    />
                    {formData.paymentType === 'other' && (
                      <CustomFormItem
                        label="Payment Details"
                        width={'100%'}
                        placeholder="Payment Details"
                        value={formData.paymentTypeDetails}
                        onChange={handleAntdFormItemChange}
                        name="paymentTypeDetails"
                        type="textarea"
                      />
                    )}
                  </>
                )}
                <CustomFormItem
                  label="Will you consider Retirement Interest Only or Later Life Mortgages that carry a repossession risk if payments are not maintained?"
                  onChange={handleAntdFormItemChange}
                  options={yesNoOptions}
                  name="willConsiderPlansIfPaymentsAreNotMaintained"
                  value={formData.willConsiderPlansIfPaymentsAreNotMaintained}
                  type="radio"
                />
              </>
            )}
          </>
        )}
        <CustomFormItem
          label="Do you require a lifetime mortgage - where the loan can be repaid from sale of property after your death? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="isLifetimeMortgageRequired"
          value={formData.isLifetimeMortgageRequired}
          type="radio"
          rules={['isRequired']}
        />
        <CustomFormItem
          label="How likely are you to repay the loan in full during your lifetime? *"
          onChange={handleAntdFormItemChange}
          options={repayOptions}
          name="howLikelyRepayFullLoanInLifetime"
          value={formData.howLikelyRepayFullLoanInLifetime}
          type="radio"
          rules={['isRequired']}
        />
        {(formData.howLikelyRepayFullLoanInLifetime === 'planToRepayEarly' || formData.howLikelyRepayFullLoanInLifetime === 'veryLikelyToRepayEarly') && (
          <>
            <CustomFormItem
              label="During what timeframe might you repay loan?"
              onChange={handleAntdFormItemChange}
              options={timeFrameForLoanRepaymentOptions}
              name="timeFrameForLoanRepayment"
              value={formData.timeFrameForLoanRepayment}
              type="radio"
            />
            <CustomFormItem
              label="How do you intend to repay the loan early"
              width={'100%'}
              placeholder="How do you intend to repay the loan early"
              defaultValue={formData.howToRepayLoanEarly}
              onChange={handleCustomSelectChange}
              options={howToRepayLoanEarlyOptions}
              name="howToRepayLoanEarly"
              type="select"
            />
            {formData.howToRepayLoanEarly === 'other' && (
              <CustomFormItem
                label="Other method of repaying early: Please describe"
                placeholder="Other method of repaying early: Please describe"
                value={formData.otherWayHowToRepayLoanEarly}
                name="otherWayHowToRepayLoanEarly"
                onChange={handleAntdFormItemChange}
                rows={4}
                type="textarea"
              />
            )}
            <CustomFormItem
              label="Are you willing to accept a variable charge if you repay the loan early?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="isVariableChargeAcceptable"
              value={formData.isVariableChargeAcceptable}
              type="radio"
            />
            <CustomFormItem
              label="Do you require a defined early repayment charge for a fixed period?"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="isDefinedEarlyRepaymentChargeRequired"
              value={formData.isDefinedEarlyRepaymentChargeRequired}
              type="radio"
            />
            <CustomFormItem
              label="For what fixed period are you comfortable having an early repayment charge?"
              onChange={handleAntdFormItemChange}
              options={fixedPeriodForEarlyRepaymentChargeOptions}
              name="fixedPeriodForEarlyRepaymentCharge"
              value={formData.fixedPeriodForEarlyRepaymentCharge}
              type="radio"
            />
          </>
        )}
        <CustomFormItem
          label="Is it important that you can repay loan without charge if you move home after 5 years? *"
          onChange={handleAntdFormItemChange}
          options={importanceOptions}
          name="repayLoanFreeIfMoveHomeAfterFiveYrs"
          value={formData.repayLoanFreeIfMoveHomeAfterFiveYrs}
          type="radio"
          rules={['isRequired']}
        />
        <CustomFormItem
          label="Is it important that you can repay the loan in event of a partner dying or going into care? *"
          onChange={handleAntdFormItemChange}
          options={importanceOptions}
          name="loanRepayImportantOnPartnerPassOrCare"
          value={formData.loanRepayImportantOnPartnerPassOrCare}
          type="radio"
          rules={['isRequired']}
        />
        <CustomFormItem
          label="Are you prepared to pay a fee for a valuation of your home, at application stage?"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="isAbleToPayFeeForValuation"
          value={formData.isAbleToPayFeeForValuation}
          type="radio"
        />
        <CustomFormItem
          label="If the lender charges a fee for facilitating the loan, what is your preference?"
          width={'100%'}
          placeholder="If the lender charges a fee for facilitating the loan, what is your preference?"
          defaultValue={formData.preferenceForLenderFeePayment}
          onChange={handleCustomSelectChange}
          options={facilitatingLoanOptions}
          name="preferenceForLenderFeePayment"
          type="select"
        />
        <CustomFormItem
          label="Do you require a fixed interest rate for life?"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="isFixedInterestRateRequired"
          value={formData.isFixedInterestRateRequired}
          type="radio"
        />
        <CustomFormItem
          label="Have you received any recent quotations for a lifetime mortgage or later life mortgage?"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="hasReceivedQuotationsForMortgage"
          value={formData.hasReceivedQuotationsForMortgage}
          type="radio"
        />
        {formData.hasReceivedQuotationsForMortgage && (
          <>
            <CustomFormItem
              label="Type of product recommended and by whom"
              name="recommendedProduct"
              placeholder="Type of product recommended and by whom"
              onChange={handleAntdFormItemChange}
            />
            <CustomFormItem
              label="Enter interest rate quoted (if known)"
              name="recommendedProductInterestRate"
              placeholder="Enter interest rate quoted (if known)"
              onChange={handleAntdFormItemChange}
              type="number"
            />
          </>
        )}
        <CustomFormItem
          label="Have you previously applied for a loan for the above objectives?"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="hasPreviouslyAppliedForLoan"
          value={formData.hasPreviouslyAppliedForLoan}
          type="radio"
        />
        {formData.hasPreviouslyAppliedForLoan && (
          <>
            <CustomFormItem
              label="What was the outcome of this? (please detail)"
              placeholder="What was the outcome of this? (please detail)"
              value={formData.detailsOfFutureCircumstancesChanges}
              name="detailsOfFutureCircumstancesChanges"
              onChange={handleAntdFormItemChange}
              rows={4}
              type="textarea"
            />
            <CustomFormItem
              label="Based on above, select any lenders that must be excluded when selecting a product"
              width={'100%'}
              placeholder="Based on above, select any lenders that must be excluded when selecting a product"
              defaultValue={formData.lendersToExclude}
              onChange={handleCustomSelectChange}
              options={lendersToExcludeOptions}
              name="lendersToExclude"
              mode="multiple"
              type="select"
            />
          </>
        )}
        <CustomFormItem
          label="Do you require the funds quickly?"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="areFundsRequiredQuickly"
          value={formData.areFundsRequiredQuickly}
          type="radio"
        />
        {formData.areFundsRequiredQuickly && (
          <>
            <CustomFormItem
              label="On average, it takes between 6 - 12 weeks to release funds. However some lenders can release funds in under 6 weeks. What is your preference? "
              width={'100%'}
              placeholder="On average, it takes between 6 - 12 weeks to release funds. However some lenders can release funds in under 6 weeks. What is your preference? "
              defaultValue={formData.lenderPreference}
              onChange={handleCustomSelectChange}
              options={lenderPreferenceOptions}
              name="lenderPreference"
              type="select"
            />
            <CustomFormItem
              label="Please detail reasons for needing funds quickly"
              placeholder="Please detail reasons for needing funds quickly"
              value={formData.detailReasonsForNeedingFundsQuickly}
              name="detailReasonsForNeedingFundsQuickly"
              onChange={handleAntdFormItemChange}
              rows={4}
              type="textarea"
            />
          </>
        )}
        <CustomFormItem
          label="Do you anticipate any of the following changes in your circumstances in future? *"
          width={'100%'}
          placeholder="Do you anticipate any of the following changes in your circumstances in future? *"
          defaultValue={formData.anticipateFutureCircumstancesChanges}
          onChange={handleCustomSelectChange}
          options={changesOptions}
          name="anticipateFutureCircumstancesChanges"
          type="select"
          rules={['isRequired']}
        />
        {formData.anticipateFutureCircumstancesChanges === 'other' && (
          <CustomFormItem
            label="Please provide details, timescales and whether you are adding a new objective in the Objectives section"
            placeholder="Please provide details, timescales and whether you are adding a new objective in the Objectives section"
            value={formData.detailsOfFutureCircumstancesChanges}
            name="detailsOfFutureCircumstancesChanges"
            onChange={handleAntdFormItemChange}
            rows={4}
            type="textarea"
          />
        )}
        <CustomFormItem
          label="Only display products approved by the Equity Release Council?"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="onlyDisplayApprovedProducts"
          value={formData.onlyDisplayApprovedProducts}
          type="radio"
          rules={['isRequired']}
        />
        <StyledBreakLine />
        <StyledTitle>Attitude to Risk</StyledTitle>
        <CustomWarnings
          label="There are four categories to risk with regards to equity release. Which of the following most reflects your views? This decision should be taken after you have discussed the various arrangements available with your adviser and you fully understand the schemes available to you."
          onChange={handleCustomCheckboxChange}
          options={attitudeToRiskOptions}
          value={formData.attitudeToRisk}
          name="attitudeToRisk"
        />
        <CustomFooter loading={loading} setSaveType={setSaveType} />
      </Form>
    </StyledContainer>
  )
}

export default BorrowingSolutionAndPriorities
