import { commonUtils, eventManager } from 'utils'

import { KeyBox, KeyWrapper } from 'pages/QuoteResult/QuoteResult.styles'
import {
  StyledInitialSumWrapper,
  StyledLinkBtn,
  StyledProviderAvatar,
  StyledProviderWrapper,
  StyledReadNoteBtn,
  KeysWrapper,
  CenteredParagraph,
  GridTitleWrapper,
  GridTitleParagraph,
} from './LifeTimeMortgagesTab.styles'
import { IMortgagesData, Quote } from './LifeTimeMortgagesTab.interface'

// const handleRedirect = (value: any) => {
//   window.open(value, '_blank')
// }

const openNewTabWithRoute = (transactionNumber: string) => {
  const fullUrl = `${window.location.origin}/test-quote-search?transactionNumber=${transactionNumber}&testing=false`
  window.open(fullUrl, '_blank')
}

export const generateMortgagesColumns = (filteredData: Quote[]) => {
  const shouldRenderInitialSum = filteredData.some((quote) => quote.initialSum !== undefined)
  const shouldRenderReserve = filteredData.some((quote) => quote.reserve !== undefined)
  const columns = [
    {
      name: 'provider',
      header: () => (
        <GridTitleWrapper>
          <GridTitleParagraph>Provider /</GridTitleParagraph>
          <GridTitleParagraph>Product</GridTitleParagraph>
        </GridTitleWrapper>
      ),
      defaultFlex: 2,
      type: 'text',
      render: ({ data }: IMortgagesData) => (
        <StyledProviderWrapper>
          <StyledProviderAvatar src={data.avatar} />
          <div>
            <p>{data.provider}</p>
            <p>{data.product}</p>
          </div>
        </StyledProviderWrapper>
      ),
    },
    {
      name: 'initialSum',
      header: () => (
        <GridTitleWrapper>
          <GridTitleParagraph>Initial Sum</GridTitleParagraph>
        </GridTitleWrapper>
      ),
      defaultFlex: 2,
      type: 'number',
      render: ({ data }: IMortgagesData) => (
        <StyledInitialSumWrapper>
          <p>£{commonUtils.formatNumberWithCommas(data.initialSum || 0)}</p>
          <KeysWrapper>
            {data.keys?.map((key: any) => (
              <KeyWrapper key={key}>
                <KeyBox>{key}</KeyBox>
              </KeyWrapper>
            ))}
          </KeysWrapper>
        </StyledInitialSumWrapper>
      ),
      hide: !shouldRenderInitialSum,
    },
    {
      name: 'netLoanAmount',
      header: () => (
        <GridTitleWrapper>
          <GridTitleParagraph>Net Loan</GridTitleParagraph>
          <GridTitleParagraph>Amount</GridTitleParagraph>
        </GridTitleWrapper>
      ),
      defaultFlex: 2,
      type: 'text',
      render: ({ data }: IMortgagesData) => <CenteredParagraph>£{commonUtils.formatNumberWithCommas(data.netLoanAmount)}</CenteredParagraph>,
    },
    {
      name: 'reserve',
      header: () => (
        <GridTitleWrapper>
          <GridTitleParagraph>Reserve</GridTitleParagraph>
        </GridTitleWrapper>
      ),
      defaultFlex: 2,
      type: 'text',
      render: ({ data }: IMortgagesData) => (
        <CenteredParagraph>{data.reserve !== undefined ? `£${commonUtils.formatNumberWithCommas(data.reserve)}` : '-'}</CenteredParagraph>
      ),
      hide: !shouldRenderReserve,
    },
    {
      name: 'mer',
      header: () => (
        <GridTitleWrapper>
          <GridTitleParagraph>MER</GridTitleParagraph>
        </GridTitleWrapper>
      ),
      defaultFlex: 1,
      type: 'text',
      render: ({ data }: IMortgagesData) => <CenteredParagraph>{data.mer}%</CenteredParagraph>,
    },
    {
      name: 'aer',
      header: () => (
        <GridTitleWrapper>
          <GridTitleParagraph>AER</GridTitleParagraph>
        </GridTitleWrapper>
      ),
      defaultFlex: 1,
      type: 'text',
      render: ({ data }: IMortgagesData) => <CenteredParagraph>{data.aer}%</CenteredParagraph>,
    },
    {
      name: 'totalCostOfLoan',
      header: () => (
        <GridTitleWrapper>
          <GridTitleParagraph>Total Cost of</GridTitleParagraph>
          <GridTitleParagraph>Loan</GridTitleParagraph>
        </GridTitleWrapper>
      ),
      defaultFlex: 2,
      type: 'text',
      render: ({ data }: IMortgagesData) => (
        <CenteredParagraph>{data.totalCostOfLoan ? `£${commonUtils.formatNumberWithCommas(data.totalCostOfLoan)}` : '-'}</CenteredParagraph>
      ),
    },
    {
      name: 'costPerPound',
      header: () => (
        <GridTitleWrapper>
          <GridTitleParagraph>Cost Per</GridTitleParagraph>
          <GridTitleParagraph>Pound</GridTitleParagraph>
        </GridTitleWrapper>
      ),
      defaultFlex: 2,
      type: 'text',
      render: ({ data }: IMortgagesData) => <CenteredParagraph>{data.costPerPound ? `£${data.costPerPound}` : '-'}</CenteredParagraph>,
    },
    {
      name: 'interestType',
      header: () => (
        <GridTitleWrapper>
          <GridTitleParagraph>Interest Type</GridTitleParagraph>
        </GridTitleWrapper>
      ),
      defaultFlex: 2,
      type: 'text',
      render: ({ data }: IMortgagesData) => (
        <CenteredParagraph>{`${data.interestType} ${
          data.rateType === 'Fixed'
            ? '(f)'
            : data.interestType + data.rateType === 'Variable'
            ? '(V)'
            : data.interestType + data.rateType === 'Capped'
            ? '(C)'
            : ''
        }`}</CenteredParagraph>
      ),
    },
    {
      name: 'erc',
      header: () => (
        <GridTitleWrapper>
          <GridTitleParagraph>ERC</GridTitleParagraph>
        </GridTitleWrapper>
      ),
      defaultFlex: 2,
      type: 'text',
      render: ({ data }: IMortgagesData) => <CenteredParagraph>{data.erc}</CenteredParagraph>,
    },
    {
      name: 'commission',
      header: () => (
        <GridTitleWrapper>
          <GridTitleParagraph>Commission</GridTitleParagraph>
        </GridTitleWrapper>
      ),
      defaultFlex: 2,
      type: 'text',
      render: ({ data }: IMortgagesData) => (
        <CenteredParagraph>{data.commission ? `£${commonUtils.formatNumberWithCommas(data.commission)}` : '-'}</CenteredParagraph>
      ),
    },
    {
      name: 'notes',
      header: () => (
        <GridTitleWrapper>
          <GridTitleParagraph>Notes</GridTitleParagraph>
        </GridTitleWrapper>
      ),
      defaultFlex: 2,
      type: 'text',
      render: ({ data }: IMortgagesData) => (
        <StyledReadNoteBtn
          disabled={data.notes.length === 0}
          onClick={(e) => {
            e.stopPropagation()
            eventManager.openQuoteResultNoteModal(
              { header: '', body: data.notes },
              {
                confirm: () => {},
              },
            )
          }}
        >
          Read note
        </StyledReadNoteBtn>
      ),
    },
    {
      name: 'transactionNumber',
      header: () => (
        <GridTitleWrapper>
          <GridTitleParagraph>Link</GridTitleParagraph>
        </GridTitleWrapper>
      ),
      defaultFlex: 2,
      type: 'text',
      render: ({ data }: IMortgagesData) => (
        <StyledLinkBtn
          disabled={!data.transactionNumber}
          onClick={(e) => {
            e.stopPropagation()
            openNewTabWithRoute(data.transactionNumber)
          }}
          formTarget="_blank"
        >
          Continue
        </StyledLinkBtn>
      ),
    },
  ]

  return columns.filter((column) => !column?.hide)
}
