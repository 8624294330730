import { Button } from 'antd'
import styled from 'styled-components'

export const StyledReadNoteBtn = styled.button`
  background: #38003c;
  border: none;
  padding: 8px 16px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fefefe;
`

export const StyledLinkBtn = styled.button`
  background: #2080f6;
  border: none;
  padding: 8px 16px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fefefe;
`

export const StyledProviderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 4px;
  text-align: center;
  white-space: pre-wrap;
`

export const StyledProviderAvatar = styled.img`
  width: 96px;
`

export const StyledInitialSumWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 4px;
  text-align: center;
  white-space: pre-wrap;
`

export const KeysWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  gap: 4px;
`

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  row-gap: 12px;
`

export const CenteredParagraph = styled.p`
  text-align: center;
`

export const GridTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const GridTitleParagraph = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`

export const StyledButtonsContainer = styled.div`
  display: flex;
  column-gap: 24px;
`

export const StyledRemoveFiltersButton = styled(Button)`
  width: 200px;
  height: 48px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`

export const StyledFilterButton = styled(Button)`
  width: 200px;
  height: 48px;

  background-color: #fefefe !important;
  color: #90258c !important;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`
