import { Button } from 'antd';
import styled from 'styled-components';

export const StyledContainer = styled.div``;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledAddFactFindButton = styled(Button)`
  width: 200px;
  height: 48px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const StyledQuikSearchButton = styled(Button)`
  width: 200px;
  height: 48px;

  background-color: #fefefe !important;
  color: #90258c !important;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const StyledGridContainer = styled.div`
  margin-top: 24px;
`;

export const StyledPaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  column-gap: 24px;
`;

export const StyledExportContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
`;

export const StyledExportImg = styled.img``;

export const StyledExportText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #219653;

  flex: none;
  order: 1;
  flex-grow: 0;
`;
