export const companyGridColumns = [
  {
    name: 'name',
    header: 'Name',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'phoneNumber',
    header: 'Phone',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'email',
    header: 'Email',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'address',
    header: 'Address',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'keyContact',
    header: 'Key Contact',
    defaultFlex: 1,
    type: 'text',
  },
];
