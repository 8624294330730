import { BLOCKS, Block, Inline } from '@contentful/rich-text-types'

import { contentfulDefaultOptions } from 'services/contentful'

const tableStyles = {
  // margin: '24px 0',
  // borderCollapse: 'collapse',
  width: '100%',
  backgroundColor: '#90258c',
  border: '1px solid #90258c',
}

const tableHeaderCellStyles = {
  backgroundColor: '#90258c',
  color: 'white',
  padding: '12px 15px',
  borderLeft: '1px solid #fff',
  borderRight: '1px solid #fff',
  fontSize: '16px',
}

const tableCellStyles = {
  padding: '12px 15px',
  // border: '1px solid #90258c',
  backgroundColor: '#f8f8f8',
}

export const options = {
  renderNode: {
    ...contentfulDefaultOptions.renderNode,
    // ... other renderers
    [BLOCKS.TABLE]: (_: Block | Inline, children: React.ReactNode) => <table style={tableStyles}>{children}</table>,
    [BLOCKS.TABLE_ROW]: (_: Block | Inline, children: React.ReactNode) => <tr>{children}</tr>,
    [BLOCKS.TABLE_HEADER_CELL]: (_: Block | Inline, children: React.ReactNode) => <th style={tableHeaderCellStyles}>{children}</th>,
    [BLOCKS.TABLE_CELL]: (_: Block | Inline, children: React.ReactNode) => <td style={tableCellStyles}>{children}</td>,
    // ...other custom renderers
  },
}
