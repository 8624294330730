import Tabs from 'antd/lib/tabs'

import Layout from 'components/Layout'
import MainContentContainer from 'components/MainContentContainer'
import StyledPercentage from 'components/StyledPercentage'
// import CustomSelect from 'components/CustomSelect'

import { UserAvatarPlaceholder } from 'assets/images'
import { useClientsDetails } from 'hooks'

import GeneralTabContent from './Tabs/GeneralTabContent'
import JourneyTabContent from './Tabs/JourneyTabContent'
import NotesTabContent from './Tabs/NotesTabContent'
import QuoteHistoryTabContent from './Tabs/QuoteHistoryTabContent'
import TrackingTabContent from './Tabs/TrackingTabContent/TrackingTabContent'
import FilesTabContent from './Tabs/FilesTabContent'

import Actions from './Actions'

import {
  StyledContainer,
  StyledClientHeaderContainer,
  StyledLeftContainer,
  StyledRightContainer,
  StyledAvatar,
  StyledInfoContainer,
  StyledName,
  StyledId,
  StyledNewQuoteButton,
} from './ClientDetails.styles'
import { useGetClientFormsProgress, useGetClientAgent, useGetCustomerInfo } from 'services/clients/clients.service'
import SuitabilityLetterTabContent from './Tabs/SuitabilityLetterTabContent'

const { TabPane } = Tabs

const ClientDetails = () => {
  const {
    idNumber,
    antdActiveTabKey,
    handleTabChange,
    handleNewQuote,
    // Note: General tab
    displayDetailForm,
    handleDetailedFormClose,
    generalTabItems,
    breadcrumbItems,
    handleBreadcrumbTabChange,
    handleItemClick,
    detailedFormName,
    handleDeleteModal,
  } = useClientsDetails()

  const { data: progressData } = useGetClientFormsProgress(idNumber)
  const { customer: customerFirst } = useGetCustomerInfo(idNumber, 1)
  const { customer: customerSecond } = useGetCustomerInfo(idNumber, 2)
  const { user } = useGetClientAgent(idNumber)
  const agentProfileLink = `/user-profile/${user?._id}`

  return (
    <Layout>
      <MainContentContainer pageName="Client Details">
        <StyledContainer>
          <StyledClientHeaderContainer>
            <StyledLeftContainer>
              <StyledAvatar src={UserAvatarPlaceholder} />
              <StyledInfoContainer>
                <StyledName>{customerFirst?.firstName ? `${customerFirst?.firstName} ${customerFirst?.lastName}` : 'New Client'}</StyledName>
                {customerSecond.firstName && (
                  <StyledName
                    style={{
                      fontSize: '16px',
                    }}
                  >{`${customerSecond?.firstName} ${customerSecond?.lastName}`}</StyledName>
                )}
                <StyledId>ID{idNumber}</StyledId>
                {user?.firstName && (
                  <StyledId>
                    Created By: <a target="_blank" rel="noreferrer" href={agentProfileLink}>{`${user?.firstName} ${user?.lastName}`}</a>
                  </StyledId>
                )}
              </StyledInfoContainer>
            </StyledLeftContainer>
            <StyledRightContainer>
              <StyledPercentage percentage={progressData?.averageProgress} />
              {/* <CustomSelect onChange={() => {}} defaultValue={null} placeholder="Submitted" options={[]} /> */}
              <StyledNewQuoteButton onClick={handleNewQuote}>New quote</StyledNewQuoteButton>
              <Actions handleDelete={handleDeleteModal} />
            </StyledRightContainer>
          </StyledClientHeaderContainer>
          <Tabs style={{ width: '100%' }} activeKey={antdActiveTabKey} type="card" onChange={handleTabChange}>
            <TabPane tab="GENERAL" key="1">
              <GeneralTabContent
                displayDetailForm={displayDetailForm}
                handleDetailedFormClose={handleDetailedFormClose}
                items={generalTabItems}
                progressData={progressData}
                breadcrumbItems={breadcrumbItems}
                handleBreadcrumbTabChange={handleBreadcrumbTabChange}
                handleItemClick={handleItemClick}
                detailedFormName={detailedFormName}
              />
            </TabPane>
            <TabPane tab="TRACKING" key="2">
              <TrackingTabContent />
            </TabPane>
            <TabPane tab="JOURNEY" key="3">
              <JourneyTabContent />
            </TabPane>
            <TabPane tab="NOTES" key="4">
              <NotesTabContent />
            </TabPane>
            <TabPane tab="QUOTE HISTORY" key="5">
              <QuoteHistoryTabContent />
            </TabPane>
            <TabPane tab="FILES" key="6">
              <FilesTabContent />
            </TabPane>
            <TabPane tab="SUITABILITY LETTER" key="7">
              <SuitabilityLetterTabContent progressData={progressData} />
            </TabPane>
          </Tabs>
        </StyledContainer>
      </MainContentContainer>
    </Layout>
  )
}

export default ClientDetails
