import { useQuery } from '@apollo/client'
import { getUserByIdQuery, getUserFilesQuery, getUserNotesQuery } from 'gql/user'

export const useGetUserById = (_id: string) => {
  const { data, error, loading, refetch } = useQuery(getUserByIdQuery, {
    variables: { _id },
  })

  return {
    user: data?.getUserById || {},
    loading,
    error,
    refetch,
  }
}

export const useGetUserNotes = (userId: string) => {
  const { data, error, loading, refetch } = useQuery(getUserNotesQuery, {
    variables: { userId },
  })

  return {
    notes: data?.getUserNotes?.notes || [],
    loading,
    error,
    refetch,
  }
}

export const useGetUserFiles = (userId: string) => {
  const { data, error, loading, refetch } = useQuery(getUserFilesQuery, {
    variables: { userId },
  })

  return {
    data: data?.getUserFiles?.files || [],
    loading,
    error,
    refetch,
  }
}
