import { Form, Modal } from 'antd'

import { StyledWrapper } from './ObjectiveModal.styles'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import TextInput from 'components/CustomFormItem/CustomFormItem'
import CustomTextArea from 'components/CustomTextArea/CustomTextArea'
import { fundsRequiredInOptions, purposeOfRaisingCapitalOptions } from './ObjectiveModal.helper'
import useObjectiveModal from 'hooks/clients/Modals/useObjectiveModal'
import Loading from 'components/Loading/Loading'
import { PoundIcon } from 'assets/images'
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox'
import { yesNoOptions } from 'utils/commonOptions'

const ObjectiveModal = () => {
  const {
    modalState,
    handleCancelClick,
    handleConfirmClick,
    formData,
    handleCustomCheckboxChange,
    handleCustomSelectChange,
    handleAntdFormInputChange,
    handleCustomTextAreaChange,
    loading,
    form,
  } = useObjectiveModal()

  if (loading) {
    return <Loading />
  }

  return (
    <Modal
      title="Edit Objective"
      open={modalState.isOpen}
      onOk={handleConfirmClick}
      onCancel={handleCancelClick}
      width={700}
      okText="Save"
      cancelText="Cancel"
      closable={false}
      className="edit-asset-modal"
      confirmLoading={modalState.loading}
    >
      {modalState.loading ? (
        <Loading />
      ) : (
        <StyledWrapper>
          <Form
            form={form}
            layout="vertical"
            size="large"
            name="basic"
            initialValues={formData}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
              marginTop: '8px',
              width: '100%',
            }}
          >
            <CustomCheckbox
              label="Do you need to raise capital to achieve your objective? *"
              onChange={handleCustomCheckboxChange}
              options={yesNoOptions}
              name="needsToRaiseCapital"
              value={formData.needsToRaiseCapital}
            />
            {formData.needsToRaiseCapital && (
              <>
                <CustomSelect
                  label="Purpose of raising capital *"
                  width={'100%'}
                  placeholder="Purpose of raising capital *"
                  defaultValue={formData.purposeOfRaisingCapital}
                  onChange={handleCustomSelectChange}
                  options={purposeOfRaisingCapitalOptions}
                  name="purposeOfRaisingCapital"
                />
                <TextInput
                  label="Amount Required? *"
                  name="requiredAmount"
                  placeholder="Amount Required?"
                  inputPrefixImg={PoundIcon}
                  prefixImgHeight={12}
                  onChange={handleAntdFormInputChange}
                  type="number"
                />
                <CustomSelect
                  label="When are funds required? *"
                  width={'100%'}
                  placeholder="When are funds required? *"
                  defaultValue={formData.fundsRequiredIn}
                  onChange={handleCustomSelectChange}
                  options={fundsRequiredInOptions}
                  name="fundsRequiredIn"
                />
              </>
            )}
          </Form>
          <CustomTextArea
            label="Please summarise your objective"
            placeholder="Please summarise your objective"
            value={formData.description}
            name="description"
            onChange={handleCustomTextAreaChange}
            rows={4}
          />
        </StyledWrapper>
      )}
    </Modal>
  )
}

export default ObjectiveModal
