import { useContext, useEffect, useState } from 'react'
import { message } from 'antd'

import { commonHelper } from 'helpers'
import { FormsContext } from 'contexts'
import { commonUtils } from 'utils'

import { useGetCustomerInfo } from 'services/clients/clients.service'
import useGeneral from './useGeneral'
import { addEditCustomerMutation } from 'gql/client'
import { useCustomMutation } from 'services/shared/mutation'
import { AddEditCustomerPayload } from 'services/shared/mutation.payload'

const useCustomerDetails = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const { customerToEdit } = useContext(FormsContext)

  const { customer, loading: formInitialDataLoading } = useGetCustomerInfo(idNumber, customerToEdit)

  const addEditCustomer = useCustomMutation<AddEditCustomerPayload>({
    mutation: addEditCustomerMutation,
    refetchQueries: ['getCustomerFirstInfo', 'getClientFormsProgress'],
  })
  const [loading, setLoading] = useState(formInitialDataLoading)
  const [formData, setFormData] = useState({
    title: customer?.title,
    firstName: customer?.firstName,
    middleNames: customer?.middleNames,
    lastName: customer?.lastName,
    relationshipToOtherCustomer: customer?.relationshipToOtherCustomer,
    gender: customer?.gender,
    dateOfBirth: customer?.dateOfBirth,
    maritalStatus: customer?.maritalStatus,
    nationality: customer?.nationality,
    livedHereSinceBirth: customer?.livedHereSinceBirth,
    hasRightToRemain: customer?.hasRightToRemain,
    landlineTelephone: customer?.landlineTelephone,
    mobileTelephone: customer?.mobileTelephone,
    email: customer?.email,
    preferredContactMethod: customer?.preferredContactMethod,
  })

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [loading])

  useEffect(() => {
    setLoading(formInitialDataLoading)
    if (customer) {
      const newData = commonUtils.removeTypeNameFromObject(customer)
      delete newData._id
      setFormData({ ...newData })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInitialDataLoading])

  const handleCustomSelectChange = (name: string, value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value, e.target?.name)
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: parsedValue,
    }))
  }

  const handleSubmit = async () => {
    const { errors } = await addEditCustomer({
      idNumber,
      customer: customerToEdit,
      values: {
        ...formData,

        ...(formData?.landlineTelephone && {
          landlineTelephone: String(formData?.landlineTelephone),
        }),

        ...(formData?.mobileTelephone && {
          mobileTelephone: String(formData?.mobileTelephone),
        }),
      },
    })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const { handleSaveAndGoBack, handleSave, handleSaveAndContinue } = useGeneral({ handleSubmit, setLoading })

  const [saveType, setSaveType] = useState<'save' | 'saveAndGoBack' | 'saveAndContinue'>('save')

  const onSubmit = () => {
    if (saveType === 'saveAndGoBack') {
      handleSaveAndGoBack()
    } else if (saveType === 'saveAndContinue') {
      handleSaveAndContinue()
    } else if (saveType === 'save') {
      handleSave()
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error('please fill out every required field')
  }

  return {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    handleAntdFormItemChange,
    handleCustomSelectChange,
    formData,
  }
}

export default useCustomerDetails
