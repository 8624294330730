export const relationshipWithCustomerOptions = [
  {
    label: 'Son',
    value: 'son',
  },
  {
    label: 'Daughter',
    value: 'daughter',
  },
  {
    label: 'Grandson',
    value: 'grandson',
  },
  {
    label: 'Granddaughter',
    value: 'granddaughter',
  },
  {
    label: 'Friend',
    value: 'friend',
  },
  {
    label: 'Carer',
    value: 'carer',
  },
  {
    label: 'Neighbour',
    value: 'neighbour',
  },
  {
    label: 'Partner',
    value: 'partner',
  },
  {
    label: 'POA',
    value: 'poa',
  },
  {
    label: 'Other',
    value: 'other',
  },
]
