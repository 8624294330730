import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledPageName = styled.h1`
  /* font-family: "Inter";
  font-style: normal; */
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #38003c;
`;

export const StyledChildren = styled.div`
  margin-top: 32px;
`;
