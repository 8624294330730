import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { browserTracingIntegration } from '@sentry/browser'
import { ApolloProvider } from '@apollo/client'

import apollo from 'services/apollo'

import './assets/styles/fonts.css'
import './assets/styles/custom-antd.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

Sentry.init({
  dsn: 'https://32a102d388c44406b46c82f666972d7d@o918338.ingest.sentry.io/4505544908144640',
  integrations: [browserTracingIntegration()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_STAGE,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ApolloProvider client={apollo}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
