import { initialQuickSearchTabs } from 'hooks/clients/staticData'
import { createContext } from 'react'

export interface FormsContext {
  generalTabItems: any
  setGeneralTabItems: any

  medicalQuestionsTabItems: any
  setMedicalQuestionsTabItems: any

  detailedFormName: string
  setDetailedFormName: any

  antdActiveTabKey: string
  setAntdActiveTabKey: any

  displayDetailForm: boolean
  setDisplayDetailForm: any

  customerToEdit: 1 | 2
  setCustomerToEdit: any

  breadcrumbItems: any[]
  setBreadcrumbItems: any

  propertyToEdit: string
  setPropertyToEdit: any

  quickSearch: {
    tabs: Record<string, string>
    setTabs: any
    activeTab: string
    setActiveTab: any
  }

  quoteResultLoading: boolean
  setQuoteResultLoading: any

  quoteResultActiveTab: string
  setQuoteResultActiveTab: any
}
const context: FormsContext = {
  generalTabItems: [],
  setGeneralTabItems: () => null,

  medicalQuestionsTabItems: [],
  setMedicalQuestionsTabItems: () => null,

  detailedFormName: '',
  setDetailedFormName: () => null,

  antdActiveTabKey: '1',
  setAntdActiveTabKey: () => null,

  displayDetailForm: false,
  setDisplayDetailForm: () => null,

  customerToEdit: 1,
  setCustomerToEdit: () => null,

  breadcrumbItems: [],
  setBreadcrumbItems: () => null,

  propertyToEdit: '',
  setPropertyToEdit: () => null,

  quickSearch: {
    tabs: initialQuickSearchTabs,
    setTabs: () => null,
    activeTab: initialQuickSearchTabs.clientDetails,
    setActiveTab: () => null,
  },

  quoteResultLoading: false,
  setQuoteResultLoading: () => null,

  quoteResultActiveTab: 'LIFE TIME MORTGAGES',
  setQuoteResultActiveTab: () => null,
}

export default createContext(context)
