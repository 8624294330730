import { commonUtils } from 'utils'
import { IContextUser } from 'contexts/AuthContext'
import { ICommissionPaidReportColumnProp, IDetailsColumnProp } from './CommissionPaid.interface'
import { CellProps } from '@inovua/reactdatagrid-community/types'
import { generateBgColor } from '../WrittenBusiness/constants'

export const commissionPaidReportDataSource = [
  {
    paid: 4041.25,
    totalCommissionsPaymentsOutstanding: 6891.25,
  },
]

export const commissionPaidReportColumns = [
  {
    name: 'paid',
    header: 'Paid',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: ICommissionPaidReportColumnProp) => (data.paid ? `£${commonUtils.formatNumberWithCommas(data.paid)}` : '-'),
  },
  {
    name: 'totalCommissionsPaymentsOutstanding',
    header: 'Total Commissions Payments Outstanding YTD',
    defaultFlex: 1,
    type: 'number',
    render: ({ data }: ICommissionPaidReportColumnProp) =>
      data.totalCommissionsPaymentsOutstanding ? `£${commonUtils.formatNumberWithCommas(data.totalCommissionsPaymentsOutstanding)}` : '-',
  },
]

export const generateDetailsColumns = (currentUser: IContextUser) => {
  const columns = [
    {
      name: 'companyName',
      header: 'Company Name',
      defaultFlex: 1,
      type: 'text',
      hide: currentUser.role !== 'superAdmin',
      onRender: (cellProps: CellProps, { data }: IDetailsColumnProp) => {
        if (cellProps.style && typeof cellProps.style === 'object') {
          const bgColor = generateBgColor(data.status)
          ;(cellProps.style as React.CSSProperties).background = bgColor
        }
      },
    },
    {
      name: 'advisorName',
      header: 'Adviser Name',
      defaultFlex: 1,
      type: 'text',
      hide: currentUser.role !== 'superAdmin',
      onRender: (cellProps: CellProps, { data }: IDetailsColumnProp) => {
        if (cellProps.style && typeof cellProps.style === 'object') {
          const bgColor = generateBgColor(data.status)
          ;(cellProps.style as React.CSSProperties).background = bgColor
        }
      },
    },
    {
      name: 'clientName',
      header: 'Client name',
      defaultFlex: 1,
      type: 'text',
      onRender: (cellProps: CellProps, { data }: IDetailsColumnProp) => {
        if (cellProps.style && typeof cellProps.style === 'object') {
          const bgColor = generateBgColor(data.status)
          ;(cellProps.style as React.CSSProperties).background = bgColor
        }
      },
    },
    {
      name: 'dateOfApplication',
      header: 'Date of application',
      defaultFlex: 1,
      type: 'date',
      onRender: (cellProps: CellProps, { data }: IDetailsColumnProp) => {
        if (cellProps.style && typeof cellProps.style === 'object') {
          const bgColor = generateBgColor(data.status)
          ;(cellProps.style as React.CSSProperties).background = bgColor
        }
      },
      render: ({ data }: IDetailsColumnProp) => (data.dateOfApplication ? commonUtils.formatDate(data.dateOfApplication) : '-'),
    },
    {
      name: 'lenderProvider',
      header: 'LENDER/PROVIDER',
      defaultFlex: 1,
      type: 'text',
      onRender: (cellProps: CellProps, { data }: IDetailsColumnProp) => {
        if (cellProps.style && typeof cellProps.style === 'object') {
          const bgColor = generateBgColor(data.status)
          ;(cellProps.style as React.CSSProperties).background = bgColor
        }
      },
    },
    {
      name: 'feeType',
      header: 'FEE TYPE',
      defaultFlex: 1,
      type: 'text',
      onRender: (cellProps: CellProps, { data }: IDetailsColumnProp) => {
        if (cellProps.style && typeof cellProps.style === 'object') {
          const bgColor = generateBgColor(data.status)
          ;(cellProps.style as React.CSSProperties).background = bgColor
        }
      },
    },
    {
      name: 'amount',
      header: 'Amount',
      defaultFlex: 1,
      type: 'number',
      render: ({ data }: IDetailsColumnProp) => (data.amount ? `£${commonUtils.formatNumberWithCommas(data.amount)}` : '-'),
      onRender: (cellProps: CellProps, { data }: IDetailsColumnProp) => {
        if (cellProps.style && typeof cellProps.style === 'object') {
          const bgColor = generateBgColor(data.status)
          ;(cellProps.style as React.CSSProperties).background = bgColor
        }
      },
    },
    {
      name: 'completion',
      header: 'Completion / ...',
      defaultFlex: 1,
      type: 'date',
      onRender: (cellProps: CellProps, { data }: IDetailsColumnProp) => {
        if (cellProps.style && typeof cellProps.style === 'object') {
          const bgColor = generateBgColor(data.status)
          ;(cellProps.style as React.CSSProperties).background = bgColor
        }
      },
      render: ({ data }: IDetailsColumnProp) => (data.completionDate ? commonUtils.formatDate(data.completionDate) : '-'),
    },
  ]

  return columns.filter((column) => !column?.hide)
}
