import FormTable from 'components/FormTable/FormTable'
import { clientDetailsColumns, generateClientDetailsDataSource, policyDetailsColumns, generatePolicyDetailsDataSource } from './staticData'
import { StyledWrapper } from './QuoteInputTab.styles'
import { IQuickSearch } from 'interfaces/quickSearch.interface'

const QuoteInputTab = () => {
  const quickSearchData: IQuickSearch = JSON.parse(localStorage.getItem('quickSearch') || '{}')
  const { clientDetails } = quickSearchData
  const clientDetailsDataSource = generateClientDetailsDataSource(clientDetails.firstLife)
  const secondLifeDetailsDataSource = generateClientDetailsDataSource(clientDetails.secondLife)
  const policyDetailsDataSource = generatePolicyDetailsDataSource({ quickSearchData })

  return (
    <StyledWrapper>
      <FormTable
        title="Client details"
        columns={clientDetailsColumns}
        dataSource={clientDetailsDataSource}
        checkboxColumn={false}
        spacesAroundGrid={false}
        headerGridGap="16px"
      />
      {clientDetails.isSecondLife && (
        <FormTable
          title="Second Life details"
          columns={clientDetailsColumns}
          dataSource={secondLifeDetailsDataSource}
          checkboxColumn={false}
          spacesAroundGrid={false}
          headerGridGap="16px"
        />
      )}
      <FormTable
        title="Policy details"
        columns={policyDetailsColumns}
        dataSource={policyDetailsDataSource}
        checkboxColumn={false}
        spacesAroundGrid={false}
        headerGridGap="16px"
      />
    </StyledWrapper>
  )
}

export default QuoteInputTab
