import styled, { css } from 'styled-components'
import Form from 'antd/lib/form'
import Radio from 'antd/lib/radio'
import Input from 'antd/lib/input'
import InputNumber from 'antd/lib/input-number'
import Select from 'antd/lib/select'

export const Wrapper = styled.div<{ width?: string }>`
  position: relative;
  width: ${({ width }) => width};
`

export const StyledFieldWrapper = styled.div`
  .MuiSvgIcon-colorAction {
    position: absolute;
    top: 4px;
    right: 0;
    z-index: 2;
  }
`

export const StyledFormItem = styled(Form.Item)<{ asterisk?: boolean }>`
  .ant-form-item-label {
    label {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #120224;
    }
    ${(p) =>
      p.asterisk &&
      css`
        &::before {
          content: '*';
          color: #f5222d;
          padding: 3px;
          font-weight: 700;
        }
      `}
  }
`

export const StyledInput = styled(Input)<{
  width?: string | number
  height?: string | number
  required?: boolean
}>`
  ${({ width }) => width && `width: ${width}px;`}
  ${({ height }) => height && `height: ${height}px;`}
  padding: 12px 16px;
  background: #fefefe;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  font-size: 1rem;

  /* &:read-only {
    background-color: #ddd;
  } */

  /* .ant-input {
    background-color: #f3f3f3 !important;
  } */
`

export const StyledInputNumber = styled(InputNumber)<{
  width?: string | number
  height?: string | number
  required?: boolean
}>`
  ${({ width }) => width && `width: ${width}px;`}
  ${({ height }) => height && `height: ${height}px;`}
  padding: 12px 16px;
  background: #fefefe;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  font-size: 1rem;
`

export const StyledInputPassword = styled(Input.Password)<{
  width?: string | number
  required?: boolean
}>`
  ${({ width }) => width && `width: ${width}px;`}
  padding: 12px 16px;
  background: #fefefe;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  font-size: 1rem;
  .ant-input {
    background-color: transparent !important;
  }
`

export const StyledPrefixImg = styled.img<{ width?: number; height?: number }>`
  width: ${({ width }) => width && `${width}px`};
  height: ${({ height }) => height && `${height}px`};
`

export const StyledSelect = styled(Select)<{ width?: string; height?: string }>`
  width: ${({ width }) => width || '200px'};

  .ant-select-selector {
    /* background: #eaeaea !important; */
    border-radius: 4px !important;
    height: ${({ height }) => height || '48px'} !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
`

export const StyledRadioGroup = styled(Radio.Group)`
  color: #120224;
  display: flex;
  flex-wrap: wrap;
  gap: 2px;

  :active {
    color: #120224;
  }

  :hover {
    color: #120224;
  }

  .ant-radio-button-wrapper:first-child {
    border-start-start-radius: 4px;
    border-end-start-radius: 4px;
  }

  .ant-radio-button-wrapper:last-child {
    border-start-end-radius: 4px;
    border-end-end-radius: 4px;
  }

  .ant-radio-button-wrapper {
    background-color: #eaeaea;
  }

  .ant-radio-button-wrapper-checked {
    background-color: #38003c;
    border: #38003c;
    color: #fefefe;
    z-index: unset;

    :active {
      color: #fefefe;
    }

    :hover {
      color: #fefefe;
    }
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: transparent;
  }
`

export const StyledRadioButton = styled(Radio.Button)``
