import ConfirmModal from 'modals/ConfirmModal/ConfirmModal'
import ConfirmDeleteModal from 'modals/ConfirmDeleteModal/ConfirmDeleteModal'
import EditAssetModal from 'modals/EditAssetModal/EditAssetModal'
import EditLiabilityModal from 'modals/EditLiabilityModal/EditLiabilityModal'
import PersonPresentDuringFactFindModal from 'modals/PersonPresentDuringFactFindModal/PersonPresentDuringFactFindModal'
import PreviousAddressHistoryModal from 'modals/PreviousAddressHistoryModal/PreviousAddressHistoryModal'
import OccupantModal from 'modals/OccupantModal/OccupantModal'
import IncomeSourceModal from 'modals/IncomeSourceModal/IncomeSourceModal'
import FuturePensionModal from 'modals/FuturePensionModal/FuturePensionModal'
import BenefitReceivingModal from 'modals/BenefitReceivingModal/BenefitReceivingModal'
import EligibleBenefitModal from 'modals/EligibleBenefitModal/EligibleBenefitModal'
import ObjectiveModal from 'modals/ObjectiveModal/ObjectiveModal'
import BeneficiaryModal from 'modals/BeneficiaryModal/BeneficiaryModal'
import DependentModal from 'modals/DependentModal/DependentModal'
import RecipientModal from 'modals/RecipientModal/RecipientModal'
import ExpenditureModal from 'modals/ExpenditureModal/ExpenditureDetailsModal'
import { QuoteResultNoteModal } from 'modals/QuoteResultNoteModal'
import OtherResidentModal from 'modals/OtherResidentModal/OtherResidentModal'
import FileUploadModal from './FileUploadModal'
import IressDetailsModal from './IressDetailsModal'
import UserFileUploadModal from './UserFileUploadModal'

const Modals = () => {
  return (
    <>
      <ConfirmModal />
      <ConfirmDeleteModal />
      <EditAssetModal />
      <EditLiabilityModal />
      <PersonPresentDuringFactFindModal />
      <PreviousAddressHistoryModal />
      <OccupantModal />
      <IncomeSourceModal />
      <FuturePensionModal />
      <BenefitReceivingModal />
      <EligibleBenefitModal />
      <ObjectiveModal />
      <BeneficiaryModal />
      <DependentModal />
      <RecipientModal />
      <ExpenditureModal />
      <QuoteResultNoteModal />
      <OtherResidentModal />
      <FileUploadModal />
      <IressDetailsModal />
      <UserFileUploadModal />
    </>
  )
}

export default Modals
