import { whenWillExpenditureChangeOptions } from 'modals/ExpenditureModal/ExpenditureDetailsModal.helper'

export const calculateTotalMonthlyExpenditure = (expendituresDetails: any, otherLiabilities: any) => {
  const expenditure = expendituresDetails
    .filter((item: any) => item.expenditurePeriod === 'monthly')
    .map((expenditure: any) => ({
      monthlyExpenditure: expenditure.expenditureAmount,
      whenWillExpenditureChange: expenditure.whenWillExpenditureChange,
      futureMonthlyExpenditure: expenditure.expenditureWillChangeTo,
    }))

  const liabilityExpenditure = otherLiabilities.map((liability: any) => ({
    monthlyExpenditure: liability.monthlyCost,
    whenWillExpenditureChange: liability.monthlyCostChangePeriod,
    futureMonthlyExpenditure: liability.monthlyCostWillChangeTo,
  }))

  const concatExpenditure = expenditure?.concat(liabilityExpenditure)

  const dateIndexObj: { [key: string]: number } = {}

  // eslint-disable-next-line array-callback-return
  whenWillExpenditureChangeOptions?.map((item: any, index: number) => {
    dateIndexObj[item.value] = index
  })

  const initialIncomeTableDataSource = [{ when: 'Now', total: 0 }]

  const uniqueDatesWithEmptyTotal =
    concatExpenditure?.reduce((acc: any, current: any) => {
      initialIncomeTableDataSource[0].total += current?.monthlyExpenditure || 0
      if (current?.whenWillExpenditureChange) {
        const found = acc.find((item: any) => item.when === current.whenWillExpenditureChange)
        if (!found) {
          acc.push({ when: current.whenWillExpenditureChange, total: 0 })
        }
      }
      return acc
    }, []) || []

  const arrayWithCalculatedTotal = uniqueDatesWithEmptyTotal.map((item: any) => {
    let total = 0

    // eslint-disable-next-line array-callback-return
    concatExpenditure.map((source: any) => {
      if (!source.whenWillExpenditureChange) {
        total += source.monthlyExpenditure
      }

      if (dateIndexObj[source.whenWillExpenditureChange] <= dateIndexObj[item.when]) {
        total += source.futureMonthlyExpenditure
      }

      if (dateIndexObj[source.whenWillExpenditureChange] > dateIndexObj[item.when]) {
        total += source.monthlyExpenditure
      }
    })

    return {
      ...item,
      total,
    }
  })

  // eslint-disable-next-line array-callback-return
  arrayWithCalculatedTotal?.map((item: any) => {
    const { when, total } = item
    let indexToAdd
    // eslint-disable-next-line array-callback-return
    initialIncomeTableDataSource.map((initialData: any, index) => {
      if (dateIndexObj[initialData.when] > dateIndexObj[when]) {
        indexToAdd = index
      }
    })
    if (typeof indexToAdd !== 'number') {
      initialIncomeTableDataSource.splice(initialIncomeTableDataSource.length, 0, { when, total })
    } else {
      initialIncomeTableDataSource.splice(indexToAdd, 0, { when, total })
    }
  })

  const incomeTableDataSource = initialIncomeTableDataSource?.map((item: any) => {
    const when = item.when === 'Now' ? 'Now' : whenWillExpenditureChangeOptions?.find((date: any) => date.value === item.when)?.label

    return {
      when,
      total: item.total,
    }
  })

  return incomeTableDataSource
}
