import styled from 'styled-components'

export const StyledContainer = styled.div`
  background-color: #fefefe;
  box-shadow: 0px 0px 24px rgba(18, 2, 36, 0.08);

  display: flex;
  flex-direction: column;
`

export const StyledMinContainer = styled.div`
  margin: 32px 0;

  max-width: 100%;
  width: 872px;

  align-self: center;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const StyledTitle = styled.h3`
  margin-bottom: 16px;

  font-weight: 600;
  font-size: 18px;
  line-height: 26px;

  color: #120224;
`

export const StyledFormContainer = styled.div`
  width: 100%;

  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
