import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 24px;
  background: #e2ecf5;
`

export const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.5px;
  text-transform: uppercase;

  color: #120224;
`

export const StyledImg = styled.img``

export const StyledLastName = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #120224;

  margin: 24px 0 16px 0;
`

export const StyledName = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #120224;
`

export const StyledActionButton = styled.img`
  cursor: pointer;
`

export const StyledActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`
