interface Config {
  API_URL: string
  GOOGLE_API_KEY: string
  CONTENTFUL_SPACE_ID: string
  CONTENTFUL_ACCESS_TOKEN: string
  IRESS_INTEGRATOR_ID: string
  IRESS_BUSINESS_CONTRACT_ID: string
}

let config: Config = {
  API_URL: 'http://localhost:8080',
  GOOGLE_API_KEY: 'AIzaSyCI2Gls0jVhdFhGXhvtFbNVRGPM0qaqA2s',
  CONTENTFUL_SPACE_ID: 'w4npsreqcq9m',
  CONTENTFUL_ACCESS_TOKEN: 'LcdrEJdSeflq2uyA7bxfINI7bZAijZygKNlE_EZri44',
  IRESS_INTEGRATOR_ID: 'Premier Equity Release Club',
  IRESS_BUSINESS_CONTRACT_ID: 'PERC',
}

switch (process.env.REACT_APP_STAGE) {
  case 'development':
    config = {
      API_URL: 'https://apidev.premiererc.co.uk',
      GOOGLE_API_KEY: 'AIzaSyCI2Gls0jVhdFhGXhvtFbNVRGPM0qaqA2s',
      CONTENTFUL_SPACE_ID: 'w4npsreqcq9m',
      CONTENTFUL_ACCESS_TOKEN: 'LcdrEJdSeflq2uyA7bxfINI7bZAijZygKNlE_EZri44',
      IRESS_INTEGRATOR_ID: 'Premier Equity Release Club',
      IRESS_BUSINESS_CONTRACT_ID: 'PERC',
    }
    break
  case 'staging':
    config = {
      API_URL: 'https://apidev.premiererc.co.uk',
      GOOGLE_API_KEY: 'AIzaSyCI2Gls0jVhdFhGXhvtFbNVRGPM0qaqA2s',
      CONTENTFUL_SPACE_ID: 'w4npsreqcq9m',
      CONTENTFUL_ACCESS_TOKEN: 'LcdrEJdSeflq2uyA7bxfINI7bZAijZygKNlE_EZri44',
      IRESS_INTEGRATOR_ID: 'Premier Equity Release Club',
      IRESS_BUSINESS_CONTRACT_ID: 'PERC',
    }
    break
  case 'production':
    config = {
      API_URL: 'https://api.premiererc.co.uk',
      GOOGLE_API_KEY: 'AIzaSyCI2Gls0jVhdFhGXhvtFbNVRGPM0qaqA2s',
      CONTENTFUL_SPACE_ID: 'w4npsreqcq9m',
      CONTENTFUL_ACCESS_TOKEN: 'LcdrEJdSeflq2uyA7bxfINI7bZAijZygKNlE_EZri44',
      IRESS_INTEGRATOR_ID: 'Premier Equity Release Club',
      IRESS_BUSINESS_CONTRACT_ID: 'PERC',
    }
    break
  default:
    config = {
      API_URL: 'http://localhost:8080',
      GOOGLE_API_KEY: 'AIzaSyCI2Gls0jVhdFhGXhvtFbNVRGPM0qaqA2s',
      CONTENTFUL_SPACE_ID: 'w4npsreqcq9m',
      CONTENTFUL_ACCESS_TOKEN: 'LcdrEJdSeflq2uyA7bxfINI7bZAijZygKNlE_EZri44',
      IRESS_INTEGRATOR_ID: 'Premier Equity Release Club',
      IRESS_BUSINESS_CONTRACT_ID: 'PERC',
    }
    break
}

export default config
