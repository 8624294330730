import { useEffect, useState } from 'react'

import { useGetContentfulDataByContentModel } from 'hooks/useContentful'

const useRateChanges = () => {
  const [data, setData] = useState<any>([])
  const { data: initialData = [], isLoading } = useGetContentfulDataByContentModel('rateChanges')

  useEffect(() => {
    setData(
      initialData?.map((item: any, index: number) => {
        return {
          //   id: item.sys.id,
          id: `${index}`,
          title: item.fields.title,
          content: item.fields.content,
        }
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const displayLoading = isLoading || !data

  return {
    data,
    displayLoading,
  }
}

export default useRateChanges
