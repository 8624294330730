import { Modal } from 'antd'
import useQuoteResultNoteModal from 'hooks/clients/Modals/useQuoteResultNoteModal'
import { StyledButton, StyledDesc, StyledHeader, StyledImg, StyledNoteWrapper, StyledWrapper } from './QuoteResultNoteModal.styles'
import { CheckIcon } from 'assets/images'

const QuoteResultNoteModal = () => {
  const { modalState, handleCancelClick, handleConfirmClick } = useQuoteResultNoteModal()

  const FormattedText = ({ content }: any) => {
    // This function will convert HTML entities back to their corresponding characters
    const decodeHtmlEntities = (text: any) => {
      return text.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
    }

    const createMarkup = (htmlContent: any) => {
      const decodedContent = decodeHtmlEntities(htmlContent)
      return { __html: decodedContent }
    }

    return <div dangerouslySetInnerHTML={createMarkup(content)} />
  }

  return (
    <Modal
      title="Note"
      open={modalState.isOpen}
      onOk={handleConfirmClick}
      onCancel={handleCancelClick}
      width={700}
      okText="Save"
      closable={true}
      className="edit-asset-modal"
      footer={[
        <StyledButton onClick={handleCancelClick} key="random_key">
          <StyledImg src={CheckIcon} /> <p>Got it</p>
        </StyledButton>,
      ]}
    >
      <StyledWrapper>
        {modalState.data?.body.map((note: any, index) => (
          <StyledNoteWrapper key={note.note_heading[0] + index}>
            <StyledHeader>{note.note_heading[0]}</StyledHeader>
            {note.notes_text[0].note_text.map((text: any, index: number) => (
              <StyledDesc key={index}>
                <FormattedText content={text} />
              </StyledDesc>
            ))}
          </StyledNoteWrapper>
        ))}
      </StyledWrapper>
    </Modal>
  )
}

export default QuoteResultNoteModal
