import styled from 'styled-components'

export const StyledWrapper = styled.div`
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const StyledBinImg = styled.img`
  width: 38px;
  height: 38px;
`

export const StyledLabelWrapper = styled.h1`
  width: 440px;

  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #120224;

  margin-top: 32px;
`
