import { useState } from 'react'
import message from 'antd/lib/message'
import { useNavigate } from 'react-router-dom'

import { commonHelper } from 'helpers'
import { useCustomMutation } from 'services/shared/mutation'
import { ResetPasswordWithTokenPayload } from 'services/shared/mutation.payload'
import { resetPasswordWithTokenMutation } from 'gql/user'

const useResetPassword = () => {
  const resetPassword = useCustomMutation<ResetPasswordWithTokenPayload>({ mutation: resetPasswordWithTokenMutation })
  const [loading, setLoading] = useState(false)
  const urlParams = new URLSearchParams(window.location.search)
  const resetToken = urlParams.get('resetToken') || ''
  const navigate = useNavigate()

  const handleSubmit = async ({ newPassword, confirmPassword }: { newPassword: string; confirmPassword: string }): Promise<void> => {
    try {
      setLoading(true)

      if (!newPassword || !confirmPassword) {
        message.info('Please fill in your new password.')
        setLoading(false)
        return
      }

      if (newPassword !== confirmPassword) {
        message.info('Passwords do not match. Please try again.')
        setLoading(false)
        return
      }

      const { errors } = await resetPassword({
        resetToken,
        newPassword,
        confirmPassword,
      })

      if (errors?.length) {
        setLoading(false)
        const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
        message.error(errMessage)
        return
      }

      message.success('Password reset successfully. Please login.')
      navigate('/')

      setLoading(false)
    } catch (err) {
      // pass.
    }
  }

  return {
    handleSubmit,
    loading,
  }
}

export default useResetPassword
