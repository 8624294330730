import { Form } from 'antd'
import TextInput from 'components/CustomFormItem'
import {
  CheckboxesWrapper,
  CheckBoxWithLabel,
  CustomCheckbox,
  StyledBreakLine,
  StyledCheckboxLabel,
  StyledCheckboxesContainer,
  StyledContainer,
  StyledFieldsContainer,
  StyledInnerContainer,
  StyledLabel,
  StyledLabelsContainer,
  StyledTabTitle,
} from './JourneyTabContent.styles'
import { recommendFieldsData, journeyDatesFields } from './staticData'
import useJourneyTabContent from 'hooks/clients/useJourneyTabContent'
import CustomFooter from 'pages/ClientDetails/CustomFooter/CustomFooter'
import Loading from 'components/Loading/Loading'

const JourneyTabContent = () => {
  const {
    loading,
    setSaveType,
    onSubmit,
    handleJourneyFormFieldChange,
    journeyFormData,
    handleCheckboxChange,
    handleTrackingRecommendFormFieldChange,
    trackingRecommendFormData,
    formInitialValues,
    keys,
  } = useJourneyTabContent()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledInnerContainer>
        <StyledLabelsContainer>
          <StyledTabTitle>Information</StyledTabTitle>
          <StyledBreakLine />
          {journeyDatesFields.map((item: any) => (
            <StyledLabel key={item.key}>{item.label}</StyledLabel>
          ))}
          <div style={{ height: '52px' }}></div>
          {recommendFieldsData.map((item: any) => (
            <StyledLabel key={item.key}>{item.label}</StyledLabel>
          ))}
        </StyledLabelsContainer>
        <div>
          <StyledFieldsContainer>
            <StyledTabTitle>Date Completed</StyledTabTitle>
            <StyledBreakLine />
            <Form
              layout="vertical"
              size="large"
              name="basic"
              onChange={handleJourneyFormFieldChange}
              initialValues={formInitialValues}
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '12px',
                marginTop: '8px',
              }}
            >
              {journeyDatesFields.map((item: any) => (
                <TextInput key={item.key} name={item.name} placeholder="DD/MM/YYYY" type="date" $inputStyle={{ height: '48px' }} />
              ))}
            </Form>
          </StyledFieldsContainer>
          <StyledFieldsContainer>
            <Form
              layout="vertical"
              size="large"
              name="basic"
              onChange={handleTrackingRecommendFormFieldChange}
              initialValues={trackingRecommendFormData}
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '12px',
                marginTop: '64px',
              }}
            >
              {recommendFieldsData.map((item: any) => (
                <TextInput key={item.key} name={item.name} placeholder={item.label} $inputStyle={{ height: '48px' }} />
              ))}
            </Form>
          </StyledFieldsContainer>
        </div>
        <StyledCheckboxesContainer>
          <StyledTabTitle>Copy On file</StyledTabTitle>
          <StyledBreakLine />
          {keys.map((key: any, index: number) => {
            const yesValue = journeyFormData[key].copyOnFile === true ? true : undefined
            const noValue = journeyFormData[key].copyOnFile === false ? true : undefined

            return (
              <CheckboxesWrapper key={index}>
                <CheckBoxWithLabel>
                  <CustomCheckbox onChange={handleCheckboxChange} checked={yesValue} name={`${key}_Yes`} />
                  <StyledCheckboxLabel>Yes</StyledCheckboxLabel>
                </CheckBoxWithLabel>
                <CheckBoxWithLabel>
                  <CustomCheckbox onChange={handleCheckboxChange} checked={noValue} name={`${key}_No`} />
                  <StyledCheckboxLabel>No</StyledCheckboxLabel>
                </CheckBoxWithLabel>
              </CheckboxesWrapper>
            )
          })}
        </StyledCheckboxesContainer>
        <Form onFinish={onSubmit}>
          <CustomFooter loading={loading} setSaveType={setSaveType} />
        </Form>
      </StyledInnerContainer>
    </StyledContainer>
  )
}

export default JourneyTabContent
