import { Form } from 'antd'

import Loading from 'components/Loading'
import CustomFormItem from 'components/CustomFormItem'
import FormTable from 'components/FormTable/FormTable'

import useBenefits from 'hooks/clients/DetailedForms/useBenefits'
import CustomFooter from 'pages/ClientDetails/CustomFooter/CustomFooter'
import { yesNoOptions } from 'utils/commonOptions'

import { benefitsTableColumns, eligibleBenefitsTableColumns, givenCustomerGuidanceOptions } from './Benefits.helper'
import { StyledContainer, StyledTitle, StyledTitleContainer, StyledRequiredMsg, StyledBenefitsHeader } from './Benefits.styles'

const Benefits = () => {
  const {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    handleAntdFormItemChange,
    handleAddBenefits,
    benefitsDataSource,
    benefitsTotalValue,
    setSelectedBenefitsRows,
    displayDeleteBenefitsButton,
    handleDeleteBenefitsModal,
    handleAddEligibleBenefit,
    eligibleBenefitsDataSource,
    eligibleBenefitsTotalValue,
    setSelectedEligibleBenefitsRows,
    displayDeleteEligibleBenefitsButton,
    handleDeleteEligibleBenefitsModal,
    displayBenefitEditButton,
    handleBenefitEditModal,
    displayEligibleBenefitEditButton,
    handleEligibleBenefitEditModal,
  } = useBenefits()

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <StyledTitle>Benefits</StyledTitle>
        <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
      </StyledTitleContainer>
      <Form
        layout="vertical"
        size="large"
        name="basic"
        initialValues={formData}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '12px',
          marginTop: '8px',
          width: '100%',
        }}
        onFinishFailed={onFinishFailed}
        onFinish={onSubmit}
      >
        <a
          rel="noreferrer"
          target="_blank"
          href={'https://www.gov.uk/browse/benefits'}
          style={{
            textDecoration: 'underline',
          }}
        >
          Please carry out a benefit check before completing this section
        </a>
        <CustomFormItem
          label="Are you receiving any state benefits? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="isReceivingAnyStateBenefits"
          value={formData.isReceivingAnyStateBenefits}
          type="radio"
          rules={['isRequired']}
        />
        <StyledBenefitsHeader>State Benefits you receive</StyledBenefitsHeader>
        <FormTable
          columns={benefitsTableColumns}
          dataSource={benefitsDataSource}
          title="Benefits"
          addButtonText="Add New Benefit"
          handleAddButton={handleAddBenefits}
          total={benefitsTotalValue}
          totalText="Total Benefits"
          setSelectedRows={setSelectedBenefitsRows}
          displayDeleteButton={displayDeleteBenefitsButton}
          handleDeleteModal={handleDeleteBenefitsModal}
          displayEditButton={displayBenefitEditButton}
          handleEditModal={handleBenefitEditModal}
        />
        <CustomFormItem
          label="Have you carried out a State Benefits Check as part of this fact find? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="hasCarriedOutStateBenefitsCheck"
          value={formData.hasCarriedOutStateBenefitsCheck}
          type="radio"
          rules={['isRequired']}
        />
        {formData.hasCarriedOutStateBenefitsCheck && (
          <>
            <StyledBenefitsHeader>State Benefits Customer/s May Be Eligible to Claim (based on Benefits Check)</StyledBenefitsHeader>
            <FormTable
              columns={eligibleBenefitsTableColumns}
              dataSource={eligibleBenefitsDataSource}
              title="Eligible Benefits"
              addButtonText="Add New Benefit"
              handleAddButton={handleAddEligibleBenefit}
              total={eligibleBenefitsTotalValue}
              totalText="Total Eligible Benefits"
              setSelectedRows={setSelectedEligibleBenefitsRows}
              displayDeleteButton={displayDeleteEligibleBenefitsButton}
              handleDeleteModal={handleDeleteEligibleBenefitsModal}
              displayEditButton={displayEligibleBenefitEditButton}
              handleEditModal={handleEligibleBenefitEditModal}
            />
          </>
        )}
        {formData.hasCarriedOutStateBenefitsCheck ? (
          <CustomFormItem
            label="Will you be issuing customer/s with details of the benefits they may be eligible to claim?"
            onChange={handleAntdFormItemChange}
            options={yesNoOptions}
            name="issuesCustomerBenefits"
            value={formData.issuesCustomerBenefits}
            type="radio"
          />
        ) : (
          <CustomFormItem
            label="Guidance given to customer regarding State Benefits"
            onChange={handleAntdFormItemChange}
            options={givenCustomerGuidanceOptions}
            name="givenCustomerGuidance"
            value={formData.givenCustomerGuidance}
            type="radio"
          />
        )}
        <CustomFooter loading={loading} setSaveType={setSaveType} />
      </Form>
    </StyledContainer>
  )
}

export default Benefits
