import config from 'config'
import { useEffect, useState } from 'react'
import { useGetUserIressDetails } from 'services/auth/auth.service'

const QuoteSearchForm = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const testing = urlParams.get('testing')
  const transactionNumber = String(urlParams.get('transactionNumber'))
  const { user: userIressDetails, loading } = useGetUserIressDetails({ skip: false })
  // Initialize form state with default and provided values
  const [formData, setFormData] = useState({
    environment: process.env.REACT_APP_STAGE, // Set 'dev' as default
    userCode: userIressDetails.iressUserId,
    userName: userIressDetails.iressLoginId,
    password: userIressDetails.iressPassword,
    transactionNumber: transactionNumber,
    autoLaunch: 'pqsereq:auto_launch_transaction="true"',
    integrator: config.IRESS_INTEGRATOR_ID,
    businessContract: config.IRESS_BUSINESS_CONTRACT_ID,
  })
  const actionURL =
    formData.environment === 'production'
      ? 'https://exweb.exchange.uk.com/Private/Elf/Public/elfExwebResponder.aspx'
      : 'https://ifapreview.exchange.uk.com/Private/Elf/Public/elfExwebResponder.aspx'

  useEffect(() => {
    if (!loading && testing === 'false') {
      handleSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const handleChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = () => {
    const authXML = `<epreq:exweb_portal xmlns:epreq="http://www.exchange.co.uk/schema/ExwebPortalRequest/v1"><epreq:token><wsse:Security xmlns:wsse="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-secext-1.0.xsd" xmlns:wsu="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-utility-1.0.xsd"><wsu:Timestamp><wsu:Created>2024-01-13T19:04:30.3182</wsu:Created><wsu:Expires>2024-01-13T19:15:30.3182</wsu:Expires></wsu:Timestamp><wsse:UsernameToken><wsse:Username>${userIressDetails.iressLoginId}</wsse:Username><wsse:Password>${userIressDetails.iressPassword}</wsse:Password><wsu:Created>2024-01-13T19:04:30.3182</wsu:Created></wsse:UsernameToken></wsse:Security></epreq:token><epreq:service_id>PerformQuoteSearchExtranetV1</epreq:service_id></epreq:exweb_portal>`
    const xmlData = `<?xml version="1.0" encoding="utf-8" ?>\n<exchange_services xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns="http://www.exchange.co.uk/schema/ExchangeServicesRequest/v2">\n<header>\n<message_timestamp>2024-01-13T19:04:30.3182</message_timestamp>\n<message_id>ac0a3a82-98d9-4a08-9d22-b281518226f6</message_id>\n<message_schema>/exchange/exchangeservices/2.0/ExchangeServicesRequestV2.xsd</message_schema>\n<service_id>PerformQuoteSearchExtranetV1</service_id>\n<integrator_id>${formData.integrator}</integrator_id>\n<orchestration_id>f6350e1a-8924-4c20-9906-49cfb10d7d61</orchestration_id>\n<business_contract_id>${formData.businessContract}</business_contract_id>\n<user_id>${userIressDetails.iressUserId}</user_id>\n<payload_provider>Exchange Services</payload_provider>\n<payload_schema>/exchange/exchangeservices/1.1/PerformQuoteSearchExtranetRequestV1.xsd</payload_schema>\n<service_provider_id>Exchange Services</service_provider_id>\n</header>\n<payload>\n<pqsereq:perform_quotesearch_extranet_request xmlns:pqsereq="http://www.exchange.co.uk/schema/PerformQuoteSearchExtranetRequest/v1">\n<pqsereq:transaction_number ${formData.autoLaunch}>${formData.transactionNumber}</pqsereq:transaction_number>\n</pqsereq:perform_quotesearch_extranet_request>\n</payload>\n</exchange_services>`
    // Pre-submit actions like validation can be added here
    const form = document.createElement('form')
    // form.target = '_blank'
    form.method = 'post'
    form.action = actionURL

    const authInput = document.createElement('textarea')
    authInput.name = 'auth'
    authInput.value = authXML
    form.appendChild(authInput)

    const xmlDataInput = document.createElement('textarea')
    xmlDataInput.name = 'xmldata'
    xmlDataInput.value = xmlData
    form.appendChild(xmlDataInput)

    document.body.appendChild(form)
    form.submit()
    document.body.removeChild(form)
  }

  if (loading) return <div>Loading...</div>

  if (testing === 'false') return <div>Submitting...</div>

  return (
    <div style={{ fontFamily: 'Verdana', backgroundColor: '#C7BFCB', padding: '20px' }}>
      <form onSubmit={handleSubmit}>
        <div>
          <p>Environment</p>
          <select name="environment" value={formData.environment} onChange={handleChange}>
            <option value="development">Test</option>
            <option value="production">Live</option>
          </select>
        </div>
        <div>
          <p>User code</p>
          <input type="text" name="userCode" value={formData.userCode} onChange={handleChange} />
        </div>
        <div>
          <p>User name</p>
          <input type="text" name="userName" value={formData.userName} onChange={handleChange} />
        </div>
        <div>
          <p>Password</p>
          <input type="password" name="password" value={formData.password} onChange={handleChange} />
        </div>
        <div>
          <p>Transaction ID</p>
          <input type="text" name="transactionNumber" value={formData.transactionNumber} onChange={handleChange} />
        </div>
        <div>
          <p>Open transaction directly</p>
          <select name="autoLaunch" value={formData.autoLaunch} onChange={handleChange}>
            <option value='pqsereq:auto_launch_transaction="true"'>True</option>
            <option value='pqsereq:auto_launch_transaction="false"'>False</option>
          </select>
        </div>
        <div>
          <p>Integrator</p>
          <input type="text" name="integrator" value={formData.integrator} onChange={handleChange} />
        </div>
        <div>
          <p>Business Contract</p>
          <input type="text" name="businessContract" value={formData.businessContract} onChange={handleChange} />
        </div>
        <button
          type="submit"
          className="submit"
          style={{ backgroundColor: '#3A1C46', color: 'white', marginTop: '20px', padding: '10px 20px', border: 'none', cursor: 'pointer' }}
        >
          Start quote search
        </button>
      </form>
    </div>
  )
}

export default QuoteSearchForm
