const validateEmailHandler = (_: unknown, value: string) => {
  // Custom email validation logic
  if (value && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/.test(value)) {
    return Promise.reject('Please enter a valid email address!')
  }
  return Promise.resolve()
}

const validateTelephoneNumberHandler = (_: unknown, value: string) => {
  if (value) {
    // Regular expression to check if the input might be a valid telephone number
    const telephonePattern = /^\+?[0-9\s]+$/

    // Remove spaces from the input value before validation
    const sanitizedValue = value?.replace(/\s/g, '')

    // Check if the input matches the telephone pattern
    if (!telephonePattern.test(sanitizedValue)) {
      return Promise.reject('Please enter a valid telephone number!')
    }
  }

  return Promise.resolve()
}

const isRequired = {
  required: true,
  message: 'Please complete this required field.',
}

const validateEmail = {
  validator: validateEmailHandler,
}

const validateTelephoneNumber = {
  validator: validateTelephoneNumberHandler,
}

const selectOption = {
  required: true,
  message: 'Please select an option from the dropdown menu.',
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  isRequired,
  selectOption,
  validateEmail,
  validateTelephoneNumber,
}
