/* eslint-disable no-useless-computed-key */
import { Form } from 'antd'

import Loading from 'components/Loading'
import FormTable from 'components/FormTable/FormTable'
import CustomFormItem from 'components/CustomFormItem/CustomFormItem'

import CustomFooter from 'pages/ClientDetails/CustomFooter/CustomFooter'
import useIncome from 'hooks/clients/DetailedForms/useIncome'
import { PoundIcon } from 'assets/images'
import { yesNoOptions } from 'utils/commonOptions'

import { employmentStatusOptions, incomeTableColumns, pensionsTableColumns } from './Income.helper'
import { StyledContainer, StyledDescription, StyledTitle, StyledTitleContainer, StyledRequiredMsg, StyledLink } from './Income.styles'

const Income = () => {
  const {
    onFinishFailed,
    onSubmit,
    setSaveType,
    loading,
    formData,
    flattenedFormData,
    handleAntdFormItemChange,
    handleCustomSelectChange,
    handleAddIncomeSource,
    incomesDataSource,
    displayDeleteIncomeSourceBtn,
    setIncomeSourceSelectedRows,
    handleDeleteIncomeSourceModal,
    handleAddFuturePension,
    pensionsDataSource,
    displayDeletePensionsBtn,
    setPensionsSelectedRows,
    handlePensionsModal,
    displayIncomeSourceEditButton,
    handleIncomeSourceEditModal,
    displayFuturePensionEditButton,
    handleFuturePensionEditModal,
    customers,
  } = useIncome()

  const displayCustomerFirstRetireField =
    formData.customerFirst.employmentStatus === 'employed' ||
    formData.customerFirst.employmentStatus === 'selfEmployed' ||
    formData.customerFirst.employmentStatus === 'other'
  const displayCustomerSecondRetireField =
    formData.customerSecond.employmentStatus === 'employed' ||
    formData.customerSecond.employmentStatus === 'selfEmployed' ||
    formData.customerSecond.employmentStatus === 'other'

  if (loading) {
    return <Loading />
  }

  return (
    <StyledContainer>
      <StyledTitleContainer>
        <StyledTitle>Income</StyledTitle>
        <StyledRequiredMsg>* For mandatory information</StyledRequiredMsg>
      </StyledTitleContainer>
      <StyledDescription>
        You are able to enter income for your customers on this page. This section is able to be skipped in order to get to the sourcing results page, however
        if you would like a Suitability Report for your customers, then you will need to ensure this section is completed.
      </StyledDescription>
      <Form
        layout="vertical"
        size="large"
        name="basic"
        initialValues={flattenedFormData}
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '12px',
          marginTop: '8px',
          width: '100%',
        }}
        onFinishFailed={onFinishFailed}
        onFinish={onSubmit}
      >
        <FormTable
          columns={incomeTableColumns}
          dataSource={incomesDataSource}
          title="Income"
          addButtonText="Add New Income"
          handleAddButton={handleAddIncomeSource}
          setSelectedRows={setIncomeSourceSelectedRows}
          displayDeleteButton={displayDeleteIncomeSourceBtn}
          handleDeleteModal={handleDeleteIncomeSourceModal}
          displayEditButton={displayIncomeSourceEditButton}
          handleEditModal={handleIncomeSourceEditModal}
        />
        <StyledLink href="https://www.thesalarycalculator.co.uk/salary.php" target="_blank">
          This link will help you work out your clients take home pay after deductions
        </StyledLink>
        <CustomFormItem
          label="Do you have any pensions that you are not taking yet? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="customerFirst.hasPensionAndNotTaking"
          value={formData.customerFirst.hasPensionAndNotTaking}
          type="radio"
          rules={['isRequired']}
        />
        {formData.customerFirst.hasPensionAndNotTaking && (
          <FormTable
            columns={pensionsTableColumns}
            dataSource={pensionsDataSource}
            title="Future Pensions"
            addButtonText="Add New Future Pension"
            handleAddButton={handleAddFuturePension}
            setSelectedRows={setPensionsSelectedRows}
            displayDeleteButton={displayDeletePensionsBtn}
            handleDeleteModal={handlePensionsModal}
            displayEditButton={displayFuturePensionEditButton}
            handleEditModal={handleFuturePensionEditModal}
          />
        )}
        <StyledTitle style={{ marginTop: '40px' }}>Income Details of {customers?.customerFirst?.firstName}</StyledTitle>
        <CustomFormItem
          label="What is your employment status? *"
          width={'100%'}
          placeholder="E.g will be shown here"
          onChange={handleCustomSelectChange}
          options={employmentStatusOptions}
          name="customerFirst.employmentStatus"
          type="select"
          rules={['isRequired']}
        />
        {displayCustomerFirstRetireField && (
          <CustomFormItem
            label="When Are you going to retire? *"
            width={'100%'}
            placeholder="E.g will be shown here"
            onChange={handleAntdFormItemChange}
            name="customerFirst.whenAreYouGoingToRetire"
            rules={['isRequired']}
          />
        )}
        {customers?.customerSecond?.firstName && (
          <CustomFormItem
            label="How would the family's income be affected in the event of your death? *"
            width={'100%'}
            placeholder="E.g will be shown here"
            onChange={handleAntdFormItemChange}
            name="customerFirst.customerIncomeFateInEventOfTheirDeath"
            rules={['isRequired']}
          />
        )}
        <CustomFormItem
          label="Are you happy with your current levels of income? *"
          onChange={handleAntdFormItemChange}
          options={yesNoOptions}
          name="customerFirst.isHappyWithCurrentIncome"
          value={formData.customerFirst.isHappyWithCurrentIncome}
          type="radio"
          rules={['isRequired']}
        />
        {formData.customerFirst.isHappyWithCurrentIncome === false && (
          <CustomFormItem
            onChange={handleAntdFormItemChange}
            label="How much additional monthly income do you require?"
            name="customerFirst.requiredAdditionalMonthlyIncome"
            placeholder="How much additional monthly income do you require?"
            inputPrefixImg={PoundIcon}
            prefixImgHeight={12}
            type="number"
          />
        )}

        {customers?.customerSecond?.firstName && (
          <>
            <StyledTitle style={{ marginTop: '40px' }}>Income Details of {customers?.customerSecond?.firstName}</StyledTitle>
            <CustomFormItem
              label="What is your employment status? *"
              width={'100%'}
              placeholder="E.g will be shown here"
              onChange={handleCustomSelectChange}
              options={employmentStatusOptions}
              name="customerSecond.employmentStatus"
              type="select"
              rules={['isRequired']}
            />
            {displayCustomerSecondRetireField && (
              <CustomFormItem
                label="When Are you going to retire? *"
                width={'100%'}
                placeholder="E.g will be shown here"
                onChange={handleAntdFormItemChange}
                name="customerSecond.whenAreYouGoingToRetire"
                rules={['isRequired']}
              />
            )}
            <CustomFormItem
              label="How would the family's income be affected in the event of your death? *"
              width={'100%'}
              placeholder="E.g will be shown here"
              onChange={handleAntdFormItemChange}
              name="customerSecond.customerIncomeFateInEventOfTheirDeath"
              rules={['isRequired']}
            />
            <CustomFormItem
              label="Are you happy with your current levels of income? *"
              onChange={handleAntdFormItemChange}
              options={yesNoOptions}
              name="customerSecond.isHappyWithCurrentIncome"
              value={formData.customerSecond.isHappyWithCurrentIncome}
              type="radio"
              rules={['isRequired']}
            />
            {formData.customerSecond.isHappyWithCurrentIncome === false && (
              <CustomFormItem
                onChange={handleAntdFormItemChange}
                label="How much additional monthly income do you require?"
                name="customerSecond.requiredAdditionalMonthlyIncome"
                placeholder="How much additional monthly income do you require?"
                inputPrefixImg={PoundIcon}
                prefixImgHeight={12}
                type="number"
              />
            )}
          </>
        )}
        <CustomFooter loading={loading} setSaveType={setSaveType} />
      </Form>
    </StyledContainer>
  )
}

export default Income
