import { message } from 'antd'
import Form from 'antd/lib/form'
import { addEditIncomeSourceMutation } from 'gql/client'
import { commonHelper } from 'helpers'
import { useState, useEffect } from 'react'
import { useGetCustomers, useGetIncomeSource } from 'services/clients/clients.service'
import { useCustomMutation } from 'services/shared/mutation'
import { AddEditIncomeSourcePayload } from 'services/shared/mutation.payload'

import { commonUtils, eventManager } from 'utils'
import { getCustomersOptions } from 'utils/getCustomersOptions'

const initialState = {
  isOpen: false,
  data: { _id: '', isEdit: false },
  cb: { confirm: () => null, cancel: () => null },
  loading: false,
}

const useIncomeSourceModal = () => {
  const [form] = Form.useForm()
  const addEditIncomeSource = useCustomMutation<AddEditIncomeSourcePayload>({
    mutation: addEditIncomeSourceMutation,
    refetchQueries: ['getIncomeSourcesForCalculation'],
  })
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const [modalState, setModalState] = useState(initialState)
  const incomeSourceId = modalState?.data?._id
  const { incomeSource, loading: initialDataLoading } = useGetIncomeSource({ idNumber, incomeSourceId, skip: !modalState.isOpen || !modalState.data.isEdit })
  const [loading, setLoading] = useState(initialDataLoading)
  const [formData, setFormData] = useState({
    incomeOwner: incomeSource?.incomeOwner,
    incomeType: incomeSource?.incomeType,
    monthlyNetIncome: incomeSource?.monthlyNetIncome,
    annualGross: incomeSource?.annualGross,
    willIncomeChange: incomeSource?.willIncomeChange,
    whenWillIncomeChange: incomeSource?.whenWillIncomeChange,
    incomeWillChangeTo: incomeSource?.incomeWillChangeTo,
    description: incomeSource?.description,
  })

  const { data: customersData } = useGetCustomers(idNumber)

  const customersOptions = getCustomersOptions(customersData, true)

  useEffect(() => {
    setLoading(initialDataLoading)
    const newData = commonUtils.removeTypeNameFromObject(incomeSource)
    delete newData._id
    setFormData({ ...newData })
    form.setFieldsValue({ ...newData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDataLoading])

  const clearFormData = () => {
    setFormData((prevState) => commonUtils.resetFormData(prevState))
    form.setFieldsValue(commonUtils.resetFormData(formData))
  }

  const handleAntdFormItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = commonUtils.parseValue(e.target?.value, e.target?.name)
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: parsedValue,
    }))
  }

  const handleCustomSelectChange = (name: string, value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  useEffect(() => {
    eventManager.addListener('income-source-modal', (data: any, cb: any) => {
      setModalState((state) => ({
        ...state,
        isOpen: data !== undefined,
        data,
        cb,
      }))
    })
  }, [setModalState])

  const handleClose = () => {
    setModalState((state) => ({ ...state, loading: false }))
    eventManager.closeIncomeSourceModal()
  }

  const handleCancelClick = async () => {
    try {
      setModalState((state) => ({ ...state, loading: true }))
      if (modalState.cb.cancel) {
        await modalState.cb.cancel()
      }
    } catch (err) {
      // console.error(err)
    }
    handleClose()
    clearFormData()
  }

  const handleSubmit = async () => {
    const { errors } = await addEditIncomeSource({
      idNumber,
      incomeSourceToEdit: incomeSourceId,
      values: {
        ...formData,
        monthlyNetIncome: Number(formData.monthlyNetIncome),
        annualGross: Number(formData.annualGross),
        incomeWillChangeTo: Number(formData.incomeWillChangeTo),
      },
    })
    if (errors?.length) {
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Operation finished successfully')
  }

  const handleConfirmClick = () => {
    form.validateFields().then(async (values) => {
      try {
        setModalState((state) => ({ ...state, loading: true }))
        await handleSubmit()
        clearFormData()
        if (modalState.cb.confirm) {
          await modalState.cb.confirm()
        }
      } catch (err) {
        // console.error(err)
      }
      setModalState((state) => ({ ...state, loading: false }))
      handleClose()
    })
  }

  return {
    modalState,
    handleCancelClick,
    handleConfirmClick,
    formData,
    handleAntdFormItemChange,
    handleCustomSelectChange,
    loading,
    form,
    customersOptions,
  }
}

export default useIncomeSourceModal
