export const purposeOfRaisingCapitalOptions = [
  {
    label: 'An extra monthly income',
    value: 'anExtraMonthlyIncome',
  },
  {
    label: 'Gifting',
    value: 'gifting',
  },
  {
    label: 'Home / Garden Improvement',
    value: 'homeGardenImprovement',
  },
  {
    label: 'Care fees',
    value: 'careFees',
  },
  {
    label: 'Essential Repairs',
    value: 'essentialRepairs',
  },
  {
    label: 'Holidays',
    value: 'holidays',
  },
  {
    label: 'Debts',
    value: 'debts',
  },
  {
    label: 'Mobility aids',
    value: 'mobilityAids',
  },
  {
    label: 'Estate Planning',
    value: 'estatePlanning',
  },
  {
    label: 'Property Purchase',
    value: 'propertyPurchase',
  },
  {
    label: 'Vehicle',
    value: 'vehicle',
  },
  {
    label: 'Set Up Fees (Adviser / Solicitor etc)',
    value: 'setUpFees',
  },
  {
    label: 'Other purpose',
    value: 'other',
  },
]

export const fundsRequiredInOptions = [
  {
    label: 'Now',
    value: 'now',
  },
  {
    label: 'Next 1 to 2 years',
    value: 'nextOneToTwoYears',
  },
  {
    label: 'Next 2 to 3 years',
    value: 'nextTwoToThreeYears',
  },
  {
    label: 'Next 3 to 4 years',
    value: 'nextThreeToFourYears',
  },
  {
    label: 'Next 5 to 10 years',
    value: 'nextFiveToTenYears',
  },
  {
    label: 'In 10 years or later',
    value: 'inTenYearsOrLater',
  },
]
