import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100% !important;
  margin-top: 28px;
  width: max-content;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledLabel = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #120224;
`;
