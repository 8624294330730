export const yesNoOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
]

export const tenureTypeOptions = [
  {
    label: 'Freehold',
    value: 'Freehold',
  },
  {
    label: 'Leasehold',
    value: 'Leasehold',
  },
]

export const propertyTypeOptions = [
  {
    label: 'House Detached',
    value: 'House Detached',
  },
  {
    label: 'House Semi Detached',
    value: 'House Semi Detached',
  },
  {
    label: 'House End Terrace',
    value: 'House End Terrace',
  },
  {
    label: 'House Mid Terrace',
    value: 'House Mid Terrace',
  },
  {
    label: 'Flat Purpose Built',
    value: 'Flat Purpose Built',
  },
  {
    label: 'Flat Studio Purpose Built',
    value: 'Flat Studio Purpose Built',
  },
  {
    label: 'Flat Converted',
    value: 'Flat Converted',
  },
  {
    label: 'Flat Studio Converted',
    value: 'Flat Studio Converted',
  },
  {
    label: 'Bungalow Detached',
    value: 'Bungalow Detached',
  },
  {
    label: 'Bungalow Semi Detached',
    value: 'Bungalow Semi Detached',
  },
  {
    label: 'Bungalow End Terrace',
    value: 'Bungalow End Terrace',
  },
  {
    label: 'Bungalow Mid Terrace',
    value: 'Bungalow Mid Terrace',
  },
  {
    label: 'Maisonette Purpose Built',
    value: 'Maisonette Purpose Built',
  },
  {
    label: 'Maisonette Converted',
    value: 'Maisonette Converted',
  },
]

export const productResearchOptions = [
  {
    label: 'Whole of market',
    value: 'Whole Of Market',
  },
  {
    label: 'Panel of providers',
    value: 'Panel',
  },
]

export const titleOptions = [
  {
    label: 'MR',
    value: 'Mr',
  },
  {
    label: 'MRS',
    value: 'Mrs',
  },
  {
    label: 'MISS',
    value: 'Miss',
  },
  {
    label: 'MS',
    value: 'Ms',
  },
  {
    label: 'DR',
    value: 'Dr',
  },
  {
    label: 'SIR',
    value: 'Sir',
  },
  {
    label: 'LORD',
    value: 'Lord',
  },
  {
    label: 'LADY',
    value: 'Lady',
  },
]

export const genderOptions = [
  {
    label: 'MALE',
    value: 'Male',
  },
  {
    label: 'FEMALE',
    value: 'Female',
  },
]

export const locationOptions = [
  {
    label: 'England',
    value: 'England',
  },
  {
    label: 'Wales',
    value: 'Wales',
  },
  {
    label: 'Scotland',
    value: 'Scotland',
  },
  {
    label: 'Northern Ireland',
    value: 'Northern Ireland',
  },
]

export const nationalityOptions = [
  {
    label: 'Afghan',
    value: 'Afghan',
  },
  {
    label: 'Albanian',
    value: 'Albanian',
  },
  {
    label: 'Algerian',
    value: 'Algerian',
  },
  {
    label: 'American',
    value: 'American',
  },
  {
    label: 'Andorran',
    value: 'Andorran',
  },
  {
    label: 'Angolan',
    value: 'Angolan',
  },
  {
    label: 'Antiguan and Barbudan',
    value: 'Antiguan and Barbudan',
  },
  {
    label: 'Argentine',
    value: 'Argentine',
  },
  {
    label: 'Armenian',
    value: 'Armenian',
  },
  {
    label: 'Australian',
    value: 'Australian',
  },
  {
    label: 'Austrian',
    value: 'Austrian',
  },
  {
    label: 'Azerbaijani',
    value: 'Azerbaijani',
  },
  {
    label: 'Bahamian',
    value: 'Bahamian',
  },
  {
    label: 'Bahraini',
    value: 'Bahraini',
  },
  {
    label: 'Bangladeshi',
    value: 'Bangladeshi',
  },
  {
    label: 'Barbadian',
    value: 'Barbadian',
  },
  {
    label: 'Belarusian',
    value: 'Belarusian',
  },
  {
    label: 'Belgian',
    value: 'Belgian',
  },
  {
    label: 'Belizean',
    value: 'Belizean',
  },
  {
    label: 'Beninese',
    value: 'Beninese',
  },
  {
    label: 'Bhutanese',
    value: 'Bhutanese',
  },
  {
    label: 'Bolivian',
    value: 'Bolivian',
  },
  {
    label: 'Bosnian and Herzegovinian',
    value: 'Bosnian and Herzegovinian',
  },
  {
    label: 'Botswanan',
    value: 'Botswanan',
  },
  {
    label: 'Brazilian',
    value: 'Brazilian',
  },
  {
    label: 'British',
    value: 'British',
  },
  {
    label: 'Bruneian',
    value: 'Bruneian',
  },
  {
    label: 'Bulgarian',
    value: 'Bulgarian',
  },
  {
    label: 'Burkinabe',
    value: 'Burkinabe',
  },
  {
    label: 'Burmese',
    value: 'Burmese',
  },
  {
    label: 'Burundian',
    value: 'Burundian',
  },
  {
    label: 'Cambodian',
    value: 'Cambodian',
  },
  {
    label: 'Cameroonian',
    value: 'Cameroonian',
  },
  {
    label: 'Canadian',
    value: 'Canadian',
  },
  {
    label: 'Cape Verdean',
    value: 'Cape Verdean',
  },
  {
    label: 'Central African',
    value: 'Central African',
  },
  {
    label: 'Chadian',
    value: 'Chadian',
  },
  {
    label: 'Chilean',
    value: 'Chilean',
  },
  {
    label: 'Chinese',
    value: 'Chinese',
  },
  {
    label: 'Colombian',
    value: 'Colombian',
  },
  {
    label: 'Comorian',
    value: 'Comorian',
  },
  {
    label: 'Congolese (DRC)',
    value: 'Congolese (DRC)',
  },
  {
    label: 'Congolese (Republic)',
    value: 'Congolese (Republic)',
  },
  {
    label: 'Costa Rican',
    value: 'Costa Rican',
  },
  {
    label: 'Croatian',
    value: 'Croatian',
  },
  {
    label: 'Cuban',
    value: 'Cuban',
  },
  {
    label: 'Cypriot',
    value: 'Cypriot',
  },
  {
    label: 'Czech',
    value: 'Czech',
  },
  {
    label: 'Danish',
    value: 'Danish',
  },
  {
    label: 'Djiboutian',
    value: 'Djiboutian',
  },
  {
    label: 'Dominican (Republic)',
    value: 'Dominican (Republic)',
  },
  {
    label: 'Dominican (Commonwealth)',
    value: 'Dominican (Commonwealth)',
  },
  {
    label: 'Dutch',
    value: 'Dutch',
  },
  {
    label: 'East Timorese',
    value: 'East Timorese',
  },
  {
    label: 'Ecuadorian',
    value: 'Ecuadorian',
  },
  {
    label: 'Egyptian',
    value: 'Egyptian',
  },
  {
    label: 'Emirati',
    value: 'Emirati',
  },
  {
    label: 'Equatorial Guinean',
    value: 'Equatorial Guinean',
  },
  {
    label: 'Eritrean',
    value: 'Eritrean',
  },
  {
    label: 'Estonian',
    value: 'Estonian',
  },
  {
    label: 'Ethiopian',
    value: 'Ethiopian',
  },
  {
    label: 'Fijian',
    value: 'Fijian',
  },
  {
    label: 'Filipino',
    value: 'Filipino',
  },
  {
    label: 'Finnish',
    value: 'Finnish',
  },
  {
    label: 'French',
    value: 'French',
  },
  {
    label: 'Gabonese',
    value: 'Gabonese',
  },
  {
    label: 'Gambian',
    value: 'Gambian',
  },
  {
    label: 'Georgian',
    value: 'Georgian',
  },
  {
    label: 'German',
    value: 'German',
  },
  {
    label: 'Ghanaian',
    value: 'Ghanaian',
  },
  {
    label: 'Greek',
    value: 'Greek',
  },
  {
    label: 'Grenadian',
    value: 'Grenadian',
  },
  {
    label: 'Guatemalan',
    value: 'Guatemalan',
  },
  {
    label: 'Guinea-Bissauan',
    value: 'Guinea-Bissauan',
  },
  {
    label: 'Guinean',
    value: 'Guinean',
  },
  {
    label: 'Guyanese',
    value: 'Guyanese',
  },
  {
    label: 'Haitian',
    value: 'Haitian',
  },
  {
    label: 'Honduran',
    value: 'Honduran',
  },
  {
    label: 'Hungarian',
    value: 'Hungarian',
  },
  {
    label: 'Icelander',
    value: 'Icelander',
  },
  {
    label: 'Indian',
    value: 'Indian',
  },
  {
    label: 'Indonesian',
    value: 'Indonesian',
  },
  {
    label: 'Iranian',
    value: 'Iranian',
  },
  {
    label: 'Iraqi',
    value: 'Iraqi',
  },
  {
    label: 'Irish',
    value: 'Irish',
  },
  {
    label: 'Israeli',
    value: 'Israeli',
  },
  {
    label: 'Italian',
    value: 'Italian',
  },
  {
    label: 'Ivorian',
    value: 'Ivorian',
  },
  {
    label: 'Jamaican',
    value: 'Jamaican',
  },
  {
    label: 'Japanese',
    value: 'Japanese',
  },
  {
    label: 'Jordanian',
    value: 'Jordanian',
  },
  {
    label: 'Kazakhstani',
    value: 'Kazakhstani',
  },
  {
    label: 'Kenyan',
    value: 'Kenyan',
  },
  {
    label: 'Kiribati',
    value: 'Kiribati',
  },
  {
    label: 'North Korean',
    value: 'North Korean',
  },
  {
    label: 'South Korean',
    value: 'South Korean',
  },
  {
    label: 'Kuwaiti',
    value: 'Kuwaiti',
  },
  {
    label: 'Kyrgyz',
    value: 'Kyrgyz',
  },
  {
    label: 'Lao',
    value: 'Lao',
  },
  {
    label: 'Latvian',
    value: 'Latvian',
  },
  {
    label: 'Lebanese',
    value: 'Lebanese',
  },
  {
    label: 'Lesothoan',
    value: 'Lesothoan',
  },
  {
    label: 'Liberian',
    value: 'Liberian',
  },
  {
    label: 'Libyan',
    value: 'Libyan',
  },
  {
    label: 'Liechtensteiner',
    value: 'Liechtensteiner',
  },
  {
    label: 'Lithuanian',
    value: 'Lithuanian',
  },
  {
    label: 'Luxembourger',
    value: 'Luxembourger',
  },
  {
    label: 'Macedonian',
    value: 'Macedonian',
  },
  {
    label: 'Malagasy',
    value: 'Malagasy',
  },
  {
    label: 'Malawian',
    value: 'Malawian',
  },
  {
    label: 'Malaysian',
    value: 'Malaysian',
  },
  {
    label: 'Maldivian',
    value: 'Maldivian',
  },
  {
    label: 'Malian',
    value: 'Malian',
  },
  {
    label: 'Maltese',
    value: 'Maltese',
  },
  {
    label: 'Marshallese',
    value: 'Marshallese',
  },
  {
    label: 'Mauritanian',
    value: 'Mauritanian',
  },
  {
    label: 'Mauritian',
    value: 'Mauritian',
  },
  {
    label: 'Mexican',
    value: 'Mexican',
  },
  {
    label: 'Micronesian',
    value: 'Micronesian',
  },
  {
    label: 'Moldovan',
    value: 'Moldovan',
  },
  {
    label: 'Monacan',
    value: 'Monacan',
  },
  {
    label: 'Mongolian',
    value: 'Mongolian',
  },
  {
    label: 'Montenegrin',
    value: 'Montenegrin',
  },
  {
    label: 'Moroccan',
    value: 'Moroccan',
  },
  {
    label: 'Mozambican',
    value: 'Mozambican',
  },
  {
    label: 'Namibian',
    value: 'Namibian',
  },
  {
    label: 'Nauruan',
    value: 'Nauruan',
  },
  {
    label: 'Nepalese',
    value: 'Nepalese',
  },
  {
    label: 'New Zealander',
    value: 'New Zealander',
  },
  {
    label: 'Nicaraguan',
    value: 'Nicaraguan',
  },
  {
    label: 'Nigerien',
    value: 'Nigerien',
  },
  {
    label: 'Nigerian',
    value: 'Nigerian',
  },
  {
    label: 'Norwegian',
    value: 'Norwegian',
  },
  {
    label: 'Omani',
    value: 'Omani',
  },
  {
    label: 'Pakistani',
    value: 'Pakistani',
  },
  {
    label: 'Palauan',
    value: 'Palauan',
  },
  {
    label: 'Panamanian',
    value: 'Panamanian',
  },
  {
    label: 'Papua New Guinean',
    value: 'Papua New Guinean',
  },
  {
    label: 'Paraguayan',
    value: 'Paraguayan',
  },
  {
    label: 'Peruvian',
    value: 'Peruvian',
  },
  {
    label: 'Polish',
    value: 'Polish',
  },
  {
    label: 'Portuguese',
    value: 'Portuguese',
  },
  {
    label: 'Qatari',
    value: 'Qatari',
  },
  {
    label: 'Romanian',
    value: 'Romanian',
  },
  {
    label: 'Russian',
    value: 'Russian',
  },
  {
    label: 'Rwandan',
    value: 'Rwandan',
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'Saint Kitts and Nevis',
  },
  {
    label: 'Saint Lucian',
    value: 'Saint Lucian',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines',
  },
  {
    label: 'Samoan',
    value: 'Samoan',
  },
  {
    label: 'San Marinese',
    value: 'San Marinese',
  },
  {
    label: 'Sao Tomean',
    value: 'Sao Tomean',
  },
  {
    label: 'Saudi',
    value: 'Saudi',
  },
  {
    label: 'Senegalese',
    value: 'Senegalese',
  },
  {
    label: 'Serbian',
    value: 'Serbian',
  },
  {
    label: 'Seychellois',
    value: 'Seychellois',
  },
  {
    label: 'Sierra Leonean',
    value: 'Sierra Leonean',
  },
  {
    label: 'Singaporean',
    value: 'Singaporean',
  },
  {
    label: 'Slovak',
    value: 'Slovak',
  },
  {
    label: 'Slovenian',
    value: 'Slovenian',
  },
  {
    label: 'Solomon Islander',
    value: 'Solomon Islander',
  },
  {
    label: 'Somali',
    value: 'Somali',
  },
  {
    label: 'South African',
    value: 'South African',
  },
  {
    label: 'South Sudanese',
    value: 'South Sudanese',
  },
  {
    label: 'Spanish',
    value: 'Spanish',
  },
  {
    label: 'Sri Lankan',
    value: 'Sri Lankan',
  },
  {
    label: 'Sudanese',
    value: 'Sudanese',
  },
  {
    label: 'Surinamese',
    value: 'Surinamese',
  },
  {
    label: 'Swazi',
    value: 'Swazi',
  },
  {
    label: 'Swedish',
    value: 'Swedish',
  },
  {
    label: 'Swiss',
    value: 'Swiss',
  },
  {
    label: 'Syrian',
    value: 'Syrian',
  },
  {
    label: 'Taiwanese',
    value: 'Taiwanese',
  },
  {
    label: 'Tajik',
    value: 'Tajik',
  },
  {
    label: 'Tanzanian',
    value: 'Tanzanian',
  },
  {
    label: 'Thai',
    value: 'Thai',
  },
  {
    label: 'Togolese',
    value: 'Togolese',
  },
  {
    label: 'Tongan',
    value: 'Tongan',
  },
  {
    label: 'Trinidadian or Tobagonian',
    value: 'Trinidadian or Tobagonian',
  },
  {
    label: 'Tunisian',
    value: 'Tunisian',
  },
  {
    label: 'Turkish',
    value: 'Turkish',
  },
  {
    label: 'Tuvaluan',
    value: 'Tuvaluan',
  },
  {
    label: 'Ugandan',
    value: 'Ugandan',
  },
  {
    label: 'Ukrainian',
    value: 'Ukrainian',
  },
  {
    label: 'Uruguayan',
    value: 'Uruguayan',
  },
  {
    label: 'Uzbek',
    value: 'Uzbek',
  },
  {
    label: 'Vanuatuan',
    value: 'Vanuatuan',
  },
  {
    label: 'Venezuelan',
    value: 'Venezuelan',
  },
  {
    label: 'Vietnamese',
    value: 'Vietnamese',
  },
  {
    label: 'Yemeni',
    value: 'Yemeni',
  },
  {
    label: 'Zambian',
    value: 'Zambian',
  },
  {
    label: 'Zimbabwean',
    value: 'Zimbabwean',
  },
]

export const countryOptions = [
  {
    label: 'Afghanistan',
    value: 'Afghanistan',
  },
  {
    label: 'Albania',
    value: 'Albania',
  },
  {
    label: 'Algeria',
    value: 'Algeria',
  },
  {
    label: 'United States',
    value: 'United States',
  },
  {
    label: 'Andorra',
    value: 'Andorra',
  },
  {
    label: 'Angola',
    value: 'Angola',
  },
  {
    label: 'Antigua and Barbuda',
    value: 'Antigua and Barbuda',
  },
  {
    label: 'Argentina',
    value: 'Argentina',
  },
  {
    label: 'Armenia',
    value: 'Armenia',
  },
  {
    label: 'Australia',
    value: 'Australia',
  },
  {
    label: 'Austria',
    value: 'Austria',
  },
  {
    label: 'Azerbaijan',
    value: 'Azerbaijan',
  },
  {
    label: 'Bahamas',
    value: 'Bahamas',
  },
  {
    label: 'Bahrain',
    value: 'Bahrain',
  },
  {
    label: 'Bangladesh',
    value: 'Bangladesh',
  },
  {
    label: 'Barbados',
    value: 'Barbados',
  },
  {
    label: 'Belarus',
    value: 'Belarus',
  },
  {
    label: 'Belgium',
    value: 'Belgium',
  },
  {
    label: 'Belize',
    value: 'Belize',
  },
  {
    label: 'Benin',
    value: 'Benin',
  },
  {
    label: 'Bhutan',
    value: 'Bhutan',
  },
  {
    label: 'Bolivia',
    value: 'Bolivia',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'Bosnia and Herzegovina',
  },
  {
    label: 'Botswana',
    value: 'Botswana',
  },
  {
    label: 'Brazil',
    value: 'Brazil',
  },
  {
    label: 'United Kingdom',
    value: 'United Kingdom',
  },
  {
    label: 'Brunei',
    value: 'Brunei',
  },
  {
    label: 'Bulgaria',
    value: 'Bulgaria',
  },
  {
    label: 'Burkina Faso',
    value: 'Burkina Faso',
  },
  {
    label: 'Burma',
    value: 'Burma',
  },
  {
    label: 'Burundi',
    value: 'Burundi',
  },
  {
    label: 'Cambodia',
    value: 'Cambodia',
  },
  {
    label: 'Cameroon',
    value: 'Cameroon',
  },
  {
    label: 'Canada',
    value: 'Canada',
  },
  {
    label: 'Cape Verde',
    value: 'Cape Verde',
  },
  {
    label: 'Central African Republic',
    value: 'Central African Republic',
  },
  {
    label: 'Chad',
    value: 'Chad',
  },
  {
    label: 'Chile',
    value: 'Chile',
  },
  {
    label: 'China',
    value: 'China',
  },
  {
    label: 'Colombia',
    value: 'Colombia',
  },
  {
    label: 'Comoros',
    value: 'Comoros',
  },
  {
    label: 'Democratic Republic of the Congo',
    value: 'Democratic Republic of the Congo',
  },
  {
    label: 'Republic of the Congo',
    value: 'Republic of the Congo',
  },
  {
    label: 'Costa Rica',
    value: 'Costa Rica',
  },
  {
    label: 'Croatia',
    value: 'Croatia',
  },
  {
    label: 'Cuba',
    value: 'Cuba',
  },
  {
    label: 'Cyprus',
    value: 'Cyprus',
  },
  {
    label: 'Czech Republic',
    value: 'Czech Republic',
  },
  {
    label: 'Denmark',
    value: 'Denmark',
  },
  {
    label: 'Djibouti',
    value: 'Djibouti',
  },
  {
    label: 'Dominican Republic',
    value: 'Dominican Republic',
  },
  {
    label: 'Commonwealth of Dominica',
    value: 'Commonwealth of Dominica',
  },
  {
    label: 'Netherlands',
    value: 'Netherlands',
  },
  {
    label: 'East Timor',
    value: 'East Timor',
  },
  {
    label: 'Ecuador',
    value: 'Ecuador',
  },
  {
    label: 'Egypt',
    value: 'Egypt',
  },
  {
    label: 'El Salvador',
    value: 'El Salvador',
  },
  {
    label: 'Equatorial Guinea',
    value: 'Equatorial Guinea',
  },
  {
    label: 'Eritrea',
    value: 'Eritrea',
  },
  {
    label: 'Estonia',
    value: 'Estonia',
  },
  {
    label: 'Ethiopia',
    value: 'Ethiopia',
  },
  {
    label: 'Fiji',
    value: 'Fiji',
  },
  {
    label: 'Finland',
    value: 'Finland',
  },
  {
    label: 'France',
    value: 'France',
  },
  {
    label: 'Gabon',
    value: 'Gabon',
  },
  {
    label: 'Gambia',
    value: 'Gambia',
  },
  {
    label: 'Georgia',
    value: 'Georgia',
  },
  {
    label: 'Germany',
    value: 'Germany',
  },
  {
    label: 'Ghana',
    value: 'Ghana',
  },
  {
    label: 'Greece',
    value: 'Greece',
  },
  {
    label: 'Grenada',
    value: 'Grenada',
  },
  {
    label: 'Guatemala',
    value: 'Guatemala',
  },
  {
    label: 'Guinea',
    value: 'Guinea',
  },
  {
    label: 'Guinea-Bissau',
    value: 'Guinea-Bissau',
  },
  {
    label: 'Guyana',
    value: 'Guyana',
  },
  {
    label: 'Haiti',
    value: 'Haiti',
  },
  {
    label: 'Honduras',
    value: 'Honduras',
  },
  {
    label: 'Hungary',
    value: 'Hungary',
  },
  {
    label: 'Iceland',
    value: 'Iceland',
  },
  {
    label: 'India',
    value: 'India',
  },
  {
    label: 'Indonesia',
    value: 'Indonesia',
  },
  {
    label: 'Iran',
    value: 'Iran',
  },
  {
    label: 'Iraq',
    value: 'Iraq',
  },
  {
    label: 'Ireland',
    value: 'Ireland',
  },
  {
    label: 'Israel',
    value: 'Israel',
  },
  {
    label: 'Italy',
    value: 'Italy',
  },
  {
    label: 'Ivory Coast',
    value: 'Ivory Coast',
  },
  {
    label: 'Jamaica',
    value: 'Jamaica',
  },
  {
    label: 'Japan',
    value: 'Japan',
  },
  {
    label: 'Jordan',
    value: 'Jordan',
  },
  {
    label: 'Kazakhstan',
    value: 'Kazakhstan',
  },
  {
    label: 'Kenya',
    value: 'Kenya',
  },
  {
    label: 'Kiribati',
    value: 'Kiribati',
  },
  {
    label: 'North Korea',
    value: 'North Korea',
  },
  {
    label: 'South Korea',
    value: 'South Korea',
  },
  {
    label: 'Kuwait',
    value: 'Kuwait',
  },
  {
    label: 'Kyrgyzstan',
    value: 'Kyrgyzstan',
  },
  {
    label: 'Laos',
    value: 'Laos',
  },
  {
    label: 'Latvia',
    value: 'Latvia',
  },
  {
    label: 'Lebanon',
    value: 'Lebanon',
  },
  {
    label: 'Lesotho',
    value: 'Lesotho',
  },
  {
    label: 'Liberia',
    value: 'Liberia',
  },
  {
    label: 'Libya',
    value: 'Libya',
  },
  {
    label: 'Liechtenstein',
    value: 'Liechtenstein',
  },
  {
    label: 'Lithuania',
    value: 'Lithuania',
  },
  {
    label: 'Luxembourg',
    value: 'Luxembourg',
  },
  {
    label: 'North Macedonia',
    value: 'North Macedonia',
  },
  {
    label: 'Madagascar',
    value: 'Madagascar',
  },
  {
    label: 'Malawi',
    value: 'Malawi',
  },
  {
    label: 'Malaysia',
    value: 'Malaysia',
  },
  {
    label: 'Maldives',
    value: 'Maldives',
  },
  {
    label: 'Mali',
    value: 'Mali',
  },
  {
    label: 'Malta',
    value: 'Malta',
  },
  {
    label: 'Marshall Islands',
    value: 'Marshall Islands',
  },
  {
    label: 'Mauritania',
    value: 'Mauritania',
  },
  {
    label: 'Mauritius',
    value: 'Mauritius',
  },
  {
    label: 'Mexico',
    value: 'Mexico',
  },
  {
    label: 'Micronesia',
    value: 'Micronesia',
  },
  {
    label: 'Moldova',
    value: 'Moldova',
  },
  {
    label: 'Monaco',
    value: 'Monaco',
  },
  {
    label: 'Mongolia',
    value: 'Mongolia',
  },
  {
    label: 'Montenegro',
    value: 'Montenegro',
  },
  {
    label: 'Morocco',
    value: 'Morocco',
  },
  {
    label: 'Mozambique',
    value: 'Mozambique',
  },
  {
    label: 'Namibia',
    value: 'Namibia',
  },
  {
    label: 'Nauru',
    value: 'Nauru',
  },
  {
    label: 'Nepal',
    value: 'Nepal',
  },
  {
    label: 'New Zealand',
    value: 'New Zealand',
  },
  {
    label: 'Nicaragua',
    value: 'Nicaragua',
  },
  {
    label: 'Niger',
    value: 'Niger',
  },
  {
    label: 'Nigeria',
    value: 'Nigeria',
  },
  {
    label: 'Norway',
    value: 'Norway',
  },
  {
    label: 'Oman',
    value: 'Oman',
  },
  {
    label: 'Pakistan',
    value: 'Pakistan',
  },
  {
    label: 'Palau',
    value: 'Palau',
  },
  {
    label: 'Palestine',
    value: 'Palestine',
  },
  {
    label: 'Panama',
    value: 'Panama',
  },
  {
    label: 'Papua New Guinea',
    value: 'Papua New Guinea',
  },
  {
    label: 'Paraguay',
    value: 'Paraguay',
  },
  {
    label: 'Peru',
    value: 'Peru',
  },
  {
    label: 'Philippines',
    value: 'Philippines',
  },
  {
    label: 'Poland',
    value: 'Poland',
  },
  {
    label: 'Portugal',
    value: 'Portugal',
  },
  {
    label: 'Qatar',
    value: 'Qatar',
  },
  {
    label: 'Romania',
    value: 'Romania',
  },
  {
    label: 'Russia',
    value: 'Russia',
  },
  {
    label: 'Rwanda',
    value: 'Rwanda',
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'Saint Kitts and Nevis',
  },
  {
    label: 'Saint Lucia',
    value: 'Saint Lucia',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines',
  },
  {
    label: 'Samoa',
    value: 'Samoa',
  },
  {
    label: 'San Marino',
    value: 'San Marino',
  },
  {
    label: 'Sao Tome and Principe',
    value: 'Sao Tome and Principe',
  },
  {
    label: 'Saudi Arabia',
    value: 'Saudi Arabia',
  },
  {
    label: 'Senegal',
    value: 'Senegal',
  },
  {
    label: 'Serbia',
    value: 'Serbia',
  },
  {
    label: 'Seychelles',
    value: 'Seychelles',
  },
  {
    label: 'Sierra Leone',
    value: 'Sierra Leone',
  },
  {
    label: 'Singapore',
    value: 'Singapore',
  },
  {
    label: 'Slovakia',
    value: 'Slovakia',
  },
  {
    label: 'Slovenia',
    value: 'Slovenia',
  },
  {
    label: 'Solomon Islands',
    value: 'Solomon Islands',
  },
  {
    label: 'Somalia',
    value: 'Somalia',
  },
  {
    label: 'South Africa',
    value: 'South Africa',
  },
  {
    label: 'South Sudan',
    value: 'South Sudan',
  },
  {
    label: 'Spain',
    value: 'Spain',
  },
  {
    label: 'Sri Lanka',
    value: 'Sri Lanka',
  },
  {
    label: 'Sudan',
    value: 'Sudan',
  },
  {
    label: 'Suriname',
    value: 'Suriname',
  },
  {
    label: 'Eswatini',
    value: 'Eswatini',
  },
  {
    label: 'Sweden',
    value: 'Sweden',
  },
  {
    label: 'Switzerland',
    value: 'Switzerland',
  },
  {
    label: 'Syria',
    value: 'Syria',
  },
  {
    label: 'Taiwan',
    value: 'Taiwan',
  },
  {
    label: 'Tajikistan',
    value: 'Tajikistan',
  },
  {
    label: 'Tanzania',
    value: 'Tanzania',
  },
  {
    label: 'Thailand',
    value: 'Thailand',
  },
  {
    label: 'Togo',
    value: 'Togo',
  },
  {
    label: 'Tonga',
    value: 'Tonga',
  },
  {
    label: 'Trinidad and Tobago',
    value: 'Trinidad and Tobago',
  },
  {
    label: 'Tunisia',
    value: 'Tunisia',
  },
  {
    label: 'Turkey',
    value: 'Turkey',
  },
  {
    label: 'Turkmenistan',
    value: 'Turkmenistan',
  },
  {
    label: 'Tuvalu',
    value: 'Tuvalu',
  },
  {
    label: 'Uganda',
    value: 'Uganda',
  },
  {
    label: 'Ukraine',
    value: 'Ukraine',
  },
  {
    label: 'United Arab Emirates',
    value: 'United Arab Emirates',
  },
  {
    label: 'United Kingdom',
    value: 'United Kingdom',
  },
  {
    label: 'United States',
    value: 'United States',
  },
  {
    label: 'Uruguay',
    value: 'Uruguay',
  },
  {
    label: 'Uzbekistan',
    value: 'Uzbekistan',
  },
  {
    label: 'Vanuatu',
    value: 'Vanuatu',
  },
  {
    label: 'Vatican City',
    value: 'Vatican City',
  },
  {
    label: 'Venezuela',
    value: 'Venezuela',
  },
  {
    label: 'Vietnam',
    value: 'Vietnam',
  },
  {
    label: 'Yemen',
    value: 'Yemen',
  },
  {
    label: 'Zambia',
    value: 'Zambia',
  },
  {
    label: 'Zimbabwe',
    value: 'Zimbabwe',
  },
]

export const periodOptions = [
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Annually',
    value: 'annually',
  },
]
