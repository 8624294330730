import { createContext } from 'react'

export interface ILayoutContext {
  sideBarWidth: number
  setSideBarWidth: any

  collapsed: boolean
  setCollapsed: any
}
const context: ILayoutContext = {
  sideBarWidth: 250,
  setSideBarWidth: () => null,

  collapsed: false,
  setCollapsed: () => null,
}

export default createContext(context)
