import styled from 'styled-components';

export const StyledActionsButtonsContainer = styled.div`
  cursor: pointer;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;

  width: 48px;
  height: 48px;

  border: 2px solid #90258c;
  border-radius: 4px;
`;

export const StyledActionsDots = styled.img``;

export const StyledMenu = styled.div`
  display: grid;
  justify-items: center;
  justify-content: flex-start;

  background-color: #fff;
  padding: 12px 16px;
  border-radius: 5px;
  box-shadow: 0 1px 5px 1px rgba(224, 224, 224, 1);
  gap: 8px;

  width: 160px;
  /* height: 128px; */
`;

export const StyledItem = styled.p`
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  justify-self: flex-start;

  color: #120224;
`;
