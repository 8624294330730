export const requiredFundingOptions = [
  {
    label: 'Roll up',
    value: 'rollUp',
  },
  {
    label: 'Interest only / servicing',
    value: 'interestOnlyServicing',
  },
]

export const interestServicingLevelOptions = [
  {
    label: 'Full interest amount',
    value: 'fullInterestAmount',
  },
  {
    label: 'Specified percentage of interest',
    value: 'specifiedPercentageOfInterest',
  },
]

export const residenceTypeOptions = [
  {
    label: 'Main Residence',
    value: 'Main Residence',
  },
  {
    label: 'Retirement Or Age Restricted Property',
    value: 'Retirement Or Age Restricted Property',
  },
  {
    label: 'Second Or Holiday Home',
    value: 'Second Or Holiday Home',
  },
  {
    label: 'Investment Property',
    value: 'Investment Property',
  },
]

export const earlyRepaymentChargeOptions = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Fixed',
    value: 'fixed',
  },
  {
    label: 'Variable',
    value: 'variable',
  },
]
