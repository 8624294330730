import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { FormsContext } from 'contexts'

import { initialGeneralTabItemsData, initialBreadcrumbData } from './staticData'
import { useCustomMutation } from 'services/shared/mutation'
import { DeleteClientsPayload } from 'services/shared/mutation.payload'
import { deleteClientsMutation } from 'gql/client'
import { commonHelper } from 'helpers'
import { message } from 'antd'
import { eventManager } from 'utils'

interface IFormStepsHistory {
  text: string
  displayForm: boolean
}

interface IItemClickProps {
  label: string
  hasChildren: boolean
  children?: any
}

const useClientsDetails = () => {
  const {
    generalTabItems,
    setGeneralTabItems,
    detailedFormName,
    setDetailedFormName,
    antdActiveTabKey,
    setAntdActiveTabKey,
    displayDetailForm,
    setDisplayDetailForm,
    breadcrumbItems,
    setBreadcrumbItems,
  } = useContext(FormsContext)
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(window.location.search)
  const idNumber = Number(urlParams.get('idNumber'))
  const deleteClients = useCustomMutation<DeleteClientsPayload>({ mutation: deleteClientsMutation })

  useEffect(() => {
    setDisplayDetailForm(false)

    return () => {
      // Note: this will be used to set the breadcrumb items initial value on dismount
      setGeneralTabItems(initialGeneralTabItemsData)
      setBreadcrumbItems(initialBreadcrumbData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTabChange = (key: string) => {
    if (key === '1') {
      setGeneralTabItems(initialGeneralTabItemsData)
      setBreadcrumbItems(initialBreadcrumbData)
      setDisplayDetailForm(false)
    }

    if (key === '4') {
      setBreadcrumbItems(initialBreadcrumbData)
      setDisplayDetailForm(false)
    }

    setAntdActiveTabKey(key)
  }

  const handleNewQuote = () => {
    localStorage.removeItem('quickSearch')
    navigate(`/quick-search?idNumber=${idNumber}`)
  }

  const [formStepsHistory, setFormStepsHistory] = useState<IFormStepsHistory[]>([
    {
      text: 'Home',
      displayForm: false,
    },
  ])

  const handleBreadcrumbTabChange = (e: React.MouseEvent<HTMLElement>) => {
    const itemText = (e.target as HTMLElement).innerHTML
    if (itemText === 'Home') {
      setGeneralTabItems(initialGeneralTabItemsData)
    }
    if (itemText !== detailedFormName) {
      setDisplayDetailForm(false)
    }
    let itemIndex = 1
    // eslint-disable-next-line array-callback-return
    breadcrumbItems.map((item, index) => {
      if (item.text === itemText) {
        itemIndex = index
      }
    })
    const newItems = breadcrumbItems.slice(0, itemIndex + 1)
    setBreadcrumbItems(newItems)

    let currentIndex = 0
    // eslint-disable-next-line array-callback-return
    formStepsHistory.map((item, index) => {
      if (item.text === itemText) {
        currentIndex = index
      }
    })
    const tabOptions = formStepsHistory[currentIndex]

    setDisplayDetailForm(tabOptions.displayForm)
    setDetailedFormName(tabOptions.text)
    // Note: removes every item history from breadcrumb after current tab
    setFormStepsHistory((prevState) => [...prevState.slice(0, currentIndex + 1)])
  }

  const pushNewItemToBreadcrumb = (text: string) => {
    setBreadcrumbItems([
      ...breadcrumbItems,
      {
        key: `${breadcrumbItems.length + 1}`,
        text,
      },
    ])
  }

  const handleItemClick = (item: IItemClickProps) => {
    pushNewItemToBreadcrumb(item.label)
    setDetailedFormName(item.label)

    if (item.hasChildren) {
      setGeneralTabItems(item.children)

      setFormStepsHistory((prevState) => [
        ...prevState,
        {
          text: item.label,
          displayForm: false,
        },
      ])
    }

    if (!item.hasChildren) {
      setDisplayDetailForm(true)

      setFormStepsHistory((prevState) => [
        ...prevState,
        {
          text: item.label,
          displayForm: true,
        },
      ])
    }
  }

  const handleDetailedFormClose = () => {
    // Note: removes opened form name from breadcrumb when closing
    setBreadcrumbItems(breadcrumbItems.slice(0, breadcrumbItems.length - 1))
    let currentIndex = 0
    // eslint-disable-next-line array-callback-return
    formStepsHistory.map((item, index) => {
      if (item.text === detailedFormName) {
        currentIndex = index
      }
    })
    // Note: finds the index of the form which will be opened to set its options
    const prevTabOptions = formStepsHistory[currentIndex - 1]

    setDisplayDetailForm(prevTabOptions.displayForm)
    setDetailedFormName(prevTabOptions.text)
    // Note: removes one element from history
    setFormStepsHistory((prevState) => [...prevState.slice(0, formStepsHistory.length - 1)])
  }

  const handleDelete = async () => {
    const { errors } = await deleteClients({ idNumbers: [String(idNumber)] })
    if (errors?.length) {
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('Deleted successfully')
    navigate('/clients')
  }

  const handleDeleteModal = () => {
    eventManager.openConfirmDeleteModal(
      { header: 'Do you really want to delete? This process can’t be undone.' },
      {
        confirm: () => handleDelete(),
      },
    )
  }

  return {
    idNumber,
    antdActiveTabKey,
    handleTabChange,
    handleNewQuote,

    // Note: general tab
    handleDetailedFormClose,
    displayDetailForm,
    generalTabItems,
    breadcrumbItems,
    handleBreadcrumbTabChange,
    handleItemClick,
    detailedFormName,

    handleDeleteModal,
  }
}

export default useClientsDetails
