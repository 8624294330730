import { useContext, useEffect, useState } from 'react'
import { Form, message } from 'antd'
import { useParams } from 'react-router-dom'

import AuthContext from 'contexts/AuthContext'
import { commonHelper } from 'helpers'

import { useGetCompanies } from 'services/company/company-service'
import { useCustomMutation } from 'services/shared/mutation'
import { EditUserPayload } from 'services/shared/mutation.payload'
import { editUserMutation } from 'gql/user'

const useProfileTabContent = () => {
  const { user, refetch } = useContext(AuthContext)
  const { userId } = useParams()
  const editUser = useCustomMutation<EditUserPayload>({ mutation: editUserMutation })
  const { companies } = useGetCompanies()

  const [companyId, setCompanyId] = useState(user.companyId || '')
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [form] = Form.useForm()
  const [companyUser, setCompanyUser] = useState(false)

  useEffect(() => {
    if (user.role === 'superAdmin') {
      setOptions(
        companies.map((company: any) => ({
          value: company._id,
          label: company.name,
        })),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies])

  const handleSubmit = async () => {
    setLoading(true)

    const formData = form.getFieldsValue(['firstName', 'lastName', 'email', 'phoneNumber'])

    const isEveryFiledFilled = Object.keys(formData).every((fieldKey: string) => !!formData[fieldKey] !== false)
    if (!isEveryFiledFilled) {
      message.info('Please fill out all the fields')
      setLoading(false)
      return
    }
    const fcaNumber = String(form.getFieldValue('fcaNumber'))
    if (!(fcaNumber?.length === 6 || fcaNumber?.length === 7)) {
      message.info('Please check FCA number, it should be 6 or 7 digits')
      setLoading(false)
      return
    }

    const { errors } = await editUser({ ...formData, companyId, fcaNumber: Number(fcaNumber), _id: userId })
    if (errors?.length) {
      setLoading(false)
      const errMessage = commonHelper.parseGraphqlErrorMessage(errors)
      message.error(errMessage)
      return
    }
    message.success('user edited successfully')

    setLoading(false)
    refetch()
  }

  const companyUserChange = (e: any) => {
    setCompanyUser(e.target.checked)
  }

  const handleCompanyChange = (value: string) => {
    setCompanyId(value)
  }

  return { loading, form, companyUser, companyUserChange, handleCompanyChange, options, handleSubmit }
}

export default useProfileTabContent
