import styled from 'styled-components';

export const StyledContainer = styled.div``;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 24px;
`;

export const StyledAddCompanyButton = styled.button`
  width: 200px;
  height: 48px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const StyledGridContainer = styled.div`
  margin-top: 24px;
`;

export const StyledPaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
`;

export const StyledSuspendButton = styled.button`
  width: 200px;
  height: 48px;

  background-color: #fefefe;
  color: #90258c;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;
