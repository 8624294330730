export const unsuccessfulQuotesTabColumns = [
  {
    name: 'provider',
    header: 'Provider',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'product',
    header: 'Product',
    defaultFlex: 1,
    type: 'text',
  },
  {
    name: 'reason',
    header: 'Reason',
    defaultFlex: 2,
    type: 'text',
  },
]
