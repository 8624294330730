import { useQuery } from '@apollo/client'
import { getSignedUrl } from 'gql'

import { userQuery, usersQueryForUsersList, userIressQuery } from 'gql/user'

export const useGetUser = () => {
  const { data, error, loading, refetch } = useQuery(userQuery)
  return {
    user: (data && data.user) || {},
    loading,
    error,
    refetch,
  }
}

export const useGetUserIressDetails = ({ skip }: { skip?: boolean }) => {
  const { data, error, loading, refetch } = useQuery(userIressQuery, {
    skip: skip,
  })
  return {
    user: (data && data.user) || {},
    loading,
    error,
    refetch,
  }
}

interface UseGetUsersProps {
  companyId?: string
  limit?: number
  skip?: number
  searchValue?: string
}

export const useGetUsers = (variables: UseGetUsersProps) => {
  const { data, error, loading, refetch } = useQuery(usersQueryForUsersList, {
    variables,
  })
  return {
    users: data?.getUsers?.users || [],
    count: data?.getUsers?.count || 0,
    loading,
    error,
    refetch,
  }
}

export const useGetUserImageFromS3 = (variables: any) => {
  const { data, error, loading, refetch } = useQuery(getSignedUrl, {
    variables,
  })
  return {
    url: data?.getSignedUrl?.url || '',
    loading,
    error,
    refetch,
  }
}
