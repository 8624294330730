import styled from 'styled-components'

export const StyledContainer = styled.div`
  margin-bottom: 56px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledClientHeaderContainer = styled.div`
  box-sizing: border-box;
  padding: 24px;
  background: #fefefe;
  border-radius: 4px;

  max-width: 100%;
  width: 100%;
  min-height: 112px;
  height: fit-content;

  margin-bottom: 24px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`

export const StyledLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
`

export const StyledRightContainer = styled.div`
  display: flex;
  flex-direction: row !important;
  flex-direction: column;
  justify-content: center;
  column-gap: 20px;
`

export const StyledAvatar = styled.img``

export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const StyledName = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #38003c;
`

export const StyledId = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #38003c;
`

export const StyledNewQuoteButton = styled.button`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 12px 0px;
  width: 140px;
  height: 48px;
`
